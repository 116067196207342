// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg8 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eZcD743TCp61"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 856 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="856"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eZcD743TCp67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp67-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp68-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp68-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp68-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp69-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp610-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp610-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp610-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp610-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp610-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp610-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp610-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp610-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp611-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp611-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp612-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp612-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp612-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp612-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp612-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp612-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp612-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp612-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp613-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp613-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp613-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp614-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp614-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp614-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp614-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp614-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp614-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp614-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp614-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp615-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp615-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp615-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp615-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp615-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp615-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp615-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp615-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp616-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp616-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp616-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp616-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp616-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp616-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp616-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp616-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp617-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp617-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp617-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp617-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp617-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp617-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp617-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp617-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp618-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp618-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp618-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp619-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp619-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp619-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp619-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp619-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp619-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp619-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp619-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp620-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp620-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp620-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp620-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp620-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp620-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp620-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp620-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp621-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp621-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp626-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp626-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp626-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp626-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp626-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp626-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp626-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp626-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp627-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp627-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp627-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp628-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp628-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp628-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp628-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp628-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp628-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp628-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp628-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp629-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp629-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp629-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp629-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp629-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp629-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp629-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp629-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp630-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp630-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp631-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp631-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp631-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp631-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp631-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp631-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp631-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp631-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp632-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp632-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp632-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp633-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp633-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp633-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp633-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp633-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp633-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp633-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp633-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp634-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp634-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp634-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp634-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp634-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp634-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp634-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp634-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp635-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp635-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp641-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp641-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp641-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp641-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp641-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp641-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp641-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp641-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp642-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp642-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp642-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp643-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp643-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp643-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp643-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp643-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp643-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp643-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp643-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp644-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp644-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp644-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp644-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp644-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp644-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp644-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp644-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp645-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp645-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp646-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp646-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp646-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp646-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp646-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp646-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp646-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp646-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp647-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp647-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp647-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp648-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp648-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp648-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp648-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp648-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp648-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp648-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp648-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp649-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp649-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp649-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp649-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp649-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp649-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp649-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp649-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp650-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp650-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp651-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp651-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp651-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp651-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp651-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp651-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp651-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp651-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp652-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp652-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp652-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp653-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp653-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp653-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp653-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp653-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp653-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp653-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp653-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp654-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp654-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp654-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp654-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp654-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp654-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp654-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp654-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp655-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp655-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp660-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp660-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp660-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp660-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp660-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp660-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp660-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp660-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp661-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp661-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp661-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp662-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp662-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp662-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp662-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp662-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp662-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp662-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp662-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp663-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp663-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp663-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp663-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp663-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp663-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp663-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp663-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp664-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp664-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp665-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp665-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp665-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp665-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp665-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp665-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp665-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp665-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp666-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp666-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp666-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp667-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp667-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp667-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp667-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp667-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp667-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp667-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp667-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp668-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp668-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp668-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp668-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp668-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp668-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp668-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp668-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp669-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp669-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp669-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp669-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp669-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp669-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp669-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp669-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp676-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp676-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp676-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp676-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp676-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp676-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp676-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp676-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp677-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp677-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp677-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp678-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp678-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp678-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp678-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp678-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp678-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp678-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp678-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp679-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp679-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp679-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp679-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp679-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp679-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp679-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp679-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp680-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp680-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp681-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp681-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp681-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp681-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp681-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp681-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp681-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp681-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp682-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp682-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp682-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp683-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp683-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp683-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp683-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp683-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp683-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp683-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp683-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp684-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp684-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp684-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp684-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp684-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp684-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp684-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp684-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp685-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp685-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp691-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp691-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp691-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp691-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp691-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp691-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp691-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp691-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp692-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp692-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp692-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp693-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp693-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp693-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp693-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp693-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp693-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp693-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp693-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp694-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp694-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp694-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp694-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp694-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp694-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp694-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp694-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp695-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp695-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp696-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp696-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp696-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp696-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp696-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp696-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp696-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp696-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp697-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp697-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp697-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp698-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp698-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp698-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp698-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp698-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp698-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp698-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp698-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp699-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp699-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp699-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp699-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp699-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp699-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp699-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp699-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6100-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6105-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6105-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp6105-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6105-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp6105-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6105-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6105-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6105-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp6106-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZcD743TCp6106-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eZcD743TCp6106-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZcD743TCp6107-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6107-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6107-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6107-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6107-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6107-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6107-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6107-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6108-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6108-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6108-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6108-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6108-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6108-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6108-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6108-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6109-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6109-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp6110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6110-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp6110-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6110-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp6110-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6110-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6110-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6110-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp6111-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZcD743TCp6111-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eZcD743TCp6111-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZcD743TCp6112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6112-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6113-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6113-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6113-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6113-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6113-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6113-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6113-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6113-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6114-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp6115-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6115-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp6115-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6115-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp6115-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6115-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6115-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6115-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp6116-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZcD743TCp6116-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eZcD743TCp6116-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZcD743TCp6117-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6117-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6117-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6117-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6117-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6117-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6117-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6117-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6118-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6118-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6118-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6118-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6118-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6118-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6118-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6119-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6119-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp6125-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6125-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp6125-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6125-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp6125-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6125-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6125-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6125-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp6126-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp6126-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp6126-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp6127-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6127-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6127-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6127-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6127-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6127-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6127-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6127-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6128-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6128-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="7,7" />
          <feOffset id="eZcD743TCp6128-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6128-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6128-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6128-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6128-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6128-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6129-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZcD743TCp6130-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6130-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZcD743TCp6130-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6130-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZcD743TCp6130-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6130-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6130-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6130-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZcD743TCp6131-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZcD743TCp6131-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZcD743TCp6131-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZcD743TCp6132-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6132-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6132-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6132-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6132-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6132-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6132-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6132-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6133-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6133-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZcD743TCp6133-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZcD743TCp6133-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZcD743TCp6133-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZcD743TCp6133-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZcD743TCp6133-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZcD743TCp6133-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZcD743TCp6134-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZcD743TCp6134-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#eZcD743TCp6136)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-12.911987 1.012683)">
            <g transform="translate(-15 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp67-filter)">
                <path
                  id="eZcD743TCp68"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eZcD743TCp68-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp69_to" transform="translate(832.121519,-4.482748)">
                  <g id="eZcD743TCp69_tr" transform="rotate(-75)">
                    <g id="eZcD743TCp69_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eZcD743TCp69"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eZcD743TCp69-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp610_to" transform="translate(826.798304,-5.828881)">
                  <g id="eZcD743TCp610_tr" transform="rotate(-75)">
                    <g id="eZcD743TCp610_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eZcD743TCp610"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eZcD743TCp610-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp611_to" transform="translate(826.568884,-6.23967)">
                  <g id="eZcD743TCp611_tr" transform="rotate(-75)">
                    <rect
                      id="eZcD743TCp611"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eZcD743TCp611-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp612-filter)">
                <path
                  id="eZcD743TCp613"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp613-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp614_to" transform="translate(917.798427,143.771297)">
                  <g id="eZcD743TCp614_tr" transform="rotate(0)">
                    <g id="eZcD743TCp614_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp614"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp614-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp615_to" transform="translate(917.798427,137.271297)">
                  <g id="eZcD743TCp615_tr" transform="rotate(0)">
                    <g id="eZcD743TCp615_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp615"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp615-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp616_to" transform="translate(917.798427,137.771297)">
                  <g id="eZcD743TCp616_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp616"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp616-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp617-filter)">
                <path
                  id="eZcD743TCp618"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp618-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp619_to" transform="translate(917.798427,143.771297)">
                  <g id="eZcD743TCp619_tr" transform="rotate(0)">
                    <g id="eZcD743TCp619_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp619"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp619-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp620_to" transform="translate(917.798427,137.271297)">
                  <g id="eZcD743TCp620_tr" transform="rotate(0)">
                    <g id="eZcD743TCp620_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp620"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp620-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp621_to" transform="translate(917.798427,140.771297)">
                  <g id="eZcD743TCp621_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp621"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp621-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-30 0)" mask="url(#eZcD743TCp637)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp626-filter)">
                  <path
                    id="eZcD743TCp627"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp627-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp628_to" transform="translate(846.5,55.771297)">
                    <g id="eZcD743TCp628_tr" transform="rotate(0)">
                      <g id="eZcD743TCp628_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp628"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp628-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp629_to" transform="translate(846.5,49.271297)">
                    <g id="eZcD743TCp629_tr" transform="rotate(0)">
                      <g id="eZcD743TCp629_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp629"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp629-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp630_to" transform="translate(846.5,51.771297)">
                    <g id="eZcD743TCp630_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp630"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp630-filter)"
                        fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp631-filter)">
                  <path
                    id="eZcD743TCp632"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp632-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp633_to" transform="translate(846.5,55.771297)">
                    <g id="eZcD743TCp633_tr" transform="rotate(0)">
                      <g id="eZcD743TCp633_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp633"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp633-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp634_to" transform="translate(846.5,49.271297)">
                    <g id="eZcD743TCp634_tr" transform="rotate(0)">
                      <g id="eZcD743TCp634_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp634"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp634-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp635_to" transform="translate(846.5,50.771297)">
                    <g id="eZcD743TCp635_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp635"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp635-filter)"
                        fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZcD743TCp637" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 605.931986-1.012683)"
                  fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-43.999999 0.624996)">
              <path
                d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp641-filter)">
                <path
                  id="eZcD743TCp642"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eZcD743TCp642-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp643_to" transform="translate(656.9,-33.549858)">
                  <rect
                    id="eZcD743TCp643"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#eZcD743TCp643-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZcD743TCp644_to" transform="translate(656.9,-37.443085)">
                  <rect
                    id="eZcD743TCp644"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#eZcD743TCp644-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZcD743TCp645_to" transform="translate(656.9,-38.549858)">
                  <rect
                    id="eZcD743TCp645"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#eZcD743TCp645-filter)"
                    fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp646-filter)">
                <path
                  id="eZcD743TCp647"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp647-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp648_to" transform="translate(786.798427,143.771297)">
                  <g id="eZcD743TCp648_tr" transform="rotate(0)">
                    <g id="eZcD743TCp648_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp648"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp648-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp649_to" transform="translate(786.798427,137.271297)">
                  <g id="eZcD743TCp649_tr" transform="rotate(0)">
                    <g id="eZcD743TCp649_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp649"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp649-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp650_to" transform="translate(786.798427,138.771297)">
                  <g id="eZcD743TCp650_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp650"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp650-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp651-filter)">
                <path
                  id="eZcD743TCp652"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp652-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp653_to" transform="translate(786.798427,143.771297)">
                  <g id="eZcD743TCp653_tr" transform="rotate(0)">
                    <g id="eZcD743TCp653_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp653"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp653-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp654_to" transform="translate(786.798427,137.271297)">
                  <g id="eZcD743TCp654_tr" transform="rotate(0)">
                    <g id="eZcD743TCp654_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp654"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp654-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp655_to" transform="translate(786.798427,136.771297)">
                  <g id="eZcD743TCp655_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp655"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp655-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-59 0)" mask="url(#eZcD743TCp671)">
              <g>
                <path
                  d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp660-filter)">
                  <path
                    id="eZcD743TCp661"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eZcD743TCp661-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp662_to" transform="translate(715.798427,56.771297)">
                    <g id="eZcD743TCp662_tr" transform="rotate(0)">
                      <g id="eZcD743TCp662_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp662"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eZcD743TCp662-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp663_to" transform="translate(715.798427,50.271297)">
                    <g id="eZcD743TCp663_tr" transform="rotate(0)">
                      <g id="eZcD743TCp663_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp663"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eZcD743TCp663-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp664_to" transform="translate(715.798427,51.771297)">
                    <g id="eZcD743TCp664_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp664"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eZcD743TCp664-filter)"
                        fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp665-filter)">
                  <path
                    id="eZcD743TCp666"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp666-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp667_to" transform="translate(715.798427,56.771297)">
                    <g id="eZcD743TCp667_tr" transform="rotate(0)">
                      <g id="eZcD743TCp667_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp667"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp667-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp668_to" transform="translate(715.798427,50.271297)">
                    <g id="eZcD743TCp668_tr" transform="rotate(0)">
                      <g id="eZcD743TCp668_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp668"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp668-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp669_to" transform="translate(715.798427,50.771297)">
                    <g id="eZcD743TCp669_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp669"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp669-filter)"
                        fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZcD743TCp671" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 535.931986-1.012683)"
                  fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(59 0)" mask="url(#eZcD743TCp687)">
              <g transform="translate(.000001 0)">
                <path
                  d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp676-filter)">
                  <path
                    id="eZcD743TCp677"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp677-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp678_to" transform="translate(291.798427,53.771297)">
                    <g id="eZcD743TCp678_tr" transform="rotate(0)">
                      <g id="eZcD743TCp678_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp678"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp678-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp679_to" transform="translate(291.798427,47.271297)">
                    <g id="eZcD743TCp679_tr" transform="rotate(0)">
                      <g id="eZcD743TCp679_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp679"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp679-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp680_to" transform="translate(291.798427,49.771297)">
                    <g id="eZcD743TCp680_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp680"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp680-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp681-filter)">
                  <path
                    id="eZcD743TCp682"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp682-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp683_to" transform="translate(291.798427,53.771297)">
                    <g id="eZcD743TCp683_tr" transform="rotate(0)">
                      <g id="eZcD743TCp683_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp683"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp683-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp684_to" transform="translate(291.798427,47.271297)">
                    <g id="eZcD743TCp684_tr" transform="rotate(0)">
                      <g id="eZcD743TCp684_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp684"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp684-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp685_to" transform="translate(291.798427,49.771297)">
                    <g id="eZcD743TCp685_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp685"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp685-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZcD743TCp687" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 209.931986-1.012683)"
                  fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(44 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp691-filter)">
                <path
                  id="eZcD743TCp692"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp692-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp693_to" transform="translate(226.798427,143.771297)">
                  <g id="eZcD743TCp693_tr" transform="rotate(0)">
                    <g id="eZcD743TCp693_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp693"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp693-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp694_to" transform="translate(226.798427,137.271297)">
                  <g id="eZcD743TCp694_tr" transform="rotate(0)">
                    <g id="eZcD743TCp694_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp694"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp694-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp695_to" transform="translate(226.798427,141.771297)">
                  <g id="eZcD743TCp695_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp695"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp695-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp696-filter)">
                <path
                  id="eZcD743TCp697"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp697-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp698_to" transform="translate(226.798427,143.771297)">
                  <g id="eZcD743TCp698_tr" transform="rotate(0)">
                    <g id="eZcD743TCp698_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp698"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp698-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp699_to" transform="translate(226.798427,137.271297)">
                  <g id="eZcD743TCp699_tr" transform="rotate(0)">
                    <g id="eZcD743TCp699_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp699"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp699-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp6100_to" transform="translate(226.798427,138.771297)">
                  <g id="eZcD743TCp6100_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp6100"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp6100-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(29 0)" mask="url(#eZcD743TCp6121)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp6105-filter)">
                  <path
                    id="eZcD743TCp6106"
                    d="M96.7109,145.439636v-17.754678l.101249-.122374c0,0,.08233.25.08233.25"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eZcD743TCp6106-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp6107_to" transform="translate(161.798427,27.271297)">
                    <g id="eZcD743TCp6107_tr" transform="rotate(0)">
                      <g id="eZcD743TCp6107_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp6107"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eZcD743TCp6107-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp6108_to" transform="translate(161.798427,23.771297)">
                    <g id="eZcD743TCp6108_tr" transform="rotate(0)">
                      <g id="eZcD743TCp6108_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp6108"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eZcD743TCp6108-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp6109_to" transform="translate(161.798427,23.271297)">
                    <g id="eZcD743TCp6109_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp6109"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eZcD743TCp6109-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp6110-filter)">
                  <path
                    id="eZcD743TCp6111"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp6111-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp6112_to" transform="translate(161.798427,53.771297)">
                    <g id="eZcD743TCp6112_tr" transform="rotate(0)">
                      <g id="eZcD743TCp6112_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp6112"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp6112-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp6113_to" transform="translate(161.798427,47.271297)">
                    <g id="eZcD743TCp6113_tr" transform="rotate(0)">
                      <g id="eZcD743TCp6113_ts" transform="scale(1,1)">
                        <rect
                          id="eZcD743TCp6113"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZcD743TCp6113-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZcD743TCp6114_to" transform="translate(161.798427,49.771297)">
                    <g id="eZcD743TCp6114_tr" transform="rotate(0)">
                      <rect
                        id="eZcD743TCp6114"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp6114-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp6115-filter)">
                  <path
                    id="eZcD743TCp6116"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZcD743TCp6116-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZcD743TCp6117_to" transform="translate(161.798427,53.771297)">
                    <rect
                      id="eZcD743TCp6117"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp6117-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eZcD743TCp6118_to" transform="translate(161.798427,47.271297)">
                    <rect
                      id="eZcD743TCp6118"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp6118-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eZcD743TCp6119_to" transform="translate(161.798427,50.771297)">
                    <rect
                      id="eZcD743TCp6119"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp6119-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eZcD743TCp6121" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 75.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(14 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp6125-filter)">
                <path
                  id="eZcD743TCp6126"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp6126-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp6127_to" transform="translate(96.798427,143.771297)">
                  <g id="eZcD743TCp6127_tr" transform="rotate(0)">
                    <g id="eZcD743TCp6127_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp6127"
                        style={{ isolation: 'isolate' }}
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp6127-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp6128_to" transform="translate(96.798427,137.271297)">
                  <g id="eZcD743TCp6128_tr" transform="rotate(0)">
                    <g id="eZcD743TCp6128_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp6128"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp6128-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp6129_to" transform="translate(96.798427,142.271297)">
                  <g id="eZcD743TCp6129_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp6129"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp6129-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZcD743TCp6130-filter)">
                <path
                  id="eZcD743TCp6131"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZcD743TCp6131-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZcD743TCp6132_to" transform="translate(96.798427,143.771297)">
                  <g id="eZcD743TCp6132_tr" transform="rotate(0)">
                    <g id="eZcD743TCp6132_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp6132"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp6132-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp6133_to" transform="translate(96.798427,137.271297)">
                  <g id="eZcD743TCp6133_tr" transform="rotate(0)">
                    <g id="eZcD743TCp6133_ts" transform="scale(1,1)">
                      <rect
                        id="eZcD743TCp6133"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZcD743TCp6133-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZcD743TCp6134_to" transform="translate(96.798427,139.771297)">
                  <g id="eZcD743TCp6134_tr" transform="rotate(0)">
                    <rect
                      id="eZcD743TCp6134"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZcD743TCp6134-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <rect
                width="63"
                height="63"
                rx="4.5"
                ry="4.5"
                transform="translate(.5 231.082)"
                fill="#030303"
                stroke="#878888"
              />
            </g>
          </g>
        </g>
        <mask id="eZcD743TCp6136" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="896.38596"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eZcD743TCp68 {
    animation-name: eZcD743TCp68__m, eZcD743TCp68_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp68__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eZcD743TCp68_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp69_to {
    animation: eZcD743TCp69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp69_to__to {
    0% {
      transform: translate(832.121519px, -4.482748px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -116.228703px);
    }
    100% {
      transform: translate(731.9px, -116.228703px);
    }
  }
  #eZcD743TCp69_tr {
    animation: eZcD743TCp69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp69_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(-75deg);
    }
    10% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp69_ts {
    animation: eZcD743TCp69_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp69_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp69 {
    animation: eZcD743TCp69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp69_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp610_to {
    animation: eZcD743TCp610_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp610_to__to {
    0% {
      transform: translate(826.798304px, -5.828881px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.728703px);
    }
    100% {
      transform: translate(731.9px, -122.728703px);
    }
  }
  #eZcD743TCp610_tr {
    animation: eZcD743TCp610_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp610_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp610_ts {
    animation: eZcD743TCp610_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp610_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp610 {
    animation: eZcD743TCp610_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp610_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp611_to {
    animation: eZcD743TCp611_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp611_to__to {
    0% {
      transform: translate(826.568884px, -6.23967px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.228703px);
    }
    100% {
      transform: translate(731.9px, -122.228703px);
    }
  }
  #eZcD743TCp611_tr {
    animation: eZcD743TCp611_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp611_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp611 {
    animation: eZcD743TCp611_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp611_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp613 {
    animation-name: eZcD743TCp613__m, eZcD743TCp613_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp613__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eZcD743TCp613_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp614_to {
    animation: eZcD743TCp614_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp614_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    25% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -115.228703px);
    }
    100% {
      transform: translate(731.9px, -115.228703px);
    }
  }
  #eZcD743TCp614_tr {
    animation: eZcD743TCp614_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp614_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(-75deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp614_ts {
    animation: eZcD743TCp614_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp614_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp614 {
    animation: eZcD743TCp614_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp614_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    68.75% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp615_to {
    animation: eZcD743TCp615_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp615_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    25% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.728703px);
    }
    100% {
      transform: translate(731.9px, -120.728703px);
    }
  }
  #eZcD743TCp615_tr {
    animation: eZcD743TCp615_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp615_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp615_ts {
    animation: eZcD743TCp615_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp615_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp615 {
    animation: eZcD743TCp615_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp615_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.9;
    }
    68.75% {
      opacity: 0.9;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp616_to {
    animation: eZcD743TCp616_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp616_to__to {
    0% {
      transform: translate(917.798427px, 137.771297px);
    }
    25% {
      transform: translate(917.798427px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.228703px);
    }
    100% {
      transform: translate(731.9px, -120.228703px);
    }
  }
  #eZcD743TCp616_tr {
    animation: eZcD743TCp616_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp616_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp616 {
    animation: eZcD743TCp616_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp616_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp618 {
    animation-name: eZcD743TCp618__m, eZcD743TCp618_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp618__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eZcD743TCp618_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp619_to {
    animation: eZcD743TCp619_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp619_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    75% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(832.121519px, -4.482748px);
    }
  }
  #eZcD743TCp619_tr {
    animation: eZcD743TCp619_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp619_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eZcD743TCp619_ts {
    animation: eZcD743TCp619_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp619_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eZcD743TCp619 {
    animation: eZcD743TCp619_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp619_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp620_to {
    animation: eZcD743TCp620_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp620_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    75% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eZcD743TCp620_tr {
    animation: eZcD743TCp620_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp620_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eZcD743TCp620_ts {
    animation: eZcD743TCp620_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp620_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eZcD743TCp620 {
    animation: eZcD743TCp620_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp620_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp621_to {
    animation: eZcD743TCp621_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp621_to__to {
    0% {
      transform: translate(917.798427px, 140.771297px);
    }
    75% {
      transform: translate(917.798427px, 140.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eZcD743TCp621_tr {
    animation: eZcD743TCp621_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp621_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eZcD743TCp621 {
    animation: eZcD743TCp621_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp621_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp627 {
    animation-name: eZcD743TCp627__m, eZcD743TCp627_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp627__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eZcD743TCp627_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp628_to {
    animation: eZcD743TCp628_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp628_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    12.5% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -116.228703px);
    }
    100% {
      transform: translate(693.9px, -116.228703px);
    }
  }
  #eZcD743TCp628_tr {
    animation: eZcD743TCp628_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp628_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(-73deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp628_ts {
    animation: eZcD743TCp628_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp628_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp628 {
    animation: eZcD743TCp628_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp628_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp629_to {
    animation: eZcD743TCp629_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp629_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    12.5% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eZcD743TCp629_tr {
    animation: eZcD743TCp629_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp629_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp629_ts {
    animation: eZcD743TCp629_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp629_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp629 {
    animation: eZcD743TCp629_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp629_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp630_to {
    animation: eZcD743TCp630_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp630_to__to {
    0% {
      transform: translate(846.5px, 51.771297px);
    }
    12.5% {
      transform: translate(846.5px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eZcD743TCp630_tr {
    animation: eZcD743TCp630_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp630_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp630 {
    animation: eZcD743TCp630_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp630_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp632 {
    animation-name: eZcD743TCp632__m, eZcD743TCp632_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp632__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eZcD743TCp632_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp633_to {
    animation: eZcD743TCp633_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp633_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    51.25% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -115.228703px);
    }
    100% {
      transform: translate(693.9px, -115.228703px);
    }
  }
  #eZcD743TCp633_tr {
    animation: eZcD743TCp633_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp633_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp633_ts {
    animation: eZcD743TCp633_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp633_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp633 {
    animation: eZcD743TCp633_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp633_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.8;
    }
    86.25% {
      opacity: 0.8;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp634_to {
    animation: eZcD743TCp634_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp634_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    51.25% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eZcD743TCp634_tr {
    animation: eZcD743TCp634_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp634_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp634_ts {
    animation: eZcD743TCp634_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp634_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp634 {
    animation: eZcD743TCp634_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp634_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.9;
    }
    86.25% {
      opacity: 0.9;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp635_to {
    animation: eZcD743TCp635_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp635_to__to {
    0% {
      transform: translate(846.5px, 50.771297px);
    }
    51.25% {
      transform: translate(846.5px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eZcD743TCp635_tr {
    animation: eZcD743TCp635_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp635_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp635 {
    animation: eZcD743TCp635_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp635_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp642 {
    animation-name: eZcD743TCp642__m, eZcD743TCp642_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp642__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes eZcD743TCp642_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp643_to {
    animation: eZcD743TCp643_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp643_to__to {
    0% {
      transform: translate(656.9px, -33.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -114.228703px);
    }
    100% {
      transform: translate(656.9px, -114.228703px);
    }
  }
  #eZcD743TCp643 {
    animation: eZcD743TCp643_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp643_c_o {
    0% {
      opacity: 0.8;
    }
    10% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp644_to {
    animation: eZcD743TCp644_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp644_to__to {
    0% {
      transform: translate(656.9px, -37.443085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eZcD743TCp644 {
    animation: eZcD743TCp644_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp644_c_o {
    0% {
      opacity: 0.9;
    }
    10% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp645_to {
    animation: eZcD743TCp645_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp645_to__to {
    0% {
      transform: translate(656.9px, -38.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.228703px);
    }
    100% {
      transform: translate(656.9px, -119.228703px);
    }
  }
  #eZcD743TCp645 {
    animation: eZcD743TCp645_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp645_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp647 {
    animation-name: eZcD743TCp647__m, eZcD743TCp647_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp647__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    62.5% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes eZcD743TCp647_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp648_to {
    animation: eZcD743TCp648_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp648_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -115.228703px);
    }
    100% {
      transform: translate(656.9px, -115.228703px);
    }
  }
  #eZcD743TCp648_tr {
    animation: eZcD743TCp648_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp648_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(-70deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp648_ts {
    animation: eZcD743TCp648_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp648_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp648 {
    animation: eZcD743TCp648_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp648_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.8;
    }
    62.5% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp649_to {
    animation: eZcD743TCp649_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp649_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    22.5% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eZcD743TCp649_tr {
    animation: eZcD743TCp649_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp649_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp649_ts {
    animation: eZcD743TCp649_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp649_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp649 {
    animation: eZcD743TCp649_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp649_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.9;
    }
    62.5% {
      opacity: 0.9;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp650_to {
    animation: eZcD743TCp650_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp650_to__to {
    0% {
      transform: translate(786.798427px, 138.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #eZcD743TCp650_tr {
    animation: eZcD743TCp650_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp650_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp650 {
    animation: eZcD743TCp650_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp650_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp652 {
    animation-name: eZcD743TCp652__m, eZcD743TCp652_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp652__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,58.945745,591.54,58.945745'
      );
    }
  }
  @keyframes eZcD743TCp652_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp653_to {
    animation: eZcD743TCp653_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp653_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -34.549858px);
    }
  }
  #eZcD743TCp653_tr {
    animation: eZcD743TCp653_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp653_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(-70deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp653_ts {
    animation: eZcD743TCp653_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp653_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp653 {
    animation: eZcD743TCp653_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp653_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eZcD743TCp654_to {
    animation: eZcD743TCp654_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp654_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    68.75% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -39.443085px);
    }
  }
  #eZcD743TCp654_tr {
    animation: eZcD743TCp654_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp654_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp654_ts {
    animation: eZcD743TCp654_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp654_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp654 {
    animation: eZcD743TCp654_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp654_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eZcD743TCp655_to {
    animation: eZcD743TCp655_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp655_to__to {
    0% {
      transform: translate(786.798427px, 136.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.549858px);
    }
  }
  #eZcD743TCp655_tr {
    animation: eZcD743TCp655_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp655_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp655 {
    animation: eZcD743TCp655_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp655_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp661 {
    animation-name: eZcD743TCp661__m, eZcD743TCp661_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp661__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eZcD743TCp661_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp662_to {
    animation: eZcD743TCp662_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp662_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -115.228703px);
    }
    100% {
      transform: translate(620.9px, -115.228703px);
    }
  }
  #eZcD743TCp662_tr {
    animation: eZcD743TCp662_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp662_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(-68deg);
    }
    23.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp662_ts {
    animation: eZcD743TCp662_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp662_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp662 {
    animation: eZcD743TCp662_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp662_c_o {
    0% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp663_to {
    animation: eZcD743TCp663_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp663_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -121.728703px);
    }
    100% {
      transform: translate(620.9px, -121.728703px);
    }
  }
  #eZcD743TCp663_tr {
    animation: eZcD743TCp663_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp663_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp663_ts {
    animation: eZcD743TCp663_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp663_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp663 {
    animation: eZcD743TCp663_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp663_c_o {
    0% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp664_to {
    animation: eZcD743TCp664_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp664_to__to {
    0% {
      transform: translate(715.798427px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -122.228703px);
    }
    100% {
      transform: translate(620.9px, -122.228703px);
    }
  }
  #eZcD743TCp664_tr {
    animation: eZcD743TCp664_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp664_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp664 {
    animation: eZcD743TCp664_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp664_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp666 {
    animation-name: eZcD743TCp666__m, eZcD743TCp666_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp666__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eZcD743TCp666_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp667_to {
    animation: eZcD743TCp667_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp667_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -114.228703px);
    }
    100% {
      transform: translate(620.9px, -114.228703px);
    }
  }
  #eZcD743TCp667_tr {
    animation: eZcD743TCp667_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp667_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(-68deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp667_ts {
    animation: eZcD743TCp667_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp667_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp667 {
    animation: eZcD743TCp667_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp667_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp668_to {
    animation: eZcD743TCp668_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp668_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -119.728703px);
    }
    100% {
      transform: translate(620.9px, -119.728703px);
    }
  }
  #eZcD743TCp668_tr {
    animation: eZcD743TCp668_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp668_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp668_ts {
    animation: eZcD743TCp668_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp668_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp668 {
    animation: eZcD743TCp668_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp668_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp669_to {
    animation: eZcD743TCp669_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp669_to__to {
    0% {
      transform: translate(715.798427px, 50.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(705.674548px, 12.059155px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(620.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -35.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -116.228703px);
    }
    100% {
      transform: translate(620.9px, -116.228703px);
    }
  }
  #eZcD743TCp669_tr {
    animation: eZcD743TCp669_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp669_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp669 {
    animation: eZcD743TCp669_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp669_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp677 {
    animation-name: eZcD743TCp677__m, eZcD743TCp677_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp677__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    41.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eZcD743TCp677_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp678_to {
    animation: eZcD743TCp678_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp678_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -115.228703px);
    }
    100% {
      transform: translate(398.699999px, -115.228703px);
    }
  }
  #eZcD743TCp678_tr {
    animation: eZcD743TCp678_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp678_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(68deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp678_ts {
    animation: eZcD743TCp678_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp678_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp678 {
    animation: eZcD743TCp678_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp678_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.8;
    }
    53.75% {
      opacity: 0.8;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp679_to {
    animation: eZcD743TCp679_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp679_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    18.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eZcD743TCp679_tr {
    animation: eZcD743TCp679_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp679_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp679_ts {
    animation: eZcD743TCp679_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp679_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp679 {
    animation: eZcD743TCp679_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp679_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.9;
    }
    53.75% {
      opacity: 0.9;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp680_to {
    animation: eZcD743TCp680_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp680_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eZcD743TCp680_tr {
    animation: eZcD743TCp680_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp680_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp680 {
    animation: eZcD743TCp680_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp680_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp682 {
    animation-name: eZcD743TCp682__m, eZcD743TCp682_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp682__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eZcD743TCp682_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp683_to {
    animation: eZcD743TCp683_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp683_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -114.228703px);
    }
    100% {
      transform: translate(398.699999px, -114.228703px);
    }
  }
  #eZcD743TCp683_tr {
    animation: eZcD743TCp683_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp683_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(68deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp683_ts {
    animation: eZcD743TCp683_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp683_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp683 {
    animation: eZcD743TCp683_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp683_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp684_to {
    animation: eZcD743TCp684_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp684_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    58.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eZcD743TCp684_tr {
    animation: eZcD743TCp684_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp684_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp684_ts {
    animation: eZcD743TCp684_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp684_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp684 {
    animation: eZcD743TCp684_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp684_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp685_to {
    animation: eZcD743TCp685_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp685_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.228703px);
    }
    100% {
      transform: translate(398.699999px, -119.228703px);
    }
  }
  #eZcD743TCp685_tr {
    animation: eZcD743TCp685_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp685_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp685 {
    animation: eZcD743TCp685_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp685_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp692 {
    animation-name: eZcD743TCp692__m, eZcD743TCp692_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp692__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    11.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    22.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    42.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eZcD743TCp692_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp693_to {
    animation: eZcD743TCp693_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp693_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #eZcD743TCp693_tr {
    animation: eZcD743TCp693_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp693_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(72deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp693_ts {
    animation: eZcD743TCp693_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp693_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp693 {
    animation: eZcD743TCp693_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp693_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp694_to {
    animation: eZcD743TCp694_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp694_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    6.25% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #eZcD743TCp694_tr {
    animation: eZcD743TCp694_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp694_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp694_ts {
    animation: eZcD743TCp694_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp694_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp694 {
    animation: eZcD743TCp694_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp694_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0.9;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp695_to {
    animation: eZcD743TCp695_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp695_to__to {
    0% {
      transform: translate(226.798427px, 141.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 141.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #eZcD743TCp695_tr {
    animation: eZcD743TCp695_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp695_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp695 {
    animation: eZcD743TCp695_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp695_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp697 {
    animation-name: eZcD743TCp697__m, eZcD743TCp697_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp697__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eZcD743TCp697_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp698_to {
    animation: eZcD743TCp698_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp698_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #eZcD743TCp698_tr {
    animation: eZcD743TCp698_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp698_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(72deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp698_ts {
    animation: eZcD743TCp698_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp698_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp698 {
    animation: eZcD743TCp698_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp698_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.8;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp699_to {
    animation: eZcD743TCp699_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp699_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    52.5% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #eZcD743TCp699_tr {
    animation: eZcD743TCp699_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp699_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp699_ts {
    animation: eZcD743TCp699_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp699_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp699 {
    animation: eZcD743TCp699_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp699_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.9;
    }
    93.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6100_to {
    animation: eZcD743TCp6100_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6100_to__to {
    0% {
      transform: translate(226.798427px, 138.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #eZcD743TCp6100_tr {
    animation: eZcD743TCp6100_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6100 {
    animation: eZcD743TCp6100_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6100_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6106 {
    animation-name: eZcD743TCp6106__m, eZcD743TCp6106_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp6106__m {
    0% {
      d: path(
        'M96.7109,145.439636L96.7109,127.684958L96.812149,127.562584C96.812149,127.562584,96.894479,127.812584,96.894479,127.812584'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    21.25% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,33.289957,259.817001,33.289957'
      );
    }
    26.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eZcD743TCp6106_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6107_to {
    animation: eZcD743TCp6107_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6107_to__to {
    0% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eZcD743TCp6107_tr {
    animation: eZcD743TCp6107_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6107_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6107_ts {
    animation: eZcD743TCp6107_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6107_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6107 {
    animation: eZcD743TCp6107_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6107_c_o {
    0% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6108_to {
    animation: eZcD743TCp6108_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6108_to__to {
    0% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -121.728703px);
    }
    100% {
      transform: translate(324.9px, -121.728703px);
    }
  }
  #eZcD743TCp6108_tr {
    animation: eZcD743TCp6108_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6108_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6108_ts {
    animation: eZcD743TCp6108_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6108_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6108 {
    animation: eZcD743TCp6108_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6108_c_o {
    0% {
      opacity: 0.9;
    }
    30% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6109_to {
    animation: eZcD743TCp6109_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6109_to__to {
    0% {
      transform: translate(161.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    15% {
      transform: translate(309.773572px, -24.042276px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    22.5% {
      transform: translate(324.9px, -86.545293px);
      animation-timing-function: cubic-bezier(0.280804, 0.399795, 0.604922, 0.750053);
    }
    26.25% {
      transform: translate(324.9px, -111.961266px);
      animation-timing-function: cubic-bezier(0.291118, 0.499369, 0.620764, 0.851063);
    }
    30% {
      transform: translate(324.9px, -122.228703px);
    }
    100% {
      transform: translate(324.9px, -122.228703px);
    }
  }
  #eZcD743TCp6109_tr {
    animation: eZcD743TCp6109_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6109_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6109 {
    animation: eZcD743TCp6109_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6109_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6111 {
    animation-name: eZcD743TCp6111__m, eZcD743TCp6111_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp6111__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,21.289957,259.817001,21.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    81.25% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eZcD743TCp6111_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6112_to {
    animation: eZcD743TCp6112_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6112_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eZcD743TCp6112_tr {
    animation: eZcD743TCp6112_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6112_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(73deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6112_ts {
    animation: eZcD743TCp6112_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6112_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6112 {
    animation: eZcD743TCp6112_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6112_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.8;
    }
    81.25% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6113_to {
    animation: eZcD743TCp6113_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6113_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    42.5% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eZcD743TCp6113_tr {
    animation: eZcD743TCp6113_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6113_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6113_ts {
    animation: eZcD743TCp6113_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6113_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6113 {
    animation: eZcD743TCp6113_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6113_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.9;
    }
    81.25% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6114_to {
    animation: eZcD743TCp6114_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6114_to__to {
    0% {
      transform: translate(161.798427px, 49.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(191.832868px, 8.675763px);
    }
    58.75% {
      transform: translate(231.488924px, -2.180118px);
    }
    66.25% {
      transform: translate(323.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eZcD743TCp6114_tr {
    animation: eZcD743TCp6114_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6114_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6114 {
    animation: eZcD743TCp6114_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6114_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6116 {
    animation-name: eZcD743TCp6116__m, eZcD743TCp6116_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp6116__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eZcD743TCp6116_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp6117_to {
    animation: eZcD743TCp6117_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6117_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 38.016262px);
    }
  }
  #eZcD743TCp6117 {
    animation: eZcD743TCp6117_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6117_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp6118_to {
    animation: eZcD743TCp6118_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6118_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    96.25% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 33.299851px);
    }
  }
  #eZcD743TCp6118 {
    animation: eZcD743TCp6118_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6118_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp6119_to {
    animation: eZcD743TCp6119_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6119_to__to {
    0% {
      transform: translate(161.798427px, 50.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 34.016262px);
    }
  }
  #eZcD743TCp6119 {
    animation: eZcD743TCp6119_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6119_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZcD743TCp6126 {
    animation-name: eZcD743TCp6126__m, eZcD743TCp6126_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp6126__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.920607,76.685694');
    }
    43.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    51.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eZcD743TCp6126_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6127_to {
    animation: eZcD743TCp6127_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6127_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    11.25% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eZcD743TCp6127_tr {
    animation: eZcD743TCp6127_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6127_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(75deg);
    }
    43.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6127_ts {
    animation: eZcD743TCp6127_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6127_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6127 {
    animation: eZcD743TCp6127_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6127_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6128_to {
    animation: eZcD743TCp6128_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6128_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eZcD743TCp6128_tr {
    animation: eZcD743TCp6128_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6128_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6128_ts {
    animation: eZcD743TCp6128_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6128_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6128 {
    animation: eZcD743TCp6128_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6128_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6129_to {
    animation: eZcD743TCp6129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6129_to__to {
    0% {
      transform: translate(96.798427px, 142.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 142.271297px);
    }
    17.5% {
      transform: translate(96.748252px, 116.509555px);
    }
    18.75% {
      transform: translate(96.738217px, 108.957207px);
    }
    21.25% {
      transform: translate(96.718147px, 82.185843px);
    }
    26.25% {
      transform: translate(96.678007px, 22.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    33.75% {
      transform: translate(174.541791px, -1.63208px);
    }
    41.25% {
      transform: translate(282.833827px, -26.93208px);
    }
    42.5% {
      transform: translate(287.711987px, -31.028703px);
    }
    43.75% {
      transform: translate(287.814424px, -38.440824px);
    }
    45% {
      transform: translate(287.91686px, -53.852945px);
    }
    47.5% {
      transform: translate(287.913114px, -81.677187px);
    }
    50% {
      transform: translate(287.909367px, -103.50143px);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eZcD743TCp6129_tr {
    animation: eZcD743TCp6129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6129 {
    animation: eZcD743TCp6129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6129_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6131 {
    animation-name: eZcD743TCp6131__m, eZcD743TCp6131_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZcD743TCp6131__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955001,75.463017');
    }
    88.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eZcD743TCp6131_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6132_to {
    animation: eZcD743TCp6132_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6132_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eZcD743TCp6132_tr {
    animation: eZcD743TCp6132_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6132_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(75deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6132_ts {
    animation: eZcD743TCp6132_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6132_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6132 {
    animation: eZcD743TCp6132_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6132_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6133_to {
    animation: eZcD743TCp6133_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6133_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    57.5% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eZcD743TCp6133_tr {
    animation: eZcD743TCp6133_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6133_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6133_ts {
    animation: eZcD743TCp6133_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6133_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZcD743TCp6133 {
    animation: eZcD743TCp6133_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6133_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZcD743TCp6134_to {
    animation: eZcD743TCp6134_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6134_to__to {
    0% {
      transform: translate(96.798427px, 139.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 139.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eZcD743TCp6134_tr {
    animation: eZcD743TCp6134_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6134_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZcD743TCp6134 {
    animation: eZcD743TCp6134_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZcD743TCp6134_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg8;
