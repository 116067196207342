// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg3 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="e6lFKywbXzs1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 624 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="624"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="e6lFKywbXzs7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs7-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs8-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs8-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs8-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs9-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs10-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e6lFKywbXzs12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs12-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs13-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs13-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs13-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs14-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs15-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e6lFKywbXzs17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs17-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs18-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs18-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs19-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs20-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e6lFKywbXzs26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs26-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs27-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs27-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs27-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs28-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs29-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs30-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e6lFKywbXzs31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs31-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs32-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs32-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs32-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs33-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs34-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs35-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e6lFKywbXzs41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs41-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs42-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs42-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs42-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs43-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs44-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e6lFKywbXzs46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e6lFKywbXzs46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs46-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e6lFKywbXzs46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e6lFKywbXzs47-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e6lFKywbXzs47-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e6lFKywbXzs47-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e6lFKywbXzs48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs48-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs49-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e6lFKywbXzs50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e6lFKywbXzs50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e6lFKywbXzs50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e6lFKywbXzs50-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e6lFKywbXzs50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e6lFKywbXzs50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e6lFKywbXzs50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e6lFKywbXzs50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g mask="url(#e6lFKywbXzs52)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-129.798432 1.012683)">
            <g transform="translate(.000001 0.624996)">
              <path
                d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs7-filter)">
                <path
                  id="e6lFKywbXzs8"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#e6lFKywbXzs8-stroke)"
                  strokeLinecap="round"
                />
                <g id="e6lFKywbXzs9_to" transform="translate(656.9,-33.549858)">
                  <rect
                    id="e6lFKywbXzs9"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#e6lFKywbXzs9-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e6lFKywbXzs10_to" transform="translate(656.9,-37.443085)">
                  <rect
                    id="e6lFKywbXzs10"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#e6lFKywbXzs10-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e6lFKywbXzs11_to" transform="translate(656.9,-38.549858)">
                  <rect
                    id="e6lFKywbXzs11"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#e6lFKywbXzs11-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs12-filter)">
                <path
                  id="e6lFKywbXzs13"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e6lFKywbXzs13-stroke)"
                  strokeLinecap="round"
                />
                <g id="e6lFKywbXzs14_to" transform="translate(786.798427,143.771297)">
                  <g id="e6lFKywbXzs14_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs14_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs14-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs15_to" transform="translate(786.798427,137.271297)">
                  <g id="e6lFKywbXzs15_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs15_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs15-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs16_to" transform="translate(786.798427,138.771297)">
                  <g id="e6lFKywbXzs16_tr" transform="rotate(0)">
                    <rect
                      id="e6lFKywbXzs16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e6lFKywbXzs16-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs17-filter)">
                <path
                  id="e6lFKywbXzs18"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e6lFKywbXzs18-stroke)"
                  strokeLinecap="round"
                />
                <g id="e6lFKywbXzs19_to" transform="translate(786.798427,143.771297)">
                  <g id="e6lFKywbXzs19_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs19_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs19-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs20_to" transform="translate(786.798427,137.271297)">
                  <g id="e6lFKywbXzs20_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs20_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs20-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs21_to" transform="translate(786.798427,136.771297)">
                  <g id="e6lFKywbXzs21_tr" transform="rotate(0)">
                    <rect
                      id="e6lFKywbXzs21"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e6lFKywbXzs21-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#e6lFKywbXzs37)">
              <g transform="translate(-5.423289-14.501953)">
                <line x1="445.277" y1="0.001953" x2="445.277" y2="155.962" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs26-filter)">
                  <path
                    id="e6lFKywbXzs27"
                    d="M357.283,158.189v-17.85784l-.429-11.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e6lFKywbXzs27-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e6lFKywbXzs28_to" transform="translate(509.798427,73.771297)">
                    <g id="e6lFKywbXzs28_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs28"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs28-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e6lFKywbXzs29_to" transform="translate(509.798427,67.271297)">
                    <g id="e6lFKywbXzs29_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs29"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs29-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e6lFKywbXzs30_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="e6lFKywbXzs30"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e6lFKywbXzs30-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs31-filter)">
                  <path
                    id="e6lFKywbXzs32"
                    d="M357.283,158.189v-17.85784l-.429-5.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e6lFKywbXzs32-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e6lFKywbXzs33_to" transform="translate(509.798427,73.771297)">
                    <g id="e6lFKywbXzs33_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs33"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs33-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e6lFKywbXzs34_to" transform="translate(509.798427,67.271297)">
                    <g id="e6lFKywbXzs34_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs34"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs34-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e6lFKywbXzs35_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="e6lFKywbXzs35"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e6lFKywbXzs35-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="e6lFKywbXzs37" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="62"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 407.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs41-filter)">
                <path
                  id="e6lFKywbXzs42"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e6lFKywbXzs42-stroke)"
                  strokeLinecap="round"
                />
                <g id="e6lFKywbXzs43_to" transform="translate(226.798427,143.771297)">
                  <g id="e6lFKywbXzs43_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs43_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs43"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs43-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs44_to" transform="translate(226.798427,137.271297)">
                  <g id="e6lFKywbXzs44_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs44_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs44"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs44-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs45_to" transform="translate(226.798427,141.771297)">
                  <g id="e6lFKywbXzs45_tr" transform="rotate(0)">
                    <rect
                      id="e6lFKywbXzs45"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e6lFKywbXzs45-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e6lFKywbXzs46-filter)">
                <path
                  id="e6lFKywbXzs47"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e6lFKywbXzs47-stroke)"
                  strokeLinecap="round"
                />
                <g id="e6lFKywbXzs48_to" transform="translate(226.798427,143.771297)">
                  <g id="e6lFKywbXzs48_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs48_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs48-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs49_to" transform="translate(226.798427,137.271297)">
                  <g id="e6lFKywbXzs49_tr" transform="rotate(0)">
                    <g id="e6lFKywbXzs49_ts" transform="scale(1,1)">
                      <rect
                        id="e6lFKywbXzs49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e6lFKywbXzs49-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e6lFKywbXzs50_to" transform="translate(226.798427,138.771297)">
                  <g id="e6lFKywbXzs50_tr" transform="rotate(0)">
                    <rect
                      id="e6lFKywbXzs50"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e6lFKywbXzs50-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="e6lFKywbXzs52" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #e6lFKywbXzs8 {
    animation-name: e6lFKywbXzs8__m, e6lFKywbXzs8_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs8__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes e6lFKywbXzs8_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs9_to {
    animation: e6lFKywbXzs9_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs9_to__to {
    0% {
      transform: translate(656.9px, -33.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -114.228703px);
    }
    100% {
      transform: translate(656.9px, -114.228703px);
    }
  }
  #e6lFKywbXzs9 {
    animation: e6lFKywbXzs9_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs9_c_o {
    0% {
      opacity: 0.8;
    }
    10% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs10_to {
    animation: e6lFKywbXzs10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs10_to__to {
    0% {
      transform: translate(656.9px, -37.443085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e6lFKywbXzs10 {
    animation: e6lFKywbXzs10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs10_c_o {
    0% {
      opacity: 0.9;
    }
    10% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs11_to {
    animation: e6lFKywbXzs11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs11_to__to {
    0% {
      transform: translate(656.9px, -38.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.228703px);
    }
    100% {
      transform: translate(656.9px, -119.228703px);
    }
  }
  #e6lFKywbXzs11 {
    animation: e6lFKywbXzs11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs13 {
    animation-name: e6lFKywbXzs13__m, e6lFKywbXzs13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs13__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    62.5% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes e6lFKywbXzs13_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs14_to {
    animation: e6lFKywbXzs14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs14_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -115.228703px);
    }
    100% {
      transform: translate(656.9px, -115.228703px);
    }
  }
  #e6lFKywbXzs14_tr {
    animation: e6lFKywbXzs14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(-70deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs14_ts {
    animation: e6lFKywbXzs14_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs14_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs14 {
    animation: e6lFKywbXzs14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs14_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.8;
    }
    62.5% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs15_to {
    animation: e6lFKywbXzs15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs15_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    22.5% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e6lFKywbXzs15_tr {
    animation: e6lFKywbXzs15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs15_ts {
    animation: e6lFKywbXzs15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs15 {
    animation: e6lFKywbXzs15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs15_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.9;
    }
    62.5% {
      opacity: 0.9;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs16_to {
    animation: e6lFKywbXzs16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs16_to__to {
    0% {
      transform: translate(786.798427px, 138.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #e6lFKywbXzs16_tr {
    animation: e6lFKywbXzs16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs16 {
    animation: e6lFKywbXzs16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs16_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs18 {
    animation-name: e6lFKywbXzs18__m, e6lFKywbXzs18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs18__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,58.945745,591.54,58.945745'
      );
    }
  }
  @keyframes e6lFKywbXzs18_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e6lFKywbXzs19_to {
    animation: e6lFKywbXzs19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs19_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -34.549858px);
    }
  }
  #e6lFKywbXzs19_tr {
    animation: e6lFKywbXzs19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(-70deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs19_ts {
    animation: e6lFKywbXzs19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs19_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs19 {
    animation: e6lFKywbXzs19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs19_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #e6lFKywbXzs20_to {
    animation: e6lFKywbXzs20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs20_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    68.75% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -39.443085px);
    }
  }
  #e6lFKywbXzs20_tr {
    animation: e6lFKywbXzs20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs20_ts {
    animation: e6lFKywbXzs20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs20 {
    animation: e6lFKywbXzs20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs20_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #e6lFKywbXzs21_to {
    animation: e6lFKywbXzs21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs21_to__to {
    0% {
      transform: translate(786.798427px, 136.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.549858px);
    }
  }
  #e6lFKywbXzs21_tr {
    animation: e6lFKywbXzs21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs21 {
    animation: e6lFKywbXzs21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs21_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e6lFKywbXzs27 {
    animation-name: e6lFKywbXzs27__m, e6lFKywbXzs27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs27__m {
    0% {
      d: path('M357.283,158.189L357.283,140.33116L356.854,128.799446');
    }
    16.25% {
      d: path('M357.283,158.189L357.283,140.33116L356.854,128.799446');
    }
    26.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    35% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    46.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes e6lFKywbXzs27_c_o {
    0% {
      opacity: 0;
    }
    16.25% {
      opacity: 0;
    }
    17.5% {
      opacity: 1;
    }
    42.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs28_to {
    animation: e6lFKywbXzs28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs28_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #e6lFKywbXzs28_ts {
    animation: e6lFKywbXzs28_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs28_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs28 {
    animation: e6lFKywbXzs28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs28_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.8;
    }
    46.25% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs29_to {
    animation: e6lFKywbXzs29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs29_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    13.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #e6lFKywbXzs29_ts {
    animation: e6lFKywbXzs29_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs29_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs29 {
    animation: e6lFKywbXzs29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs29_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.9;
    }
    46.25% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs30_to {
    animation: e6lFKywbXzs30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs30_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #e6lFKywbXzs30 {
    animation: e6lFKywbXzs30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs30_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs32 {
    animation-name: e6lFKywbXzs32__m, e6lFKywbXzs32_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs32__m {
    0% {
      d: path('M357.283,158.189L357.283,140.33116L356.854,134.799446');
    }
    66.25% {
      d: path('M357.283,158.189L357.283,140.33116L356.854,134.799446');
    }
    76.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    85% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    96.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes e6lFKywbXzs32_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs33_to {
    animation: e6lFKywbXzs33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs33_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #e6lFKywbXzs33_ts {
    animation: e6lFKywbXzs33_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs33_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs33 {
    animation: e6lFKywbXzs33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs33_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs34_to {
    animation: e6lFKywbXzs34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs34_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    63.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #e6lFKywbXzs34_ts {
    animation: e6lFKywbXzs34_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs34_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs34 {
    animation: e6lFKywbXzs34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs34_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs35_to {
    animation: e6lFKywbXzs35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs35_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #e6lFKywbXzs35 {
    animation: e6lFKywbXzs35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs35_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs42 {
    animation-name: e6lFKywbXzs42__m, e6lFKywbXzs42_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs42__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    11.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    22.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    42.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e6lFKywbXzs42_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs43_to {
    animation: e6lFKywbXzs43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs43_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #e6lFKywbXzs43_tr {
    animation: e6lFKywbXzs43_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs43_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(72deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs43_ts {
    animation: e6lFKywbXzs43_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs43_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs43 {
    animation: e6lFKywbXzs43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs43_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs44_to {
    animation: e6lFKywbXzs44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs44_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    6.25% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #e6lFKywbXzs44_tr {
    animation: e6lFKywbXzs44_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs44_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs44_ts {
    animation: e6lFKywbXzs44_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs44_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs44 {
    animation: e6lFKywbXzs44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs44_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0.9;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs45_to {
    animation: e6lFKywbXzs45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs45_to__to {
    0% {
      transform: translate(226.798427px, 141.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 141.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #e6lFKywbXzs45_tr {
    animation: e6lFKywbXzs45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs45 {
    animation: e6lFKywbXzs45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs45_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs47 {
    animation-name: e6lFKywbXzs47__m, e6lFKywbXzs47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e6lFKywbXzs47__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e6lFKywbXzs47_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs48_to {
    animation: e6lFKywbXzs48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs48_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #e6lFKywbXzs48_tr {
    animation: e6lFKywbXzs48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(72deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs48_ts {
    animation: e6lFKywbXzs48_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs48_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs48 {
    animation: e6lFKywbXzs48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs48_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.8;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs49_to {
    animation: e6lFKywbXzs49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs49_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    52.5% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #e6lFKywbXzs49_tr {
    animation: e6lFKywbXzs49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs49_ts {
    animation: e6lFKywbXzs49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e6lFKywbXzs49 {
    animation: e6lFKywbXzs49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs49_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.9;
    }
    93.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e6lFKywbXzs50_to {
    animation: e6lFKywbXzs50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs50_to__to {
    0% {
      transform: translate(226.798427px, 138.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #e6lFKywbXzs50_tr {
    animation: e6lFKywbXzs50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e6lFKywbXzs50 {
    animation: e6lFKywbXzs50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e6lFKywbXzs50_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg3;
