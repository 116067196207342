// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg6 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eTVLWPn2yM61"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 328 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="328"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eTVLWPn2yM66-stroke"
          x1="282.586"
          y1="213.684"
          x2="242.632"
          y2="182.642"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM66-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eTVLWPn2yM66-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eTVLWPn2yM67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM67-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM68-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eTVLWPn2yM68-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eTVLWPn2yM68-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="eTVLWPn2yM69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM69-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM610-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM610-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM610-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM610-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM610-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM610-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM610-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM610-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM611-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM611-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM612-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM612-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM612-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM612-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM612-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM612-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM612-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM612-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM613-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eTVLWPn2yM613-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eTVLWPn2yM613-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="eTVLWPn2yM614-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM614-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM614-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM614-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM614-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM614-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM614-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM614-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM615-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM615-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM615-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM615-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM615-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM615-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM615-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM615-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM616-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM616-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eTVLWPn2yM618-stroke"
          x1="235.691"
          y1="233.94"
          x2="194.587"
          y2="216.085"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM618-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eTVLWPn2yM618-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eTVLWPn2yM619-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM619-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM619-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM619-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM619-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM619-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM619-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM619-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM620-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM620-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM620-stroke-1"
            offset="100%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM621-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM621-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM621-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM621-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM621-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM621-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM621-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM621-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM622-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM622-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM622-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM622-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM622-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM622-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM622-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM622-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM623-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM623-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM624-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM624-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM624-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM624-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM624-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM624-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM624-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM624-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM625-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM625-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM625-stroke-1"
            offset="100%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM626-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM626-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM626-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM626-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM626-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM626-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM626-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM626-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM627-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM627-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM627-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM627-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM627-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM627-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM627-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM627-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM628-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM628-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM629-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM629-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM629-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM629-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM629-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM629-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM629-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM629-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM630-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM630-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM630-stroke-1"
            offset="100%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM631-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM631-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM631-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM631-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM631-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM631-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM631-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM631-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM632-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM632-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM632-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM632-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM632-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM632-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM632-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM632-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM633-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM633-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eTVLWPn2yM635-stroke"
          x1="188.844"
          y1="214.159"
          x2="170.027"
          y2="209.803"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM635-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eTVLWPn2yM635-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eTVLWPn2yM636-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM636-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM636-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM636-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM636-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM636-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM636-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM636-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM637-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM637-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM637-stroke-1"
            offset="100%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM638-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM638-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM638-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM638-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM638-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM638-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM638-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM638-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM639-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM639-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM639-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM639-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM639-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM639-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM639-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM639-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM640-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM640-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM641-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM641-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM641-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM641-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM641-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM641-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM641-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM641-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM642-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM642-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM642-stroke-1"
            offset="100%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM643-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM643-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM643-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM643-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM643-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM643-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM643-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM643-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM644-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM644-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM644-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM644-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM644-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM644-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM644-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM644-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM645-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM645-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eTVLWPn2yM647-stroke"
          x1="95.125"
          y1="214.384"
          x2="114.397"
          y2="209.963"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM647-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eTVLWPn2yM647-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eTVLWPn2yM648-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM648-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM648-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM648-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM648-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM648-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM648-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM648-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM649-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM649-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="eTVLWPn2yM649-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM650-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM650-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM650-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM650-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM650-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM650-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM650-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM650-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM651-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM651-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM651-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM651-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM651-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM651-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM651-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM651-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM652-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM652-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM653-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM653-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM653-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM653-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM653-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM653-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM653-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM653-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM654-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM654-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="eTVLWPn2yM654-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM655-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM655-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM655-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM655-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM655-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM655-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM655-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM655-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM656-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM656-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM656-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM656-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM656-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM656-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM656-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM656-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM657-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM657-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eTVLWPn2yM659-stroke"
          x1="48.3008"
          y1="235.601"
          x2="87.0793"
          y2="218.937"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM659-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eTVLWPn2yM659-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eTVLWPn2yM660-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM660-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM660-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM660-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM660-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM660-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM660-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM660-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM661-stroke"
          x1="1"
          y1="-0.015964"
          x2="1"
          y2="0.983627"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eTVLWPn2yM661-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eTVLWPn2yM661-stroke-1" offset="100%" stopColor="rgba(220,220,220,0)" />
        </linearGradient>
        <filter id="eTVLWPn2yM662-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM662-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM662-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM662-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM662-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM662-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM662-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM662-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM663-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM663-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM663-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM663-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM663-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM663-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM663-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM663-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM664-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM664-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM665-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM665-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM665-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM665-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM665-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM665-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM665-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM665-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM666-stroke"
          x1="2.846422"
          y1="1.054686"
          x2="2.846422"
          y2="-0.113172"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM666-stroke-0" offset="0%" stopColor="rgba(220,220,220,0)" />
          <stop
            id="eTVLWPn2yM666-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM667-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM667-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM667-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM667-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM667-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM667-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM667-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM667-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM668-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM668-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM668-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM668-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM668-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM668-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM668-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM668-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM669-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM669-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM670-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM670-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM670-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM670-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM670-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM670-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM670-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM670-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM671-stroke"
          x1="74.479276"
          y1="-349.713613"
          x2="12.97488"
          y2="-5.470225"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM671-stroke-0" offset="0%" stopColor="#838383" />
          <stop
            id="eTVLWPn2yM671-stroke-1"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eTVLWPn2yM671-stroke-2" offset="100%" stopColor="rgba(30,30,30,0)" />
        </linearGradient>
        <filter id="eTVLWPn2yM672-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM672-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM672-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM672-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM672-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM672-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM672-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM672-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM673-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM673-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM673-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM673-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM673-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM673-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM673-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM673-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM674-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM674-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eTVLWPn2yM676-stroke"
          x1="1.46094"
          y1="214.01"
          x2="43.493"
          y2="181.528"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM676-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eTVLWPn2yM676-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eTVLWPn2yM677-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM677-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM677-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM677-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM677-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM677-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM677-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM677-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM678-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM678-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM678-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM679-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM679-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM679-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM679-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM679-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM679-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM679-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM679-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM680-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM680-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM680-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM680-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM680-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM680-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM680-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM680-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM681-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM681-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eTVLWPn2yM682-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM682-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eTVLWPn2yM682-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM682-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eTVLWPn2yM682-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM682-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM682-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM682-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eTVLWPn2yM683-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM683-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eTVLWPn2yM683-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eTVLWPn2yM684-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM684-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM684-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM684-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM684-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM684-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM684-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM684-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM685-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM685-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eTVLWPn2yM685-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eTVLWPn2yM685-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eTVLWPn2yM685-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eTVLWPn2yM685-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eTVLWPn2yM685-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eTVLWPn2yM685-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eTVLWPn2yM686-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eTVLWPn2yM686-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eTVLWPn2yM687-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eTVLWPn2yM687-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="eTVLWPn2yM687-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="eTVLWPn2yM687-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g mask="url(#eTVLWPn2yM688)">
        <g>
          <g transform="translate(21.97653-6.66309)">
            <g transform="translate(2 0)">
              <path
                d="M284.586,213.684v-85.983L230.273,82.9652l-.004-76.30211"
                fill="none"
                stroke="url(#eTVLWPn2yM66-stroke)"
              />
              <g transform="translate(388 0)" filter="url(#eTVLWPn2yM67-filter)">
                <path
                  id="eTVLWPn2yM68"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM68-stroke)"
                />
                <g id="eTVLWPn2yM69_to" transform="translate(-104.650543,236.66309)">
                  <g id="eTVLWPn2yM69_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM69_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM69"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM69-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM610_to" transform="translate(-104.650543,230.66309)">
                  <g id="eTVLWPn2yM610_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM610_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM610"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM610-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM611_to" transform="translate(-104.650543,229.66309)">
                  <g id="eTVLWPn2yM611_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM611"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM611-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(388 0)" filter="url(#eTVLWPn2yM612-filter)">
                <path
                  id="eTVLWPn2yM613"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM613-stroke)"
                />
                <g id="eTVLWPn2yM614_to" transform="translate(-104.650543,236.66309)">
                  <g id="eTVLWPn2yM614_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM614_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM614"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM614-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM615_to" transform="translate(-104.650543,230.66309)">
                  <g id="eTVLWPn2yM615_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM615_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM615"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM615-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM616_to" transform="translate(-104.650543,229.66309)">
                  <g id="eTVLWPn2yM616_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM616"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM616-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-4 0)">
              <path
                d="M235.691,233.94v-110.447L199.845,78.761l-.001-76.09791"
                transform="translate(0 4)"
                fill="none"
                stroke="url(#eTVLWPn2yM618-stroke)"
              />
              <g transform="translate(186 0)" filter="url(#eTVLWPn2yM619-filter)">
                <path
                  id="eTVLWPn2yM620"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM620-stroke)"
                />
                <g id="eTVLWPn2yM621_to" transform="translate(49.349457,235.66309)">
                  <g id="eTVLWPn2yM621_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM621_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM621"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM621-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM622_to" transform="translate(49.349457,230.66309)">
                  <g id="eTVLWPn2yM622_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM622_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM622"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM622-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM623_to" transform="translate(49.349457,229.66309)">
                  <g id="eTVLWPn2yM623_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM623"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM623-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#eTVLWPn2yM624-filter)">
                <path
                  id="eTVLWPn2yM625"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM625-stroke)"
                />
                <g id="eTVLWPn2yM626_to" transform="translate(49.349457,235.66309)">
                  <g id="eTVLWPn2yM626_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM626_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM626"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM626-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM627_to" transform="translate(49.349457,230.66309)">
                  <g id="eTVLWPn2yM627_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM627_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM627"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM627-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM628_to" transform="translate(49.349457,229.66309)">
                  <g id="eTVLWPn2yM628_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM628"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM628-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#eTVLWPn2yM629-filter)">
                <path
                  id="eTVLWPn2yM630"
                  d="M-14.387819,106.271538v0l-18.67408-23.157707-.046745-21.6845"
                  transform="translate(46.96253 0)"
                  fill="none"
                  stroke="url(#eTVLWPn2yM630-stroke)"
                />
                <g id="eTVLWPn2yM631_to" transform="translate(13.965137,65.993562)">
                  <rect
                    id="eTVLWPn2yM631"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    opacity="0.9"
                    filter="url(#eTVLWPn2yM631-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eTVLWPn2yM632_to" transform="translate(13.963603,61.496423)">
                  <rect
                    id="eTVLWPn2yM632"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    filter="url(#eTVLWPn2yM632-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eTVLWPn2yM633_to" transform="translate(14.040137,60.578326)">
                  <rect
                    id="eTVLWPn2yM633"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#eTVLWPn2yM633-filter)"
                    fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-11 0)">
              <path
                d="M188.844,214.159v-86.374L171.396,83.0597l-.034265-77"
                fill="none"
                stroke="url(#eTVLWPn2yM635-stroke)"
              />
              <g transform="translate(94 0)" filter="url(#eTVLWPn2yM636-filter)">
                <path
                  id="eTVLWPn2yM637"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM637-stroke)"
                />
                <g id="eTVLWPn2yM638_to" transform="translate(95.02347,237.66309)">
                  <g id="eTVLWPn2yM638_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM638_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM638"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM638-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM639_to" transform="translate(95.02347,230.66309)">
                  <g id="eTVLWPn2yM639_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM639_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM639"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM639-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM640_to" transform="translate(94.62347,229.66309)">
                  <g id="eTVLWPn2yM640_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM640"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM640-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(94 0)" filter="url(#eTVLWPn2yM641-filter)">
                <path
                  id="eTVLWPn2yM642"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM642-stroke)"
                />
                <g id="eTVLWPn2yM643_to" transform="translate(95.02347,237.66309)">
                  <g id="eTVLWPn2yM643_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM643_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM643"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM643-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM644_to" transform="translate(95.02347,230.66309)">
                  <g id="eTVLWPn2yM644_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM644_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM644"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM644-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM645_to" transform="translate(94.62347,229.66309)">
                  <g id="eTVLWPn2yM645_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM645"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM645-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(11 0.000001)">
              <path
                d="M95.125,214.384v-86.716l17.88847-45.004911v-76"
                fill="none"
                stroke="url(#eTVLWPn2yM647-stroke)"
              />
              <g filter="url(#eTVLWPn2yM648-filter)">
                <path
                  id="eTVLWPn2yM649"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM649-stroke)"
                />
                <g id="eTVLWPn2yM650_to" transform="translate(95.349457,237.66309)">
                  <g id="eTVLWPn2yM650_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM650_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM650"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM650-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM651_to" transform="translate(95.349457,230.66309)">
                  <g id="eTVLWPn2yM651_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM651_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM651"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM651-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM652_to" transform="translate(95.349457,229.66309)">
                  <g id="eTVLWPn2yM652_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM652"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM652-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eTVLWPn2yM653-filter)">
                <path
                  id="eTVLWPn2yM654"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM654-stroke)"
                />
                <g id="eTVLWPn2yM655_to" transform="translate(95.349457,237.66309)">
                  <g id="eTVLWPn2yM655_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM655_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM655"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM655-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM656_to" transform="translate(95.349457,230.66309)">
                  <g id="eTVLWPn2yM656_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM656_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM656"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM656-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM657_to" transform="translate(95.349457,229.66309)">
                  <g id="eTVLWPn2yM657_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM657"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM657-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(4 0)">
              <path
                d="M48.3008,235.601v-107.92L84.1294,82.9597v-76.29661"
                fill="none"
                stroke="url(#eTVLWPn2yM659-stroke)"
              />
              <g filter="url(#eTVLWPn2yM660-filter)">
                <path
                  id="eTVLWPn2yM661"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM661-stroke)"
                />
                <g id="eTVLWPn2yM662_to" transform="translate(48.349457,135.66309)">
                  <g id="eTVLWPn2yM662_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM662_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM662"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0.9"
                        filter="url(#eTVLWPn2yM662-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM663_to" transform="translate(48.349457,130.66309)">
                  <g id="eTVLWPn2yM663_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM663_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM663"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        filter="url(#eTVLWPn2yM663-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM664_to" transform="translate(48.349457,129.66309)">
                  <g id="eTVLWPn2yM664_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM664"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      filter="url(#eTVLWPn2yM664-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eTVLWPn2yM665-filter)">
                <path
                  id="eTVLWPn2yM666"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM666-stroke)"
                />
                <g id="eTVLWPn2yM667_to" transform="translate(49.349457,237.66309)">
                  <g id="eTVLWPn2yM667_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM667_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM667"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM667-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM668_to" transform="translate(49.349457,230.66309)">
                  <g id="eTVLWPn2yM668_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM668_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM668"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM668-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM669_to" transform="translate(49.349457,229.66309)">
                  <g id="eTVLWPn2yM669_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM669"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM669-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eTVLWPn2yM670-filter)">
                <path
                  id="eTVLWPn2yM671"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM671-stroke)"
                />
                <g id="eTVLWPn2yM672_to" transform="translate(49.349457,237.66309)">
                  <rect
                    id="eTVLWPn2yM672"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eTVLWPn2yM672-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eTVLWPn2yM673_to" transform="translate(49.349457,230.66309)">
                  <rect
                    id="eTVLWPn2yM673"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eTVLWPn2yM673-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eTVLWPn2yM674_to" transform="translate(49.349457,229.66309)">
                  <rect
                    id="eTVLWPn2yM674"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eTVLWPn2yM674-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(134 0)">
              <path
                d="M1.46094,214.01v-86.346L55.705,82.9513v-76.28821"
                transform="translate(-137.088013 0)"
                fill="none"
                stroke="url(#eTVLWPn2yM676-stroke)"
              />
              <g filter="url(#eTVLWPn2yM677-filter)">
                <path
                  id="eTVLWPn2yM678"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM678-stroke)"
                />
                <g id="eTVLWPn2yM679_to" transform="translate(-135.650543,235.66309)">
                  <g id="eTVLWPn2yM679_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM679_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM679"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM679-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM680_to" transform="translate(-135.650543,230.66309)">
                  <g id="eTVLWPn2yM680_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM680_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM680"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM680-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM681_to" transform="translate(-135.650543,229.66309)">
                  <g id="eTVLWPn2yM681_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM681"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM681-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eTVLWPn2yM682-filter)">
                <path
                  id="eTVLWPn2yM683"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eTVLWPn2yM683-stroke)"
                />
                <g id="eTVLWPn2yM684_to" transform="translate(-135.650543,235.66309)">
                  <g id="eTVLWPn2yM684_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM684_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM684"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM684-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM685_to" transform="translate(-135.650543,230.66309)">
                  <g id="eTVLWPn2yM685_tr" transform="rotate(0)">
                    <g id="eTVLWPn2yM685_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eTVLWPn2yM685"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eTVLWPn2yM685-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eTVLWPn2yM686_to" transform="translate(-135.650543,229.66309)">
                  <g id="eTVLWPn2yM686_tr" transform="rotate(0)">
                    <rect
                      id="eTVLWPn2yM686"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eTVLWPn2yM686-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <rect
            width="328"
            height="61.866793"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1.616376 0 134)"
            fill="url(#eTVLWPn2yM687-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="eTVLWPn2yM688" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="328" height="234" rx="0" ry="0" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eTVLWPn2yM68 {
    animation-name: eTVLWPn2yM68__m, eTVLWPn2yM68_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM68__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    21.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes eTVLWPn2yM68_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    41.666667% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM69_to {
    animation: eTVLWPn2yM69_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM69_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    5% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    23.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    25% {
      transform: translate(-114.42px, 118.973437px);
    }
    33.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    35% {
      transform: translate(-156.650543px, 85.099607px);
    }
    36.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    46.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #eTVLWPn2yM69_tr {
    animation: eTVLWPn2yM69_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(-50deg);
    }
    36.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM69_ts {
    animation: eTVLWPn2yM69_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM69_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM69 {
    animation: eTVLWPn2yM69_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM69_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0.9;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM610_to {
    animation: eTVLWPn2yM610_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM610_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    5% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    23.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    25% {
      transform: translate(-118.131567px, 116.016274px);
    }
    33.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    35% {
      transform: translate(-157.988371px, 81.66309px);
    }
    36.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    46.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #eTVLWPn2yM610_tr {
    animation: eTVLWPn2yM610_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM610_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM610_ts {
    animation: eTVLWPn2yM610_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM610_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM610 {
    animation: eTVLWPn2yM610_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM610_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM611_to {
    animation: eTVLWPn2yM611_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM611_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    5% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    23.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    25% {
      transform: translate(-118.48136px, 115.681349px);
    }
    33.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    35% {
      transform: translate(-157.87653px, 80.828326px);
    }
    36.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    46.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #eTVLWPn2yM611_tr {
    animation: eTVLWPn2yM611_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM611_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM611 {
    animation: eTVLWPn2yM611_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM611_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM613 {
    animation-name: eTVLWPn2yM613__m, eTVLWPn2yM613_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM613__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    66.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    88.333333% {
      d: path('M-12.054849,90.545445L-12.054849,90.545445L-21.125297,82.9513L-21.125297,27.635917');
    }
    91.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes eTVLWPn2yM613_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    88.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM614_to {
    animation: eTVLWPn2yM614_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM614_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    50% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    68.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    70% {
      transform: translate(-114.42px, 118.973437px);
    }
    78.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    80% {
      transform: translate(-156.650543px, 85.099607px);
    }
    81.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    91.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #eTVLWPn2yM614_tr {
    animation: eTVLWPn2yM614_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM614_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(-50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM614_ts {
    animation: eTVLWPn2yM614_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM614_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM614 {
    animation: eTVLWPn2yM614_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM614_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    91.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM615_to {
    animation: eTVLWPn2yM615_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM615_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    50% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    68.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    70% {
      transform: translate(-118.131567px, 116.016274px);
    }
    78.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    80% {
      transform: translate(-157.988371px, 81.66309px);
    }
    81.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    91.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #eTVLWPn2yM615_tr {
    animation: eTVLWPn2yM615_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM615_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM615_ts {
    animation: eTVLWPn2yM615_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM615_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM615 {
    animation: eTVLWPn2yM615_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM615_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM616_to {
    animation: eTVLWPn2yM616_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM616_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    50% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    70% {
      transform: translate(-118.48136px, 115.681349px);
    }
    78.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    80% {
      transform: translate(-157.87653px, 80.828326px);
    }
    81.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    91.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #eTVLWPn2yM616_tr {
    animation: eTVLWPn2yM616_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM616_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM616 {
    animation: eTVLWPn2yM616_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM616_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM620 {
    animation-name: eTVLWPn2yM620__m, eTVLWPn2yM620_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM620__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    33.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    46.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.306743, 0.414094, 0.620378, 0.807255);
    }
    51.666667% {
      d: path('M-29.243604,87.852739L-29.243604,87.852739L-33.168515,83.071077L-33.255832,50.544574');
      animation-timing-function: cubic-bezier(0.308844, 0.6341, 0.639526, 1);
    }
    60% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes eTVLWPn2yM620_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    61.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM621_to {
    animation: eTVLWPn2yM621_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM621_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    35% {
      transform: translate(49.349457px, 129.993562px);
    }
    36.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    43.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    45% {
      transform: translate(17.349457px, 87.540794px);
    }
    46.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    48.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    58.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #eTVLWPn2yM621_tr {
    animation: eTVLWPn2yM621_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM621_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(0deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(-40deg);
    }
    48.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM621_ts {
    animation: eTVLWPn2yM621_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM621_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM621 {
    animation: eTVLWPn2yM621_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM621_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 0.9;
    }
    58.333333% {
      opacity: 0.9;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM622_to {
    animation: eTVLWPn2yM622_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM622_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    35% {
      transform: translate(48.011629px, 125.66309px);
    }
    36.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    43.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    45% {
      transform: translate(15.215426px, 85.140794px);
    }
    46.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    48.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    58.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #eTVLWPn2yM622_tr {
    animation: eTVLWPn2yM622_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM622_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM622_ts {
    animation: eTVLWPn2yM622_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM622_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM622 {
    animation: eTVLWPn2yM622_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM622_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM623_to {
    animation: eTVLWPn2yM623_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM623_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(47.093619px, 124.50245px);
    }
    36.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    43.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    45% {
      transform: translate(14.762322px, 84.381349px);
    }
    46.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    48.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    58.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #eTVLWPn2yM623_tr {
    animation: eTVLWPn2yM623_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM623_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM623 {
    animation: eTVLWPn2yM623_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM623_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM625 {
    animation-name: eTVLWPn2yM625__m, eTVLWPn2yM625_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM625__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    83.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    96.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.325674, 0.351957, 0.654677, 0.695819);
    }
    100% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
    }
  }
  @keyframes eTVLWPn2yM625_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eTVLWPn2yM626_to {
    animation: eTVLWPn2yM626_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM626_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    85% {
      transform: translate(49.349457px, 129.993562px);
    }
    86.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    93.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    95% {
      transform: translate(17.349457px, 87.540794px);
    }
    96.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    98.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    100% {
      transform: translate(13.965137px, 65.993562px);
    }
  }
  #eTVLWPn2yM626_tr {
    animation: eTVLWPn2yM626_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM626_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(-40deg);
    }
    98.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM626_ts {
    animation: eTVLWPn2yM626_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM626_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM626 {
    animation: eTVLWPn2yM626_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM626_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eTVLWPn2yM627_to {
    animation: eTVLWPn2yM627_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM627_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    85% {
      transform: translate(48.011629px, 125.66309px);
    }
    86.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    93.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    95% {
      transform: translate(15.215426px, 85.140794px);
    }
    96.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    98.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    100% {
      transform: translate(13.963603px, 61.496423px);
    }
  }
  #eTVLWPn2yM627_tr {
    animation: eTVLWPn2yM627_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM627_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM627_ts {
    animation: eTVLWPn2yM627_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM627_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM627 {
    animation: eTVLWPn2yM627_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM627_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eTVLWPn2yM628_to {
    animation: eTVLWPn2yM628_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM628_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(47.093619px, 124.50245px);
    }
    86.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    93.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    95% {
      transform: translate(14.762322px, 84.381349px);
    }
    96.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    98.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    100% {
      transform: translate(14.040137px, 60.578326px);
    }
  }
  #eTVLWPn2yM628_tr {
    animation: eTVLWPn2yM628_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM628_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM628 {
    animation: eTVLWPn2yM628_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM628_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eTVLWPn2yM630 {
    animation-name: eTVLWPn2yM630__m, eTVLWPn2yM630_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM630__m {
    0% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
      animation-timing-function: cubic-bezier(0.316312, 0.39148, 0.646318, 0.736182);
    }
    8.333333% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes eTVLWPn2yM630_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    5% {
      opacity: 1;
    }
    11.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM631_to {
    animation: eTVLWPn2yM631_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM631_to__to {
    0% {
      transform: translate(13.965137px, 65.993562px);
    }
    8.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #eTVLWPn2yM631 {
    animation: eTVLWPn2yM631_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM631_c_o {
    0% {
      opacity: 0.9;
    }
    8.333333% {
      opacity: 0.9;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM632_to {
    animation: eTVLWPn2yM632_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM632_to__to {
    0% {
      transform: translate(13.963603px, 61.496423px);
    }
    8.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #eTVLWPn2yM632 {
    animation: eTVLWPn2yM632_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM632_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM633_to {
    animation: eTVLWPn2yM633_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM633_to__to {
    0% {
      transform: translate(14.040137px, 60.578326px);
    }
    8.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #eTVLWPn2yM633 {
    animation: eTVLWPn2yM633_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM633_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM637 {
    animation-name: eTVLWPn2yM637__m, eTVLWPn2yM637_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM637__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    30% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    41.666667% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    51.666667% {
      d: path('M-14.393265,88.779336L-14.393265,88.779336L-16.643335,83.174861L-16.643335,23.521822');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    55% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes eTVLWPn2yM637_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.666667% {
      opacity: 1;
    }
    51.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM638_to {
    animation: eTVLWPn2yM638_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM638_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 136.66309px);
    }
    31.666667% {
      transform: translate(95.02347px, 129.993562px);
    }
    33.333333% {
      transform: translate(92.792379px, 121.74181px);
    }
    41.666667% {
      transform: translate(79.572206px, 88.521493px);
    }
    43.333333% {
      transform: translate(77.728902px, 84.993562px);
    }
    45% {
      transform: translate(77.02347px, 79.660944px);
    }
    55% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #eTVLWPn2yM638_tr {
    animation: eTVLWPn2yM638_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM638_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(-25deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM638_ts {
    animation: eTVLWPn2yM638_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM638_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM638 {
    animation: eTVLWPn2yM638_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM638_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 0.9;
    }
    55% {
      opacity: 0.9;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM639_to {
    animation: eTVLWPn2yM639_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM639_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 130.66309px);
    }
    31.666667% {
      transform: translate(94.17641px, 125.66309px);
    }
    33.333333% {
      transform: translate(91px, 117.66309px);
    }
    41.666667% {
      transform: translate(78.011629px, 85.140794px);
    }
    43.333333% {
      transform: translate(77px, 81.5px);
    }
    45% {
      transform: translate(77.02347px, 75.66309px);
    }
    55% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #eTVLWPn2yM639_tr {
    animation: eTVLWPn2yM639_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM639_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM639_ts {
    animation: eTVLWPn2yM639_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM639_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM639 {
    animation: eTVLWPn2yM639_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM639_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM640_to {
    animation: eTVLWPn2yM640_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM640_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    13.333333% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    31.666667% {
      transform: translate(93.59994px, 124.50245px);
    }
    33.333333% {
      transform: translate(90.3px, 116.5px);
    }
    41.666667% {
      transform: translate(77.51864px, 84.381349px);
    }
    43.333333% {
      transform: translate(77.12347px, 80.828326px);
    }
    45% {
      transform: translate(77.12347px, 75.328326px);
    }
    55% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #eTVLWPn2yM640_tr {
    animation: eTVLWPn2yM640_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM640_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM640 {
    animation: eTVLWPn2yM640_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM640_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM642 {
    animation-name: eTVLWPn2yM642__m, eTVLWPn2yM642_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM642__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    73.333333% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    85% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    93.333333% {
      d: path('M-11.477665,96.161688L-11.477665,96.161688L-16.577892,83.320735L-16.577892,35.774152');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    98.333333% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes eTVLWPn2yM642_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0.25;
    }
  }
  #eTVLWPn2yM643_to {
    animation: eTVLWPn2yM643_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM643_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 136.66309px);
    }
    75% {
      transform: translate(95.02347px, 129.993562px);
    }
    76.666667% {
      transform: translate(92.792379px, 121.74181px);
    }
    85% {
      transform: translate(79.572206px, 88.521493px);
    }
    86.666667% {
      transform: translate(77.728902px, 84.993562px);
    }
    88.333333% {
      transform: translate(77.02347px, 79.660944px);
    }
    98.333333% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #eTVLWPn2yM643_tr {
    animation: eTVLWPn2yM643_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM643_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(0deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(-25deg);
    }
    88.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM643_ts {
    animation: eTVLWPn2yM643_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM643_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM643 {
    animation: eTVLWPn2yM643_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM643_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 0.9;
    }
    98.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM644_to {
    animation: eTVLWPn2yM644_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM644_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 130.66309px);
    }
    75% {
      transform: translate(94.17641px, 125.66309px);
    }
    76.666667% {
      transform: translate(91px, 117.66309px);
    }
    85% {
      transform: translate(78.011629px, 85.140794px);
    }
    86.666667% {
      transform: translate(77px, 81.5px);
    }
    88.333333% {
      transform: translate(77.02347px, 75.66309px);
    }
    98.333333% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #eTVLWPn2yM644_tr {
    animation: eTVLWPn2yM644_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM644_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM644_ts {
    animation: eTVLWPn2yM644_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM644_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM644 {
    animation: eTVLWPn2yM644_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM644_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM645_to {
    animation: eTVLWPn2yM645_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM645_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    56.666667% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      transform: translate(93.59994px, 124.50245px);
    }
    76.666667% {
      transform: translate(90.3px, 116.5px);
    }
    85% {
      transform: translate(77.51864px, 84.381349px);
    }
    86.666667% {
      transform: translate(77.12347px, 80.828326px);
    }
    88.333333% {
      transform: translate(77.12347px, 75.328326px);
    }
    98.333333% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #eTVLWPn2yM645_tr {
    animation: eTVLWPn2yM645_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM645_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM645 {
    animation: eTVLWPn2yM645_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM645_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM649 {
    animation-name: eTVLWPn2yM649__m, eTVLWPn2yM649_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM649__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    25% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.666667% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    46.666667% {
      d: path('M17.127316,88.675201L17.144733,88.59732L19.483755,83.045699L19.705,23.39266');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    50% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes eTVLWPn2yM649_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    53.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM650_to {
    animation: eTVLWPn2yM650_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM650_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 136.66309px);
    }
    26.666667% {
      transform: translate(95.349457px, 127.493561px);
    }
    28.333333% {
      transform: translate(96.886038px, 122.125682px);
    }
    36.666667% {
      transform: translate(110.349457px, 88.540794px);
    }
    38.333333% {
      transform: translate(112px, 84.993562px);
    }
    40% {
      transform: translate(113.22347px, 79.460943px);
    }
    50% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #eTVLWPn2yM650_tr {
    animation: eTVLWPn2yM650_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM650_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(0deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(25deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM650_ts {
    animation: eTVLWPn2yM650_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM650_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM650 {
    animation: eTVLWPn2yM650_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM650_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.9;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM651_to {
    animation: eTVLWPn2yM651_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM651_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 130.66309px);
    }
    26.666667% {
      transform: translate(96.5px, 122.66309px);
    }
    28.333333% {
      transform: translate(99px, 117.66309px);
    }
    36.666667% {
      transform: translate(112.011629px, 85.140794px);
    }
    38.333333% {
      transform: translate(113px, 81px);
    }
    40% {
      transform: translate(113.22347px, 75.66309px);
    }
    50% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #eTVLWPn2yM651_tr {
    animation: eTVLWPn2yM651_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM651_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM651_ts {
    animation: eTVLWPn2yM651_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM651_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM651 {
    animation: eTVLWPn2yM651_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM651_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM652_to {
    animation: eTVLWPn2yM652_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM652_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    26.666667% {
      transform: translate(97px, 121.50245px);
    }
    28.333333% {
      transform: translate(99.5px, 116.5px);
    }
    36.666667% {
      transform: translate(112.51864px, 84.381349px);
    }
    38.333333% {
      transform: translate(113px, 80px);
    }
    40% {
      transform: translate(113.12347px, 74.828326px);
    }
    50% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #eTVLWPn2yM652_tr {
    animation: eTVLWPn2yM652_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM652_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM652 {
    animation: eTVLWPn2yM652_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM652_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM654 {
    animation-name: eTVLWPn2yM654__m, eTVLWPn2yM654_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM654__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    68.333333% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M14.183989,95.925645L14.198135,95.749112L19.525321,83.027964L19.705,35.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes eTVLWPn2yM654_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM655_to {
    animation: eTVLWPn2yM655_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM655_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 136.66309px);
    }
    70% {
      transform: translate(95.349457px, 127.493561px);
    }
    71.666667% {
      transform: translate(96.886038px, 122.125682px);
    }
    80% {
      transform: translate(110.349457px, 88.540794px);
    }
    81.666667% {
      transform: translate(112px, 84.993562px);
    }
    83.333333% {
      transform: translate(113.22347px, 79.460943px);
    }
    93.333333% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #eTVLWPn2yM655_tr {
    animation: eTVLWPn2yM655_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM655_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(25deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM655_ts {
    animation: eTVLWPn2yM655_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM655_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM655 {
    animation: eTVLWPn2yM655_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM655_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM656_to {
    animation: eTVLWPn2yM656_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM656_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 130.66309px);
    }
    70% {
      transform: translate(96.5px, 122.66309px);
    }
    71.666667% {
      transform: translate(99px, 117.66309px);
    }
    80% {
      transform: translate(112.011629px, 85.140794px);
    }
    81.666667% {
      transform: translate(113px, 81px);
    }
    83.333333% {
      transform: translate(113.22347px, 75.66309px);
    }
    93.333333% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #eTVLWPn2yM656_tr {
    animation: eTVLWPn2yM656_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM656_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM656_ts {
    animation: eTVLWPn2yM656_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM656_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM656 {
    animation: eTVLWPn2yM656_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM656_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM657_to {
    animation: eTVLWPn2yM657_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM657_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(97px, 121.50245px);
    }
    71.666667% {
      transform: translate(99.5px, 116.5px);
    }
    80% {
      transform: translate(112.51864px, 84.381349px);
    }
    81.666667% {
      transform: translate(113px, 80px);
    }
    83.333333% {
      transform: translate(113.12347px, 74.828326px);
    }
    93.333333% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #eTVLWPn2yM657_tr {
    animation: eTVLWPn2yM657_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM657_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM657 {
    animation: eTVLWPn2yM657_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM657_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM661 {
    animation-name: eTVLWPn2yM661__m, eTVLWPn2yM661_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM661__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    13.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.705,82.9513L36.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    16.666667% {
      d: path('M11.808104,114.29263L36.733725,83.0597L36.733725,82.983036L36.779932,59.996492');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    26.666667% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes eTVLWPn2yM661_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    28.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM662_to {
    animation: eTVLWPn2yM662_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM662_to__to {
    0% {
      transform: translate(48.349457px, 135.66309px);
    }
    1.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    3.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    11.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    13.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    15% {
      transform: translate(84.01347px, 79.993562px);
    }
    25% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #eTVLWPn2yM662_tr {
    animation: eTVLWPn2yM662_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM662_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(0deg);
    }
    3.333333% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(40deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM662_ts {
    animation: eTVLWPn2yM662_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM662_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM662 {
    animation: eTVLWPn2yM662_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM662_c_o {
    0% {
      opacity: 0.9;
    }
    25% {
      opacity: 0.9;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM663_to {
    animation: eTVLWPn2yM663_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM663_to__to {
    0% {
      transform: translate(48.349457px, 130.66309px);
    }
    1.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    3.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    11.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    13.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    15% {
      transform: translate(84.011629px, 75.66309px);
    }
    25% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #eTVLWPn2yM663_tr {
    animation: eTVLWPn2yM663_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM663_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM663_ts {
    animation: eTVLWPn2yM663_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM663_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM663 {
    animation: eTVLWPn2yM663_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM663_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM664_to {
    animation: eTVLWPn2yM664_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM664_to__to {
    0% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    1.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    3.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    11.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    13.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    15% {
      transform: translate(84.12347px, 74.828326px);
    }
    25% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #eTVLWPn2yM664_tr {
    animation: eTVLWPn2yM664_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM664_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM664 {
    animation: eTVLWPn2yM664_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM664_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM666 {
    animation-name: eTVLWPn2yM666__m, eTVLWPn2yM666_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM666__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
    }
    46.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.882028,83.113746L36.960941,83.0597');
      animation-timing-function: cubic-bezier(0.38481, 0, 0.70726, 0.403804);
    }
    61.666667% {
      d: path('M7.458603,119.746485L36.882027,83.059701L36.868636,83.104574L36.960941,69.691269');
      animation-timing-function: cubic-bezier(0.330218, 0.34364, 0.565924, 1);
    }
    70% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes eTVLWPn2yM666_c_o {
    0% {
      opacity: 0;
    }
    43.333333% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    66.666667% {
      opacity: 1;
    }
    73.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM667_to {
    animation: eTVLWPn2yM667_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM667_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 135.66309px);
    }
    46.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    48.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    56.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    58.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    60% {
      transform: translate(84.01347px, 79.993562px);
    }
    70% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #eTVLWPn2yM667_tr {
    animation: eTVLWPn2yM667_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM667_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    48.333333% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(40deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM667_ts {
    animation: eTVLWPn2yM667_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM667_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM667 {
    animation: eTVLWPn2yM667_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM667_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 0.9;
    }
    70% {
      opacity: 0.9;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM668_to {
    animation: eTVLWPn2yM668_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM668_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 130.66309px);
    }
    46.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    48.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    56.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    58.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    60% {
      transform: translate(84.011629px, 75.66309px);
    }
    70% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #eTVLWPn2yM668_tr {
    animation: eTVLWPn2yM668_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM668_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM668_ts {
    animation: eTVLWPn2yM668_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM668_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM668 {
    animation: eTVLWPn2yM668_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM668_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM669_to {
    animation: eTVLWPn2yM669_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM669_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    48.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    56.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    58.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    60% {
      transform: translate(84.12347px, 74.828326px);
    }
    70% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #eTVLWPn2yM669_tr {
    animation: eTVLWPn2yM669_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM669_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM669 {
    animation: eTVLWPn2yM669_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM669_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM671 {
    animation: eTVLWPn2yM671_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM671_c_o {
    0% {
      opacity: 0;
    }
    98.333333% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #eTVLWPn2yM672_to {
    animation: eTVLWPn2yM672_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM672_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 135.66309px);
    }
  }
  #eTVLWPn2yM672 {
    animation: eTVLWPn2yM672_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM672_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eTVLWPn2yM673_to {
    animation: eTVLWPn2yM673_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM673_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 130.66309px);
    }
  }
  #eTVLWPn2yM673 {
    animation: eTVLWPn2yM673_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM673_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eTVLWPn2yM674_to {
    animation: eTVLWPn2yM674_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM674_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 129.66309px);
    }
  }
  #eTVLWPn2yM674 {
    animation: eTVLWPn2yM674_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM674_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eTVLWPn2yM678 {
    animation-name: eTVLWPn2yM678__m, eTVLWPn2yM678_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM678__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    21.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    38.333333% {
      d: path('M17.077982,114.999859L17.077982,114.573417L55.412229,82.9513L55.412229,49.638019');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    46.666667% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes eTVLWPn2yM678_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    43.333333% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM679_to {
    animation: eTVLWPn2yM679_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM679_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 135.66309px);
    }
    21.666667% {
      transform: translate(-135.650543px, 130.993562px);
    }
    23.333333% {
      transform: translate(-129.28364px, 121.74181px);
    }
    31.666667% {
      transform: translate(-86.650543px, 86.993562px);
    }
    33.333333% {
      transform: translate(-83.650543px, 83.993562px);
    }
    35% {
      transform: translate(-81.98653px, 79.993562px);
    }
    45% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #eTVLWPn2yM679_tr {
    animation: eTVLWPn2yM679_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM679_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM679_ts {
    animation: eTVLWPn2yM679_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM679_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM679 {
    animation: eTVLWPn2yM679_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM679_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0.9;
    }
    45% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM680_to {
    animation: eTVLWPn2yM680_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM680_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 130.66309px);
    }
    21.666667% {
      transform: translate(-133.988371px, 126.66309px);
    }
    23.333333% {
      transform: translate(-125.655038px, 118.66309px);
    }
    31.666667% {
      transform: translate(-83.988371px, 84.66309px);
    }
    33.333333% {
      transform: translate(-81.988371px, 80.66309px);
    }
    35% {
      transform: translate(-81.988371px, 75.66309px);
    }
    45% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #eTVLWPn2yM680_tr {
    animation: eTVLWPn2yM680_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM680_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM680_ts {
    animation: eTVLWPn2yM680_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM680_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM680 {
    animation: eTVLWPn2yM680_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM680_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM681_to {
    animation: eTVLWPn2yM681_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM681_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-132.650543px, 125.50245px);
    }
    23.333333% {
      transform: translate(-125.48136px, 118.381349px);
    }
    31.666667% {
      transform: translate(-83.48136px, 84.381349px);
    }
    33.333333% {
      transform: translate(-81.87653px, 79.828326px);
    }
    35% {
      transform: translate(-81.87653px, 74.828326px);
    }
    45% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #eTVLWPn2yM681_tr {
    animation: eTVLWPn2yM681_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM681_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM681 {
    animation: eTVLWPn2yM681_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM681_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM683 {
    animation-name: eTVLWPn2yM683__m, eTVLWPn2yM683_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eTVLWPn2yM683__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    68.333333% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.430309, 0, 0.626686, 0.804094);
    }
    78.333333% {
      d: path('M1.46094,128.256464L1.46094,127.664L46.219884,90.745962L46.219884,90.745961');
      animation-timing-function: cubic-bezier(0.310712, 0.637203, 0.641852, 1);
    }
    80% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M39.186071,96.218416L39.186071,96.041883L54.997771,82.9513L54.997771,34.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes eTVLWPn2yM683_c_o {
    0% {
      opacity: 0;
    }
    61.666667% {
      opacity: 0;
    }
    63.333333% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM684_to {
    animation: eTVLWPn2yM684_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM684_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 135.66309px);
    }
    70% {
      transform: translate(-135.650543px, 130.993562px);
    }
    71.666667% {
      transform: translate(-129.28364px, 121.74181px);
    }
    80% {
      transform: translate(-86.650543px, 86.993562px);
    }
    81.666667% {
      transform: translate(-83.650543px, 83.993562px);
    }
    83.333333% {
      transform: translate(-81.98653px, 79.993562px);
    }
    93.333333% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #eTVLWPn2yM684_tr {
    animation: eTVLWPn2yM684_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM684_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(50deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM684_ts {
    animation: eTVLWPn2yM684_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM684_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM684 {
    animation: eTVLWPn2yM684_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM684_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM685_to {
    animation: eTVLWPn2yM685_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM685_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 130.66309px);
    }
    70% {
      transform: translate(-133.988371px, 126.66309px);
    }
    71.666667% {
      transform: translate(-125.655038px, 118.66309px);
    }
    80% {
      transform: translate(-83.988371px, 84.66309px);
    }
    81.666667% {
      transform: translate(-81.988371px, 80.66309px);
    }
    83.333333% {
      transform: translate(-81.988371px, 75.66309px);
    }
    93.333333% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #eTVLWPn2yM685_tr {
    animation: eTVLWPn2yM685_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM685_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM685_ts {
    animation: eTVLWPn2yM685_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM685_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eTVLWPn2yM685 {
    animation: eTVLWPn2yM685_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM685_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eTVLWPn2yM686_to {
    animation: eTVLWPn2yM686_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM686_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(-132.650543px, 125.50245px);
    }
    71.666667% {
      transform: translate(-125.48136px, 118.381349px);
    }
    80% {
      transform: translate(-83.48136px, 84.381349px);
    }
    81.666667% {
      transform: translate(-81.87653px, 79.828326px);
    }
    83.333333% {
      transform: translate(-81.87653px, 74.828326px);
    }
    93.333333% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #eTVLWPn2yM686_tr {
    animation: eTVLWPn2yM686_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM686_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eTVLWPn2yM686 {
    animation: eTVLWPn2yM686_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eTVLWPn2yM686_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg6;
