// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg4 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eAvhQKabB9o1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 750 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="750"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eAvhQKabB9o8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o8-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o9-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o9-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o10-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o11-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o11-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o11-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o11-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o11-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o11-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o11-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o12-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o13-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o13-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o13-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o13-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o13-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o13-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o13-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o13-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o14-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o14-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o14-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o15-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o16-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o17-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o23-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o24-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o24-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o24-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o25-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o26-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o27-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o28-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o29-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o29-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o29-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o30-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o30-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o30-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o30-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o30-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o30-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o30-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o31-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o32-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o33-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o34-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o34-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o34-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o35-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o36-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o37-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o41-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o42-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o42-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o42-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o43-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o44-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o46-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o47-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o47-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o47-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o48-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o49-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o50-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o51-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o52-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eAvhQKabB9o52-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eAvhQKabB9o52-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eAvhQKabB9o53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o53-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o54-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o55-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o60-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o61-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eAvhQKabB9o61-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eAvhQKabB9o61-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eAvhQKabB9o62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o62-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o63-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o63-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o63-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o63-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o63-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o63-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o63-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o64-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o65-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o66-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eAvhQKabB9o66-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eAvhQKabB9o66-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eAvhQKabB9o67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o67-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o68-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o69-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eAvhQKabB9o70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eAvhQKabB9o70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o70-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eAvhQKabB9o70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eAvhQKabB9o71-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eAvhQKabB9o71-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eAvhQKabB9o71-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eAvhQKabB9o72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o72-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o73-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o73-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eAvhQKabB9o73-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eAvhQKabB9o73-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eAvhQKabB9o73-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eAvhQKabB9o73-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eAvhQKabB9o73-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eAvhQKabB9o73-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eAvhQKabB9o74-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eAvhQKabB9o74-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#eAvhQKabB9o78)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-62.911987 1.012683)">
            <g mask="url(#eAvhQKabB9o19)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o8-filter)">
                  <path
                    id="eAvhQKabB9o9"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eAvhQKabB9o9-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eAvhQKabB9o10_to" transform="translate(846.5,55.771297)">
                    <g id="eAvhQKabB9o10_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o10_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o10"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eAvhQKabB9o10-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o11_to" transform="translate(846.5,49.271297)">
                    <g id="eAvhQKabB9o11_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o11_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o11"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eAvhQKabB9o11-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o12_to" transform="translate(846.5,51.771297)">
                    <g id="eAvhQKabB9o12_tr" transform="rotate(0)">
                      <rect
                        id="eAvhQKabB9o12"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o12-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o13-filter)">
                  <path
                    id="eAvhQKabB9o14"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eAvhQKabB9o14-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eAvhQKabB9o15_to" transform="translate(846.5,55.771297)">
                    <g id="eAvhQKabB9o15_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o15_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o15"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eAvhQKabB9o15-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o16_to" transform="translate(846.5,49.271297)">
                    <g id="eAvhQKabB9o16_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o16_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o16"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eAvhQKabB9o16-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o17_to" transform="translate(846.5,50.771297)">
                    <g id="eAvhQKabB9o17_tr" transform="rotate(0)">
                      <rect
                        id="eAvhQKabB9o17"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o17-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eAvhQKabB9o19" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 605.931986-1.012683)"
                  fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o23-filter)">
                <path
                  id="eAvhQKabB9o24"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eAvhQKabB9o24-stroke)"
                  strokeLinecap="round"
                />
                <g id="eAvhQKabB9o25_to" transform="translate(656.203897,77.481243)">
                  <g id="eAvhQKabB9o25_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o25_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o25"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eAvhQKabB9o25-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o26_to" transform="translate(656.203897,72.170302)">
                  <g id="eAvhQKabB9o26_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o26_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o26"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eAvhQKabB9o26-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o27_to" transform="translate(656.411986,71.237316)">
                  <g id="eAvhQKabB9o27_tr" transform="rotate(0)">
                    <rect
                      id="eAvhQKabB9o27"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eAvhQKabB9o27-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o28-filter)">
                <path
                  id="eAvhQKabB9o29"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eAvhQKabB9o29-stroke)"
                  strokeLinecap="round"
                />
                <g id="eAvhQKabB9o30_to" transform="translate(656.798427,143.771297)">
                  <g id="eAvhQKabB9o30_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o30_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o30"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o30-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o31_to" transform="translate(656.798427,137.271297)">
                  <g id="eAvhQKabB9o31_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o31_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o31"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o31-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o32_to" transform="translate(656.798427,136.771297)">
                  <g id="eAvhQKabB9o32_tr" transform="rotate(0)">
                    <rect
                      id="eAvhQKabB9o32"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o32-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o33-filter)">
                <path
                  id="eAvhQKabB9o34"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eAvhQKabB9o34-stroke)"
                  strokeLinecap="round"
                />
                <g id="eAvhQKabB9o35_to" transform="translate(656.798427,143.771297)">
                  <rect
                    id="eAvhQKabB9o35"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eAvhQKabB9o35-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eAvhQKabB9o36_to" transform="translate(656.798427,137.271297)">
                  <rect
                    id="eAvhQKabB9o36"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eAvhQKabB9o36-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eAvhQKabB9o37_to" transform="translate(656.798427,135.771297)">
                  <rect
                    id="eAvhQKabB9o37"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eAvhQKabB9o37-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g>
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o41-filter)">
                <path
                  id="eAvhQKabB9o42"
                  d="M292.146,229.76v-108.078l.000003.940405h-.000001"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eAvhQKabB9o42-stroke)"
                  strokeLinecap="round"
                />
                <g id="eAvhQKabB9o43_to" transform="translate(357.798427,19.271297)">
                  <g id="eAvhQKabB9o43_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o43_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o43"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eAvhQKabB9o43-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o44_to" transform="translate(361.798427,14.771297)">
                  <g id="eAvhQKabB9o44_tr" transform="rotate(65)">
                    <g id="eAvhQKabB9o44_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o44"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eAvhQKabB9o44-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o45_to" transform="translate(362.798427,14.271297)">
                  <g id="eAvhQKabB9o45_tr" transform="rotate(65)">
                    <rect
                      id="eAvhQKabB9o45"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eAvhQKabB9o45-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o46-filter)">
                <path
                  id="eAvhQKabB9o47"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eAvhQKabB9o47-stroke)"
                  strokeLinecap="round"
                />
                <g id="eAvhQKabB9o48_to" transform="translate(357.5,143.771297)">
                  <g id="eAvhQKabB9o48_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o48_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o48-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o49_to" transform="translate(357.5,137.271297)">
                  <g id="eAvhQKabB9o49_tr" transform="rotate(0)">
                    <g id="eAvhQKabB9o49_ts" transform="scale(1,1)">
                      <rect
                        id="eAvhQKabB9o49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o49-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eAvhQKabB9o50_to" transform="translate(357.5,138.771297)">
                  <g id="eAvhQKabB9o50_tr" transform="rotate(0)">
                    <rect
                      id="eAvhQKabB9o50"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o50-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o51-filter)">
                <path
                  id="eAvhQKabB9o52"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eAvhQKabB9o52-stroke)"
                  strokeLinecap="round"
                />
                <g id="eAvhQKabB9o53_to" transform="translate(357.5,143.771297)">
                  <rect
                    id="eAvhQKabB9o53"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eAvhQKabB9o53-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eAvhQKabB9o54_to" transform="translate(357.5,137.271297)">
                  <g id="eAvhQKabB9o54_tr" transform="rotate(0)">
                    <rect
                      id="eAvhQKabB9o54"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o54-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eAvhQKabB9o55_to" transform="translate(357.5,138.771297)">
                  <g id="eAvhQKabB9o55_tr" transform="rotate(0)">
                    <rect
                      id="eAvhQKabB9o55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o55-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eAvhQKabB9o76)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o60-filter)">
                  <path
                    id="eAvhQKabB9o61"
                    d="M96.7109,145.439636v-17.754678l.101249-.122374c0,0,.08233.25.08233.25"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eAvhQKabB9o61-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eAvhQKabB9o62_to" transform="translate(161.798427,27.271297)">
                    <g id="eAvhQKabB9o62_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o62_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o62"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eAvhQKabB9o62-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o63_to" transform="translate(161.798427,23.771297)">
                    <g id="eAvhQKabB9o63_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o63_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o63"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eAvhQKabB9o63-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o64_to" transform="translate(161.798427,23.271297)">
                    <g id="eAvhQKabB9o64_tr" transform="rotate(0)">
                      <rect
                        id="eAvhQKabB9o64"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eAvhQKabB9o64-filter)"
                        fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o65-filter)">
                  <path
                    id="eAvhQKabB9o66"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eAvhQKabB9o66-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eAvhQKabB9o67_to" transform="translate(161.798427,53.771297)">
                    <g id="eAvhQKabB9o67_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o67_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o67"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eAvhQKabB9o67-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o68_to" transform="translate(161.798427,47.271297)">
                    <g id="eAvhQKabB9o68_tr" transform="rotate(0)">
                      <g id="eAvhQKabB9o68_ts" transform="scale(1,1)">
                        <rect
                          id="eAvhQKabB9o68"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eAvhQKabB9o68-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eAvhQKabB9o69_to" transform="translate(161.798427,49.771297)">
                    <g id="eAvhQKabB9o69_tr" transform="rotate(0)">
                      <rect
                        id="eAvhQKabB9o69"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eAvhQKabB9o69-filter)"
                        fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eAvhQKabB9o70-filter)">
                  <path
                    id="eAvhQKabB9o71"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eAvhQKabB9o71-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eAvhQKabB9o72_to" transform="translate(161.798427,53.771297)">
                    <rect
                      id="eAvhQKabB9o72"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o72-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eAvhQKabB9o73_to" transform="translate(161.798427,47.271297)">
                    <rect
                      id="eAvhQKabB9o73"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o73-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eAvhQKabB9o74_to" transform="translate(161.798427,50.771297)">
                    <rect
                      id="eAvhQKabB9o74"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eAvhQKabB9o74-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eAvhQKabB9o76" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 75.931986-1.012683)"
                  fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
          </g>
        </g>
        <mask id="eAvhQKabB9o78" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="896.38596"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eAvhQKabB9o9 {
    animation-name: eAvhQKabB9o9__m, eAvhQKabB9o9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o9__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eAvhQKabB9o9_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o10_to {
    animation: eAvhQKabB9o10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o10_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    12.5% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -116.228703px);
    }
    100% {
      transform: translate(693.9px, -116.228703px);
    }
  }
  #eAvhQKabB9o10_tr {
    animation: eAvhQKabB9o10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(-73deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o10_ts {
    animation: eAvhQKabB9o10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o10_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o10 {
    animation: eAvhQKabB9o10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o10_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o11_to {
    animation: eAvhQKabB9o11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o11_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    12.5% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eAvhQKabB9o11_tr {
    animation: eAvhQKabB9o11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o11_ts {
    animation: eAvhQKabB9o11_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o11_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o11 {
    animation: eAvhQKabB9o11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o11_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o12_to {
    animation: eAvhQKabB9o12_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o12_to__to {
    0% {
      transform: translate(846.5px, 51.771297px);
    }
    12.5% {
      transform: translate(846.5px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eAvhQKabB9o12_tr {
    animation: eAvhQKabB9o12_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o12_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o12 {
    animation: eAvhQKabB9o12_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o12_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o14 {
    animation-name: eAvhQKabB9o14__m, eAvhQKabB9o14_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o14__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eAvhQKabB9o14_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o15_to {
    animation: eAvhQKabB9o15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o15_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    51.25% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -115.228703px);
    }
    100% {
      transform: translate(693.9px, -115.228703px);
    }
  }
  #eAvhQKabB9o15_tr {
    animation: eAvhQKabB9o15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o15_ts {
    animation: eAvhQKabB9o15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o15 {
    animation: eAvhQKabB9o15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o15_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.8;
    }
    86.25% {
      opacity: 0.8;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o16_to {
    animation: eAvhQKabB9o16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o16_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    51.25% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eAvhQKabB9o16_tr {
    animation: eAvhQKabB9o16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o16_ts {
    animation: eAvhQKabB9o16_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o16_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o16 {
    animation: eAvhQKabB9o16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o16_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.9;
    }
    86.25% {
      opacity: 0.9;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o17_to {
    animation: eAvhQKabB9o17_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o17_to__to {
    0% {
      transform: translate(846.5px, 50.771297px);
    }
    51.25% {
      transform: translate(846.5px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eAvhQKabB9o17_tr {
    animation: eAvhQKabB9o17_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o17_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o17 {
    animation: eAvhQKabB9o17_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o17_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o24 {
    animation-name: eAvhQKabB9o24__m, eAvhQKabB9o24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o24__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    30% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eAvhQKabB9o24_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o25_to {
    animation: eAvhQKabB9o25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o25_to__to {
    0% {
      transform: translate(656.203897px, 77.481243px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(583.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(583.9px, -109.228703px);
    }
    100% {
      transform: translate(583.9px, -109.228703px);
    }
  }
  #eAvhQKabB9o25_tr {
    animation: eAvhQKabB9o25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(-60deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o25_ts {
    animation: eAvhQKabB9o25_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o25_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o25 {
    animation: eAvhQKabB9o25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o25_c_o {
    0% {
      opacity: 0.8;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o26_to {
    animation: eAvhQKabB9o26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o26_to__to {
    0% {
      transform: translate(656.203897px, 72.170302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(583.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(583.9px, -115.728703px);
    }
    100% {
      transform: translate(583.9px, -115.728703px);
    }
  }
  #eAvhQKabB9o26_tr {
    animation: eAvhQKabB9o26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o26_ts {
    animation: eAvhQKabB9o26_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o26_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o26 {
    animation: eAvhQKabB9o26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o26_c_o {
    0% {
      opacity: 0.9;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o27_to {
    animation: eAvhQKabB9o27_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o27_to__to {
    0% {
      transform: translate(656.411986px, 71.237316px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(583.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(583.9px, -115.228703px);
    }
    100% {
      transform: translate(583.9px, -115.228703px);
    }
  }
  #eAvhQKabB9o27_tr {
    animation: eAvhQKabB9o27_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o27 {
    animation: eAvhQKabB9o27_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o27_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o29 {
    animation-name: eAvhQKabB9o29__m, eAvhQKabB9o29_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o29__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    72.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eAvhQKabB9o29_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o30_to {
    animation: eAvhQKabB9o30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o30_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -111.228703px);
    }
    100% {
      transform: translate(583.9px, -111.228703px);
    }
  }
  #eAvhQKabB9o30_tr {
    animation: eAvhQKabB9o30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(-60deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o30_ts {
    animation: eAvhQKabB9o30_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o30_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o30 {
    animation: eAvhQKabB9o30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o30_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o31_to {
    animation: eAvhQKabB9o31_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o31_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    37.5% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -116.728703px);
    }
    100% {
      transform: translate(583.9px, -116.728703px);
    }
  }
  #eAvhQKabB9o31_tr {
    animation: eAvhQKabB9o31_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o31_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o31_ts {
    animation: eAvhQKabB9o31_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o31_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o31 {
    animation: eAvhQKabB9o31_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o31_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o32_to {
    animation: eAvhQKabB9o32_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o32_to__to {
    0% {
      transform: translate(656.798427px, 136.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -118.228703px);
    }
    100% {
      transform: translate(583.9px, -118.228703px);
    }
  }
  #eAvhQKabB9o32_tr {
    animation: eAvhQKabB9o32_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o32_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o32 {
    animation: eAvhQKabB9o32_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o32_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o34 {
    animation-name: eAvhQKabB9o34__m, eAvhQKabB9o34_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o34__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    93.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
  }
  @keyframes eAvhQKabB9o34_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o35_to {
    animation: eAvhQKabB9o35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o35_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    90% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 77.481243px);
    }
  }
  #eAvhQKabB9o35 {
    animation: eAvhQKabB9o35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o35_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o36_to {
    animation: eAvhQKabB9o36_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o36_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    90% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 72.170302px);
    }
  }
  #eAvhQKabB9o36 {
    animation: eAvhQKabB9o36_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o36_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.928571;
    }
  }
  #eAvhQKabB9o37_to {
    animation: eAvhQKabB9o37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o37_to__to {
    0% {
      transform: translate(656.798427px, 135.771297px);
    }
    90% {
      transform: translate(656.798427px, 135.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 70.481243px);
    }
  }
  #eAvhQKabB9o37 {
    animation: eAvhQKabB9o37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o37_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o42 {
    animation-name: eAvhQKabB9o42__m, eAvhQKabB9o42_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o42__m {
    0% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    13.75% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    18.75% {
      d: path('M316.216154,108.23846L316.216154,108.04326L370.374,76.7761L370.374,20.292588');
    }
    25% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    30% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eAvhQKabB9o42_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.25% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o43_to {
    animation: eAvhQKabB9o43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o43_to__to {
    0% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    1.25% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    12.5% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    13.75% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    15% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    26.25% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eAvhQKabB9o43_tr {
    animation: eAvhQKabB9o43_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o43_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(65deg);
    }
    13.75% {
      transform: rotate(65deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o43_ts {
    animation: eAvhQKabB9o43_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o43_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    12.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o43 {
    animation: eAvhQKabB9o43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o43_c_o {
    0% {
      opacity: 0.8;
    }
    26.25% {
      opacity: 0.8;
    }
    27.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o44_to {
    animation: eAvhQKabB9o44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o44_to__to {
    0% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    1.25% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    12.5% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    13.75% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    15% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    26.25% {
      transform: translate(435.7px, -121.728703px);
    }
    100% {
      transform: translate(435.7px, -121.728703px);
    }
  }
  #eAvhQKabB9o44_tr {
    animation: eAvhQKabB9o44_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o44_tr__tr {
    0% {
      transform: rotate(65deg);
    }
    12.5% {
      transform: rotate(65deg);
    }
    13.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o44_ts {
    animation: eAvhQKabB9o44_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o44_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    12.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o44 {
    animation: eAvhQKabB9o44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o44_c_o {
    0% {
      opacity: 0.9;
    }
    26.25% {
      opacity: 0.9;
    }
    27.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o45_to {
    animation: eAvhQKabB9o45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o45_to__to {
    0% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    1.25% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    12.5% {
      transform: translate(433.502724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    13.75% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    15% {
      transform: translate(435.7px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    26.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eAvhQKabB9o45_tr {
    animation: eAvhQKabB9o45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o45_tr__tr {
    0% {
      transform: rotate(65deg);
    }
    12.5% {
      transform: rotate(65deg);
    }
    13.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o45 {
    animation: eAvhQKabB9o45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o45_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    26.25% {
      opacity: 1;
    }
    27.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o47 {
    animation-name: eAvhQKabB9o47__m, eAvhQKabB9o47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o47__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    37.5% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    43.75% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    53.75% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    67.5% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    78.75% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    83.75% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eAvhQKabB9o47_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o48_to {
    animation: eAvhQKabB9o48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o48_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    37.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eAvhQKabB9o48_tr {
    animation: eAvhQKabB9o48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(65deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o48_ts {
    animation: eAvhQKabB9o48_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o48_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o48 {
    animation: eAvhQKabB9o48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o48_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o49_to {
    animation: eAvhQKabB9o49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o49_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    37.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eAvhQKabB9o49_tr {
    animation: eAvhQKabB9o49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o49_ts {
    animation: eAvhQKabB9o49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o49 {
    animation: eAvhQKabB9o49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o49_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0.9;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o50_to {
    animation: eAvhQKabB9o50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o50_to__to {
    0% {
      transform: translate(357.5px, 138.771297px);
    }
    37.5% {
      transform: translate(357.5px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eAvhQKabB9o50_tr {
    animation: eAvhQKabB9o50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o50 {
    animation: eAvhQKabB9o50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o50_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o52 {
    animation-name: eAvhQKabB9o52__m, eAvhQKabB9o52_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o52__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    83.75% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    90% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    100% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
  }
  @keyframes eAvhQKabB9o52_c_o {
    0% {
      opacity: 0;
    }
    82.5% {
      opacity: 0;
    }
    83.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o53_to {
    animation: eAvhQKabB9o53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o53_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    83.75% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    98.75% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    100% {
      transform: translate(357.798427px, 19.271297px);
    }
  }
  #eAvhQKabB9o53 {
    animation: eAvhQKabB9o53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o53_c_o {
    0% {
      opacity: 0;
    }
    82.5% {
      opacity: 0;
    }
    83.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eAvhQKabB9o54_to {
    animation: eAvhQKabB9o54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o54_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    83.75% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    98.75% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    100% {
      transform: translate(361.798427px, 14.771297px);
    }
  }
  #eAvhQKabB9o54_tr {
    animation: eAvhQKabB9o54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(65deg);
    }
  }
  #eAvhQKabB9o54 {
    animation: eAvhQKabB9o54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o54_c_o {
    0% {
      opacity: 0;
    }
    82.5% {
      opacity: 0;
    }
    83.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eAvhQKabB9o55_to {
    animation: eAvhQKabB9o55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o55_to__to {
    0% {
      transform: translate(357.5px, 138.771297px);
    }
    83.75% {
      transform: translate(357.5px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    98.75% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    100% {
      transform: translate(362.798427px, 14.271297px);
    }
  }
  #eAvhQKabB9o55_tr {
    animation: eAvhQKabB9o55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(65deg);
    }
  }
  #eAvhQKabB9o55 {
    animation: eAvhQKabB9o55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o55_c_o {
    0% {
      opacity: 0;
    }
    82.5% {
      opacity: 0;
    }
    83.75% {
      opacity: 1;
    }
    100% {
      opacity: 0.927778;
    }
  }
  #eAvhQKabB9o61 {
    animation-name: eAvhQKabB9o61__m, eAvhQKabB9o61_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o61__m {
    0% {
      d: path(
        'M96.7109,145.439636L96.7109,127.684958L96.812149,127.562584C96.812149,127.562584,96.894479,127.812584,96.894479,127.812584'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    21.25% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,33.289957,259.817001,33.289957'
      );
    }
    26.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eAvhQKabB9o61_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o62_to {
    animation: eAvhQKabB9o62_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o62_to__to {
    0% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eAvhQKabB9o62_tr {
    animation: eAvhQKabB9o62_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o62_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o62_ts {
    animation: eAvhQKabB9o62_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o62_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o62 {
    animation: eAvhQKabB9o62_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o62_c_o {
    0% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o63_to {
    animation: eAvhQKabB9o63_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o63_to__to {
    0% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -121.728703px);
    }
    100% {
      transform: translate(324.9px, -121.728703px);
    }
  }
  #eAvhQKabB9o63_tr {
    animation: eAvhQKabB9o63_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o63_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o63_ts {
    animation: eAvhQKabB9o63_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o63_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o63 {
    animation: eAvhQKabB9o63_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o63_c_o {
    0% {
      opacity: 0.9;
    }
    30% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o64_to {
    animation: eAvhQKabB9o64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o64_to__to {
    0% {
      transform: translate(161.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    15% {
      transform: translate(309.773572px, -24.042276px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    22.5% {
      transform: translate(324.9px, -86.545293px);
      animation-timing-function: cubic-bezier(0.280804, 0.399795, 0.604922, 0.750053);
    }
    26.25% {
      transform: translate(324.9px, -111.961266px);
      animation-timing-function: cubic-bezier(0.291118, 0.499369, 0.620764, 0.851063);
    }
    30% {
      transform: translate(324.9px, -122.228703px);
    }
    100% {
      transform: translate(324.9px, -122.228703px);
    }
  }
  #eAvhQKabB9o64_tr {
    animation: eAvhQKabB9o64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o64 {
    animation: eAvhQKabB9o64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o64_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o66 {
    animation-name: eAvhQKabB9o66__m, eAvhQKabB9o66_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o66__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,21.289957,259.817001,21.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    81.25% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eAvhQKabB9o66_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o67_to {
    animation: eAvhQKabB9o67_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o67_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eAvhQKabB9o67_tr {
    animation: eAvhQKabB9o67_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(73deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o67_ts {
    animation: eAvhQKabB9o67_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o67_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o67 {
    animation: eAvhQKabB9o67_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o67_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.8;
    }
    81.25% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o68_to {
    animation: eAvhQKabB9o68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o68_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    42.5% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eAvhQKabB9o68_tr {
    animation: eAvhQKabB9o68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o68_ts {
    animation: eAvhQKabB9o68_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o68_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eAvhQKabB9o68 {
    animation: eAvhQKabB9o68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o68_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.9;
    }
    81.25% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o69_to {
    animation: eAvhQKabB9o69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o69_to__to {
    0% {
      transform: translate(161.798427px, 49.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(191.832868px, 8.675763px);
    }
    58.75% {
      transform: translate(231.488924px, -2.180118px);
    }
    66.25% {
      transform: translate(323.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eAvhQKabB9o69_tr {
    animation: eAvhQKabB9o69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eAvhQKabB9o69 {
    animation: eAvhQKabB9o69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o69_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eAvhQKabB9o71 {
    animation-name: eAvhQKabB9o71__m, eAvhQKabB9o71_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eAvhQKabB9o71__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eAvhQKabB9o71_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o72_to {
    animation: eAvhQKabB9o72_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o72_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 38.016262px);
    }
  }
  #eAvhQKabB9o72 {
    animation: eAvhQKabB9o72_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o72_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o73_to {
    animation: eAvhQKabB9o73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o73_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    96.25% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 33.299851px);
    }
  }
  #eAvhQKabB9o73 {
    animation: eAvhQKabB9o73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o73_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eAvhQKabB9o74_to {
    animation: eAvhQKabB9o74_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o74_to__to {
    0% {
      transform: translate(161.798427px, 50.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 34.016262px);
    }
  }
  #eAvhQKabB9o74 {
    animation: eAvhQKabB9o74_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eAvhQKabB9o74_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesBg4;
