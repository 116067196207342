// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg7 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="efwo0H2uiWV1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="efwo0H2uiWV7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV7-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV8-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV8-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV8-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV9-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV10-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV12-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV13-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV13-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV13-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV14-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV15-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV16-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV17-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV18-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV18-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV19-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV20-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV26-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV27-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV27-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV27-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV28-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV29-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV30-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV31-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV32-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV32-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV32-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV33-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV34-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV35-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV41-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV42-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV42-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV42-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV43-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV44-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV46-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV47-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV47-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV47-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV48-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV49-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV50-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV51-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV52-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV52-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV52-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV53-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV54-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV55-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV60-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV61-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV61-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV61-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV62-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV63-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV63-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV63-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV63-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV63-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV63-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV63-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV64-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV65-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV66-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV66-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV66-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV67-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV68-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV69-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV75-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV75-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV75-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV75-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV75-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV75-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV75-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV75-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV76-stroke"
          x1="0.586824"
          y1="0.007596"
          x2="0.413176"
          y2="0.992404"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV76-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV76-stroke-1" offset="77%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV77-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV78-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV79-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV80-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV80-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV80-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV80-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV80-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV80-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV80-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV81-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV81-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV81-stroke-1" offset="76%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV82-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV82-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV82-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV82-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV82-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV82-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV82-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV82-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV83-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV84-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV85-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV85-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV85-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV85-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV85-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV85-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV85-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV86-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV86-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV86-stroke-1" offset="76%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV87-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV87-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV87-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV87-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV87-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV87-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV87-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV87-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV88-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV89-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV94-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV94-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV94-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV94-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV94-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV94-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV94-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV94-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV95-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV95-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV95-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV96-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV97-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV97-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV97-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV97-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV97-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV97-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV97-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV98-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV98-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV99-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV99-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV99-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV99-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV99-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV99-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV99-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV99-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV100-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV100-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV100-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV101-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV102-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV102-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV102-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV102-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV102-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV102-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV102-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV103-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV103-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV109-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV109-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV109-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV109-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV109-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV109-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV109-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV109-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV110-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV110-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV110-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV111-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV111-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV111-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV111-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV111-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV111-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV111-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV111-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="7,7" />
          <feOffset id="efwo0H2uiWV112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV112-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV113-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV113-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="efwo0H2uiWV114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="efwo0H2uiWV114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV114-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="efwo0H2uiWV114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="efwo0H2uiWV115-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="efwo0H2uiWV115-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="efwo0H2uiWV115-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="efwo0H2uiWV116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV116-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV117-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV117-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="efwo0H2uiWV117-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="efwo0H2uiWV117-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="efwo0H2uiWV117-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="efwo0H2uiWV117-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="efwo0H2uiWV117-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="efwo0H2uiWV117-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="efwo0H2uiWV118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="efwo0H2uiWV118-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#efwo0H2uiWV120)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g>
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV7-filter)">
                <path
                  id="efwo0H2uiWV8"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#efwo0H2uiWV8-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV9_to" transform="translate(832.121519,-4.482748)">
                  <g id="efwo0H2uiWV9_tr" transform="rotate(-75)">
                    <g id="efwo0H2uiWV9_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="efwo0H2uiWV9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#efwo0H2uiWV9-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV10_to" transform="translate(826.798304,-5.828881)">
                  <g id="efwo0H2uiWV10_tr" transform="rotate(-75)">
                    <g id="efwo0H2uiWV10_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="efwo0H2uiWV10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#efwo0H2uiWV10-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV11_to" transform="translate(826.568884,-6.23967)">
                  <g id="efwo0H2uiWV11_tr" transform="rotate(-75)">
                    <rect
                      id="efwo0H2uiWV11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#efwo0H2uiWV11-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV12-filter)">
                <path
                  id="efwo0H2uiWV13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV13-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV14_to" transform="translate(917.798427,143.771297)">
                  <g id="efwo0H2uiWV14_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV14_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV14-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV15_to" transform="translate(917.798427,137.271297)">
                  <g id="efwo0H2uiWV15_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV15_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV15-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV16_to" transform="translate(917.798427,137.771297)">
                  <g id="efwo0H2uiWV16_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV16-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV17-filter)">
                <path
                  id="efwo0H2uiWV18"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV18-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV19_to" transform="translate(917.798427,143.771297)">
                  <g id="efwo0H2uiWV19_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV19_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV19-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV20_to" transform="translate(917.798427,137.271297)">
                  <g id="efwo0H2uiWV20_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV20_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV20-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV21_to" transform="translate(917.798427,140.771297)">
                  <g id="efwo0H2uiWV21_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV21"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV21-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#efwo0H2uiWV37)">
              <g>
                <path
                  d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV26-filter)">
                  <path
                    id="efwo0H2uiWV27"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#efwo0H2uiWV27-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="efwo0H2uiWV28_to" transform="translate(715.798427,56.771297)">
                    <g id="efwo0H2uiWV28_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV28_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV28"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#efwo0H2uiWV28-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV29_to" transform="translate(715.798427,50.271297)">
                    <g id="efwo0H2uiWV29_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV29_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV29"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#efwo0H2uiWV29-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV30_to" transform="translate(715.798427,51.771297)">
                    <g id="efwo0H2uiWV30_tr" transform="rotate(0)">
                      <rect
                        id="efwo0H2uiWV30"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#efwo0H2uiWV30-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV31-filter)">
                  <path
                    id="efwo0H2uiWV32"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#efwo0H2uiWV32-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="efwo0H2uiWV33_to" transform="translate(715.798427,56.771297)">
                    <g id="efwo0H2uiWV33_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV33_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV33"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#efwo0H2uiWV33-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV34_to" transform="translate(715.798427,50.271297)">
                    <g id="efwo0H2uiWV34_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV34_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV34"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#efwo0H2uiWV34-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV35_to" transform="translate(715.798427,50.771297)">
                    <g id="efwo0H2uiWV35_tr" transform="rotate(0)">
                      <rect
                        id="efwo0H2uiWV35"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV35-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="efwo0H2uiWV37" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 533.931986-1.012683)"
                  fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path
                d="M520.668,232.079v-110.398L480.969,76.7549l.005-76.508643"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV41-filter)">
                <path
                  id="efwo0H2uiWV42"
                  d="M520.668,121.038711v.642289L480.969,76.7549l.005-4.679761"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#efwo0H2uiWV42-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV43_to" transform="translate(545.9,-33.514307)">
                  <rect
                    id="efwo0H2uiWV43"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#efwo0H2uiWV43-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="efwo0H2uiWV44_to" transform="translate(545.9,-38.280793)">
                  <rect
                    id="efwo0H2uiWV44"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#efwo0H2uiWV44-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="efwo0H2uiWV45_to" transform="translate(545.9,-38.514307)">
                  <rect
                    id="efwo0H2uiWV45"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#efwo0H2uiWV45-filter)"
                    fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV46-filter)">
                <path
                  id="efwo0H2uiWV47"
                  d="M520.668,224.079c0,0,0-3.777975,0-3.777975v-.741319v1.211592"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV47-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV48_to" transform="translate(584.798427,119.771297)">
                  <g id="efwo0H2uiWV48_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV48_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV48-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV49_to" transform="translate(584.798427,115.271297)">
                  <g id="efwo0H2uiWV49_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV49_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV49-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV50_to" transform="translate(584.798427,117.771297)">
                  <g id="efwo0H2uiWV50_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV50"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV50-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV51-filter)">
                <path
                  id="efwo0H2uiWV52"
                  d="M520.668,224.079c0,0,0-3.777975,0-3.777975v-.741319v1.211592"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV52-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV53_to" transform="translate(584.798427,119.771297)">
                  <g id="efwo0H2uiWV53_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV53_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV53"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV53-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV54_to" transform="translate(584.798427,115.271297)">
                  <g id="efwo0H2uiWV54_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV54_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV54"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV54-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV55_to" transform="translate(584.798427,117.771297)">
                  <g id="efwo0H2uiWV55_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV55-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(3 0)" mask="url(#efwo0H2uiWV71)">
              <g transform="translate(-5.423289-14.501953)">
                <line x1="445.277" y1="0.001953" x2="445.277" y2="155.962" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV60-filter)">
                  <path
                    id="efwo0H2uiWV61"
                    d="M357.283,158.189v-17.85784c0,0-.429-5.531714-.429-5.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#efwo0H2uiWV61-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="efwo0H2uiWV62_to" transform="translate(509.798427,73.771297)">
                    <g id="efwo0H2uiWV62_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV62"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV62-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="efwo0H2uiWV63_to" transform="translate(509.798427,67.271297)">
                    <g id="efwo0H2uiWV63_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV63"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV63-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="efwo0H2uiWV64_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="efwo0H2uiWV64"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV64-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV65-filter)">
                  <path
                    id="efwo0H2uiWV66"
                    d="M357.283,156.189v-15.85784l-.429-6.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#efwo0H2uiWV66-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="efwo0H2uiWV67_to" transform="translate(509.798427,73.771297)">
                    <g id="efwo0H2uiWV67_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV67"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV67-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="efwo0H2uiWV68_to" transform="translate(509.798427,67.271297)">
                    <g id="efwo0H2uiWV68_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV68"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV68-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="efwo0H2uiWV69_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="efwo0H2uiWV69"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV69-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="efwo0H2uiWV71" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="62"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 408.931986-1.012683)"
                  fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-2 0)">
              <path
                d="M357.283,231.189v-109.506l49.955-44.9838v-76.35545"
                transform="translate(0 0.202507)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV75-filter)">
                <path
                  id="efwo0H2uiWV76"
                  d="M357.283,138.189v-16.506l16.069591-14.911067l2.209448-3.288527"
                  transform="translate(65-104.797494)"
                  opacity="0.8"
                  fill="none"
                  stroke="url(#efwo0H2uiWV76-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV77_to" transform="translate(445.052153,-3.536834)">
                  <g id="efwo0H2uiWV77_tr" transform="rotate(45)">
                    <g id="efwo0H2uiWV77_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV77"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#efwo0H2uiWV77-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV78_to" transform="translate(449.063292,-7.316354)">
                  <g id="efwo0H2uiWV78_tr" transform="rotate(45)">
                    <g id="efwo0H2uiWV78_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV78"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#efwo0H2uiWV78-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV79_to" transform="translate(450.624298,-9.536834)">
                  <g id="efwo0H2uiWV79_tr" transform="rotate(45)">
                    <rect
                      id="efwo0H2uiWV79"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#efwo0H2uiWV79-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV80-filter)">
                <path
                  id="efwo0H2uiWV81"
                  d="M530.668,224.079c0,0,0-3.777975,0-3.777975v-.741319v1.211592"
                  transform="matrix(-1 0 0 1 953.080016-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV81-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV82_to" transform="translate(422.798427,133.771297)">
                  <g id="efwo0H2uiWV82_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV82_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV82"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV82-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV83_to" transform="translate(422.798427,127.271297)">
                  <g id="efwo0H2uiWV83_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV83_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV83"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV83-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV84_to" transform="translate(422.798427,137.771297)">
                  <g id="efwo0H2uiWV84_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV84"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV84-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV85-filter)">
                <path
                  id="efwo0H2uiWV86"
                  d="M530.668,224.079c0,0,0-3.777975,0-3.777975v-.741319v1.211592"
                  transform="matrix(-1 0 0 1 953.080016-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV86-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV87_to" transform="translate(422.798427,133.771297)">
                  <g id="efwo0H2uiWV87_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV87_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV87"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV87-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV88_to" transform="translate(422.798427,127.271297)">
                  <g id="efwo0H2uiWV88_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV88_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV88"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV88-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV89_to" transform="translate(422.798427,137.771297)">
                  <g id="efwo0H2uiWV89_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV89"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV89-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#efwo0H2uiWV105)">
              <g transform="translate(.000001 0)">
                <path
                  d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV94-filter)">
                  <path
                    id="efwo0H2uiWV95"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#efwo0H2uiWV95-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="efwo0H2uiWV96_to" transform="translate(291.798427,53.771297)">
                    <g id="efwo0H2uiWV96_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV96_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV96"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#efwo0H2uiWV96-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV97_to" transform="translate(291.798427,47.271297)">
                    <g id="efwo0H2uiWV97_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV97_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV97"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#efwo0H2uiWV97-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV98_to" transform="translate(291.798427,49.771297)">
                    <g id="efwo0H2uiWV98_tr" transform="rotate(0)">
                      <rect
                        id="efwo0H2uiWV98"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV98-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV99-filter)">
                  <path
                    id="efwo0H2uiWV100"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#efwo0H2uiWV100-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="efwo0H2uiWV101_to" transform="translate(291.798427,53.771297)">
                    <g id="efwo0H2uiWV101_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV101_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV101"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#efwo0H2uiWV101-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV102_to" transform="translate(291.798427,47.271297)">
                    <g id="efwo0H2uiWV102_tr" transform="rotate(0)">
                      <g id="efwo0H2uiWV102_ts" transform="scale(1,1)">
                        <rect
                          id="efwo0H2uiWV102"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#efwo0H2uiWV102-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="efwo0H2uiWV103_to" transform="translate(291.798427,49.771297)">
                    <g id="efwo0H2uiWV103_tr" transform="rotate(0)">
                      <rect
                        id="efwo0H2uiWV103"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV103-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="efwo0H2uiWV105" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 208.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV109-filter)">
                <path
                  id="efwo0H2uiWV110"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV110-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV111_to" transform="translate(96.798427,143.771297)">
                  <g id="efwo0H2uiWV111_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV111_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV111"
                        style={{ isolation: 'isolate' }}
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV111-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV112_to" transform="translate(96.798427,137.271297)">
                  <g id="efwo0H2uiWV112_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV112_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV112"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV112-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV113_to" transform="translate(96.798427,142.271297)">
                  <g id="efwo0H2uiWV113_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV113"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV113-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#efwo0H2uiWV114-filter)">
                <path
                  id="efwo0H2uiWV115"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#efwo0H2uiWV115-stroke)"
                  strokeLinecap="round"
                />
                <g id="efwo0H2uiWV116_to" transform="translate(96.798427,143.771297)">
                  <g id="efwo0H2uiWV116_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV116_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV116"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV116-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV117_to" transform="translate(96.798427,137.271297)">
                  <g id="efwo0H2uiWV117_tr" transform="rotate(0)">
                    <g id="efwo0H2uiWV117_ts" transform="scale(1,1)">
                      <rect
                        id="efwo0H2uiWV117"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#efwo0H2uiWV117-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="efwo0H2uiWV118_to" transform="translate(96.798427,139.771297)">
                  <g id="efwo0H2uiWV118_tr" transform="rotate(0)">
                    <rect
                      id="efwo0H2uiWV118"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#efwo0H2uiWV118-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="efwo0H2uiWV120" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #efwo0H2uiWV8 {
    animation-name: efwo0H2uiWV8__m, efwo0H2uiWV8_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV8__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    16.25% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-10.61159');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-10.61159');
    }
  }
  @keyframes efwo0H2uiWV8_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV9_to {
    animation: efwo0H2uiWV9_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV9_to__to {
    0% {
      transform: translate(832.121519px, -4.482748px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -116.228703px);
    }
    100% {
      transform: translate(731.9px, -116.228703px);
    }
  }
  #efwo0H2uiWV9_tr {
    animation: efwo0H2uiWV9_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV9_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(-75deg);
    }
    10% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV9_ts {
    animation: efwo0H2uiWV9_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV9_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV9 {
    animation: efwo0H2uiWV9_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV9_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV10_to {
    animation: efwo0H2uiWV10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV10_to__to {
    0% {
      transform: translate(826.798304px, -5.828881px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.728703px);
    }
    100% {
      transform: translate(731.9px, -122.728703px);
    }
  }
  #efwo0H2uiWV10_tr {
    animation: efwo0H2uiWV10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV10_ts {
    animation: efwo0H2uiWV10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV10_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV10 {
    animation: efwo0H2uiWV10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV10_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV11_to {
    animation: efwo0H2uiWV11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV11_to__to {
    0% {
      transform: translate(826.568884px, -6.23967px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.228703px);
    }
    100% {
      transform: translate(731.9px, -122.228703px);
    }
  }
  #efwo0H2uiWV11_tr {
    animation: efwo0H2uiWV11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV11 {
    animation: efwo0H2uiWV11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV13 {
    animation-name: efwo0H2uiWV13__m, efwo0H2uiWV13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes efwo0H2uiWV13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV14_to {
    animation: efwo0H2uiWV14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV14_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    25% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -115.228703px);
    }
    100% {
      transform: translate(731.9px, -115.228703px);
    }
  }
  #efwo0H2uiWV14_tr {
    animation: efwo0H2uiWV14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(-75deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV14_ts {
    animation: efwo0H2uiWV14_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV14_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV14 {
    animation: efwo0H2uiWV14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    68.75% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV15_to {
    animation: efwo0H2uiWV15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV15_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    25% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.728703px);
    }
    100% {
      transform: translate(731.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV15_tr {
    animation: efwo0H2uiWV15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV15_ts {
    animation: efwo0H2uiWV15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV15 {
    animation: efwo0H2uiWV15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.9;
    }
    68.75% {
      opacity: 0.9;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV16_to {
    animation: efwo0H2uiWV16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV16_to__to {
    0% {
      transform: translate(917.798427px, 137.771297px);
    }
    25% {
      transform: translate(917.798427px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.228703px);
    }
    100% {
      transform: translate(731.9px, -120.228703px);
    }
  }
  #efwo0H2uiWV16_tr {
    animation: efwo0H2uiWV16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV16 {
    animation: efwo0H2uiWV16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV16_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV18 {
    animation-name: efwo0H2uiWV18__m, efwo0H2uiWV18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV18__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes efwo0H2uiWV18_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV19_to {
    animation: efwo0H2uiWV19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV19_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    75% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(832.121519px, -4.482748px);
    }
  }
  #efwo0H2uiWV19_tr {
    animation: efwo0H2uiWV19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #efwo0H2uiWV19_ts {
    animation: efwo0H2uiWV19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV19_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #efwo0H2uiWV19 {
    animation: efwo0H2uiWV19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV20_to {
    animation: efwo0H2uiWV20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV20_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    75% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #efwo0H2uiWV20_tr {
    animation: efwo0H2uiWV20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #efwo0H2uiWV20_ts {
    animation: efwo0H2uiWV20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #efwo0H2uiWV20 {
    animation: efwo0H2uiWV20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV20_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV21_to {
    animation: efwo0H2uiWV21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV21_to__to {
    0% {
      transform: translate(917.798427px, 140.771297px);
    }
    75% {
      transform: translate(917.798427px, 140.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #efwo0H2uiWV21_tr {
    animation: efwo0H2uiWV21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #efwo0H2uiWV21 {
    animation: efwo0H2uiWV21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV21_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV27 {
    animation-name: efwo0H2uiWV27__m, efwo0H2uiWV27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV27__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes efwo0H2uiWV27_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV28_to {
    animation: efwo0H2uiWV28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV28_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -115.228703px);
    }
    100% {
      transform: translate(620.9px, -115.228703px);
    }
  }
  #efwo0H2uiWV28_tr {
    animation: efwo0H2uiWV28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(-68deg);
    }
    23.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV28_ts {
    animation: efwo0H2uiWV28_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV28_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV28 {
    animation: efwo0H2uiWV28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV28_c_o {
    0% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV29_to {
    animation: efwo0H2uiWV29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV29_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -121.728703px);
    }
    100% {
      transform: translate(620.9px, -121.728703px);
    }
  }
  #efwo0H2uiWV29_tr {
    animation: efwo0H2uiWV29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV29_ts {
    animation: efwo0H2uiWV29_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV29_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV29 {
    animation: efwo0H2uiWV29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV29_c_o {
    0% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV30_to {
    animation: efwo0H2uiWV30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV30_to__to {
    0% {
      transform: translate(715.798427px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -122.228703px);
    }
    100% {
      transform: translate(620.9px, -122.228703px);
    }
  }
  #efwo0H2uiWV30_tr {
    animation: efwo0H2uiWV30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV30 {
    animation: efwo0H2uiWV30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV30_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV32 {
    animation-name: efwo0H2uiWV32__m, efwo0H2uiWV32_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV32__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes efwo0H2uiWV32_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV33_to {
    animation: efwo0H2uiWV33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV33_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -114.228703px);
    }
    100% {
      transform: translate(620.9px, -114.228703px);
    }
  }
  #efwo0H2uiWV33_tr {
    animation: efwo0H2uiWV33_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(-68deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV33_ts {
    animation: efwo0H2uiWV33_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV33_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV33 {
    animation: efwo0H2uiWV33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV33_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV34_to {
    animation: efwo0H2uiWV34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV34_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -119.728703px);
    }
    100% {
      transform: translate(620.9px, -119.728703px);
    }
  }
  #efwo0H2uiWV34_tr {
    animation: efwo0H2uiWV34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV34_ts {
    animation: efwo0H2uiWV34_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV34_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV34 {
    animation: efwo0H2uiWV34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV34_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV35_to {
    animation: efwo0H2uiWV35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV35_to__to {
    0% {
      transform: translate(715.798427px, 50.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(705.674548px, 12.059155px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(620.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -35.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -116.228703px);
    }
    100% {
      transform: translate(620.9px, -116.228703px);
    }
  }
  #efwo0H2uiWV35_tr {
    animation: efwo0H2uiWV35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV35 {
    animation: efwo0H2uiWV35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV35_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV42 {
    animation-name: efwo0H2uiWV42__m, efwo0H2uiWV42_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV42__m {
    0% {
      d: path('M520.668,121.038711L520.668,121.681L480.969,76.7549L480.974,72.075139');
    }
    6.25% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes efwo0H2uiWV42_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    6.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV43_to {
    animation: efwo0H2uiWV43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV43_to__to {
    0% {
      transform: translate(545.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(545.9px, -114.228703px);
    }
    100% {
      transform: translate(545.9px, -114.228703px);
    }
  }
  #efwo0H2uiWV43 {
    animation: efwo0H2uiWV43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV43_c_o {
    0% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV44_to {
    animation: efwo0H2uiWV44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV44_to__to {
    0% {
      transform: translate(545.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(545.9px, -121.728703px);
    }
    100% {
      transform: translate(545.9px, -121.728703px);
    }
  }
  #efwo0H2uiWV44 {
    animation: efwo0H2uiWV44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV44_c_o {
    0% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV45_to {
    animation: efwo0H2uiWV45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV45_to__to {
    0% {
      transform: translate(545.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(545.9px, -121.228703px);
    }
    100% {
      transform: translate(545.9px, -121.228703px);
    }
  }
  #efwo0H2uiWV45 {
    animation: efwo0H2uiWV45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV45_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV47 {
    animation-name: efwo0H2uiWV47__m, efwo0H2uiWV47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV47__m {
    0% {
      d: path(
        'M520.668,224.079C520.668,224.079,520.668,220.301025,520.668,220.301025L520.668,219.559706L520.668,220.771298'
      );
    }
    16.25% {
      d: path(
        'M520.668,224.079C520.668,224.079,520.668,220.301025,520.668,220.301025L520.668,219.559706L520.668,220.771298'
      );
    }
    23.75% {
      d: path(
        'M520.668,216.412334C520.668,216.412334,520.668,190.991013,520.668,190.991013L520.733213,190.722607L520.733213,191.328403'
      );
    }
    31.25% {
      d: path(
        'M520.668,208.745667C520.668,208.745667,520.668,121.681,520.668,121.681L520.798427,121.885507L520.798427,121.885507'
      );
    }
    45% {
      d: path('M520.668,122.079C520.668,122.079,520.668,121.681,520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    53.75% {
      d: path(
        'M480.974,77.356098C480.974,77.356098,480.974,77.356096,480.974,77.356096L480.969,76.7549L480.974,-19.753743'
      );
    }
    100% {
      d: path(
        'M480.974,77.356098C480.974,77.356098,480.974,77.356096,480.974,77.356096L480.969,76.7549L480.974,-19.753743'
      );
    }
  }
  @keyframes efwo0H2uiWV47_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV48_to {
    animation: efwo0H2uiWV48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV48_to__to {
    0% {
      transform: translate(584.798427px, 119.771297px);
    }
    16.25% {
      transform: translate(584.798427px, 119.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    30% {
      transform: translate(585.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(585.798427px, 20.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(582.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(550.801035px, -23px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(547.851035px, -25.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -114.228703px);
    }
    100% {
      transform: translate(545.9px, -114.228703px);
    }
  }
  #efwo0H2uiWV48_tr {
    animation: efwo0H2uiWV48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(0deg);
    }
    32.5% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(-45deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV48_ts {
    animation: efwo0H2uiWV48_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV48_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    31.25% {
      transform: scale(1, 1);
    }
    43.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV48 {
    animation: efwo0H2uiWV48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV48_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV49_to {
    animation: efwo0H2uiWV49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV49_to__to {
    0% {
      transform: translate(584.798427px, 115.271297px);
    }
    16.25% {
      transform: translate(584.798427px, 115.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    30% {
      transform: translate(585.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -119.728703px);
    }
    100% {
      transform: translate(545.9px, -119.728703px);
    }
  }
  #efwo0H2uiWV49_tr {
    animation: efwo0H2uiWV49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV49_ts {
    animation: efwo0H2uiWV49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    31.25% {
      transform: scale(1, 1);
    }
    43.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV49 {
    animation: efwo0H2uiWV49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV49_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV50_to {
    animation: efwo0H2uiWV50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV50_to__to {
    0% {
      transform: translate(584.798427px, 117.771297px);
    }
    16.25% {
      transform: translate(584.798427px, 117.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    30% {
      transform: translate(585.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(579.798427px, 10.099627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.624298px, -26.300373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.851035px, -30.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -120.228703px);
    }
    100% {
      transform: translate(545.9px, -120.228703px);
    }
  }
  #efwo0H2uiWV50_tr {
    animation: efwo0H2uiWV50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV50 {
    animation: efwo0H2uiWV50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV50_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV52 {
    animation-name: efwo0H2uiWV52__m, efwo0H2uiWV52_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV52__m {
    0% {
      d: path(
        'M520.668,224.079C520.668,224.079,520.668,220.301025,520.668,220.301025L520.668,219.559706L520.668,220.771298'
      );
    }
    70% {
      d: path(
        'M520.668,224.079C520.668,224.079,520.668,220.301025,520.668,220.301025L520.668,219.559706L520.668,220.771298'
      );
    }
    77.5% {
      d: path(
        'M520.668,216.412334C520.668,216.412334,520.668,190.991013,520.668,190.991013L520.733213,190.722607L520.733213,191.328403'
      );
    }
    85% {
      d: path(
        'M520.668,208.745667C520.668,208.745667,520.668,121.681,520.668,121.681L520.798427,121.885507L520.798427,121.885507'
      );
    }
    100% {
      d: path('M520.668,122.079C520.668,122.079,520.668,121.681,520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
  }
  @keyframes efwo0H2uiWV52_c_o {
    0% {
      opacity: 0;
    }
    68.75% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV53_to {
    animation: efwo0H2uiWV53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV53_to__to {
    0% {
      transform: translate(584.798427px, 119.771297px);
    }
    70% {
      transform: translate(584.798427px, 119.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    83.75% {
      transform: translate(585.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    85% {
      transform: translate(585.798427px, 20.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    86.25% {
      transform: translate(582.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(550.801035px, -23px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(547.851035px, -25.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(545.9px, -33.514307px);
    }
  }
  #efwo0H2uiWV53_tr {
    animation: efwo0H2uiWV53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.25% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV53_ts {
    animation: efwo0H2uiWV53_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV53_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    85% {
      transform: scale(1, 1);
    }
    97.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV53 {
    animation: efwo0H2uiWV53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV53_c_o {
    0% {
      opacity: 0;
    }
    68.75% {
      opacity: 0;
    }
    70% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #efwo0H2uiWV54_to {
    animation: efwo0H2uiWV54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV54_to__to {
    0% {
      transform: translate(584.798427px, 115.271297px);
    }
    70% {
      transform: translate(584.798427px, 115.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    83.75% {
      transform: translate(585.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    85% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    86.25% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(545.9px, -38.280793px);
    }
  }
  #efwo0H2uiWV54_tr {
    animation: efwo0H2uiWV54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV54_ts {
    animation: efwo0H2uiWV54_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV54_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    85% {
      transform: scale(1, 1);
    }
    97.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV54 {
    animation: efwo0H2uiWV54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV54_c_o {
    0% {
      opacity: 0;
    }
    68.75% {
      opacity: 0;
    }
    70% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #efwo0H2uiWV55_to {
    animation: efwo0H2uiWV55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV55_to__to {
    0% {
      transform: translate(584.798427px, 117.771297px);
    }
    70% {
      transform: translate(584.798427px, 117.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    83.75% {
      transform: translate(585.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    85% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    86.25% {
      transform: translate(579.798427px, 10.099627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.624298px, -26.300373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(545.851035px, -30.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(545.9px, -38.514307px);
    }
  }
  #efwo0H2uiWV55_tr {
    animation: efwo0H2uiWV55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV55 {
    animation: efwo0H2uiWV55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV55_c_o {
    0% {
      opacity: 0;
    }
    68.75% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV61 {
    animation-name: efwo0H2uiWV61__m, efwo0H2uiWV61_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV61__m {
    0% {
      d: path('M357.283,158.189L357.283,140.33116C357.283,140.33116,356.854,134.799446,356.854,134.799446');
    }
    18.75% {
      d: path('M357.283,158.189L357.283,140.33116C357.283,140.33116,356.854,134.799446,356.854,134.799446');
    }
    27.5% {
      d: path('M357.283,138.189L357.283,117.474017C357.283,117.474017,356.854,106.370875,356.854,106.370875');
    }
    35% {
      d: path('M357.283,138.189L357.283,100.33116C357.283,100.33116,356.854,55.799446,356.854,55.799446');
    }
    46.25% {
      d: path('M357.283,58.189L357.283,10.33116C357.283,10.33116,356.854,-34.200554,356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116C357.283,10.33116,356.854,-34.200554,356.854,-34.200554');
    }
  }
  @keyframes efwo0H2uiWV61_c_o {
    0% {
      opacity: 0;
    }
    16.25% {
      opacity: 0;
    }
    17.5% {
      opacity: 1;
    }
    42.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV62_to {
    animation: efwo0H2uiWV62_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV62_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #efwo0H2uiWV62_ts {
    animation: efwo0H2uiWV62_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV62_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV62 {
    animation: efwo0H2uiWV62_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV62_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.8;
    }
    46.25% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV63_to {
    animation: efwo0H2uiWV63_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV63_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    13.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV63_ts {
    animation: efwo0H2uiWV63_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV63_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV63 {
    animation: efwo0H2uiWV63_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV63_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.9;
    }
    46.25% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV64_to {
    animation: efwo0H2uiWV64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV64_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #efwo0H2uiWV64 {
    animation: efwo0H2uiWV64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV64_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV66 {
    animation-name: efwo0H2uiWV66__m, efwo0H2uiWV66_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV66__m {
    0% {
      d: path('M357.283,156.189L357.283,140.33116L356.854,133.799446');
    }
    68.75% {
      d: path('M357.283,156.189L357.283,140.33116L356.854,133.799446');
    }
    76.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    85% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    96.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes efwo0H2uiWV66_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV67_to {
    animation: efwo0H2uiWV67_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV67_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #efwo0H2uiWV67_ts {
    animation: efwo0H2uiWV67_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV67_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV67 {
    animation: efwo0H2uiWV67_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV67_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV68_to {
    animation: efwo0H2uiWV68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV68_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    63.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV68_ts {
    animation: efwo0H2uiWV68_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV68_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV68 {
    animation: efwo0H2uiWV68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV68_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV69_to {
    animation: efwo0H2uiWV69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV69_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #efwo0H2uiWV69 {
    animation: efwo0H2uiWV69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV69_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV76 {
    animation-name: efwo0H2uiWV76__m, efwo0H2uiWV76_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV76__m {
    0% {
      d: path('M357.283,138.189L357.283,121.683L373.352591,106.771933L375.562039,103.483406');
    }
    7.5% {
      d: path('M357.293,122.419896L357.283,121.683L407.238,76.6992L407.900001,71.065917');
    }
    8.75% {
      d: path('M367.282,113.129698L367.274,112.540182L407.238,76.553141L407.9,63.316701');
    }
    13.75% {
      d: path('M407.238,75.968908L407.238,75.968908L407.238,75.968907L407.427143,17.282256');
    }
    18.75% {
      d: path('M407.238,75.676791L407.238,75.67679L407.238,75.67679L407.238,-4.231209');
    }
    100% {
      d: path('M407.238,75.676791L407.238,75.67679L407.238,75.67679L407.238,-4.231209');
    }
  }
  @keyframes efwo0H2uiWV76_c_o {
    0% {
      opacity: 0.8;
    }
    13.75% {
      opacity: 0.8;
    }
    18.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV77_to {
    animation: efwo0H2uiWV77_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV77_to__to {
    0% {
      transform: translate(445.052153px, -3.536834px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(467.4px, -23.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(470.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.9px, -114.228703px);
    }
    100% {
      transform: translate(472.9px, -114.228703px);
    }
  }
  #efwo0H2uiWV77_tr {
    animation: efwo0H2uiWV77_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV77_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(45deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV77_ts {
    animation: efwo0H2uiWV77_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV77_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    6.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV77 {
    animation: efwo0H2uiWV77_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV77_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV78_to {
    animation: efwo0H2uiWV78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV78_to__to {
    0% {
      transform: translate(449.063292px, -7.316354px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(472.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.9px, -120.728703px);
    }
    100% {
      transform: translate(472.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV78_tr {
    animation: efwo0H2uiWV78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV78_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV78_ts {
    animation: efwo0H2uiWV78_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV78_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    6.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV78 {
    animation: efwo0H2uiWV78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV78_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV79_to {
    animation: efwo0H2uiWV79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV79_to__to {
    0% {
      transform: translate(450.624298px, -9.536834px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.4px, -26.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(473.011987px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.9px, -117.228703px);
    }
    100% {
      transform: translate(472.9px, -117.228703px);
    }
  }
  #efwo0H2uiWV79_tr {
    animation: efwo0H2uiWV79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV79_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV79 {
    animation: efwo0H2uiWV79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV79_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV81 {
    animation-name: efwo0H2uiWV81__m, efwo0H2uiWV81_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV81__m {
    0% {
      d: path(
        'M530.668,224.079C530.668,224.079,530.668,220.301025,530.668,220.301025L530.668,219.559706L530.668,220.771298'
      );
    }
    35% {
      d: path(
        'M530.668,224.079C530.668,224.079,530.668,220.301025,530.668,220.301025L530.668,219.559706L530.668,220.771298'
      );
    }
    42.5% {
      d: path(
        'M530.668,208.745667C530.668,208.745667,530.668,121.681,530.668,121.681L530.798427,121.885507L530.798427,121.885507'
      );
    }
    57.5% {
      d: path('M530.668,122.079C530.668,122.079,530.668,121.681,530.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    61.25% {
      d: path(
        'M505.821,99.717549C505.821,99.717549,505.821,99.518548,505.821,99.518548L480.969,76.7549L480.974,6.246257'
      );
    }
    65% {
      d: path(
        'M480.974,77.356098C480.974,77.356098,480.974,77.356096,480.974,77.356096L480.969,76.7549L480.974,-19.753743'
      );
    }
    100% {
      d: path(
        'M480.974,77.356098C480.974,77.356098,480.974,77.356096,480.974,77.356096L480.969,76.7549L480.974,-19.753743'
      );
    }
  }
  @keyframes efwo0H2uiWV81_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV82_to {
    animation: efwo0H2uiWV82_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV82_to__to {
    0% {
      transform: translate(422.798427px, 133.771297px);
    }
    28.75% {
      transform: translate(422.798427px, 133.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    41.25% {
      transform: translate(422.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(422.798427px, 21px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(425.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(467.4px, -23.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(470.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(471.9px, -114.228703px);
    }
    100% {
      transform: translate(471.9px, -114.228703px);
    }
  }
  #efwo0H2uiWV82_tr {
    animation: efwo0H2uiWV82_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV82_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    43.75% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(45deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV82_ts {
    animation: efwo0H2uiWV82_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV82_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    42.5% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV82 {
    animation: efwo0H2uiWV82_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV82_c_o {
    0% {
      opacity: 0;
    }
    27.375% {
      opacity: 0;
    }
    28.75% {
      opacity: 0.8;
    }
    65% {
      opacity: 0.8;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV83_to {
    animation: efwo0H2uiWV83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV83_to__to {
    0% {
      transform: translate(422.798427px, 127.271297px);
    }
    28.75% {
      transform: translate(422.798427px, 127.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    41.25% {
      transform: translate(422.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(471.9px, -120.728703px);
    }
    100% {
      transform: translate(471.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV83_tr {
    animation: efwo0H2uiWV83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV83_ts {
    animation: efwo0H2uiWV83_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV83_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    42.5% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV83 {
    animation: efwo0H2uiWV83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV83_c_o {
    0% {
      opacity: 0;
    }
    27.5% {
      opacity: 0;
    }
    28.75% {
      opacity: 0.9;
    }
    65% {
      opacity: 0.9;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV84_to {
    animation: efwo0H2uiWV84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV84_to__to {
    0% {
      transform: translate(422.798427px, 137.771297px);
    }
    28.75% {
      transform: translate(422.798427px, 137.771297px);
    }
    35% {
      transform: translate(422.798427px, 101.021297px);
    }
    36.25% {
      transform: translate(422.798427px, 88.871297px);
    }
    41.25% {
      transform: translate(422.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(425.798427px, 15px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(431.124298px, 8.699627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(471.4px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(471.9px, -121.228703px);
    }
    100% {
      transform: translate(471.9px, -121.228703px);
    }
  }
  #efwo0H2uiWV84_tr {
    animation: efwo0H2uiWV84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV84 {
    animation: efwo0H2uiWV84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV84_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV86 {
    animation-name: efwo0H2uiWV86__m, efwo0H2uiWV86_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV86__m {
    0% {
      d: path(
        'M530.668,224.079C530.668,224.079,530.668,220.301025,530.668,220.301025L530.668,219.559706L530.668,220.771298'
      );
    }
    86.25% {
      d: path(
        'M530.668,224.079C530.668,224.079,530.668,220.301025,530.668,220.301025L530.668,219.559706L530.668,220.771298'
      );
    }
    93.75% {
      d: path(
        'M530.668,208.745667C530.668,208.745667,530.668,121.681,530.668,121.681L530.798427,121.885507L530.798427,121.885507'
      );
    }
    100% {
      d: path(
        'M530.668,172.634556C530.668,172.634556,530.668,121.681,530.668,121.681L510.036166,103.081087L510.038249,102.03582'
      );
    }
  }
  @keyframes efwo0H2uiWV86_c_o {
    0% {
      opacity: 0;
    }
    93.75% {
      opacity: 0;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0.25;
    }
  }
  #efwo0H2uiWV87_to {
    animation: efwo0H2uiWV87_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV87_to__to {
    0% {
      transform: translate(422.798427px, 133.771297px);
    }
    80% {
      transform: translate(422.798427px, 133.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    92.5% {
      transform: translate(422.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    93.75% {
      transform: translate(422.798427px, 21px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    95% {
      transform: translate(425.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    100% {
      transform: translate(444.288015px, -2.840836px);
    }
  }
  #efwo0H2uiWV87_tr {
    animation: efwo0H2uiWV87_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV87_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    93.75% {
      transform: rotate(0deg);
    }
    95% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #efwo0H2uiWV87_ts {
    animation: efwo0H2uiWV87_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV87_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    93.75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.9, 0.9);
    }
  }
  #efwo0H2uiWV87 {
    animation: efwo0H2uiWV87_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV87_c_o {
    0% {
      opacity: 0;
    }
    78.625% {
      opacity: 0;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #efwo0H2uiWV88_to {
    animation: efwo0H2uiWV88_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV88_to__to {
    0% {
      transform: translate(422.798427px, 127.271297px);
    }
    80% {
      transform: translate(422.798427px, 127.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    92.5% {
      transform: translate(422.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    93.75% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    95% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    100% {
      transform: translate(448.320016px, -6.635385px);
    }
  }
  #efwo0H2uiWV88_tr {
    animation: efwo0H2uiWV88_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV88_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(0deg);
    }
    93.75% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #efwo0H2uiWV88_ts {
    animation: efwo0H2uiWV88_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV88_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    93.75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.9, 0.9);
    }
  }
  #efwo0H2uiWV88 {
    animation: efwo0H2uiWV88_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV88_c_o {
    0% {
      opacity: 0;
    }
    78.75% {
      opacity: 0;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #efwo0H2uiWV89_to {
    animation: efwo0H2uiWV89_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV89_to__to {
    0% {
      transform: translate(422.798427px, 137.771297px);
    }
    80% {
      transform: translate(422.798427px, 137.771297px);
    }
    86.25% {
      transform: translate(422.798427px, 101.021297px);
    }
    87.5% {
      transform: translate(422.798427px, 88.871297px);
    }
    92.5% {
      transform: translate(422.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    93.75% {
      transform: translate(425.798427px, 15px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    95% {
      transform: translate(431.124298px, 8.699627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    100% {
      transform: translate(449.02461px, -7.563265px);
    }
  }
  #efwo0H2uiWV89_tr {
    animation: efwo0H2uiWV89_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV89_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(0deg);
    }
    93.75% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #efwo0H2uiWV89 {
    animation: efwo0H2uiWV89_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV89_c_o {
    0% {
      opacity: 0;
    }
    82.5% {
      opacity: 0;
    }
    83.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #efwo0H2uiWV95 {
    animation-name: efwo0H2uiWV95__m, efwo0H2uiWV95_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV95__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    41.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes efwo0H2uiWV95_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV96_to {
    animation: efwo0H2uiWV96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV96_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -115.228703px);
    }
    100% {
      transform: translate(398.699999px, -115.228703px);
    }
  }
  #efwo0H2uiWV96_tr {
    animation: efwo0H2uiWV96_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(68deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV96_ts {
    animation: efwo0H2uiWV96_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV96_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV96 {
    animation: efwo0H2uiWV96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV96_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.8;
    }
    53.75% {
      opacity: 0.8;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV97_to {
    animation: efwo0H2uiWV97_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV97_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    18.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #efwo0H2uiWV97_tr {
    animation: efwo0H2uiWV97_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV97_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV97_ts {
    animation: efwo0H2uiWV97_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV97_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV97 {
    animation: efwo0H2uiWV97_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV97_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.9;
    }
    53.75% {
      opacity: 0.9;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV98_to {
    animation: efwo0H2uiWV98_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV98_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #efwo0H2uiWV98_tr {
    animation: efwo0H2uiWV98_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV98_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV98 {
    animation: efwo0H2uiWV98_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV98_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV100 {
    animation-name: efwo0H2uiWV100__m, efwo0H2uiWV100_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV100__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes efwo0H2uiWV100_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV101_to {
    animation: efwo0H2uiWV101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV101_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -114.228703px);
    }
    100% {
      transform: translate(398.699999px, -114.228703px);
    }
  }
  #efwo0H2uiWV101_tr {
    animation: efwo0H2uiWV101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(68deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV101_ts {
    animation: efwo0H2uiWV101_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV101_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV101 {
    animation: efwo0H2uiWV101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV101_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV102_to {
    animation: efwo0H2uiWV102_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV102_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    58.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #efwo0H2uiWV102_tr {
    animation: efwo0H2uiWV102_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV102_ts {
    animation: efwo0H2uiWV102_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV102_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV102 {
    animation: efwo0H2uiWV102_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV102_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV103_to {
    animation: efwo0H2uiWV103_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV103_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.228703px);
    }
    100% {
      transform: translate(398.699999px, -119.228703px);
    }
  }
  #efwo0H2uiWV103_tr {
    animation: efwo0H2uiWV103_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV103_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV103 {
    animation: efwo0H2uiWV103_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV103_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV110 {
    animation-name: efwo0H2uiWV110__m, efwo0H2uiWV110_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV110__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955001,75.463017');
    }
    43.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    51.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes efwo0H2uiWV110_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV111_to {
    animation: efwo0H2uiWV111_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV111_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    11.25% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #efwo0H2uiWV111_tr {
    animation: efwo0H2uiWV111_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV111_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(75deg);
    }
    43.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV111_ts {
    animation: efwo0H2uiWV111_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV111_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV111 {
    animation: efwo0H2uiWV111_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV111_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV112_to {
    animation: efwo0H2uiWV112_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV112_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV112_tr {
    animation: efwo0H2uiWV112_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV112_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV112_ts {
    animation: efwo0H2uiWV112_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV112_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV112 {
    animation: efwo0H2uiWV112_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV112_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV113_to {
    animation: efwo0H2uiWV113_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV113_to__to {
    0% {
      transform: translate(96.798427px, 142.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 142.271297px);
    }
    17.5% {
      transform: translate(96.748252px, 116.509555px);
    }
    18.75% {
      transform: translate(96.738217px, 108.957207px);
    }
    21.25% {
      transform: translate(96.718147px, 82.185843px);
    }
    26.25% {
      transform: translate(96.678007px, 22.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    33.75% {
      transform: translate(174.541791px, -1.63208px);
    }
    41.25% {
      transform: translate(282.833827px, -26.93208px);
    }
    42.5% {
      transform: translate(287.711987px, -31.028703px);
    }
    43.75% {
      transform: translate(287.814424px, -38.440824px);
    }
    45% {
      transform: translate(287.91686px, -53.852945px);
    }
    47.5% {
      transform: translate(287.913114px, -81.677187px);
    }
    50% {
      transform: translate(287.909367px, -103.50143px);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV113_tr {
    animation: efwo0H2uiWV113_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV113_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV113 {
    animation: efwo0H2uiWV113_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV113_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV115 {
    animation-name: efwo0H2uiWV115__m, efwo0H2uiWV115_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes efwo0H2uiWV115__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955001,75.463017');
    }
    88.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes efwo0H2uiWV115_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV116_to {
    animation: efwo0H2uiWV116_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV116_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #efwo0H2uiWV116_tr {
    animation: efwo0H2uiWV116_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV116_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(75deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV116_ts {
    animation: efwo0H2uiWV116_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV116_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV116 {
    animation: efwo0H2uiWV116_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV116_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV117_to {
    animation: efwo0H2uiWV117_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV117_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    57.5% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #efwo0H2uiWV117_tr {
    animation: efwo0H2uiWV117_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV117_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV117_ts {
    animation: efwo0H2uiWV117_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV117_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #efwo0H2uiWV117 {
    animation: efwo0H2uiWV117_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV117_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #efwo0H2uiWV118_to {
    animation: efwo0H2uiWV118_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV118_to__to {
    0% {
      transform: translate(96.798427px, 139.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 139.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #efwo0H2uiWV118_tr {
    animation: efwo0H2uiWV118_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV118_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #efwo0H2uiWV118 {
    animation: efwo0H2uiWV118_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes efwo0H2uiWV118_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg7;
