// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg5 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="emVpGN6xq7I1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 328 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="328"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="emVpGN6xq7I6-stroke"
          x1="282.586"
          y1="213.684"
          x2="242.632"
          y2="182.642"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I6-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="emVpGN6xq7I6-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I7-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I8-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="emVpGN6xq7I8-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="emVpGN6xq7I8-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="emVpGN6xq7I9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I9-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I10-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I12-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I13-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="emVpGN6xq7I13-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="emVpGN6xq7I13-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="emVpGN6xq7I14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I14-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I15-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I18-stroke"
          x1="235.691"
          y1="233.94"
          x2="194.587"
          y2="216.085"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I18-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="emVpGN6xq7I18-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I19-filter-drop-shadow-0-flood" floodColor="#d9d9d9" />
          <feComposite id="emVpGN6xq7I19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I20-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I20-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop id="emVpGN6xq7I20-stroke-1" offset="100%" stopColor="#dcdcdc" />
        </linearGradient>
        <filter id="emVpGN6xq7I21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I21-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I21-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I21-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I21-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I21-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I21-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I21-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I22-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I23-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I24-filter-drop-shadow-0-flood" floodColor="#d9d9d9" />
          <feComposite id="emVpGN6xq7I24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I25-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I25-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop id="emVpGN6xq7I25-stroke-1" offset="100%" stopColor="#dcdcdc" />
        </linearGradient>
        <filter id="emVpGN6xq7I26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I26-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I27-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I28-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I29-filter-drop-shadow-0-flood" floodColor="#d9d9d9" />
          <feComposite id="emVpGN6xq7I29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I30-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I30-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop id="emVpGN6xq7I30-stroke-1" offset="100%" stopColor="#dcdcdc" />
        </linearGradient>
        <filter id="emVpGN6xq7I31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I31-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I32-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I33-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I35-stroke"
          x1="188.844"
          y1="214.159"
          x2="170.027"
          y2="209.803"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I35-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="emVpGN6xq7I35-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I36-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I37-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I37-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="emVpGN6xq7I37-stroke-1"
            offset="100%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I38-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I39-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I40-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I41-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I42-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I42-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="emVpGN6xq7I42-stroke-1"
            offset="100%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I43-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I44-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I47-stroke"
          x1="142.055"
          y1="134.612"
          x2="142.477"
          y2="253.311"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I47-stroke-0" offset="0%" stopColor={styles.strokeColor} />
          <stop id="emVpGN6xq7I47-stroke-1" offset="100%" stopColor="#030303" />
        </linearGradient>
        <filter id="emVpGN6xq7I48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I48-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I49-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I50-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I51-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I52-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I53-stroke"
          x1="1.68507"
          y1="84.330149"
          x2="0.186927"
          y2="6.0597"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I53-stroke-0" offset="0%" stopColor="rgba(51,51,51,0.3)" />
          <stop
            id="emVpGN6xq7I53-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I54-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I55-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I55-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I55-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I55-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I55-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I55-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I55-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I56-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I57-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I57-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I57-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I57-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I57-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I57-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I57-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I57-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I58-stroke"
          x1="1.803113"
          y1="84.330149"
          x2="1.45094"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I58-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="emVpGN6xq7I58-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I59-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I59-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I59-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I59-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I59-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I59-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I59-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I59-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I60-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I61-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I61-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I63-stroke"
          x1="95.125"
          y1="214.384"
          x2="114.397"
          y2="209.963"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I63-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="emVpGN6xq7I63-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I64-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I65-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I65-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="emVpGN6xq7I65-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I66-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I67-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I68-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I69-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I70-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I70-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="emVpGN6xq7I70-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I71-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I71-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I71-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I71-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I71-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I71-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I71-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I71-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I72-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I73-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I73-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I75-stroke"
          x1="48.3008"
          y1="235.601"
          x2="87.0793"
          y2="218.937"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I75-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="emVpGN6xq7I75-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I76-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I76-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I76-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I76-filter-drop-shadow-0-flood" floodColor="#d9d9d9" />
          <feComposite id="emVpGN6xq7I76-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I76-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I76-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I76-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I77-stroke"
          x1="1"
          y1="-0.015964"
          x2="1"
          y2="0.983627"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I77-stroke-0" offset="0%" stopColor="#dcdcdc" />
          <stop id="emVpGN6xq7I77-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I78-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I79-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I80-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I81-filter-drop-shadow-0-flood" floodColor="#d9d9d9" />
          <feComposite id="emVpGN6xq7I81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I82-stroke"
          x1="2.846422"
          y1="1.054686"
          x2="2.846422"
          y2="-0.113172"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I82-stroke-0" offset="0%" stopColor="rgba(220,220,220,0)" />
          <stop id="emVpGN6xq7I82-stroke-1" offset="100%" stopColor="#dcdcdc" />
        </linearGradient>
        <filter id="emVpGN6xq7I83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I83-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I84-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I84-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I84-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I84-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I84-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I84-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I84-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I85-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I86-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I86-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I86-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I86-filter-drop-shadow-0-flood" floodColor="#d9d9d9" />
          <feComposite id="emVpGN6xq7I86-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I86-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I86-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I86-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I87-stroke"
          x1="74.479276"
          y1="-349.713613"
          x2="12.97488"
          y2="-5.470225"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I87-stroke-0" offset="0%" stopColor="#838383" />
          <stop id="emVpGN6xq7I87-stroke-1" offset="0%" stopColor="#dcdcdc" />
          <stop id="emVpGN6xq7I87-stroke-2" offset="100%" stopColor="rgba(30,30,30,0)" />
        </linearGradient>
        <filter id="emVpGN6xq7I88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I88-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I89-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I89-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emVpGN6xq7I89-filter-drop-shadow-0-flood" floodColor="#fff" />
          <feComposite id="emVpGN6xq7I89-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I89-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I89-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I89-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I90-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I90-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I92-stroke"
          x1="1.46094"
          y1="214.01"
          x2="43.493"
          y2="181.528"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I92-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="emVpGN6xq7I92-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="emVpGN6xq7I93-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I93-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I93-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I93-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I93-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I93-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I93-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I93-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I94-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I94-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="emVpGN6xq7I94-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I95-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I95-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I95-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I95-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I95-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I95-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I95-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I95-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I96-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I97-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="emVpGN6xq7I98-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I98-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="emVpGN6xq7I98-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I98-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="emVpGN6xq7I98-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I98-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I98-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I98-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emVpGN6xq7I99-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I99-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="emVpGN6xq7I99-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="emVpGN6xq7I100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I100-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emVpGN6xq7I101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="emVpGN6xq7I101-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="emVpGN6xq7I101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emVpGN6xq7I101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emVpGN6xq7I101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emVpGN6xq7I101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="emVpGN6xq7I102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emVpGN6xq7I102-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="emVpGN6xq7I103-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="emVpGN6xq7I103-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="emVpGN6xq7I103-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="emVpGN6xq7I103-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g mask="url(#emVpGN6xq7I104)">
        <g>
          <g transform="translate(21.97653-6.66309)">
            <g transform="translate(2 0)">
              <path
                d="M284.586,213.684v-85.983L230.273,82.9652l-.004-76.30211"
                fill="none"
                stroke="url(#emVpGN6xq7I6-stroke)"
              />
              <g transform="translate(388 0)" filter="url(#emVpGN6xq7I7-filter)">
                <path
                  id="emVpGN6xq7I8"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I8-stroke)"
                />
                <g id="emVpGN6xq7I9_to" transform="translate(-104.650543,236.66309)">
                  <g id="emVpGN6xq7I9_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I9_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I9-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I10_to" transform="translate(-104.650543,230.66309)">
                  <g id="emVpGN6xq7I10_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I10_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I10-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I11_to" transform="translate(-104.650543,229.66309)">
                  <g id="emVpGN6xq7I11_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I11-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(388 0)" filter="url(#emVpGN6xq7I12-filter)">
                <path
                  id="emVpGN6xq7I13"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I13-stroke)"
                />
                <g id="emVpGN6xq7I14_to" transform="translate(-104.650543,236.66309)">
                  <g id="emVpGN6xq7I14_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I14_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I14-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I15_to" transform="translate(-104.650543,230.66309)">
                  <g id="emVpGN6xq7I15_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I15_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I15-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I16_to" transform="translate(-104.650543,229.66309)">
                  <g id="emVpGN6xq7I16_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I16-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(16 0)">
              <path
                d="M188.844,214.159v-86.374L171.396,83.0597l-.034265-77"
                fill="none"
                stroke="url(#emVpGN6xq7I35-stroke)"
              />
              <g transform="translate(94 0)" filter="url(#emVpGN6xq7I36-filter)">
                <path
                  id="emVpGN6xq7I37"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I37-stroke)"
                />
                <g id="emVpGN6xq7I38_to" transform="translate(95.02347,237.66309)">
                  <g id="emVpGN6xq7I38_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I38_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I38"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I38-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I39_to" transform="translate(95.02347,230.66309)">
                  <g id="emVpGN6xq7I39_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I39_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I39"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I39-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I40_to" transform="translate(94.62347,229.66309)">
                  <g id="emVpGN6xq7I40_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I40"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I40-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(94 0)" filter="url(#emVpGN6xq7I41-filter)">
                <path
                  id="emVpGN6xq7I42"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I42-stroke)"
                />
                <g id="emVpGN6xq7I43_to" transform="translate(95.02347,237.66309)">
                  <g id="emVpGN6xq7I43_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I43_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I43"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I43-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I44_to" transform="translate(95.02347,230.66309)">
                  <g id="emVpGN6xq7I44_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I44_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I44"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I44-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I45_to" transform="translate(94.62347,229.66309)">
                  <g id="emVpGN6xq7I45_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I45"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I45-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path d="M141.977,6.66309v234" fill="none" stroke="url(#emVpGN6xq7I47-stroke)" />
              <g filter="url(#emVpGN6xq7I48-filter)">
                <g id="emVpGN6xq7I49_to" transform="translate(142.349457,232.66309)">
                  <rect
                    id="emVpGN6xq7I49"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#emVpGN6xq7I49-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="emVpGN6xq7I50_to" transform="translate(142.349457,225.66309)">
                  <rect
                    id="emVpGN6xq7I50"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#emVpGN6xq7I50-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="emVpGN6xq7I51_to" transform="translate(142.349457,224.66309)">
                  <rect
                    id="emVpGN6xq7I51"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#emVpGN6xq7I51-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#emVpGN6xq7I52-filter)">
                <path
                  id="emVpGN6xq7I53"
                  d="M1.620239,125.822142v1.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I53-stroke)"
                />
                <g id="emVpGN6xq7I54_to" transform="translate(142.349457,237.66309)">
                  <g id="emVpGN6xq7I54_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="emVpGN6xq7I54"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I54-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="emVpGN6xq7I55_to" transform="translate(142.349457,230.66309)">
                  <g id="emVpGN6xq7I55_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="emVpGN6xq7I55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I55-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="emVpGN6xq7I56_to" transform="translate(142.349457,229.66309)">
                  <rect
                    id="emVpGN6xq7I56"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#emVpGN6xq7I56-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#emVpGN6xq7I57-filter)">
                <path
                  id="emVpGN6xq7I58"
                  d="M1.620239,126.822142v.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  fill="none"
                  stroke="url(#emVpGN6xq7I58-stroke)"
                />
                <g id="emVpGN6xq7I59_to" transform="translate(142.193038,127.481163)">
                  <g id="emVpGN6xq7I59_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="emVpGN6xq7I59"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0.9"
                      filter="url(#emVpGN6xq7I59-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="emVpGN6xq7I60_to" transform="translate(142.193038,121.258415)">
                  <g id="emVpGN6xq7I60_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="emVpGN6xq7I60"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      filter="url(#emVpGN6xq7I60-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="emVpGN6xq7I61_to" transform="translate(142.244248,120.335341)">
                  <rect
                    id="emVpGN6xq7I61"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#emVpGN6xq7I61-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-16 0.000001)">
              <path
                d="M95.125,214.384v-86.716l17.88847-45.004911v-76"
                fill="none"
                stroke="url(#emVpGN6xq7I63-stroke)"
              />
              <g filter="url(#emVpGN6xq7I64-filter)">
                <path
                  id="emVpGN6xq7I65"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I65-stroke)"
                />
                <g id="emVpGN6xq7I66_to" transform="translate(95.349457,237.66309)">
                  <g id="emVpGN6xq7I66_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I66_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I66"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I66-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I67_to" transform="translate(95.349457,230.66309)">
                  <g id="emVpGN6xq7I67_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I67_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I67"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I67-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I68_to" transform="translate(95.349457,229.66309)">
                  <g id="emVpGN6xq7I68_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I68"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I68-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#emVpGN6xq7I69-filter)">
                <path
                  id="emVpGN6xq7I70"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I70-stroke)"
                />
                <g id="emVpGN6xq7I71_to" transform="translate(95.349457,237.66309)">
                  <g id="emVpGN6xq7I71_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I71_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I71"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I71-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I72_to" transform="translate(95.349457,230.66309)">
                  <g id="emVpGN6xq7I72_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I72_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I72"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I72-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I73_to" transform="translate(95.349457,229.66309)">
                  <g id="emVpGN6xq7I73_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I73"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I73-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(134 0)">
              <path
                d="M1.46094,214.01v-86.346L55.705,82.9513v-76.28821"
                transform="translate(-137.088013 0)"
                fill="none"
                stroke="url(#emVpGN6xq7I92-stroke)"
              />
              <g filter="url(#emVpGN6xq7I93-filter)">
                <path
                  id="emVpGN6xq7I94"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I94-stroke)"
                />
                <g id="emVpGN6xq7I95_to" transform="translate(-135.650543,235.66309)">
                  <g id="emVpGN6xq7I95_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I95_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I95"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I95-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I96_to" transform="translate(-135.650543,230.66309)">
                  <g id="emVpGN6xq7I96_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I96_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I96"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I96-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I97_to" transform="translate(-135.650543,229.66309)">
                  <g id="emVpGN6xq7I97_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I97"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I97-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#emVpGN6xq7I98-filter)">
                <path
                  id="emVpGN6xq7I99"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#emVpGN6xq7I99-stroke)"
                />
                <g id="emVpGN6xq7I100_to" transform="translate(-135.650543,235.66309)">
                  <g id="emVpGN6xq7I100_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I100_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I100"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I100-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I101_to" transform="translate(-135.650543,230.66309)">
                  <g id="emVpGN6xq7I101_tr" transform="rotate(0)">
                    <g id="emVpGN6xq7I101_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="emVpGN6xq7I101"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#emVpGN6xq7I101-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="emVpGN6xq7I102_to" transform="translate(-135.650543,229.66309)">
                  <g id="emVpGN6xq7I102_tr" transform="rotate(0)">
                    <rect
                      id="emVpGN6xq7I102"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#emVpGN6xq7I102-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <rect
            width="328"
            height="61.866793"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1.616376 0 134)"
            fill="url(#emVpGN6xq7I103-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="emVpGN6xq7I104" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="328" height="234" rx="0" ry="0" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #emVpGN6xq7I8 {
    animation-name: emVpGN6xq7I8__m, emVpGN6xq7I8_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I8__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    21.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes emVpGN6xq7I8_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    41.666667% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I9_to {
    animation: emVpGN6xq7I9_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I9_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    5% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    23.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    25% {
      transform: translate(-114.42px, 118.973437px);
    }
    33.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    35% {
      transform: translate(-156.650543px, 85.099607px);
    }
    36.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    46.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #emVpGN6xq7I9_tr {
    animation: emVpGN6xq7I9_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I9_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(-50deg);
    }
    36.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I9_ts {
    animation: emVpGN6xq7I9_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I9_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I9 {
    animation: emVpGN6xq7I9_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I9_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0.9;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I10_to {
    animation: emVpGN6xq7I10_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I10_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    5% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    23.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    25% {
      transform: translate(-118.131567px, 116.016274px);
    }
    33.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    35% {
      transform: translate(-157.988371px, 81.66309px);
    }
    36.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    46.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #emVpGN6xq7I10_tr {
    animation: emVpGN6xq7I10_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I10_ts {
    animation: emVpGN6xq7I10_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I10_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I10 {
    animation: emVpGN6xq7I10_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I10_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I11_to {
    animation: emVpGN6xq7I11_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I11_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    5% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    23.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    25% {
      transform: translate(-118.48136px, 115.681349px);
    }
    33.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    35% {
      transform: translate(-157.87653px, 80.828326px);
    }
    36.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    46.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #emVpGN6xq7I11_tr {
    animation: emVpGN6xq7I11_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I11 {
    animation: emVpGN6xq7I11_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I11_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I13 {
    animation-name: emVpGN6xq7I13__m, emVpGN6xq7I13_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I13__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    66.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    88.333333% {
      d: path('M-12.054849,90.545445L-12.054849,90.545445L-21.125297,82.9513L-21.125297,27.635917');
    }
    91.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes emVpGN6xq7I13_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    88.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I14_to {
    animation: emVpGN6xq7I14_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I14_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    50% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    68.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    70% {
      transform: translate(-114.42px, 118.973437px);
    }
    78.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    80% {
      transform: translate(-156.650543px, 85.099607px);
    }
    81.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    91.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #emVpGN6xq7I14_tr {
    animation: emVpGN6xq7I14_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(-50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I14_ts {
    animation: emVpGN6xq7I14_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I14_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I14 {
    animation: emVpGN6xq7I14_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I14_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    91.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I15_to {
    animation: emVpGN6xq7I15_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I15_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    50% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    68.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    70% {
      transform: translate(-118.131567px, 116.016274px);
    }
    78.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    80% {
      transform: translate(-157.988371px, 81.66309px);
    }
    81.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    91.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #emVpGN6xq7I15_tr {
    animation: emVpGN6xq7I15_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I15_ts {
    animation: emVpGN6xq7I15_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I15_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I15 {
    animation: emVpGN6xq7I15_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I15_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I16_to {
    animation: emVpGN6xq7I16_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I16_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    50% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    70% {
      transform: translate(-118.48136px, 115.681349px);
    }
    78.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    80% {
      transform: translate(-157.87653px, 80.828326px);
    }
    81.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    91.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #emVpGN6xq7I16_tr {
    animation: emVpGN6xq7I16_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I16 {
    animation: emVpGN6xq7I16_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I16_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I21_to {
    animation: emVpGN6xq7I21_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I21_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    35% {
      transform: translate(49.349457px, 129.993562px);
    }
    36.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    43.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    45% {
      transform: translate(17.349457px, 87.540794px);
    }
    46.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    48.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    58.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #emVpGN6xq7I21_tr {
    animation: emVpGN6xq7I21_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(0deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(-40deg);
    }
    48.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I21_ts {
    animation: emVpGN6xq7I21_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I21_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I22_to {
    animation: emVpGN6xq7I22_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I22_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    35% {
      transform: translate(48.011629px, 125.66309px);
    }
    36.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    43.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    45% {
      transform: translate(15.215426px, 85.140794px);
    }
    46.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    48.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    58.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #emVpGN6xq7I22_tr {
    animation: emVpGN6xq7I22_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I22_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I22_ts {
    animation: emVpGN6xq7I22_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I22_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I23_to {
    animation: emVpGN6xq7I23_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I23_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(47.093619px, 124.50245px);
    }
    36.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    43.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    45% {
      transform: translate(14.762322px, 84.381349px);
    }
    46.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    48.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    58.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #emVpGN6xq7I23_tr {
    animation: emVpGN6xq7I23_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I23_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I26_to {
    animation: emVpGN6xq7I26_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I26_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    85% {
      transform: translate(49.349457px, 129.993562px);
    }
    86.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    93.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    95% {
      transform: translate(17.349457px, 87.540794px);
    }
    96.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    98.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    100% {
      transform: translate(13.965137px, 65.993562px);
    }
  }
  #emVpGN6xq7I26_tr {
    animation: emVpGN6xq7I26_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(-40deg);
    }
    98.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I26_ts {
    animation: emVpGN6xq7I26_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I26_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I27_to {
    animation: emVpGN6xq7I27_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I27_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    85% {
      transform: translate(48.011629px, 125.66309px);
    }
    86.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    93.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    95% {
      transform: translate(15.215426px, 85.140794px);
    }
    96.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    98.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    100% {
      transform: translate(13.963603px, 61.496423px);
    }
  }
  #emVpGN6xq7I27_tr {
    animation: emVpGN6xq7I27_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I27_ts {
    animation: emVpGN6xq7I27_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I27_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I28_to {
    animation: emVpGN6xq7I28_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I28_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(47.093619px, 124.50245px);
    }
    86.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    93.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    95% {
      transform: translate(14.762322px, 84.381349px);
    }
    96.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    98.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    100% {
      transform: translate(14.040137px, 60.578326px);
    }
  }
  #emVpGN6xq7I28_tr {
    animation: emVpGN6xq7I28_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I31_to {
    animation: emVpGN6xq7I31_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I31_to__to {
    0% {
      transform: translate(13.965137px, 65.993562px);
    }
    8.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #emVpGN6xq7I32_to {
    animation: emVpGN6xq7I32_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I32_to__to {
    0% {
      transform: translate(13.963603px, 61.496423px);
    }
    8.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #emVpGN6xq7I33_to {
    animation: emVpGN6xq7I33_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I33_to__to {
    0% {
      transform: translate(14.040137px, 60.578326px);
    }
    8.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #emVpGN6xq7I37 {
    animation-name: emVpGN6xq7I37__m, emVpGN6xq7I37_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I37__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    30% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    41.666667% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    51.666667% {
      d: path('M-14.393265,88.779336L-14.393265,88.779336L-16.643335,83.174861L-16.643335,23.521822');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    55% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes emVpGN6xq7I37_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.666667% {
      opacity: 1;
    }
    51.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I38_to {
    animation: emVpGN6xq7I38_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I38_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 136.66309px);
    }
    31.666667% {
      transform: translate(95.02347px, 129.993562px);
    }
    33.333333% {
      transform: translate(92.792379px, 121.74181px);
    }
    41.666667% {
      transform: translate(79.572206px, 88.521493px);
    }
    43.333333% {
      transform: translate(77.728902px, 84.993562px);
    }
    45% {
      transform: translate(77.02347px, 79.660944px);
    }
    55% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #emVpGN6xq7I38_tr {
    animation: emVpGN6xq7I38_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(-25deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I38_ts {
    animation: emVpGN6xq7I38_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I38_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I38 {
    animation: emVpGN6xq7I38_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I38_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 0.9;
    }
    55% {
      opacity: 0.9;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I39_to {
    animation: emVpGN6xq7I39_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I39_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 130.66309px);
    }
    31.666667% {
      transform: translate(94.17641px, 125.66309px);
    }
    33.333333% {
      transform: translate(91px, 117.66309px);
    }
    41.666667% {
      transform: translate(78.011629px, 85.140794px);
    }
    43.333333% {
      transform: translate(77px, 81.5px);
    }
    45% {
      transform: translate(77.02347px, 75.66309px);
    }
    55% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #emVpGN6xq7I39_tr {
    animation: emVpGN6xq7I39_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I39_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I39_ts {
    animation: emVpGN6xq7I39_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I39_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I39 {
    animation: emVpGN6xq7I39_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I39_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I40_to {
    animation: emVpGN6xq7I40_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I40_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    13.333333% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    31.666667% {
      transform: translate(93.59994px, 124.50245px);
    }
    33.333333% {
      transform: translate(90.3px, 116.5px);
    }
    41.666667% {
      transform: translate(77.51864px, 84.381349px);
    }
    43.333333% {
      transform: translate(77.12347px, 80.828326px);
    }
    45% {
      transform: translate(77.12347px, 75.328326px);
    }
    55% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #emVpGN6xq7I40_tr {
    animation: emVpGN6xq7I40_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I40_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I40 {
    animation: emVpGN6xq7I40_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I40_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I42 {
    animation-name: emVpGN6xq7I42__m, emVpGN6xq7I42_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I42__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    73.333333% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    85% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    93.333333% {
      d: path('M-11.477665,96.161688L-11.477665,96.161688L-16.577892,83.320735L-16.577892,35.774152');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    98.333333% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes emVpGN6xq7I42_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0.25;
    }
  }
  #emVpGN6xq7I43_to {
    animation: emVpGN6xq7I43_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I43_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 136.66309px);
    }
    75% {
      transform: translate(95.02347px, 129.993562px);
    }
    76.666667% {
      transform: translate(92.792379px, 121.74181px);
    }
    85% {
      transform: translate(79.572206px, 88.521493px);
    }
    86.666667% {
      transform: translate(77.728902px, 84.993562px);
    }
    88.333333% {
      transform: translate(77.02347px, 79.660944px);
    }
    98.333333% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #emVpGN6xq7I43_tr {
    animation: emVpGN6xq7I43_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I43_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(0deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(-25deg);
    }
    88.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I43_ts {
    animation: emVpGN6xq7I43_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I43_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I43 {
    animation: emVpGN6xq7I43_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I43_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 0.9;
    }
    98.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I44_to {
    animation: emVpGN6xq7I44_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I44_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 130.66309px);
    }
    75% {
      transform: translate(94.17641px, 125.66309px);
    }
    76.666667% {
      transform: translate(91px, 117.66309px);
    }
    85% {
      transform: translate(78.011629px, 85.140794px);
    }
    86.666667% {
      transform: translate(77px, 81.5px);
    }
    88.333333% {
      transform: translate(77.02347px, 75.66309px);
    }
    98.333333% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #emVpGN6xq7I44_tr {
    animation: emVpGN6xq7I44_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I44_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I44_ts {
    animation: emVpGN6xq7I44_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I44_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I44 {
    animation: emVpGN6xq7I44_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I44_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I45_to {
    animation: emVpGN6xq7I45_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I45_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    56.666667% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      transform: translate(93.59994px, 124.50245px);
    }
    76.666667% {
      transform: translate(90.3px, 116.5px);
    }
    85% {
      transform: translate(77.51864px, 84.381349px);
    }
    86.666667% {
      transform: translate(77.12347px, 80.828326px);
    }
    88.333333% {
      transform: translate(77.12347px, 75.328326px);
    }
    98.333333% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #emVpGN6xq7I45_tr {
    animation: emVpGN6xq7I45_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I45 {
    animation: emVpGN6xq7I45_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I45_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I49_to {
    animation: emVpGN6xq7I49_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I49_to__to {
    0% {
      transform: translate(142.349457px, 232.66309px);
    }
    80% {
      transform: translate(142.349457px, 232.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 127.481163px);
    }
  }
  #emVpGN6xq7I49 {
    animation: emVpGN6xq7I49_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I49_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #emVpGN6xq7I50_to {
    animation: emVpGN6xq7I50_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I50_to__to {
    0% {
      transform: translate(142.349457px, 225.66309px);
    }
    80% {
      transform: translate(142.349457px, 225.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 121.258415px);
    }
  }
  #emVpGN6xq7I50 {
    animation: emVpGN6xq7I50_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I50_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #emVpGN6xq7I51_to {
    animation: emVpGN6xq7I51_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I51_to__to {
    0% {
      transform: translate(142.349457px, 224.66309px);
    }
    80% {
      transform: translate(142.349457px, 224.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.244248px, 120.335341px);
    }
  }
  #emVpGN6xq7I51 {
    animation: emVpGN6xq7I51_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I51_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #emVpGN6xq7I53 {
    animation-name: emVpGN6xq7I53__m, emVpGN6xq7I53_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I53__m {
    0% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
    }
    55% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.291939, 0.488237, 0.577592, 1);
    }
    76.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes emVpGN6xq7I53_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    71.666667% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I54_to {
    animation: emVpGN6xq7I54_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I54_to__to {
    0% {
      transform: translate(142.349457px, 237.66309px);
    }
    35% {
      transform: translate(142.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #emVpGN6xq7I54_ts {
    animation: emVpGN6xq7I54_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I54_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I54 {
    animation: emVpGN6xq7I54_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I54_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 0.9;
    }
    76.666667% {
      opacity: 0.9;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I55_to {
    animation: emVpGN6xq7I55_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I55_to__to {
    0% {
      transform: translate(142.349457px, 230.66309px);
    }
    35% {
      transform: translate(142.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #emVpGN6xq7I55_ts {
    animation: emVpGN6xq7I55_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I55_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I55 {
    animation: emVpGN6xq7I55_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I55_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I56_to {
    animation: emVpGN6xq7I56_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I56_to__to {
    0% {
      transform: translate(142.349457px, 229.66309px);
    }
    35% {
      transform: translate(142.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #emVpGN6xq7I56 {
    animation: emVpGN6xq7I56_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I56_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I58 {
    animation-name: emVpGN6xq7I58__m, emVpGN6xq7I58_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I58__m {
    0% {
      d: path('M1.620239,126.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.319296, 0.369702, 0.634542, 0.749896);
    }
    11.666667% {
      d: path('M1.762467,92.702027L1.762467,92.733775L1.762467,92.607038L1.676009,50.333564');
      animation-timing-function: cubic-bezier(0.297615, 0.610899, 0.623772, 1);
    }
    21.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes emVpGN6xq7I58_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I59_to {
    animation: emVpGN6xq7I59_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I59_to__to {
    0% {
      transform: translate(142.193038px, 127.481163px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #emVpGN6xq7I59_ts {
    animation: emVpGN6xq7I59_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I59_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I59 {
    animation: emVpGN6xq7I59_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I59_c_o {
    0% {
      opacity: 0.9;
    }
    21.666667% {
      opacity: 0.9;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I60_to {
    animation: emVpGN6xq7I60_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I60_to__to {
    0% {
      transform: translate(142.193038px, 121.258415px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #emVpGN6xq7I60_ts {
    animation: emVpGN6xq7I60_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I60_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I60 {
    animation: emVpGN6xq7I60_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I60_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I61_to {
    animation: emVpGN6xq7I61_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I61_to__to {
    0% {
      transform: translate(142.244248px, 120.335341px);
      animation-timing-function: cubic-bezier(0.430844, 0, 0.69332, 0.575556);
    }
    13.333333% {
      transform: translate(142.160623px, 33.975185px);
      animation-timing-function: cubic-bezier(0.28661, 0.558021, 0.597351, 1);
    }
    21.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #emVpGN6xq7I61 {
    animation: emVpGN6xq7I61_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I61_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I65 {
    animation-name: emVpGN6xq7I65__m, emVpGN6xq7I65_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I65__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    25% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.666667% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    46.666667% {
      d: path('M17.127316,88.675201L17.144733,88.59732L19.483755,83.045699L19.705,23.39266');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    50% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes emVpGN6xq7I65_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    53.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I66_to {
    animation: emVpGN6xq7I66_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I66_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 136.66309px);
    }
    26.666667% {
      transform: translate(95.349457px, 127.493561px);
    }
    28.333333% {
      transform: translate(96.886038px, 122.125682px);
    }
    36.666667% {
      transform: translate(110.349457px, 88.540794px);
    }
    38.333333% {
      transform: translate(112px, 84.993562px);
    }
    40% {
      transform: translate(113.22347px, 79.460943px);
    }
    50% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #emVpGN6xq7I66_tr {
    animation: emVpGN6xq7I66_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(0deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(25deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I66_ts {
    animation: emVpGN6xq7I66_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I66_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I66 {
    animation: emVpGN6xq7I66_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I66_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.9;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I67_to {
    animation: emVpGN6xq7I67_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I67_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 130.66309px);
    }
    26.666667% {
      transform: translate(96.5px, 122.66309px);
    }
    28.333333% {
      transform: translate(99px, 117.66309px);
    }
    36.666667% {
      transform: translate(112.011629px, 85.140794px);
    }
    38.333333% {
      transform: translate(113px, 81px);
    }
    40% {
      transform: translate(113.22347px, 75.66309px);
    }
    50% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #emVpGN6xq7I67_tr {
    animation: emVpGN6xq7I67_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I67_ts {
    animation: emVpGN6xq7I67_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I67_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I67 {
    animation: emVpGN6xq7I67_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I67_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I68_to {
    animation: emVpGN6xq7I68_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I68_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    26.666667% {
      transform: translate(97px, 121.50245px);
    }
    28.333333% {
      transform: translate(99.5px, 116.5px);
    }
    36.666667% {
      transform: translate(112.51864px, 84.381349px);
    }
    38.333333% {
      transform: translate(113px, 80px);
    }
    40% {
      transform: translate(113.12347px, 74.828326px);
    }
    50% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #emVpGN6xq7I68_tr {
    animation: emVpGN6xq7I68_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I68 {
    animation: emVpGN6xq7I68_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I68_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I70 {
    animation-name: emVpGN6xq7I70__m, emVpGN6xq7I70_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I70__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    68.333333% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M14.183989,95.925645L14.198135,95.749112L19.525321,83.027964L19.705,35.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes emVpGN6xq7I70_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I71_to {
    animation: emVpGN6xq7I71_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I71_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 136.66309px);
    }
    70% {
      transform: translate(95.349457px, 127.493561px);
    }
    71.666667% {
      transform: translate(96.886038px, 122.125682px);
    }
    80% {
      transform: translate(110.349457px, 88.540794px);
    }
    81.666667% {
      transform: translate(112px, 84.993562px);
    }
    83.333333% {
      transform: translate(113.22347px, 79.460943px);
    }
    93.333333% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #emVpGN6xq7I71_tr {
    animation: emVpGN6xq7I71_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I71_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(25deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I71_ts {
    animation: emVpGN6xq7I71_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I71_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I71 {
    animation: emVpGN6xq7I71_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I71_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I72_to {
    animation: emVpGN6xq7I72_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I72_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 130.66309px);
    }
    70% {
      transform: translate(96.5px, 122.66309px);
    }
    71.666667% {
      transform: translate(99px, 117.66309px);
    }
    80% {
      transform: translate(112.011629px, 85.140794px);
    }
    81.666667% {
      transform: translate(113px, 81px);
    }
    83.333333% {
      transform: translate(113.22347px, 75.66309px);
    }
    93.333333% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #emVpGN6xq7I72_tr {
    animation: emVpGN6xq7I72_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I72_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I72_ts {
    animation: emVpGN6xq7I72_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I72_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I72 {
    animation: emVpGN6xq7I72_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I72_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I73_to {
    animation: emVpGN6xq7I73_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I73_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(97px, 121.50245px);
    }
    71.666667% {
      transform: translate(99.5px, 116.5px);
    }
    80% {
      transform: translate(112.51864px, 84.381349px);
    }
    81.666667% {
      transform: translate(113px, 80px);
    }
    83.333333% {
      transform: translate(113.12347px, 74.828326px);
    }
    93.333333% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #emVpGN6xq7I73_tr {
    animation: emVpGN6xq7I73_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I73_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I73 {
    animation: emVpGN6xq7I73_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I73_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I78_to {
    animation: emVpGN6xq7I78_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I78_to__to {
    0% {
      transform: translate(48.349457px, 135.66309px);
    }
    1.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    3.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    11.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    13.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    15% {
      transform: translate(84.01347px, 79.993562px);
    }
    25% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #emVpGN6xq7I78_tr {
    animation: emVpGN6xq7I78_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(0deg);
    }
    3.333333% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(40deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I78_ts {
    animation: emVpGN6xq7I78_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I78_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I79_to {
    animation: emVpGN6xq7I79_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I79_to__to {
    0% {
      transform: translate(48.349457px, 130.66309px);
    }
    1.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    3.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    11.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    13.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    15% {
      transform: translate(84.011629px, 75.66309px);
    }
    25% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #emVpGN6xq7I79_tr {
    animation: emVpGN6xq7I79_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I79_ts {
    animation: emVpGN6xq7I79_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I79_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I80_to {
    animation: emVpGN6xq7I80_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I80_to__to {
    0% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    1.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    3.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    11.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    13.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    15% {
      transform: translate(84.12347px, 74.828326px);
    }
    25% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #emVpGN6xq7I80_tr {
    animation: emVpGN6xq7I80_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I80_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I83_to {
    animation: emVpGN6xq7I83_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I83_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 135.66309px);
    }
    46.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    48.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    56.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    58.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    60% {
      transform: translate(84.01347px, 79.993562px);
    }
    70% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #emVpGN6xq7I83_tr {
    animation: emVpGN6xq7I83_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    48.333333% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(40deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I83_ts {
    animation: emVpGN6xq7I83_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I83_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I84_to {
    animation: emVpGN6xq7I84_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I84_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 130.66309px);
    }
    46.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    48.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    56.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    58.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    60% {
      transform: translate(84.011629px, 75.66309px);
    }
    70% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #emVpGN6xq7I84_tr {
    animation: emVpGN6xq7I84_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I84_ts {
    animation: emVpGN6xq7I84_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I84_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I85_to {
    animation: emVpGN6xq7I85_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I85_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    48.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    56.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    58.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    60% {
      transform: translate(84.12347px, 74.828326px);
    }
    70% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #emVpGN6xq7I85_tr {
    animation: emVpGN6xq7I85_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I85_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I88_to {
    animation: emVpGN6xq7I88_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I88_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 135.66309px);
    }
  }
  #emVpGN6xq7I89_to {
    animation: emVpGN6xq7I89_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I89_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 130.66309px);
    }
  }
  #emVpGN6xq7I90_to {
    animation: emVpGN6xq7I90_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I90_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 129.66309px);
    }
  }
  #emVpGN6xq7I94 {
    animation-name: emVpGN6xq7I94__m, emVpGN6xq7I94_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I94__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    21.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    38.333333% {
      d: path('M17.077982,114.999859L17.077982,114.573417L55.412229,82.9513L55.412229,49.638019');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    46.666667% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes emVpGN6xq7I94_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    43.333333% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I95_to {
    animation: emVpGN6xq7I95_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I95_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 135.66309px);
    }
    21.666667% {
      transform: translate(-135.650543px, 130.993562px);
    }
    23.333333% {
      transform: translate(-129.28364px, 121.74181px);
    }
    31.666667% {
      transform: translate(-86.650543px, 86.993562px);
    }
    33.333333% {
      transform: translate(-83.650543px, 83.993562px);
    }
    35% {
      transform: translate(-81.98653px, 79.993562px);
    }
    45% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #emVpGN6xq7I95_tr {
    animation: emVpGN6xq7I95_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I95_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I95_ts {
    animation: emVpGN6xq7I95_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I95_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I95 {
    animation: emVpGN6xq7I95_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I95_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0.9;
    }
    45% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I96_to {
    animation: emVpGN6xq7I96_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I96_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 130.66309px);
    }
    21.666667% {
      transform: translate(-133.988371px, 126.66309px);
    }
    23.333333% {
      transform: translate(-125.655038px, 118.66309px);
    }
    31.666667% {
      transform: translate(-83.988371px, 84.66309px);
    }
    33.333333% {
      transform: translate(-81.988371px, 80.66309px);
    }
    35% {
      transform: translate(-81.988371px, 75.66309px);
    }
    45% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #emVpGN6xq7I96_tr {
    animation: emVpGN6xq7I96_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I96_ts {
    animation: emVpGN6xq7I96_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I96_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I96 {
    animation: emVpGN6xq7I96_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I96_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I97_to {
    animation: emVpGN6xq7I97_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I97_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-132.650543px, 125.50245px);
    }
    23.333333% {
      transform: translate(-125.48136px, 118.381349px);
    }
    31.666667% {
      transform: translate(-83.48136px, 84.381349px);
    }
    33.333333% {
      transform: translate(-81.87653px, 79.828326px);
    }
    35% {
      transform: translate(-81.87653px, 74.828326px);
    }
    45% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #emVpGN6xq7I97_tr {
    animation: emVpGN6xq7I97_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I97_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I97 {
    animation: emVpGN6xq7I97_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I97_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I99 {
    animation-name: emVpGN6xq7I99__m, emVpGN6xq7I99_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emVpGN6xq7I99__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    68.333333% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.430309, 0, 0.626686, 0.804094);
    }
    78.333333% {
      d: path('M1.46094,128.256464L1.46094,127.664L46.219884,90.745962L46.219884,90.745961');
      animation-timing-function: cubic-bezier(0.310712, 0.637203, 0.641852, 1);
    }
    80% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M39.186071,96.218416L39.186071,96.041883L54.997771,82.9513L54.997771,34.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes emVpGN6xq7I99_c_o {
    0% {
      opacity: 0;
    }
    61.666667% {
      opacity: 0;
    }
    63.333333% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I100_to {
    animation: emVpGN6xq7I100_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I100_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 135.66309px);
    }
    70% {
      transform: translate(-135.650543px, 130.993562px);
    }
    71.666667% {
      transform: translate(-129.28364px, 121.74181px);
    }
    80% {
      transform: translate(-86.650543px, 86.993562px);
    }
    81.666667% {
      transform: translate(-83.650543px, 83.993562px);
    }
    83.333333% {
      transform: translate(-81.98653px, 79.993562px);
    }
    93.333333% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #emVpGN6xq7I100_tr {
    animation: emVpGN6xq7I100_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(50deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I100_ts {
    animation: emVpGN6xq7I100_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I100_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I100 {
    animation: emVpGN6xq7I100_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I100_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I101_to {
    animation: emVpGN6xq7I101_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I101_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 130.66309px);
    }
    70% {
      transform: translate(-133.988371px, 126.66309px);
    }
    71.666667% {
      transform: translate(-125.655038px, 118.66309px);
    }
    80% {
      transform: translate(-83.988371px, 84.66309px);
    }
    81.666667% {
      transform: translate(-81.988371px, 80.66309px);
    }
    83.333333% {
      transform: translate(-81.988371px, 75.66309px);
    }
    93.333333% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #emVpGN6xq7I101_tr {
    animation: emVpGN6xq7I101_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I101_ts {
    animation: emVpGN6xq7I101_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I101_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #emVpGN6xq7I101 {
    animation: emVpGN6xq7I101_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I101_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emVpGN6xq7I102_to {
    animation: emVpGN6xq7I102_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I102_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(-132.650543px, 125.50245px);
    }
    71.666667% {
      transform: translate(-125.48136px, 118.381349px);
    }
    80% {
      transform: translate(-83.48136px, 84.381349px);
    }
    81.666667% {
      transform: translate(-81.87653px, 79.828326px);
    }
    83.333333% {
      transform: translate(-81.87653px, 74.828326px);
    }
    93.333333% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #emVpGN6xq7I102_tr {
    animation: emVpGN6xq7I102_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #emVpGN6xq7I102 {
    animation: emVpGN6xq7I102_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes emVpGN6xq7I102_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg5;
