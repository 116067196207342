// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg9 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="e9Asl6wGzg11"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 856 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="856"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="e9Asl6wGzg17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg17-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg18-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg18-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg19-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg110-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg110-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg110-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg110-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg110-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg110-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg110-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg111-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg111-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg112-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg113-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg113-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg113-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg114-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg115-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg115-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg115-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg115-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg115-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg115-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg115-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg115-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg116-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg117-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg117-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg117-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg117-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg117-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg117-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg117-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg117-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg118-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg118-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg118-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg119-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg119-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg119-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg119-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg119-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg119-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg119-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg119-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg120-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg120-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg120-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg120-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg120-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg120-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg120-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg120-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg121-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg121-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg126-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg126-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg126-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg126-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg126-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg126-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg126-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg126-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg127-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg127-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg127-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg128-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg128-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg128-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg128-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg128-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg128-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg128-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg128-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg129-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg129-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg129-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg129-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg129-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg129-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg129-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg130-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg130-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg131-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg131-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg131-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg131-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg131-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg131-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg131-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg131-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg132-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg132-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg132-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg133-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg133-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg133-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg133-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg133-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg133-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg133-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg133-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg134-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg134-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg134-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg134-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg134-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg134-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg134-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg134-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg135-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg135-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg141-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg141-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg141-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg141-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg141-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg141-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg141-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg141-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg142-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg142-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg142-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg143-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg144-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg144-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg144-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg144-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg144-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg144-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg144-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg144-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg145-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg145-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg146-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg146-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg146-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg146-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg146-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg146-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg146-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg146-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg147-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg147-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg147-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg148-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg148-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg148-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg148-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg148-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg148-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg148-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg148-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg149-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg149-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg149-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg149-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg149-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg149-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg149-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg149-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg150-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg150-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg151-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg151-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg151-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg151-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg151-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg151-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg151-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg151-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg152-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg152-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg152-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg153-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg153-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg153-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg153-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg153-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg153-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg153-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg153-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg154-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg154-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg154-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg154-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg154-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg154-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg154-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg154-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg155-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg155-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg160-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg160-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg160-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg160-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg160-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg160-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg160-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg160-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg161-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg161-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg161-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg162-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg162-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg162-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg162-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg162-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg162-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg162-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg162-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg163-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg163-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg163-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg163-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg163-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg163-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg163-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg163-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg164-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg164-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg165-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg165-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg165-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg165-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg165-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg165-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg165-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg165-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg166-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg166-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg166-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg167-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg167-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg167-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg167-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg167-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg167-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg167-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg167-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg168-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg168-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg168-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg168-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg168-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg168-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg168-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg168-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg169-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg169-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg169-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg169-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg169-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg169-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg169-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg169-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg176-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg176-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg176-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg176-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg176-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg176-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg176-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg176-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg177-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg177-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg177-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg178-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg178-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg178-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg178-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg178-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg178-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg178-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg178-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg179-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg179-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg179-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg179-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg179-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg179-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg179-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg179-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg180-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg180-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg181-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg181-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg181-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg181-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg181-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg181-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg181-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg181-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg182-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg182-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg182-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg183-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg183-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg183-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg183-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg183-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg183-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg183-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg183-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg184-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg184-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg184-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg184-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg184-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg184-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg184-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg184-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg185-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg185-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg192-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg192-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg192-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg192-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg192-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg192-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg192-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg192-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg193-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg193-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg193-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg194-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg194-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg194-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg194-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg194-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg194-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg194-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg194-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg195-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg195-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg195-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg195-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg195-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg195-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg195-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg195-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg196-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg196-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg197-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg197-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg197-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg197-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg197-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg197-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg197-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg197-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg198-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg198-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg198-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg199-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg199-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg199-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg199-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg199-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg199-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg199-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg199-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1100-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1101-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg1107-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1107-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1107-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1107-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1107-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1107-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1107-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1107-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1108-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg1108-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg1108-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg1109-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1109-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1109-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1109-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1109-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1109-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1109-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1109-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1110-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1110-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1110-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1110-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1110-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1110-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1110-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1111-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1111-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg1112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1112-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1113-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg1113-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg1113-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg1114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1114-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1115-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1115-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1115-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1115-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1115-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1115-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1115-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1115-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1116-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1121-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1121-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1121-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1121-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1121-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1121-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1121-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1121-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1122-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e9Asl6wGzg1122-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="e9Asl6wGzg1122-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e9Asl6wGzg1123-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1123-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1123-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1123-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1123-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1123-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1123-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1123-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1124-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1124-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1124-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1124-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1124-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1124-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1124-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1124-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1125-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1125-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg1126-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1126-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1126-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1126-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1126-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1126-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1126-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1126-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1127-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e9Asl6wGzg1127-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="e9Asl6wGzg1127-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e9Asl6wGzg1128-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1128-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1128-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1128-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1128-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1128-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1128-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1128-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1129-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1129-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1129-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1129-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1129-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1129-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1129-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1130-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1130-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg1131-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1131-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1131-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1131-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1131-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1131-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1131-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1131-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1132-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e9Asl6wGzg1132-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="e9Asl6wGzg1132-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e9Asl6wGzg1133-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1133-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1133-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1133-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1133-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1133-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1133-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1133-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1134-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1134-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1134-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1134-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1134-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1134-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1134-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1134-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1135-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1135-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg1141-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1141-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1141-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1141-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1141-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1141-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1141-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1141-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1142-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg1142-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg1142-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg1143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1143-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1144-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1144-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="7,7" />
          <feOffset id="e9Asl6wGzg1144-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1144-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1144-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1144-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1144-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1144-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1145-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1145-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e9Asl6wGzg1146-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1146-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e9Asl6wGzg1146-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1146-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e9Asl6wGzg1146-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1146-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1146-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1146-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9Asl6wGzg1147-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9Asl6wGzg1147-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9Asl6wGzg1147-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e9Asl6wGzg1148-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1148-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1148-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1148-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1148-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1148-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1148-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1148-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1149-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1149-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e9Asl6wGzg1149-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e9Asl6wGzg1149-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e9Asl6wGzg1149-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9Asl6wGzg1149-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9Asl6wGzg1149-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9Asl6wGzg1149-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e9Asl6wGzg1150-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9Asl6wGzg1150-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#e9Asl6wGzg1152)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-13.911987 1.012683)">
            <g transform="translate(-14 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg17-filter)">
                <path
                  id="e9Asl6wGzg18"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#e9Asl6wGzg18-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg19_to" transform="translate(832.121519,-4.482748)">
                  <g id="e9Asl6wGzg19_tr" transform="rotate(-75)">
                    <g id="e9Asl6wGzg19_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="e9Asl6wGzg19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#e9Asl6wGzg19-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg110_to" transform="translate(826.798304,-5.828881)">
                  <g id="e9Asl6wGzg110_tr" transform="rotate(-75)">
                    <g id="e9Asl6wGzg110_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="e9Asl6wGzg110"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#e9Asl6wGzg110-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg111_to" transform="translate(826.568884,-6.23967)">
                  <g id="e9Asl6wGzg111_tr" transform="rotate(-75)">
                    <rect
                      id="e9Asl6wGzg111"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#e9Asl6wGzg111-filter)"
                      fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg112-filter)">
                <path
                  id="e9Asl6wGzg113"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg113-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg114_to" transform="translate(917.798427,143.771297)">
                  <g id="e9Asl6wGzg114_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg114_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg114"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg114-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg115_to" transform="translate(917.798427,137.271297)">
                  <g id="e9Asl6wGzg115_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg115_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg115"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg115-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg116_to" transform="translate(917.798427,137.771297)">
                  <g id="e9Asl6wGzg116_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg116"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg116-filter)"
                      fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg117-filter)">
                <path
                  id="e9Asl6wGzg118"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg118-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg119_to" transform="translate(917.798427,143.771297)">
                  <g id="e9Asl6wGzg119_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg119_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg119"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg119-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg120_to" transform="translate(917.798427,137.271297)">
                  <g id="e9Asl6wGzg120_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg120_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg120"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg120-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg121_to" transform="translate(917.798427,140.771297)">
                  <g id="e9Asl6wGzg121_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg121"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg121-filter)"
                      fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-29 0)" mask="url(#e9Asl6wGzg137)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg126-filter)">
                  <path
                    id="e9Asl6wGzg127"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg127-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg128_to" transform="translate(846.5,55.771297)">
                    <g id="e9Asl6wGzg128_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg128_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg128"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg128-filter)"
                          fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg129_to" transform="translate(846.5,49.271297)">
                    <g id="e9Asl6wGzg129_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg129_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg129"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg129-filter)"
                          fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg130_to" transform="translate(846.5,51.771297)">
                    <g id="e9Asl6wGzg130_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg130"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg130-filter)"
                        fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg131-filter)">
                  <path
                    id="e9Asl6wGzg132"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg132-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg133_to" transform="translate(846.5,55.771297)">
                    <g id="e9Asl6wGzg133_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg133_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg133"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg133-filter)"
                          fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg134_to" transform="translate(846.5,49.271297)">
                    <g id="e9Asl6wGzg134_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg134_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg134"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg134-filter)"
                          fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg135_to" transform="translate(846.5,50.771297)">
                    <g id="e9Asl6wGzg135_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg135"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg135-filter)"
                        fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e9Asl6wGzg137" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 605.931986-1.012683)"
                  fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-43.999999 0.624996)">
              <path
                d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg141-filter)">
                <path
                  id="e9Asl6wGzg142"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#e9Asl6wGzg142-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg143_to" transform="translate(656.9,-33.549858)">
                  <rect
                    id="e9Asl6wGzg143"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#e9Asl6wGzg143-filter)"
                    fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e9Asl6wGzg144_to" transform="translate(656.9,-37.443085)">
                  <rect
                    id="e9Asl6wGzg144"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#e9Asl6wGzg144-filter)"
                    fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e9Asl6wGzg145_to" transform="translate(656.9,-38.549858)">
                  <rect
                    id="e9Asl6wGzg145"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#e9Asl6wGzg145-filter)"
                    fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg146-filter)">
                <path
                  id="e9Asl6wGzg147"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg147-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg148_to" transform="translate(786.798427,143.771297)">
                  <g id="e9Asl6wGzg148_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg148_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg148"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg148-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg149_to" transform="translate(786.798427,137.271297)">
                  <g id="e9Asl6wGzg149_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg149_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg149"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg149-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg150_to" transform="translate(786.798427,138.771297)">
                  <g id="e9Asl6wGzg150_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg150"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg150-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg151-filter)">
                <path
                  id="e9Asl6wGzg152"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg152-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg153_to" transform="translate(786.798427,143.771297)">
                  <g id="e9Asl6wGzg153_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg153_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg153"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg153-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg154_to" transform="translate(786.798427,137.271297)">
                  <g id="e9Asl6wGzg154_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg154_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg154"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg154-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg155_to" transform="translate(786.798427,136.771297)">
                  <g id="e9Asl6wGzg155_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg155"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg155-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-59 0)" mask="url(#e9Asl6wGzg171)">
              <g>
                <path
                  d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg160-filter)">
                  <path
                    id="e9Asl6wGzg161"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#e9Asl6wGzg161-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg162_to" transform="translate(715.798427,56.771297)">
                    <g id="e9Asl6wGzg162_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg162_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg162"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#e9Asl6wGzg162-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg163_to" transform="translate(715.798427,50.271297)">
                    <g id="e9Asl6wGzg163_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg163_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg163"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#e9Asl6wGzg163-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg164_to" transform="translate(715.798427,51.771297)">
                    <g id="e9Asl6wGzg164_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg164"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#e9Asl6wGzg164-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg165-filter)">
                  <path
                    id="e9Asl6wGzg166"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg166-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg167_to" transform="translate(715.798427,56.771297)">
                    <g id="e9Asl6wGzg167_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg167_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg167"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg167-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg168_to" transform="translate(715.798427,50.271297)">
                    <g id="e9Asl6wGzg168_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg168_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg168"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg168-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg169_to" transform="translate(715.798427,50.771297)">
                    <g id="e9Asl6wGzg169_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg169"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg169-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e9Asl6wGzg171" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 535.931986-1.012683)"
                  fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(4 0)" mask="url(#e9Asl6wGzg187)">
              <g transform="translate(-5.423289-14.501953)">
                <line x1="445.277" y1="0.001953" x2="445.277" y2="155.962" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg176-filter)">
                  <path
                    id="e9Asl6wGzg177"
                    d="M357.283,158.189v-17.85784c0,0-.429-5.531714-.429-5.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg177-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg178_to" transform="translate(509.798427,73.771297)">
                    <g id="e9Asl6wGzg178_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg178"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg178-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9Asl6wGzg179_to" transform="translate(509.798427,67.271297)">
                    <g id="e9Asl6wGzg179_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg179"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg179-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9Asl6wGzg180_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="e9Asl6wGzg180"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg180-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg181-filter)">
                  <path
                    id="e9Asl6wGzg182"
                    d="M357.283,156.189v-15.85784l-.429-6.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg182-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg183_to" transform="translate(509.798427,73.771297)">
                    <g id="e9Asl6wGzg183_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg183"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg183-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9Asl6wGzg184_to" transform="translate(509.798427,67.271297)">
                    <g id="e9Asl6wGzg184_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg184"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg184-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9Asl6wGzg185_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="e9Asl6wGzg185"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg185-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="e9Asl6wGzg187" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="62"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 408.931986-1.012683)"
                  fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(59 0)" mask="url(#e9Asl6wGzg1103)">
              <g transform="translate(.000001 0)">
                <path
                  d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg192-filter)">
                  <path
                    id="e9Asl6wGzg193"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg193-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg194_to" transform="translate(291.798427,53.771297)">
                    <g id="e9Asl6wGzg194_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg194_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg194"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg194-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg195_to" transform="translate(291.798427,47.271297)">
                    <g id="e9Asl6wGzg195_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg195_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg195"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg195-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg196_to" transform="translate(291.798427,49.771297)">
                    <g id="e9Asl6wGzg196_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg196"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg196-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg197-filter)">
                  <path
                    id="e9Asl6wGzg198"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg198-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg199_to" transform="translate(291.798427,53.771297)">
                    <g id="e9Asl6wGzg199_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg199_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg199"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg199-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg1100_to" transform="translate(291.798427,47.271297)">
                    <g id="e9Asl6wGzg1100_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg1100_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg1100"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg1100-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg1101_to" transform="translate(291.798427,49.771297)">
                    <g id="e9Asl6wGzg1101_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg1101"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1101-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e9Asl6wGzg1103" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 209.931986-1.012683)"
                  fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(44 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1107-filter)">
                <path
                  id="e9Asl6wGzg1108"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg1108-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg1109_to" transform="translate(226.798427,143.771297)">
                  <g id="e9Asl6wGzg1109_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1109_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1109"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1109-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1110_to" transform="translate(226.798427,137.271297)">
                  <g id="e9Asl6wGzg1110_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1110_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1110"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1110-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1111_to" transform="translate(226.798427,141.771297)">
                  <g id="e9Asl6wGzg1111_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg1111"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1111-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1112-filter)">
                <path
                  id="e9Asl6wGzg1113"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg1113-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg1114_to" transform="translate(226.798427,143.771297)">
                  <g id="e9Asl6wGzg1114_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1114_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1114"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1114-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1115_to" transform="translate(226.798427,137.271297)">
                  <g id="e9Asl6wGzg1115_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1115_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1115"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1115-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1116_to" transform="translate(226.798427,138.771297)">
                  <g id="e9Asl6wGzg1116_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg1116"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1116-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(30 0)" mask="url(#e9Asl6wGzg1137)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1121-filter)">
                  <path
                    id="e9Asl6wGzg1122"
                    d="M96.7109,145.439636v-17.754678l.101249-.122374c0,0,.08233.25.08233.25"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#e9Asl6wGzg1122-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg1123_to" transform="translate(161.798427,27.271297)">
                    <g id="e9Asl6wGzg1123_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg1123_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg1123"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#e9Asl6wGzg1123-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg1124_to" transform="translate(161.798427,23.771297)">
                    <g id="e9Asl6wGzg1124_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg1124_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg1124"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#e9Asl6wGzg1124-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg1125_to" transform="translate(161.798427,23.271297)">
                    <g id="e9Asl6wGzg1125_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg1125"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#e9Asl6wGzg1125-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1126-filter)">
                  <path
                    id="e9Asl6wGzg1127"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg1127-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg1128_to" transform="translate(161.798427,53.771297)">
                    <g id="e9Asl6wGzg1128_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg1128_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg1128"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg1128-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg1129_to" transform="translate(161.798427,47.271297)">
                    <g id="e9Asl6wGzg1129_tr" transform="rotate(0)">
                      <g id="e9Asl6wGzg1129_ts" transform="scale(1,1)">
                        <rect
                          id="e9Asl6wGzg1129"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#e9Asl6wGzg1129-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="e9Asl6wGzg1130_to" transform="translate(161.798427,49.771297)">
                    <g id="e9Asl6wGzg1130_tr" transform="rotate(0)">
                      <rect
                        id="e9Asl6wGzg1130"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1130-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1131-filter)">
                  <path
                    id="e9Asl6wGzg1132"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9Asl6wGzg1132-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9Asl6wGzg1133_to" transform="translate(161.798427,53.771297)">
                    <rect
                      id="e9Asl6wGzg1133"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1133-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e9Asl6wGzg1134_to" transform="translate(161.798427,47.271297)">
                    <rect
                      id="e9Asl6wGzg1134"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1134-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e9Asl6wGzg1135_to" transform="translate(161.798427,50.771297)">
                    <rect
                      id="e9Asl6wGzg1135"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1135-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="e9Asl6wGzg1137" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 75.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(15 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1141-filter)">
                <path
                  id="e9Asl6wGzg1142"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg1142-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg1143_to" transform="translate(96.798427,143.771297)">
                  <g id="e9Asl6wGzg1143_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1143_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1143"
                        style={{ isolation: 'isolate' }}
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1143-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1144_to" transform="translate(96.798427,137.271297)">
                  <g id="e9Asl6wGzg1144_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1144_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1144"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1144-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1145_to" transform="translate(96.798427,142.271297)">
                  <g id="e9Asl6wGzg1145_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg1145"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1145-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9Asl6wGzg1146-filter)">
                <path
                  id="e9Asl6wGzg1147"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9Asl6wGzg1147-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9Asl6wGzg1148_to" transform="translate(96.798427,143.771297)">
                  <g id="e9Asl6wGzg1148_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1148_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1148"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1148-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1149_to" transform="translate(96.798427,137.271297)">
                  <g id="e9Asl6wGzg1149_tr" transform="rotate(0)">
                    <g id="e9Asl6wGzg1149_ts" transform="scale(1,1)">
                      <rect
                        id="e9Asl6wGzg1149"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e9Asl6wGzg1149-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e9Asl6wGzg1150_to" transform="translate(96.798427,139.771297)">
                  <g id="e9Asl6wGzg1150_tr" transform="rotate(0)">
                    <rect
                      id="e9Asl6wGzg1150"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e9Asl6wGzg1150-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <rect
                width="63"
                height="63"
                rx="4.5"
                ry="4.5"
                transform="translate(.5 231.082)"
                fill="#030303"
                stroke="#878888"
              />
            </g>
          </g>
        </g>
        <mask id="e9Asl6wGzg1152" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="896.38596"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #e9Asl6wGzg18 {
    animation-name: e9Asl6wGzg18__m, e9Asl6wGzg18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg18__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    10% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.781004');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes e9Asl6wGzg18_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg19_to {
    animation: e9Asl6wGzg19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg19_to__to {
    0% {
      transform: translate(832.121519px, -4.482748px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -116.228703px);
    }
    100% {
      transform: translate(731.9px, -116.228703px);
    }
  }
  #e9Asl6wGzg19_tr {
    animation: e9Asl6wGzg19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg19_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(-75deg);
    }
    10% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg19_ts {
    animation: e9Asl6wGzg19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg19_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg19 {
    animation: e9Asl6wGzg19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg19_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg110_to {
    animation: e9Asl6wGzg110_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg110_to__to {
    0% {
      transform: translate(826.798304px, -5.828881px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.728703px);
    }
    100% {
      transform: translate(731.9px, -122.728703px);
    }
  }
  #e9Asl6wGzg110_tr {
    animation: e9Asl6wGzg110_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg110_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg110_ts {
    animation: e9Asl6wGzg110_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg110_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg110 {
    animation: e9Asl6wGzg110_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg110_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg111_to {
    animation: e9Asl6wGzg111_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg111_to__to {
    0% {
      transform: translate(826.568884px, -6.23967px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.228703px);
    }
    100% {
      transform: translate(731.9px, -122.228703px);
    }
  }
  #e9Asl6wGzg111_tr {
    animation: e9Asl6wGzg111_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg111_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg111 {
    animation: e9Asl6wGzg111_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg111_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg113 {
    animation-name: e9Asl6wGzg113__m, e9Asl6wGzg113_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg113__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes e9Asl6wGzg113_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg114_to {
    animation: e9Asl6wGzg114_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg114_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    25% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -115.228703px);
    }
    100% {
      transform: translate(731.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg114_tr {
    animation: e9Asl6wGzg114_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg114_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(-75deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg114_ts {
    animation: e9Asl6wGzg114_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg114_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg114 {
    animation: e9Asl6wGzg114_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg114_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    68.75% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg115_to {
    animation: e9Asl6wGzg115_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg115_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    25% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.728703px);
    }
    100% {
      transform: translate(731.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg115_tr {
    animation: e9Asl6wGzg115_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg115_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg115_ts {
    animation: e9Asl6wGzg115_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg115_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg115 {
    animation: e9Asl6wGzg115_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg115_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.9;
    }
    68.75% {
      opacity: 0.9;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg116_to {
    animation: e9Asl6wGzg116_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg116_to__to {
    0% {
      transform: translate(917.798427px, 137.771297px);
    }
    25% {
      transform: translate(917.798427px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.228703px);
    }
    100% {
      transform: translate(731.9px, -120.228703px);
    }
  }
  #e9Asl6wGzg116_tr {
    animation: e9Asl6wGzg116_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg116_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg116 {
    animation: e9Asl6wGzg116_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg116_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg118 {
    animation-name: e9Asl6wGzg118__m, e9Asl6wGzg118_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg118__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes e9Asl6wGzg118_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg119_to {
    animation: e9Asl6wGzg119_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg119_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    75% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(832.121519px, -4.482748px);
    }
  }
  #e9Asl6wGzg119_tr {
    animation: e9Asl6wGzg119_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg119_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #e9Asl6wGzg119_ts {
    animation: e9Asl6wGzg119_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg119_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #e9Asl6wGzg119 {
    animation: e9Asl6wGzg119_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg119_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg120_to {
    animation: e9Asl6wGzg120_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg120_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    75% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #e9Asl6wGzg120_tr {
    animation: e9Asl6wGzg120_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg120_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #e9Asl6wGzg120_ts {
    animation: e9Asl6wGzg120_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg120_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #e9Asl6wGzg120 {
    animation: e9Asl6wGzg120_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg120_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg121_to {
    animation: e9Asl6wGzg121_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg121_to__to {
    0% {
      transform: translate(917.798427px, 140.771297px);
    }
    75% {
      transform: translate(917.798427px, 140.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #e9Asl6wGzg121_tr {
    animation: e9Asl6wGzg121_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg121_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #e9Asl6wGzg121 {
    animation: e9Asl6wGzg121_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg121_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg127 {
    animation-name: e9Asl6wGzg127__m, e9Asl6wGzg127_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg127__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes e9Asl6wGzg127_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg128_to {
    animation: e9Asl6wGzg128_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg128_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    12.5% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -116.228703px);
    }
    100% {
      transform: translate(693.9px, -116.228703px);
    }
  }
  #e9Asl6wGzg128_tr {
    animation: e9Asl6wGzg128_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg128_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(-73deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg128_ts {
    animation: e9Asl6wGzg128_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg128_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg128 {
    animation: e9Asl6wGzg128_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg128_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg129_to {
    animation: e9Asl6wGzg129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg129_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    12.5% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg129_tr {
    animation: e9Asl6wGzg129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg129_ts {
    animation: e9Asl6wGzg129_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg129_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg129 {
    animation: e9Asl6wGzg129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg129_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg130_to {
    animation: e9Asl6wGzg130_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg130_to__to {
    0% {
      transform: translate(846.5px, 51.771297px);
    }
    12.5% {
      transform: translate(846.5px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #e9Asl6wGzg130_tr {
    animation: e9Asl6wGzg130_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg130_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg130 {
    animation: e9Asl6wGzg130_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg130_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg132 {
    animation-name: e9Asl6wGzg132__m, e9Asl6wGzg132_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg132__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes e9Asl6wGzg132_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg133_to {
    animation: e9Asl6wGzg133_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg133_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    51.25% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -115.228703px);
    }
    100% {
      transform: translate(693.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg133_tr {
    animation: e9Asl6wGzg133_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg133_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg133_ts {
    animation: e9Asl6wGzg133_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg133_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg133 {
    animation: e9Asl6wGzg133_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg133_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.8;
    }
    86.25% {
      opacity: 0.8;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg134_to {
    animation: e9Asl6wGzg134_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg134_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    51.25% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #e9Asl6wGzg134_tr {
    animation: e9Asl6wGzg134_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg134_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg134_ts {
    animation: e9Asl6wGzg134_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg134_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg134 {
    animation: e9Asl6wGzg134_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg134_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.9;
    }
    86.25% {
      opacity: 0.9;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg135_to {
    animation: e9Asl6wGzg135_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg135_to__to {
    0% {
      transform: translate(846.5px, 50.771297px);
    }
    51.25% {
      transform: translate(846.5px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #e9Asl6wGzg135_tr {
    animation: e9Asl6wGzg135_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg135_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg135 {
    animation: e9Asl6wGzg135_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg135_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg142 {
    animation-name: e9Asl6wGzg142__m, e9Asl6wGzg142_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg142__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes e9Asl6wGzg142_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg143_to {
    animation: e9Asl6wGzg143_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg143_to__to {
    0% {
      transform: translate(656.9px, -33.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -114.228703px);
    }
    100% {
      transform: translate(656.9px, -114.228703px);
    }
  }
  #e9Asl6wGzg143 {
    animation: e9Asl6wGzg143_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg143_c_o {
    0% {
      opacity: 0.8;
    }
    10% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg144_to {
    animation: e9Asl6wGzg144_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg144_to__to {
    0% {
      transform: translate(656.9px, -37.443085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e9Asl6wGzg144 {
    animation: e9Asl6wGzg144_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg144_c_o {
    0% {
      opacity: 0.9;
    }
    10% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg145_to {
    animation: e9Asl6wGzg145_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg145_to__to {
    0% {
      transform: translate(656.9px, -38.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.228703px);
    }
    100% {
      transform: translate(656.9px, -119.228703px);
    }
  }
  #e9Asl6wGzg145 {
    animation: e9Asl6wGzg145_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg145_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg147 {
    animation-name: e9Asl6wGzg147__m, e9Asl6wGzg147_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg147__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    62.5% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes e9Asl6wGzg147_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg148_to {
    animation: e9Asl6wGzg148_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg148_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -115.228703px);
    }
    100% {
      transform: translate(656.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg148_tr {
    animation: e9Asl6wGzg148_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg148_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(-70deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg148_ts {
    animation: e9Asl6wGzg148_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg148_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg148 {
    animation: e9Asl6wGzg148_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg148_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.8;
    }
    62.5% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg149_to {
    animation: e9Asl6wGzg149_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg149_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    22.5% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e9Asl6wGzg149_tr {
    animation: e9Asl6wGzg149_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg149_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg149_ts {
    animation: e9Asl6wGzg149_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg149_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg149 {
    animation: e9Asl6wGzg149_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg149_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.9;
    }
    62.5% {
      opacity: 0.9;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg150_to {
    animation: e9Asl6wGzg150_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg150_to__to {
    0% {
      transform: translate(786.798427px, 138.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #e9Asl6wGzg150_tr {
    animation: e9Asl6wGzg150_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg150_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg150 {
    animation: e9Asl6wGzg150_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg150_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg152 {
    animation-name: e9Asl6wGzg152__m, e9Asl6wGzg152_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg152__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,58.945745,591.54,58.945745'
      );
    }
  }
  @keyframes e9Asl6wGzg152_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg153_to {
    animation: e9Asl6wGzg153_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg153_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -34.549858px);
    }
  }
  #e9Asl6wGzg153_tr {
    animation: e9Asl6wGzg153_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg153_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(-70deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg153_ts {
    animation: e9Asl6wGzg153_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg153_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg153 {
    animation: e9Asl6wGzg153_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg153_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #e9Asl6wGzg154_to {
    animation: e9Asl6wGzg154_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg154_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    68.75% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -39.443085px);
    }
  }
  #e9Asl6wGzg154_tr {
    animation: e9Asl6wGzg154_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg154_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg154_ts {
    animation: e9Asl6wGzg154_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg154_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg154 {
    animation: e9Asl6wGzg154_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg154_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #e9Asl6wGzg155_to {
    animation: e9Asl6wGzg155_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg155_to__to {
    0% {
      transform: translate(786.798427px, 136.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.549858px);
    }
  }
  #e9Asl6wGzg155_tr {
    animation: e9Asl6wGzg155_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg155_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg155 {
    animation: e9Asl6wGzg155_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg155_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg161 {
    animation-name: e9Asl6wGzg161__m, e9Asl6wGzg161_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg161__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes e9Asl6wGzg161_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg162_to {
    animation: e9Asl6wGzg162_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg162_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -115.228703px);
    }
    100% {
      transform: translate(620.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg162_tr {
    animation: e9Asl6wGzg162_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg162_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(-68deg);
    }
    23.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg162_ts {
    animation: e9Asl6wGzg162_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg162_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg162 {
    animation: e9Asl6wGzg162_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg162_c_o {
    0% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg163_to {
    animation: e9Asl6wGzg163_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg163_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -121.728703px);
    }
    100% {
      transform: translate(620.9px, -121.728703px);
    }
  }
  #e9Asl6wGzg163_tr {
    animation: e9Asl6wGzg163_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg163_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg163_ts {
    animation: e9Asl6wGzg163_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg163_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg163 {
    animation: e9Asl6wGzg163_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg163_c_o {
    0% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg164_to {
    animation: e9Asl6wGzg164_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg164_to__to {
    0% {
      transform: translate(715.798427px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -122.228703px);
    }
    100% {
      transform: translate(620.9px, -122.228703px);
    }
  }
  #e9Asl6wGzg164_tr {
    animation: e9Asl6wGzg164_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg164_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg164 {
    animation: e9Asl6wGzg164_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg164_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg166 {
    animation-name: e9Asl6wGzg166__m, e9Asl6wGzg166_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg166__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes e9Asl6wGzg166_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg167_to {
    animation: e9Asl6wGzg167_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg167_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -114.228703px);
    }
    100% {
      transform: translate(620.9px, -114.228703px);
    }
  }
  #e9Asl6wGzg167_tr {
    animation: e9Asl6wGzg167_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg167_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(-68deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg167_ts {
    animation: e9Asl6wGzg167_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg167_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg167 {
    animation: e9Asl6wGzg167_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg167_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg168_to {
    animation: e9Asl6wGzg168_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg168_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -119.728703px);
    }
    100% {
      transform: translate(620.9px, -119.728703px);
    }
  }
  #e9Asl6wGzg168_tr {
    animation: e9Asl6wGzg168_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg168_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg168_ts {
    animation: e9Asl6wGzg168_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg168_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg168 {
    animation: e9Asl6wGzg168_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg168_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg169_to {
    animation: e9Asl6wGzg169_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg169_to__to {
    0% {
      transform: translate(715.798427px, 50.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(705.674548px, 12.059155px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(620.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -35.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -116.228703px);
    }
    100% {
      transform: translate(620.9px, -116.228703px);
    }
  }
  #e9Asl6wGzg169_tr {
    animation: e9Asl6wGzg169_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg169_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg169 {
    animation: e9Asl6wGzg169_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg169_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg177 {
    animation-name: e9Asl6wGzg177__m, e9Asl6wGzg177_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg177__m {
    0% {
      d: path('M357.283,158.189L357.283,140.33116C357.283,140.33116,356.854,134.799446,356.854,134.799446');
    }
    18.75% {
      d: path('M357.283,158.189L357.283,140.33116C357.283,140.33116,356.854,134.799446,356.854,134.799446');
    }
    27.5% {
      d: path('M357.283,138.189L357.283,117.474017C357.283,117.474017,356.854,106.370875,356.854,106.370875');
    }
    35% {
      d: path('M357.283,138.189L357.283,100.33116C357.283,100.33116,356.854,55.799446,356.854,55.799446');
    }
    46.25% {
      d: path('M357.283,58.189L357.283,10.33116C357.283,10.33116,356.854,-34.200554,356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116C357.283,10.33116,356.854,-34.200554,356.854,-34.200554');
    }
  }
  @keyframes e9Asl6wGzg177_c_o {
    0% {
      opacity: 0;
    }
    16.25% {
      opacity: 0;
    }
    17.5% {
      opacity: 1;
    }
    42.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg178_to {
    animation: e9Asl6wGzg178_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg178_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg178_ts {
    animation: e9Asl6wGzg178_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg178_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg178 {
    animation: e9Asl6wGzg178_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg178_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.8;
    }
    46.25% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg179_to {
    animation: e9Asl6wGzg179_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg179_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    13.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg179_ts {
    animation: e9Asl6wGzg179_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg179_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg179 {
    animation: e9Asl6wGzg179_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg179_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.9;
    }
    46.25% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg180_to {
    animation: e9Asl6wGzg180_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg180_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #e9Asl6wGzg180 {
    animation: e9Asl6wGzg180_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg180_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg182 {
    animation-name: e9Asl6wGzg182__m, e9Asl6wGzg182_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg182__m {
    0% {
      d: path('M357.283,156.189L357.283,140.33116L356.854,133.799446');
    }
    68.75% {
      d: path('M357.283,156.189L357.283,140.33116L356.854,133.799446');
    }
    76.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    85% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    96.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes e9Asl6wGzg182_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg183_to {
    animation: e9Asl6wGzg183_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg183_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg183_ts {
    animation: e9Asl6wGzg183_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg183_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg183 {
    animation: e9Asl6wGzg183_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg183_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg184_to {
    animation: e9Asl6wGzg184_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg184_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    63.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg184_ts {
    animation: e9Asl6wGzg184_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg184_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg184 {
    animation: e9Asl6wGzg184_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg184_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg185_to {
    animation: e9Asl6wGzg185_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg185_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #e9Asl6wGzg185 {
    animation: e9Asl6wGzg185_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg185_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg193 {
    animation-name: e9Asl6wGzg193__m, e9Asl6wGzg193_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg193__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    41.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes e9Asl6wGzg193_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg194_to {
    animation: e9Asl6wGzg194_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg194_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -115.228703px);
    }
    100% {
      transform: translate(398.699999px, -115.228703px);
    }
  }
  #e9Asl6wGzg194_tr {
    animation: e9Asl6wGzg194_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg194_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(68deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg194_ts {
    animation: e9Asl6wGzg194_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg194_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg194 {
    animation: e9Asl6wGzg194_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg194_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.8;
    }
    53.75% {
      opacity: 0.8;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg195_to {
    animation: e9Asl6wGzg195_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg195_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    18.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #e9Asl6wGzg195_tr {
    animation: e9Asl6wGzg195_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg195_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg195_ts {
    animation: e9Asl6wGzg195_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg195_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg195 {
    animation: e9Asl6wGzg195_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg195_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.9;
    }
    53.75% {
      opacity: 0.9;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg196_to {
    animation: e9Asl6wGzg196_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg196_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #e9Asl6wGzg196_tr {
    animation: e9Asl6wGzg196_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg196_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg196 {
    animation: e9Asl6wGzg196_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg196_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg198 {
    animation-name: e9Asl6wGzg198__m, e9Asl6wGzg198_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg198__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes e9Asl6wGzg198_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg199_to {
    animation: e9Asl6wGzg199_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg199_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -114.228703px);
    }
    100% {
      transform: translate(398.699999px, -114.228703px);
    }
  }
  #e9Asl6wGzg199_tr {
    animation: e9Asl6wGzg199_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg199_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(68deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg199_ts {
    animation: e9Asl6wGzg199_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg199_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg199 {
    animation: e9Asl6wGzg199_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg199_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1100_to {
    animation: e9Asl6wGzg1100_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1100_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    58.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #e9Asl6wGzg1100_tr {
    animation: e9Asl6wGzg1100_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1100_ts {
    animation: e9Asl6wGzg1100_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1100_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1100 {
    animation: e9Asl6wGzg1100_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1100_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1101_to {
    animation: e9Asl6wGzg1101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1101_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.228703px);
    }
    100% {
      transform: translate(398.699999px, -119.228703px);
    }
  }
  #e9Asl6wGzg1101_tr {
    animation: e9Asl6wGzg1101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1101 {
    animation: e9Asl6wGzg1101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1101_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1108 {
    animation-name: e9Asl6wGzg1108__m, e9Asl6wGzg1108_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1108__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    11.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    22.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    42.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e9Asl6wGzg1108_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1109_to {
    animation: e9Asl6wGzg1109_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1109_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg1109_tr {
    animation: e9Asl6wGzg1109_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1109_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(72deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1109_ts {
    animation: e9Asl6wGzg1109_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1109_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1109 {
    animation: e9Asl6wGzg1109_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1109_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1110_to {
    animation: e9Asl6wGzg1110_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1110_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    6.25% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg1110_tr {
    animation: e9Asl6wGzg1110_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1110_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1110_ts {
    animation: e9Asl6wGzg1110_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1110_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1110 {
    animation: e9Asl6wGzg1110_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1110_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0.9;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1111_to {
    animation: e9Asl6wGzg1111_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1111_to__to {
    0% {
      transform: translate(226.798427px, 141.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 141.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #e9Asl6wGzg1111_tr {
    animation: e9Asl6wGzg1111_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1111_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1111 {
    animation: e9Asl6wGzg1111_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1111_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1113 {
    animation-name: e9Asl6wGzg1113__m, e9Asl6wGzg1113_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1113__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e9Asl6wGzg1113_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1114_to {
    animation: e9Asl6wGzg1114_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1114_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg1114_tr {
    animation: e9Asl6wGzg1114_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1114_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(72deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1114_ts {
    animation: e9Asl6wGzg1114_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1114_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1114 {
    animation: e9Asl6wGzg1114_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1114_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.8;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1115_to {
    animation: e9Asl6wGzg1115_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1115_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    52.5% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #e9Asl6wGzg1115_tr {
    animation: e9Asl6wGzg1115_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1115_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1115_ts {
    animation: e9Asl6wGzg1115_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1115_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1115 {
    animation: e9Asl6wGzg1115_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1115_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.9;
    }
    93.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1116_to {
    animation: e9Asl6wGzg1116_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1116_to__to {
    0% {
      transform: translate(226.798427px, 138.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #e9Asl6wGzg1116_tr {
    animation: e9Asl6wGzg1116_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1116_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1116 {
    animation: e9Asl6wGzg1116_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1116_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1122 {
    animation-name: e9Asl6wGzg1122__m, e9Asl6wGzg1122_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1122__m {
    0% {
      d: path(
        'M96.7109,145.439636L96.7109,127.684958L96.812149,127.562584C96.812149,127.562584,96.894479,127.812584,96.894479,127.812584'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    21.25% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,33.289957,259.817001,33.289957'
      );
    }
    26.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes e9Asl6wGzg1122_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1123_to {
    animation: e9Asl6wGzg1123_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1123_to__to {
    0% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg1123_tr {
    animation: e9Asl6wGzg1123_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1123_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1123_ts {
    animation: e9Asl6wGzg1123_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1123_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1123 {
    animation: e9Asl6wGzg1123_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1123_c_o {
    0% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1124_to {
    animation: e9Asl6wGzg1124_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1124_to__to {
    0% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -121.728703px);
    }
    100% {
      transform: translate(324.9px, -121.728703px);
    }
  }
  #e9Asl6wGzg1124_tr {
    animation: e9Asl6wGzg1124_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1124_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1124_ts {
    animation: e9Asl6wGzg1124_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1124_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1124 {
    animation: e9Asl6wGzg1124_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1124_c_o {
    0% {
      opacity: 0.9;
    }
    30% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1125_to {
    animation: e9Asl6wGzg1125_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1125_to__to {
    0% {
      transform: translate(161.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    15% {
      transform: translate(309.773572px, -24.042276px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    22.5% {
      transform: translate(324.9px, -86.545293px);
      animation-timing-function: cubic-bezier(0.280804, 0.399795, 0.604922, 0.750053);
    }
    26.25% {
      transform: translate(324.9px, -111.961266px);
      animation-timing-function: cubic-bezier(0.291118, 0.499369, 0.620764, 0.851063);
    }
    30% {
      transform: translate(324.9px, -122.228703px);
    }
    100% {
      transform: translate(324.9px, -122.228703px);
    }
  }
  #e9Asl6wGzg1125_tr {
    animation: e9Asl6wGzg1125_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1125_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1125 {
    animation: e9Asl6wGzg1125_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1125_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1127 {
    animation-name: e9Asl6wGzg1127__m, e9Asl6wGzg1127_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1127__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,21.289957,259.817001,21.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    81.25% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes e9Asl6wGzg1127_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1128_to {
    animation: e9Asl6wGzg1128_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1128_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #e9Asl6wGzg1128_tr {
    animation: e9Asl6wGzg1128_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1128_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(73deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1128_ts {
    animation: e9Asl6wGzg1128_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1128_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1128 {
    animation: e9Asl6wGzg1128_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1128_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.8;
    }
    81.25% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1129_to {
    animation: e9Asl6wGzg1129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1129_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    42.5% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg1129_tr {
    animation: e9Asl6wGzg1129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1129_ts {
    animation: e9Asl6wGzg1129_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1129_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1129 {
    animation: e9Asl6wGzg1129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1129_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.9;
    }
    81.25% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1130_to {
    animation: e9Asl6wGzg1130_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1130_to__to {
    0% {
      transform: translate(161.798427px, 49.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(191.832868px, 8.675763px);
    }
    58.75% {
      transform: translate(231.488924px, -2.180118px);
    }
    66.25% {
      transform: translate(323.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #e9Asl6wGzg1130_tr {
    animation: e9Asl6wGzg1130_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1130_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1130 {
    animation: e9Asl6wGzg1130_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1130_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1132 {
    animation-name: e9Asl6wGzg1132__m, e9Asl6wGzg1132_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1132__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes e9Asl6wGzg1132_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg1133_to {
    animation: e9Asl6wGzg1133_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1133_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 38.016262px);
    }
  }
  #e9Asl6wGzg1133 {
    animation: e9Asl6wGzg1133_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1133_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg1134_to {
    animation: e9Asl6wGzg1134_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1134_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    96.25% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 33.299851px);
    }
  }
  #e9Asl6wGzg1134 {
    animation: e9Asl6wGzg1134_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1134_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg1135_to {
    animation: e9Asl6wGzg1135_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1135_to__to {
    0% {
      transform: translate(161.798427px, 50.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 34.016262px);
    }
  }
  #e9Asl6wGzg1135 {
    animation: e9Asl6wGzg1135_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1135_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9Asl6wGzg1142 {
    animation-name: e9Asl6wGzg1142__m, e9Asl6wGzg1142_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1142__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.920607,76.685694');
    }
    43.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    51.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes e9Asl6wGzg1142_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1143_to {
    animation: e9Asl6wGzg1143_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1143_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    11.25% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #e9Asl6wGzg1143_tr {
    animation: e9Asl6wGzg1143_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1143_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(75deg);
    }
    43.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1143_ts {
    animation: e9Asl6wGzg1143_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1143_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1143 {
    animation: e9Asl6wGzg1143_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1143_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1144_to {
    animation: e9Asl6wGzg1144_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1144_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg1144_tr {
    animation: e9Asl6wGzg1144_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1144_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1144_ts {
    animation: e9Asl6wGzg1144_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1144_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1144 {
    animation: e9Asl6wGzg1144_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1144_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1145_to {
    animation: e9Asl6wGzg1145_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1145_to__to {
    0% {
      transform: translate(96.798427px, 142.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 142.271297px);
    }
    17.5% {
      transform: translate(96.748252px, 116.509555px);
    }
    18.75% {
      transform: translate(96.738217px, 108.957207px);
    }
    21.25% {
      transform: translate(96.718147px, 82.185843px);
    }
    26.25% {
      transform: translate(96.678007px, 22.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    33.75% {
      transform: translate(174.541791px, -1.63208px);
    }
    41.25% {
      transform: translate(282.833827px, -26.93208px);
    }
    42.5% {
      transform: translate(287.711987px, -31.028703px);
    }
    43.75% {
      transform: translate(287.814424px, -38.440824px);
    }
    45% {
      transform: translate(287.91686px, -53.852945px);
    }
    47.5% {
      transform: translate(287.913114px, -81.677187px);
    }
    50% {
      transform: translate(287.909367px, -103.50143px);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg1145_tr {
    animation: e9Asl6wGzg1145_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1145_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1145 {
    animation: e9Asl6wGzg1145_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1145_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1147 {
    animation-name: e9Asl6wGzg1147__m, e9Asl6wGzg1147_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9Asl6wGzg1147__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955,76.696465');
    }
    88.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes e9Asl6wGzg1147_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1148_to {
    animation: e9Asl6wGzg1148_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1148_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #e9Asl6wGzg1148_tr {
    animation: e9Asl6wGzg1148_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1148_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(75deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1148_ts {
    animation: e9Asl6wGzg1148_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1148_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1148 {
    animation: e9Asl6wGzg1148_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1148_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1149_to {
    animation: e9Asl6wGzg1149_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1149_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    57.5% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #e9Asl6wGzg1149_tr {
    animation: e9Asl6wGzg1149_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1149_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1149_ts {
    animation: e9Asl6wGzg1149_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1149_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e9Asl6wGzg1149 {
    animation: e9Asl6wGzg1149_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1149_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9Asl6wGzg1150_to {
    animation: e9Asl6wGzg1150_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1150_to__to {
    0% {
      transform: translate(96.798427px, 139.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 139.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #e9Asl6wGzg1150_tr {
    animation: e9Asl6wGzg1150_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1150_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9Asl6wGzg1150 {
    animation: e9Asl6wGzg1150_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9Asl6wGzg1150_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg9;
