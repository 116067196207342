// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg1 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="egZarFQoIyJ1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 64 212"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="64"
      height="212"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="egZarFQoIyJ8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="egZarFQoIyJ8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ8-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="egZarFQoIyJ8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="6,6" />
          <feOffset id="egZarFQoIyJ9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="egZarFQoIyJ9-filter-drop-shadow-0-flood" floodColor="rgba(94,255,215,0.6)" />
          <feComposite id="egZarFQoIyJ9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="egZarFQoIyJ9-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="egZarFQoIyJ9-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="egZarFQoIyJ9-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="egZarFQoIyJ10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="egZarFQoIyJ10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ10-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="egZarFQoIyJ10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ11-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="egZarFQoIyJ11-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ11-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="egZarFQoIyJ11-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ11-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ11-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ11-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ12-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="egZarFQoIyJ13-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ13-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="egZarFQoIyJ13-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ13-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="egZarFQoIyJ13-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ13-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ13-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ13-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="6,6" />
          <feOffset id="egZarFQoIyJ14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="egZarFQoIyJ14-filter-drop-shadow-0-flood" floodColor="rgba(94,255,215,0.6)" />
          <feComposite id="egZarFQoIyJ14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="egZarFQoIyJ14-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="egZarFQoIyJ14-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="egZarFQoIyJ14-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="egZarFQoIyJ15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="egZarFQoIyJ15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ15-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="egZarFQoIyJ15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="egZarFQoIyJ16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ16-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="egZarFQoIyJ16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ17-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="egZarFQoIyJ18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="egZarFQoIyJ18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ18-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="egZarFQoIyJ18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="6,6" />
          <feOffset id="egZarFQoIyJ19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="egZarFQoIyJ19-filter-drop-shadow-0-flood" floodColor="rgba(94,255,215,0.6)" />
          <feComposite id="egZarFQoIyJ19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="egZarFQoIyJ19-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="egZarFQoIyJ19-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="egZarFQoIyJ19-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="egZarFQoIyJ20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="egZarFQoIyJ20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ20-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="egZarFQoIyJ20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ21-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="egZarFQoIyJ21-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="egZarFQoIyJ21-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="egZarFQoIyJ21-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="egZarFQoIyJ21-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="egZarFQoIyJ21-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="egZarFQoIyJ21-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="egZarFQoIyJ22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="egZarFQoIyJ22-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#egZarFQoIyJ26)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-407.375154 1.012683)">
            <g mask="url(#egZarFQoIyJ24)">
              <g transform="translate(-5.423289-14.501953)">
                <line x1="445.277" y1="0.001953" x2="445.277" y2="155.962" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#egZarFQoIyJ8-filter)">
                  <path
                    id="egZarFQoIyJ9"
                    d="M357.283,148.189v-9.85784l-.429-3.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    filter="url(#egZarFQoIyJ9-filter)"
                    fill="none"
                    stroke="url(#egZarFQoIyJ9-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="egZarFQoIyJ10_to" transform="translate(509.798427,73.771297)">
                    <g id="egZarFQoIyJ10_ts" transform="scale(1,1)">
                      <rect
                        id="egZarFQoIyJ10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#egZarFQoIyJ10-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="egZarFQoIyJ11_to" transform="translate(509.798427,67.271297)">
                    <g id="egZarFQoIyJ11_ts" transform="scale(1,1)">
                      <rect
                        id="egZarFQoIyJ11"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#egZarFQoIyJ11-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="egZarFQoIyJ12_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="egZarFQoIyJ12"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#egZarFQoIyJ12-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#egZarFQoIyJ13-filter)">
                  <path
                    id="egZarFQoIyJ14"
                    d="M357.283,148.189v-7.85784l-.429-5.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    filter="url(#egZarFQoIyJ14-filter)"
                    fill="none"
                    stroke="url(#egZarFQoIyJ14-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="egZarFQoIyJ15_to" transform="translate(509.798427,73.771297)">
                    <g id="egZarFQoIyJ15_ts" transform="scale(1,1)">
                      <rect
                        id="egZarFQoIyJ15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#egZarFQoIyJ15-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="egZarFQoIyJ16_to" transform="translate(509.798427,67.271297)">
                    <g id="egZarFQoIyJ16_ts" transform="scale(1,1)">
                      <rect
                        id="egZarFQoIyJ16"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#egZarFQoIyJ16-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="egZarFQoIyJ17_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="egZarFQoIyJ17"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#egZarFQoIyJ17-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#egZarFQoIyJ18-filter)">
                  <path
                    id="egZarFQoIyJ19"
                    d="M357.283,148.189v-7.85784l-.429-5.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    filter="url(#egZarFQoIyJ19-filter)"
                    fill="none"
                    stroke="url(#egZarFQoIyJ19-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="egZarFQoIyJ20_to" transform="translate(509.798427,73.771297)">
                    <g id="egZarFQoIyJ20_ts" transform="scale(1,1)">
                      <rect
                        id="egZarFQoIyJ20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#egZarFQoIyJ20-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="egZarFQoIyJ21_to" transform="translate(509.798427,67.271297)">
                    <g id="egZarFQoIyJ21_ts" transform="scale(1,1)">
                      <rect
                        id="egZarFQoIyJ21"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#egZarFQoIyJ21-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="egZarFQoIyJ22_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="egZarFQoIyJ22"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#egZarFQoIyJ22-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="egZarFQoIyJ24" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="62"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 407.931986-1.012683)"
                  fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
          </g>
        </g>
        <mask id="egZarFQoIyJ26" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="275.919156"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #egZarFQoIyJ9 {
    animation-name: egZarFQoIyJ9__m, egZarFQoIyJ9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes egZarFQoIyJ9__m {
    0% {
      d: path('M357.283,148.189L357.283,138.33116L356.854,134.799446');
    }
    6.25% {
      d: path('M357.283,148.189L357.283,138.33116L356.854,134.799446');
    }
    16.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    25% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    36.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes egZarFQoIyJ9_c_o {
    0% {
      opacity: 0;
    }
    6.25% {
      opacity: 0;
    }
    7.5% {
      opacity: 1;
    }
    32.5% {
      opacity: 1;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ10_to {
    animation: egZarFQoIyJ10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ10_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    3.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #egZarFQoIyJ10_ts {
    animation: egZarFQoIyJ10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ10_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    13.75% {
      transform: scale(1, 1);
    }
    26.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #egZarFQoIyJ10 {
    animation: egZarFQoIyJ10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ10_c_o {
    0% {
      opacity: 0;
    }
    2.5% {
      opacity: 0;
    }
    3.75% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0.8;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ11_to {
    animation: egZarFQoIyJ11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ11_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    3.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #egZarFQoIyJ11_ts {
    animation: egZarFQoIyJ11_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ11_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    13.75% {
      transform: scale(1, 1);
    }
    26.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #egZarFQoIyJ11 {
    animation: egZarFQoIyJ11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ11_c_o {
    0% {
      opacity: 0;
    }
    2.5% {
      opacity: 0;
    }
    3.75% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0.9;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ12_to {
    animation: egZarFQoIyJ12_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ12_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    3.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #egZarFQoIyJ12 {
    animation: egZarFQoIyJ12_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ12_c_o {
    0% {
      opacity: 0;
    }
    2.5% {
      opacity: 0;
    }
    3.75% {
      opacity: 1;
    }
    36.25% {
      opacity: 1;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ14 {
    animation-name: egZarFQoIyJ14__m, egZarFQoIyJ14_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes egZarFQoIyJ14__m {
    0% {
      d: path('M357.283,148.189L357.283,140.33116L356.854,134.799446');
    }
    41.25% {
      d: path('M357.283,148.189L357.283,140.33116L356.854,134.799446');
    }
    51.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    60% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    71.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes egZarFQoIyJ14_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    67.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ15_to {
    animation: egZarFQoIyJ15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ15_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    38.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    71.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #egZarFQoIyJ15_ts {
    animation: egZarFQoIyJ15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    48.75% {
      transform: scale(1, 1);
    }
    61.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #egZarFQoIyJ15 {
    animation: egZarFQoIyJ15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ15_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    38.75% {
      opacity: 0.8;
    }
    71.25% {
      opacity: 0.8;
    }
    72.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ16_to {
    animation: egZarFQoIyJ16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ16_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    38.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    71.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #egZarFQoIyJ16_ts {
    animation: egZarFQoIyJ16_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ16_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    48.75% {
      transform: scale(1, 1);
    }
    61.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #egZarFQoIyJ16 {
    animation: egZarFQoIyJ16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ16_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    38.75% {
      opacity: 0.9;
    }
    71.25% {
      opacity: 0.9;
    }
    72.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ17_to {
    animation: egZarFQoIyJ17_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ17_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    38.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    71.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #egZarFQoIyJ17 {
    animation: egZarFQoIyJ17_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ17_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    38.75% {
      opacity: 1;
    }
    71.25% {
      opacity: 1;
    }
    72.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ19 {
    animation-name: egZarFQoIyJ19__m, egZarFQoIyJ19_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes egZarFQoIyJ19__m {
    0% {
      d: path('M357.283,148.189L357.283,140.33116L356.854,134.799446');
    }
    70% {
      d: path('M357.283,148.189L357.283,140.33116L356.854,134.799446');
    }
    80% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    88.75% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    98.75% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes egZarFQoIyJ19_c_o {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    71.25% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ20_to {
    animation: egZarFQoIyJ20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ20_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    67.5% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.46046, 0, 0.734272, 0.447694);
    }
    91.25% {
      transform: translate(509.855663px, -40.983824px);
      animation-timing-function: cubic-bezier(0.328783, 0.320599, 0.654309, 0.66097);
    }
    97.5% {
      transform: translate(509.887769px, -102.642984px);
      animation-timing-function: cubic-bezier(0.325157, 0.334815, 0.657451, 0.669133);
    }
    100% {
      transform: translate(509.9px, -119.228703px);
    }
  }
  #egZarFQoIyJ20_ts {
    animation: egZarFQoIyJ20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    77.5% {
      transform: scale(1, 1);
    }
    90% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #egZarFQoIyJ20 {
    animation: egZarFQoIyJ20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ20_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 0.8;
    }
    98.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ21_to {
    animation: egZarFQoIyJ21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ21_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    67.5% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.464987, 0, 0.732025, 0.455544);
    }
    91.25% {
      transform: translate(509.860469px, -47.561612px);
      animation-timing-function: cubic-bezier(0.321492, 0.323734, 0.64341, 0.667497);
    }
    98.75% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #egZarFQoIyJ21_ts {
    animation: egZarFQoIyJ21_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ21_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    77.5% {
      transform: scale(1, 1);
    }
    90% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #egZarFQoIyJ21 {
    animation: egZarFQoIyJ21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ21_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 0.9;
    }
    98.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }
  #egZarFQoIyJ22_to {
    animation: egZarFQoIyJ22_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ22_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    67.5% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    98.75% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #egZarFQoIyJ22 {
    animation: egZarFQoIyJ22_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes egZarFQoIyJ22_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg1;
