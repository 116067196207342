// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg5 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eQBqfzDpdml1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 748 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="748"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eQBqfzDpdml8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml8-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml9-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml9-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml10-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml11-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml11-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml11-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml11-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml11-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml11-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml11-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml12-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml13-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml13-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml13-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml13-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml13-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml13-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml13-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml13-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml14-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml14-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml14-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml15-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml16-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml17-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml23-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml24-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml24-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml24-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml25-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml26-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml27-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml28-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml29-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml29-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml29-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml30-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml30-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml30-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml30-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml30-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml30-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml30-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml31-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml32-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml33-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml34-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml34-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml34-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml35-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml36-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml37-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml42-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml42-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml42-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml42-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml42-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml42-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml42-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml42-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml43-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml43-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml43-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml44-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml45-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml46-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml47-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml47-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml47-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml47-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml47-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml47-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml47-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml47-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml48-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml48-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml48-stroke-1" offset="75%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml49-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml50-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml51-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml57-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml57-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml57-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml57-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml57-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml57-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml57-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml57-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml58-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml58-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml58-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml59-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml59-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml59-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml59-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml59-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml59-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml59-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml59-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml60-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml61-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml61-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml62-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml63-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml63-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml63-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml64-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml65-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml66-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml67-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml68-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eQBqfzDpdml68-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eQBqfzDpdml68-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eQBqfzDpdml69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml69-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml70-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml71-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml71-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml76-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml76-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml76-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml76-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml76-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml76-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml76-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml76-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml77-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eQBqfzDpdml77-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eQBqfzDpdml77-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eQBqfzDpdml78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml78-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml79-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml80-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml81-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml82-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eQBqfzDpdml82-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eQBqfzDpdml82-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eQBqfzDpdml83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml83-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml84-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml84-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml84-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml84-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml84-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml84-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml84-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml85-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eQBqfzDpdml86-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml86-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eQBqfzDpdml86-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml86-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eQBqfzDpdml86-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml86-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml86-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml86-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eQBqfzDpdml87-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eQBqfzDpdml87-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eQBqfzDpdml87-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eQBqfzDpdml88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml88-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml89-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eQBqfzDpdml89-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eQBqfzDpdml89-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eQBqfzDpdml89-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eQBqfzDpdml89-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eQBqfzDpdml89-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eQBqfzDpdml89-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eQBqfzDpdml90-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eQBqfzDpdml90-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#eQBqfzDpdml94)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-64.798432 1.012683)">
            <g mask="url(#eQBqfzDpdml19)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml8-filter)">
                  <path
                    id="eQBqfzDpdml9"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eQBqfzDpdml9-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml10_to" transform="translate(846.5,55.771297)">
                    <g id="eQBqfzDpdml10_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml10_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml10"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eQBqfzDpdml10-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml11_to" transform="translate(846.5,49.271297)">
                    <g id="eQBqfzDpdml11_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml11_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml11"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eQBqfzDpdml11-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml12_to" transform="translate(846.5,51.771297)">
                    <g id="eQBqfzDpdml12_tr" transform="rotate(0)">
                      <rect
                        id="eQBqfzDpdml12"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml12-filter)"
                        fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml13-filter)">
                  <path
                    id="eQBqfzDpdml14"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eQBqfzDpdml14-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml15_to" transform="translate(846.5,55.771297)">
                    <g id="eQBqfzDpdml15_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml15_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml15"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eQBqfzDpdml15-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml16_to" transform="translate(846.5,49.271297)">
                    <g id="eQBqfzDpdml16_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml16_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml16"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eQBqfzDpdml16-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml17_to" transform="translate(846.5,50.771297)">
                    <g id="eQBqfzDpdml17_tr" transform="rotate(0)">
                      <rect
                        id="eQBqfzDpdml17"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml17-filter)"
                        fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eQBqfzDpdml19" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="240"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 585.931986-1.012683)"
                  fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml23-filter)">
                <path
                  id="eQBqfzDpdml24"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eQBqfzDpdml24-stroke)"
                  strokeLinecap="round"
                />
                <g id="eQBqfzDpdml25_to" transform="translate(656.203897,77.481243)">
                  <g id="eQBqfzDpdml25_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml25_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml25"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eQBqfzDpdml25-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml26_to" transform="translate(656.203897,72.170302)">
                  <g id="eQBqfzDpdml26_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml26_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml26"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eQBqfzDpdml26-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml27_to" transform="translate(656.411986,71.237316)">
                  <g id="eQBqfzDpdml27_tr" transform="rotate(0)">
                    <rect
                      id="eQBqfzDpdml27"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eQBqfzDpdml27-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml28-filter)">
                <path
                  id="eQBqfzDpdml29"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eQBqfzDpdml29-stroke)"
                  strokeLinecap="round"
                />
                <g id="eQBqfzDpdml30_to" transform="translate(656.798427,143.771297)">
                  <g id="eQBqfzDpdml30_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml30_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml30"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml30-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml31_to" transform="translate(656.798427,137.271297)">
                  <g id="eQBqfzDpdml31_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml31_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml31"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml31-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml32_to" transform="translate(656.798427,136.771297)">
                  <g id="eQBqfzDpdml32_tr" transform="rotate(0)">
                    <rect
                      id="eQBqfzDpdml32"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml32-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml33-filter)">
                <path
                  id="eQBqfzDpdml34"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eQBqfzDpdml34-stroke)"
                  strokeLinecap="round"
                />
                <g id="eQBqfzDpdml35_to" transform="translate(656.798427,143.771297)">
                  <rect
                    id="eQBqfzDpdml35"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eQBqfzDpdml35-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eQBqfzDpdml36_to" transform="translate(656.798427,137.271297)">
                  <rect
                    id="eQBqfzDpdml36"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eQBqfzDpdml36-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eQBqfzDpdml37_to" transform="translate(656.798427,135.771297)">
                  <rect
                    id="eQBqfzDpdml37"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eQBqfzDpdml37-filter)"
                    fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#eQBqfzDpdml53)">
              <g transform="translate(-5.423289-14.501953)">
                <line x1="445.277" y1="0.001953" x2="445.277" y2="155.962" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml42-filter)">
                  <path
                    id="eQBqfzDpdml43"
                    d="M357.283,158.189v-17.85784c0,0-.429-5.531714-.429-5.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eQBqfzDpdml43-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml44_to" transform="translate(509.798427,73.771297)">
                    <g id="eQBqfzDpdml44_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml44"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml44-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eQBqfzDpdml45_to" transform="translate(509.798427,67.271297)">
                    <g id="eQBqfzDpdml45_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml45"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml45-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eQBqfzDpdml46_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="eQBqfzDpdml46"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml46-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml47-filter)">
                  <path
                    id="eQBqfzDpdml48"
                    d="M357.283,156.189v-15.85784l-.429-6.531714"
                    transform="translate(153-73.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eQBqfzDpdml48-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml49_to" transform="translate(509.798427,73.771297)">
                    <g id="eQBqfzDpdml49_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml49-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eQBqfzDpdml50_to" transform="translate(509.798427,67.271297)">
                    <g id="eQBqfzDpdml50_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml50"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml50-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eQBqfzDpdml51_to" transform="translate(509.798427,65.771297)">
                    <rect
                      id="eQBqfzDpdml51"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml51-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eQBqfzDpdml53" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="62"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 408.931986-1.012683)"
                  fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml57-filter)">
                <path
                  id="eQBqfzDpdml58"
                  d="M590.336,239.869727v-53.816765l.000001-.109794.133858.109795"
                  transform="matrix(-1 0 0 1 948.153992-110.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eQBqfzDpdml58-stroke)"
                  strokeLinecap="round"
                />
                <g id="eQBqfzDpdml59_to" transform="translate(357.5,74.508595)">
                  <g id="eQBqfzDpdml59_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml59_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml59"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eQBqfzDpdml59-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml60_to" transform="translate(357.5,69.792183)">
                  <g id="eQBqfzDpdml60_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml60_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml60"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eQBqfzDpdml60-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml61_to" transform="translate(357.5,68.508595)">
                  <g id="eQBqfzDpdml61_tr" transform="rotate(0)">
                    <rect
                      id="eQBqfzDpdml61"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eQBqfzDpdml61-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml62-filter)">
                <path
                  id="eQBqfzDpdml63"
                  d="M590.336,239.869727v-53.816765l.000001-.109794.133858.109795"
                  transform="matrix(-1 0 0 1 948.153992-110.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eQBqfzDpdml63-stroke)"
                  strokeLinecap="round"
                />
                <g id="eQBqfzDpdml64_to" transform="translate(357.5,143.771297)">
                  <g id="eQBqfzDpdml64_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml64_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml64"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml64-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml65_to" transform="translate(357.5,137.271297)">
                  <g id="eQBqfzDpdml65_tr" transform="rotate(0)">
                    <g id="eQBqfzDpdml65_ts" transform="scale(1,1)">
                      <rect
                        id="eQBqfzDpdml65"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml65-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eQBqfzDpdml66_to" transform="translate(357.5,138.771297)">
                  <g id="eQBqfzDpdml66_tr" transform="rotate(0)">
                    <rect
                      id="eQBqfzDpdml66"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml66-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml67-filter)">
                <path
                  id="eQBqfzDpdml68"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eQBqfzDpdml68-stroke)"
                  strokeLinecap="round"
                />
                <g id="eQBqfzDpdml69_to" transform="translate(357.5,143.771297)">
                  <rect
                    id="eQBqfzDpdml69"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eQBqfzDpdml69-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eQBqfzDpdml70_to" transform="translate(357.5,137.271297)">
                  <rect
                    id="eQBqfzDpdml70"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eQBqfzDpdml70-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eQBqfzDpdml71_to" transform="translate(357.5,136.771297)">
                  <rect
                    id="eQBqfzDpdml71"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eQBqfzDpdml71-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#eQBqfzDpdml92)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml76-filter)">
                  <path
                    id="eQBqfzDpdml77"
                    d="M96.7109,145.439636v-17.754678l.101249-.122374c0,0,.08233.25.08233.25"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eQBqfzDpdml77-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml78_to" transform="translate(161.798427,27.271297)">
                    <g id="eQBqfzDpdml78_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml78_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml78"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eQBqfzDpdml78-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml79_to" transform="translate(161.798427,23.771297)">
                    <g id="eQBqfzDpdml79_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml79_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml79"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eQBqfzDpdml79-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml80_to" transform="translate(161.798427,23.271297)">
                    <g id="eQBqfzDpdml80_tr" transform="rotate(0)">
                      <rect
                        id="eQBqfzDpdml80"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eQBqfzDpdml80-filter)"
                        fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml81-filter)">
                  <path
                    id="eQBqfzDpdml82"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eQBqfzDpdml82-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml83_to" transform="translate(161.798427,53.771297)">
                    <g id="eQBqfzDpdml83_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml83_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml83"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eQBqfzDpdml83-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml84_to" transform="translate(161.798427,47.271297)">
                    <g id="eQBqfzDpdml84_tr" transform="rotate(0)">
                      <g id="eQBqfzDpdml84_ts" transform="scale(1,1)">
                        <rect
                          id="eQBqfzDpdml84"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eQBqfzDpdml84-filter)"
                          fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eQBqfzDpdml85_to" transform="translate(161.798427,49.771297)">
                    <g id="eQBqfzDpdml85_tr" transform="rotate(0)">
                      <rect
                        id="eQBqfzDpdml85"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eQBqfzDpdml85-filter)"
                        fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eQBqfzDpdml86-filter)">
                  <path
                    id="eQBqfzDpdml87"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eQBqfzDpdml87-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eQBqfzDpdml88_to" transform="translate(161.798427,53.771297)">
                    <rect
                      id="eQBqfzDpdml88"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml88-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eQBqfzDpdml89_to" transform="translate(161.798427,47.271297)">
                    <rect
                      id="eQBqfzDpdml89"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml89-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eQBqfzDpdml90_to" transform="translate(161.798427,50.771297)">
                    <rect
                      id="eQBqfzDpdml90"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eQBqfzDpdml90-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eQBqfzDpdml92" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="240"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 65.931986-1.012683)"
                  fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
          </g>
        </g>
        <mask id="eQBqfzDpdml94" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eQBqfzDpdml9 {
    animation-name: eQBqfzDpdml9__m, eQBqfzDpdml9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml9__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eQBqfzDpdml9_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml10_to {
    animation: eQBqfzDpdml10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml10_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    12.5% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -116.228703px);
    }
    100% {
      transform: translate(693.9px, -116.228703px);
    }
  }
  #eQBqfzDpdml10_tr {
    animation: eQBqfzDpdml10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(-73deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml10_ts {
    animation: eQBqfzDpdml10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml10_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml10 {
    animation: eQBqfzDpdml10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml10_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml11_to {
    animation: eQBqfzDpdml11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml11_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    12.5% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eQBqfzDpdml11_tr {
    animation: eQBqfzDpdml11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml11_ts {
    animation: eQBqfzDpdml11_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml11_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml11 {
    animation: eQBqfzDpdml11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml11_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml12_to {
    animation: eQBqfzDpdml12_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml12_to__to {
    0% {
      transform: translate(846.5px, 51.771297px);
    }
    12.5% {
      transform: translate(846.5px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eQBqfzDpdml12_tr {
    animation: eQBqfzDpdml12_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml12_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml12 {
    animation: eQBqfzDpdml12_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml12_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml14 {
    animation-name: eQBqfzDpdml14__m, eQBqfzDpdml14_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml14__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eQBqfzDpdml14_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml15_to {
    animation: eQBqfzDpdml15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml15_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    51.25% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -115.228703px);
    }
    100% {
      transform: translate(693.9px, -115.228703px);
    }
  }
  #eQBqfzDpdml15_tr {
    animation: eQBqfzDpdml15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml15_ts {
    animation: eQBqfzDpdml15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml15 {
    animation: eQBqfzDpdml15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml15_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.8;
    }
    86.25% {
      opacity: 0.8;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml16_to {
    animation: eQBqfzDpdml16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml16_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    51.25% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eQBqfzDpdml16_tr {
    animation: eQBqfzDpdml16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml16_ts {
    animation: eQBqfzDpdml16_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml16_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml16 {
    animation: eQBqfzDpdml16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml16_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.9;
    }
    86.25% {
      opacity: 0.9;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml17_to {
    animation: eQBqfzDpdml17_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml17_to__to {
    0% {
      transform: translate(846.5px, 50.771297px);
    }
    51.25% {
      transform: translate(846.5px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eQBqfzDpdml17_tr {
    animation: eQBqfzDpdml17_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml17_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml17 {
    animation: eQBqfzDpdml17_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml17_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml24 {
    animation-name: eQBqfzDpdml24__m, eQBqfzDpdml24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml24__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    26.25% {
      d: path('M569.616571,112.934287L569.616571,112.88377L517.819,80.7272L517.818,21.947269');
    }
    35% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eQBqfzDpdml24_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml25_to {
    animation: eQBqfzDpdml25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml25_to__to {
    0% {
      transform: translate(656.203897px, 77.481243px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -109.228703px);
    }
    100% {
      transform: translate(582.9px, -109.228703px);
    }
  }
  #eQBqfzDpdml25_tr {
    animation: eQBqfzDpdml25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(-60deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml25_ts {
    animation: eQBqfzDpdml25_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml25_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml25 {
    animation: eQBqfzDpdml25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml25_c_o {
    0% {
      opacity: 0.8;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml26_to {
    animation: eQBqfzDpdml26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml26_to__to {
    0% {
      transform: translate(656.203897px, 72.170302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #eQBqfzDpdml26_tr {
    animation: eQBqfzDpdml26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml26_ts {
    animation: eQBqfzDpdml26_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml26_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml26 {
    animation: eQBqfzDpdml26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml26_c_o {
    0% {
      opacity: 0.9;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml27_to {
    animation: eQBqfzDpdml27_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml27_to__to {
    0% {
      transform: translate(656.411986px, 71.237316px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #eQBqfzDpdml27_tr {
    animation: eQBqfzDpdml27_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml27 {
    animation: eQBqfzDpdml27_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml27_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml29 {
    animation-name: eQBqfzDpdml29__m, eQBqfzDpdml29_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml29__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    72.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eQBqfzDpdml29_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml30_to {
    animation: eQBqfzDpdml30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml30_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -111.228703px);
    }
    100% {
      transform: translate(582.9px, -111.228703px);
    }
  }
  #eQBqfzDpdml30_tr {
    animation: eQBqfzDpdml30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(-60deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml30_ts {
    animation: eQBqfzDpdml30_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml30_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml30 {
    animation: eQBqfzDpdml30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml30_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml31_to {
    animation: eQBqfzDpdml31_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml31_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    37.5% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -116.728703px);
    }
    100% {
      transform: translate(582.9px, -116.728703px);
    }
  }
  #eQBqfzDpdml31_tr {
    animation: eQBqfzDpdml31_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml31_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml31_ts {
    animation: eQBqfzDpdml31_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml31_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml31 {
    animation: eQBqfzDpdml31_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml31_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml32_to {
    animation: eQBqfzDpdml32_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml32_to__to {
    0% {
      transform: translate(656.798427px, 136.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.9px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -118.228703px);
    }
    100% {
      transform: translate(582.9px, -118.228703px);
    }
  }
  #eQBqfzDpdml32_tr {
    animation: eQBqfzDpdml32_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml32_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml32 {
    animation: eQBqfzDpdml32_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml32_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml34 {
    animation-name: eQBqfzDpdml34__m, eQBqfzDpdml34_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml34__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    93.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
  }
  @keyframes eQBqfzDpdml34_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml35_to {
    animation: eQBqfzDpdml35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml35_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    90% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 77.481243px);
    }
  }
  #eQBqfzDpdml35 {
    animation: eQBqfzDpdml35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml35_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml36_to {
    animation: eQBqfzDpdml36_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml36_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    90% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 72.170302px);
    }
  }
  #eQBqfzDpdml36 {
    animation: eQBqfzDpdml36_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml36_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.928571;
    }
  }
  #eQBqfzDpdml37_to {
    animation: eQBqfzDpdml37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml37_to__to {
    0% {
      transform: translate(656.798427px, 135.771297px);
    }
    90% {
      transform: translate(656.798427px, 135.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 70.481243px);
    }
  }
  #eQBqfzDpdml37 {
    animation: eQBqfzDpdml37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml37_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml43 {
    animation-name: eQBqfzDpdml43__m, eQBqfzDpdml43_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml43__m {
    0% {
      d: path('M357.283,158.189L357.283,140.33116C357.283,140.33116,356.854,134.799446,356.854,134.799446');
    }
    18.75% {
      d: path('M357.283,158.189L357.283,140.33116C357.283,140.33116,356.854,134.799446,356.854,134.799446');
    }
    27.5% {
      d: path('M357.283,138.189L357.283,117.474017C357.283,117.474017,356.854,106.370875,356.854,106.370875');
    }
    35% {
      d: path('M357.283,138.189L357.283,100.33116C357.283,100.33116,356.854,55.799446,356.854,55.799446');
    }
    46.25% {
      d: path('M357.283,58.189L357.283,10.33116C357.283,10.33116,356.854,-34.200554,356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116C357.283,10.33116,356.854,-34.200554,356.854,-34.200554');
    }
  }
  @keyframes eQBqfzDpdml43_c_o {
    0% {
      opacity: 0;
    }
    16.25% {
      opacity: 0;
    }
    17.5% {
      opacity: 1;
    }
    42.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml44_to {
    animation: eQBqfzDpdml44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml44_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #eQBqfzDpdml44_ts {
    animation: eQBqfzDpdml44_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml44_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml44 {
    animation: eQBqfzDpdml44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml44_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.8;
    }
    46.25% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml45_to {
    animation: eQBqfzDpdml45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml45_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    13.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #eQBqfzDpdml45_ts {
    animation: eQBqfzDpdml45_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml45_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    23.75% {
      transform: scale(1, 1);
    }
    36.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml45 {
    animation: eQBqfzDpdml45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml45_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.9;
    }
    46.25% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml46_to {
    animation: eQBqfzDpdml46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml46_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    13.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    46.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #eQBqfzDpdml46 {
    animation: eQBqfzDpdml46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml46_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml48 {
    animation-name: eQBqfzDpdml48__m, eQBqfzDpdml48_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml48__m {
    0% {
      d: path('M357.283,156.189L357.283,140.33116L356.854,133.799446');
    }
    68.75% {
      d: path('M357.283,156.189L357.283,140.33116L356.854,133.799446');
    }
    76.25% {
      d: path('M357.283,138.189L357.283,120.33116L356.854,114.799446');
    }
    85% {
      d: path('M357.283,138.189L357.283,100.33116L356.854,55.799446');
    }
    96.25% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
    100% {
      d: path('M357.283,58.189L357.283,10.33116L356.854,-34.200554');
    }
  }
  @keyframes eQBqfzDpdml48_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml49_to {
    animation: eQBqfzDpdml49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml49_to__to {
    0% {
      transform: translate(509.798427px, 73.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 73.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -115.228703px);
    }
    100% {
      transform: translate(509.9px, -115.228703px);
    }
  }
  #eQBqfzDpdml49_ts {
    animation: eQBqfzDpdml49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml49 {
    animation: eQBqfzDpdml49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml49_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml50_to {
    animation: eQBqfzDpdml50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml50_to__to {
    0% {
      transform: translate(509.798427px, 67.271297px);
    }
    63.75% {
      transform: translate(509.798427px, 67.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -120.728703px);
    }
    100% {
      transform: translate(509.9px, -120.728703px);
    }
  }
  #eQBqfzDpdml50_ts {
    animation: eQBqfzDpdml50_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml50_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    73.75% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml50 {
    animation: eQBqfzDpdml50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml50_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml51_to {
    animation: eQBqfzDpdml51_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml51_to__to {
    0% {
      transform: translate(509.798427px, 65.771297px);
    }
    63.75% {
      transform: translate(509.798427px, 65.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    96.25% {
      transform: translate(509.9px, -121.228703px);
    }
    100% {
      transform: translate(509.9px, -121.228703px);
    }
  }
  #eQBqfzDpdml51 {
    animation: eQBqfzDpdml51_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml51_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml58 {
    animation-name: eQBqfzDpdml58__m, eQBqfzDpdml58_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml58__m {
    0% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    20% {
      d: path('M590.336,125.761512L590.336,125.817L511.819,80.7272L511.818,79.9168');
    }
    26.25% {
      d: path('M554.077,105.313869L554.077,105.183848L512.819,80.7272L512.818,2.72012');
    }
    32.5% {
      d: path('M513.818,80.866225L513.818,80.550695L513.819,80.7272L513.818,-6.47656');
    }
    100% {
      d: path('M513.818,80.866225L513.818,80.550695L513.819,80.7272L513.818,-6.47656');
    }
  }
  @keyframes eQBqfzDpdml58_c_o {
    0% {
      opacity: 0;
    }
    26.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml59_to {
    animation: eQBqfzDpdml59_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml59_to__to {
    0% {
      transform: translate(357.5px, 74.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eQBqfzDpdml59_tr {
    animation: eQBqfzDpdml59_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml59_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(65deg);
    }
    20% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml59_ts {
    animation: eQBqfzDpdml59_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml59_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml59 {
    animation: eQBqfzDpdml59_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml59_c_o {
    0% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0.8;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml60_to {
    animation: eQBqfzDpdml60_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml60_to__to {
    0% {
      transform: translate(357.5px, 69.792183px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -121.728703px);
    }
    100% {
      transform: translate(435.7px, -121.728703px);
    }
  }
  #eQBqfzDpdml60_tr {
    animation: eQBqfzDpdml60_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml60_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml60_ts {
    animation: eQBqfzDpdml60_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml60_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml60 {
    animation: eQBqfzDpdml60_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml60_c_o {
    0% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0.9;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml61_to {
    animation: eQBqfzDpdml61_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml61_to__to {
    0% {
      transform: translate(357.5px, 68.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(433.502724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eQBqfzDpdml61_tr {
    animation: eQBqfzDpdml61_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml61_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml61 {
    animation: eQBqfzDpdml61_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml61_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml63 {
    animation-name: eQBqfzDpdml63__m, eQBqfzDpdml63_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml63__m {
    0% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    48.75% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    53.75% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    68.75% {
      d: path('M590.336,125.761512L590.336,125.817L511.819,80.7272L511.818,79.9168');
    }
    75% {
      d: path('M554.077,105.313869L554.077,105.183848L512.819,80.7272L512.818,2.72012');
    }
    81.25% {
      d: path('M513.818,80.866225L513.818,80.550695L513.819,80.7272L513.818,-6.47656');
    }
    100% {
      d: path('M513.818,80.866225L513.818,80.550695L513.819,80.7272L513.818,-6.47656');
    }
  }
  @keyframes eQBqfzDpdml63_c_o {
    0% {
      opacity: 0;
    }
    48.75% {
      opacity: 0;
    }
    53.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    81.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml64_to {
    animation: eQBqfzDpdml64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml64_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    37.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eQBqfzDpdml64_tr {
    animation: eQBqfzDpdml64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(65deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml64_ts {
    animation: eQBqfzDpdml64_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml64_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml64 {
    animation: eQBqfzDpdml64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml64_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml65_to {
    animation: eQBqfzDpdml65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml65_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    37.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eQBqfzDpdml65_tr {
    animation: eQBqfzDpdml65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml65_ts {
    animation: eQBqfzDpdml65_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml65_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml65 {
    animation: eQBqfzDpdml65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml65_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0.9;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml66_to {
    animation: eQBqfzDpdml66_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml66_to__to {
    0% {
      transform: translate(357.5px, 138.771297px);
    }
    37.5% {
      transform: translate(357.5px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eQBqfzDpdml66_tr {
    animation: eQBqfzDpdml66_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml66 {
    animation: eQBqfzDpdml66_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml66_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml68 {
    animation-name: eQBqfzDpdml68__m, eQBqfzDpdml68_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml68__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    88.75% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    95% {
      d: path('M292.146,245.913846L292.146,243.521298L292.363848,245.008315L292.363849,245.771298');
    }
    100% {
      d: path('M292.146,237.836923L292.146,182.601649L292.254926,183.81536L292.254926,184.196852');
    }
  }
  @keyframes eQBqfzDpdml68_c_o {
    0% {
      opacity: 0;
    }
    87.5% {
      opacity: 0;
    }
    88.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml69_to {
    animation: eQBqfzDpdml69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml69_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    92.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 74.508595px);
    }
  }
  #eQBqfzDpdml69 {
    animation: eQBqfzDpdml69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml69_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml70_to {
    animation: eQBqfzDpdml70_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml70_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    92.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 69.792183px);
    }
  }
  #eQBqfzDpdml70 {
    animation: eQBqfzDpdml70_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml70_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml71_to {
    animation: eQBqfzDpdml71_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml71_to__to {
    0% {
      transform: translate(357.5px, 136.771297px);
    }
    92.5% {
      transform: translate(357.5px, 136.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 68.508595px);
    }
  }
  #eQBqfzDpdml71 {
    animation: eQBqfzDpdml71_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml71_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml77 {
    animation-name: eQBqfzDpdml77__m, eQBqfzDpdml77_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml77__m {
    0% {
      d: path(
        'M96.7109,145.439636L96.7109,127.684958L96.812149,127.562584C96.812149,127.562584,96.894479,127.812584,96.894479,127.812584'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    21.25% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,33.289957,259.817001,33.289957'
      );
    }
    26.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eQBqfzDpdml77_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml78_to {
    animation: eQBqfzDpdml78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml78_to__to {
    0% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eQBqfzDpdml78_tr {
    animation: eQBqfzDpdml78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml78_ts {
    animation: eQBqfzDpdml78_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml78_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml78 {
    animation: eQBqfzDpdml78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml78_c_o {
    0% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml79_to {
    animation: eQBqfzDpdml79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml79_to__to {
    0% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -121.728703px);
    }
    100% {
      transform: translate(324.9px, -121.728703px);
    }
  }
  #eQBqfzDpdml79_tr {
    animation: eQBqfzDpdml79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml79_ts {
    animation: eQBqfzDpdml79_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml79_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml79 {
    animation: eQBqfzDpdml79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml79_c_o {
    0% {
      opacity: 0.9;
    }
    30% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml80_to {
    animation: eQBqfzDpdml80_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml80_to__to {
    0% {
      transform: translate(161.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    15% {
      transform: translate(309.773572px, -24.042276px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    22.5% {
      transform: translate(324.9px, -86.545293px);
      animation-timing-function: cubic-bezier(0.280804, 0.399795, 0.604922, 0.750053);
    }
    26.25% {
      transform: translate(324.9px, -111.961266px);
      animation-timing-function: cubic-bezier(0.291118, 0.499369, 0.620764, 0.851063);
    }
    30% {
      transform: translate(324.9px, -122.228703px);
    }
    100% {
      transform: translate(324.9px, -122.228703px);
    }
  }
  #eQBqfzDpdml80_tr {
    animation: eQBqfzDpdml80_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml80_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml80 {
    animation: eQBqfzDpdml80_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml80_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml82 {
    animation-name: eQBqfzDpdml82__m, eQBqfzDpdml82_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml82__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,21.289957,259.817001,21.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    81.25% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eQBqfzDpdml82_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml83_to {
    animation: eQBqfzDpdml83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml83_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eQBqfzDpdml83_tr {
    animation: eQBqfzDpdml83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(73deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml83_ts {
    animation: eQBqfzDpdml83_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml83_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml83 {
    animation: eQBqfzDpdml83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml83_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.8;
    }
    81.25% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml84_to {
    animation: eQBqfzDpdml84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml84_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    42.5% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eQBqfzDpdml84_tr {
    animation: eQBqfzDpdml84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml84_ts {
    animation: eQBqfzDpdml84_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml84_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eQBqfzDpdml84 {
    animation: eQBqfzDpdml84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml84_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.9;
    }
    81.25% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml85_to {
    animation: eQBqfzDpdml85_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml85_to__to {
    0% {
      transform: translate(161.798427px, 49.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(191.832868px, 8.675763px);
    }
    58.75% {
      transform: translate(231.488924px, -2.180118px);
    }
    66.25% {
      transform: translate(323.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eQBqfzDpdml85_tr {
    animation: eQBqfzDpdml85_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml85_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eQBqfzDpdml85 {
    animation: eQBqfzDpdml85_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml85_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eQBqfzDpdml87 {
    animation-name: eQBqfzDpdml87__m, eQBqfzDpdml87_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eQBqfzDpdml87__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eQBqfzDpdml87_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml88_to {
    animation: eQBqfzDpdml88_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml88_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 38.016262px);
    }
  }
  #eQBqfzDpdml88 {
    animation: eQBqfzDpdml88_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml88_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml89_to {
    animation: eQBqfzDpdml89_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml89_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    96.25% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 33.299851px);
    }
  }
  #eQBqfzDpdml89 {
    animation: eQBqfzDpdml89_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml89_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eQBqfzDpdml90_to {
    animation: eQBqfzDpdml90_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml90_to__to {
    0% {
      transform: translate(161.798427px, 50.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 34.016262px);
    }
  }
  #eQBqfzDpdml90 {
    animation: eQBqfzDpdml90_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eQBqfzDpdml90_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesBg5;
