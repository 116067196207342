// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg2 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="ewsTWgMSIrb1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 231 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="231"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="ewsTWgMSIrb6-stroke"
          x1="235.691"
          y1="233.94"
          x2="194.587"
          y2="216.085"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb6-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="ewsTWgMSIrb6-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="ewsTWgMSIrb7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="ewsTWgMSIrb7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb7-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="ewsTWgMSIrb7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ewsTWgMSIrb8-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb8-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="ewsTWgMSIrb8-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="ewsTWgMSIrb9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb9-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb10-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="ewsTWgMSIrb12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="ewsTWgMSIrb12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb12-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="ewsTWgMSIrb12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ewsTWgMSIrb13-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb13-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="ewsTWgMSIrb13-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="ewsTWgMSIrb14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb14-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb15-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="ewsTWgMSIrb17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="ewsTWgMSIrb17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb17-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="ewsTWgMSIrb17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ewsTWgMSIrb18-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb18-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="ewsTWgMSIrb18-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="ewsTWgMSIrb19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb19-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb20-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="ewsTWgMSIrb23-stroke"
          x1="48.3008"
          y1="235.601"
          x2="87.0793"
          y2="218.937"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb23-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="ewsTWgMSIrb23-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="ewsTWgMSIrb24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="ewsTWgMSIrb24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb24-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="ewsTWgMSIrb24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ewsTWgMSIrb25-stroke"
          x1="1"
          y1="-0.015964"
          x2="1"
          y2="0.983627"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ewsTWgMSIrb25-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ewsTWgMSIrb25-stroke-1" offset="100%" stopColor="rgba(220,220,220,0)" />
        </linearGradient>
        <filter id="ewsTWgMSIrb26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb26-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb27-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb28-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="ewsTWgMSIrb29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="ewsTWgMSIrb29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb29-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="ewsTWgMSIrb29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ewsTWgMSIrb30-stroke"
          x1="2.846422"
          y1="1.054686"
          x2="2.846422"
          y2="-0.113172"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb30-stroke-0" offset="0%" stopColor="rgba(220,220,220,0)" />
          <stop
            id="ewsTWgMSIrb30-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="ewsTWgMSIrb31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb31-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb32-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb33-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="ewsTWgMSIrb34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="ewsTWgMSIrb34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb34-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="ewsTWgMSIrb34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ewsTWgMSIrb35-stroke"
          x1="74.479276"
          y1="-349.713613"
          x2="12.97488"
          y2="-5.470225"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb35-stroke-0" offset="0%" stopColor="#838383" />
          <stop
            id="ewsTWgMSIrb35-stroke-1"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ewsTWgMSIrb35-stroke-2" offset="100%" stopColor="rgba(30,30,30,0)" />
        </linearGradient>
        <filter id="ewsTWgMSIrb36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb36-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ewsTWgMSIrb37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="ewsTWgMSIrb37-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="ewsTWgMSIrb37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ewsTWgMSIrb37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ewsTWgMSIrb37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ewsTWgMSIrb37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="ewsTWgMSIrb38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ewsTWgMSIrb38-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="ewsTWgMSIrb39-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ewsTWgMSIrb39-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="ewsTWgMSIrb39-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="ewsTWgMSIrb39-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g mask="url(#ewsTWgMSIrb40)">
        <g>
          <g transform="translate(-27.02347-6.66309)">
            <g transform="translate(2 0)">
              <path
                d="M235.691,233.94v-110.447L199.845,78.761l-.001-76.09791"
                transform="translate(0 4)"
                fill="none"
                stroke="url(#ewsTWgMSIrb6-stroke)"
              />
              <g transform="translate(186 0)" filter="url(#ewsTWgMSIrb7-filter)">
                <path
                  id="ewsTWgMSIrb8"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ewsTWgMSIrb8-stroke)"
                />
                <g id="ewsTWgMSIrb9_to" transform="translate(49.349457,235.66309)">
                  <g id="ewsTWgMSIrb9_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb9_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#ewsTWgMSIrb9-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb10_to" transform="translate(49.349457,230.66309)">
                  <g id="ewsTWgMSIrb10_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb10_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#ewsTWgMSIrb10-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb11_to" transform="translate(49.349457,229.66309)">
                  <g id="ewsTWgMSIrb11_tr" transform="rotate(0)">
                    <rect
                      id="ewsTWgMSIrb11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#ewsTWgMSIrb11-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#ewsTWgMSIrb12-filter)">
                <path
                  id="ewsTWgMSIrb13"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ewsTWgMSIrb13-stroke)"
                />
                <g id="ewsTWgMSIrb14_to" transform="translate(49.349457,235.66309)">
                  <g id="ewsTWgMSIrb14_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb14_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#ewsTWgMSIrb14-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb15_to" transform="translate(49.349457,230.66309)">
                  <g id="ewsTWgMSIrb15_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb15_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#ewsTWgMSIrb15-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb16_to" transform="translate(49.349457,229.66309)">
                  <g id="ewsTWgMSIrb16_tr" transform="rotate(0)">
                    <rect
                      id="ewsTWgMSIrb16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#ewsTWgMSIrb16-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#ewsTWgMSIrb17-filter)">
                <path
                  id="ewsTWgMSIrb18"
                  d="M-14.387819,106.271538v0l-18.67408-23.157707-.046745-21.6845"
                  transform="translate(46.96253 0)"
                  fill="none"
                  stroke="url(#ewsTWgMSIrb18-stroke)"
                />
                <g id="ewsTWgMSIrb19_to" transform="translate(13.965137,65.993562)">
                  <rect
                    id="ewsTWgMSIrb19"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    opacity="0.9"
                    filter="url(#ewsTWgMSIrb19-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="ewsTWgMSIrb20_to" transform="translate(13.963603,61.496423)">
                  <rect
                    id="ewsTWgMSIrb20"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    filter="url(#ewsTWgMSIrb20-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="ewsTWgMSIrb21_to" transform="translate(14.040137,60.578326)">
                  <rect
                    id="ewsTWgMSIrb21"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#ewsTWgMSIrb21-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-2 0)">
              <path
                d="M48.3008,235.601v-107.92L84.1294,82.9597v-76.29661"
                fill="none"
                stroke="url(#ewsTWgMSIrb23-stroke)"
              />
              <g filter="url(#ewsTWgMSIrb24-filter)">
                <path
                  id="ewsTWgMSIrb25"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ewsTWgMSIrb25-stroke)"
                />
                <g id="ewsTWgMSIrb26_to" transform="translate(48.349457,135.66309)">
                  <g id="ewsTWgMSIrb26_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb26_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb26"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0.9"
                        filter="url(#ewsTWgMSIrb26-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb27_to" transform="translate(48.349457,130.66309)">
                  <g id="ewsTWgMSIrb27_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb27_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb27"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        filter="url(#ewsTWgMSIrb27-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb28_to" transform="translate(48.349457,129.66309)">
                  <g id="ewsTWgMSIrb28_tr" transform="rotate(0)">
                    <rect
                      id="ewsTWgMSIrb28"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      filter="url(#ewsTWgMSIrb28-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#ewsTWgMSIrb29-filter)">
                <path
                  id="ewsTWgMSIrb30"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ewsTWgMSIrb30-stroke)"
                />
                <g id="ewsTWgMSIrb31_to" transform="translate(49.349457,237.66309)">
                  <g id="ewsTWgMSIrb31_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb31_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb31"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#ewsTWgMSIrb31-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb32_to" transform="translate(49.349457,230.66309)">
                  <g id="ewsTWgMSIrb32_tr" transform="rotate(0)">
                    <g id="ewsTWgMSIrb32_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="ewsTWgMSIrb32"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#ewsTWgMSIrb32-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="ewsTWgMSIrb33_to" transform="translate(49.349457,229.66309)">
                  <g id="ewsTWgMSIrb33_tr" transform="rotate(0)">
                    <rect
                      id="ewsTWgMSIrb33"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#ewsTWgMSIrb33-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#ewsTWgMSIrb34-filter)">
                <path
                  id="ewsTWgMSIrb35"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ewsTWgMSIrb35-stroke)"
                />
                <g id="ewsTWgMSIrb36_to" transform="translate(49.349457,237.66309)">
                  <rect
                    id="ewsTWgMSIrb36"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#ewsTWgMSIrb36-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="ewsTWgMSIrb37_to" transform="translate(49.349457,230.66309)">
                  <rect
                    id="ewsTWgMSIrb37"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#ewsTWgMSIrb37-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="ewsTWgMSIrb38_to" transform="translate(49.349457,229.66309)">
                  <rect
                    id="ewsTWgMSIrb38"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#ewsTWgMSIrb38-filter)"
                    fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
          </g>
          <rect
            width="231"
            height="61.866793"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1.616376 0 134)"
            fill="url(#ewsTWgMSIrb39-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="ewsTWgMSIrb40" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="328" height="234" rx="0" ry="0" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #ewsTWgMSIrb8 {
    animation-name: ewsTWgMSIrb8__m, ewsTWgMSIrb8_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ewsTWgMSIrb8__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    33.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    46.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.306743, 0.414094, 0.620378, 0.807255);
    }
    51.666667% {
      d: path('M-29.243604,87.852739L-29.243604,87.852739L-33.168515,83.071077L-33.255832,50.544574');
      animation-timing-function: cubic-bezier(0.308844, 0.6341, 0.639526, 1);
    }
    60% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes ewsTWgMSIrb8_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    61.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb9_to {
    animation: ewsTWgMSIrb9_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb9_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    35% {
      transform: translate(49.349457px, 129.993562px);
    }
    36.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    43.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    45% {
      transform: translate(17.349457px, 87.540794px);
    }
    46.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    48.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    58.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #ewsTWgMSIrb9_tr {
    animation: ewsTWgMSIrb9_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb9_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(0deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(-40deg);
    }
    48.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb9_ts {
    animation: ewsTWgMSIrb9_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb9_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb9 {
    animation: ewsTWgMSIrb9_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb9_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 0.9;
    }
    58.333333% {
      opacity: 0.9;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb10_to {
    animation: ewsTWgMSIrb10_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb10_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    35% {
      transform: translate(48.011629px, 125.66309px);
    }
    36.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    43.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    45% {
      transform: translate(15.215426px, 85.140794px);
    }
    46.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    48.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    58.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #ewsTWgMSIrb10_tr {
    animation: ewsTWgMSIrb10_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb10_ts {
    animation: ewsTWgMSIrb10_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb10_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb10 {
    animation: ewsTWgMSIrb10_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb10_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb11_to {
    animation: ewsTWgMSIrb11_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb11_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(47.093619px, 124.50245px);
    }
    36.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    43.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    45% {
      transform: translate(14.762322px, 84.381349px);
    }
    46.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    48.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    58.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #ewsTWgMSIrb11_tr {
    animation: ewsTWgMSIrb11_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb11 {
    animation: ewsTWgMSIrb11_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb11_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb13 {
    animation-name: ewsTWgMSIrb13__m, ewsTWgMSIrb13_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ewsTWgMSIrb13__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    83.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    96.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.325674, 0.351957, 0.654677, 0.695819);
    }
    100% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
    }
  }
  @keyframes ewsTWgMSIrb13_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #ewsTWgMSIrb14_to {
    animation: ewsTWgMSIrb14_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb14_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    85% {
      transform: translate(49.349457px, 129.993562px);
    }
    86.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    93.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    95% {
      transform: translate(17.349457px, 87.540794px);
    }
    96.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    98.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    100% {
      transform: translate(13.965137px, 65.993562px);
    }
  }
  #ewsTWgMSIrb14_tr {
    animation: ewsTWgMSIrb14_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(-40deg);
    }
    98.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb14_ts {
    animation: ewsTWgMSIrb14_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb14_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb14 {
    animation: ewsTWgMSIrb14_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb14_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #ewsTWgMSIrb15_to {
    animation: ewsTWgMSIrb15_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb15_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    85% {
      transform: translate(48.011629px, 125.66309px);
    }
    86.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    93.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    95% {
      transform: translate(15.215426px, 85.140794px);
    }
    96.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    98.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    100% {
      transform: translate(13.963603px, 61.496423px);
    }
  }
  #ewsTWgMSIrb15_tr {
    animation: ewsTWgMSIrb15_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb15_ts {
    animation: ewsTWgMSIrb15_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb15_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb15 {
    animation: ewsTWgMSIrb15_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb15_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #ewsTWgMSIrb16_to {
    animation: ewsTWgMSIrb16_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb16_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(47.093619px, 124.50245px);
    }
    86.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    93.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    95% {
      transform: translate(14.762322px, 84.381349px);
    }
    96.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    98.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    100% {
      transform: translate(14.040137px, 60.578326px);
    }
  }
  #ewsTWgMSIrb16_tr {
    animation: ewsTWgMSIrb16_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb16 {
    animation: ewsTWgMSIrb16_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb16_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #ewsTWgMSIrb18 {
    animation-name: ewsTWgMSIrb18__m, ewsTWgMSIrb18_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ewsTWgMSIrb18__m {
    0% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
      animation-timing-function: cubic-bezier(0.316312, 0.39148, 0.646318, 0.736182);
    }
    8.333333% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes ewsTWgMSIrb18_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    5% {
      opacity: 1;
    }
    11.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb19_to {
    animation: ewsTWgMSIrb19_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb19_to__to {
    0% {
      transform: translate(13.965137px, 65.993562px);
    }
    8.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #ewsTWgMSIrb19 {
    animation: ewsTWgMSIrb19_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb19_c_o {
    0% {
      opacity: 0.9;
    }
    8.333333% {
      opacity: 0.9;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb20_to {
    animation: ewsTWgMSIrb20_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb20_to__to {
    0% {
      transform: translate(13.963603px, 61.496423px);
    }
    8.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #ewsTWgMSIrb20 {
    animation: ewsTWgMSIrb20_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb20_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb21_to {
    animation: ewsTWgMSIrb21_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb21_to__to {
    0% {
      transform: translate(14.040137px, 60.578326px);
    }
    8.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #ewsTWgMSIrb21 {
    animation: ewsTWgMSIrb21_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb21_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb25 {
    animation-name: ewsTWgMSIrb25__m, ewsTWgMSIrb25_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ewsTWgMSIrb25__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    13.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.705,82.9513L36.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    16.666667% {
      d: path('M11.808104,114.29263L36.733725,83.0597L36.733725,82.983036L36.779932,59.996492');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    26.666667% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes ewsTWgMSIrb25_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    28.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb26_to {
    animation: ewsTWgMSIrb26_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb26_to__to {
    0% {
      transform: translate(48.349457px, 135.66309px);
    }
    1.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    3.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    11.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    13.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    15% {
      transform: translate(84.01347px, 79.993562px);
    }
    25% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #ewsTWgMSIrb26_tr {
    animation: ewsTWgMSIrb26_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(0deg);
    }
    3.333333% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(40deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb26_ts {
    animation: ewsTWgMSIrb26_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb26_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb26 {
    animation: ewsTWgMSIrb26_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb26_c_o {
    0% {
      opacity: 0.9;
    }
    25% {
      opacity: 0.9;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb27_to {
    animation: ewsTWgMSIrb27_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb27_to__to {
    0% {
      transform: translate(48.349457px, 130.66309px);
    }
    1.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    3.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    11.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    13.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    15% {
      transform: translate(84.011629px, 75.66309px);
    }
    25% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #ewsTWgMSIrb27_tr {
    animation: ewsTWgMSIrb27_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb27_ts {
    animation: ewsTWgMSIrb27_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb27_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb27 {
    animation: ewsTWgMSIrb27_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb27_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb28_to {
    animation: ewsTWgMSIrb28_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb28_to__to {
    0% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    1.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    3.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    11.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    13.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    15% {
      transform: translate(84.12347px, 74.828326px);
    }
    25% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #ewsTWgMSIrb28_tr {
    animation: ewsTWgMSIrb28_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb28 {
    animation: ewsTWgMSIrb28_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb28_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb30 {
    animation-name: ewsTWgMSIrb30__m, ewsTWgMSIrb30_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ewsTWgMSIrb30__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
    }
    46.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.882028,83.113746L36.960941,83.0597');
      animation-timing-function: cubic-bezier(0.38481, 0, 0.70726, 0.403804);
    }
    61.666667% {
      d: path('M7.458603,119.746485L36.882027,83.059701L36.868636,83.104574L36.960941,69.691269');
      animation-timing-function: cubic-bezier(0.330218, 0.34364, 0.565924, 1);
    }
    70% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes ewsTWgMSIrb30_c_o {
    0% {
      opacity: 0;
    }
    43.333333% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    66.666667% {
      opacity: 1;
    }
    73.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb31_to {
    animation: ewsTWgMSIrb31_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb31_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 135.66309px);
    }
    46.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    48.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    56.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    58.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    60% {
      transform: translate(84.01347px, 79.993562px);
    }
    70% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #ewsTWgMSIrb31_tr {
    animation: ewsTWgMSIrb31_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb31_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    48.333333% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(40deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb31_ts {
    animation: ewsTWgMSIrb31_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb31_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb31 {
    animation: ewsTWgMSIrb31_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb31_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 0.9;
    }
    70% {
      opacity: 0.9;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb32_to {
    animation: ewsTWgMSIrb32_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb32_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 130.66309px);
    }
    46.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    48.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    56.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    58.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    60% {
      transform: translate(84.011629px, 75.66309px);
    }
    70% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #ewsTWgMSIrb32_tr {
    animation: ewsTWgMSIrb32_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb32_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb32_ts {
    animation: ewsTWgMSIrb32_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb32_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #ewsTWgMSIrb32 {
    animation: ewsTWgMSIrb32_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb32_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb33_to {
    animation: ewsTWgMSIrb33_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb33_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    48.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    56.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    58.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    60% {
      transform: translate(84.12347px, 74.828326px);
    }
    70% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #ewsTWgMSIrb33_tr {
    animation: ewsTWgMSIrb33_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ewsTWgMSIrb33 {
    animation: ewsTWgMSIrb33_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb33_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ewsTWgMSIrb35 {
    animation: ewsTWgMSIrb35_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb35_c_o {
    0% {
      opacity: 0;
    }
    98.333333% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #ewsTWgMSIrb36_to {
    animation: ewsTWgMSIrb36_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb36_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 135.66309px);
    }
  }
  #ewsTWgMSIrb36 {
    animation: ewsTWgMSIrb36_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb36_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #ewsTWgMSIrb37_to {
    animation: ewsTWgMSIrb37_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb37_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 130.66309px);
    }
  }
  #ewsTWgMSIrb37 {
    animation: ewsTWgMSIrb37_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb37_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #ewsTWgMSIrb38_to {
    animation: ewsTWgMSIrb38_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb38_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 129.66309px);
    }
  }
  #ewsTWgMSIrb38 {
    animation: ewsTWgMSIrb38_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes ewsTWgMSIrb38_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesMobileBg2;
