// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg3 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eO0d1GiqPoA1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 231 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="231"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eO0d1GiqPoA6-stroke"
          x1="235.691"
          y1="233.94"
          x2="194.587"
          y2="216.085"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA6-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eO0d1GiqPoA6-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eO0d1GiqPoA7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA7-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA8-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA8-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eO0d1GiqPoA8-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eO0d1GiqPoA9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA9-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA10-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eO0d1GiqPoA12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA12-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA13-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA13-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eO0d1GiqPoA13-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eO0d1GiqPoA14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA14-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA15-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eO0d1GiqPoA17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA17-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA18-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA18-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eO0d1GiqPoA18-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eO0d1GiqPoA19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA19-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA20-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eO0d1GiqPoA23-stroke"
          x1="142.055"
          y1="134.612"
          x2="142.477"
          y2="253.311"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA23-stroke-0" offset="0%" stopColor={styles.strokeColor} />
          <stop id="eO0d1GiqPoA23-stroke-1" offset="100%" stopColor="#030303" />
        </linearGradient>
        <filter id="eO0d1GiqPoA24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA24-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA25-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA26-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA27-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eO0d1GiqPoA28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA28-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA29-stroke"
          x1="1.68507"
          y1="84.330149"
          x2="0.186927"
          y2="6.0597"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA29-stroke-0" offset="0%" stopColor="rgba(51,51,51,0.3)" />
          <stop
            id="eO0d1GiqPoA29-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eO0d1GiqPoA30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA30-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA30-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA30-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA30-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA30-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA30-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA30-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA31-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA32-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eO0d1GiqPoA33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA33-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA34-stroke"
          x1="1.803113"
          y1="84.330149"
          x2="1.45094"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA34-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eO0d1GiqPoA34-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eO0d1GiqPoA35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA35-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA36-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA37-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eO0d1GiqPoA39-stroke"
          x1="48.3008"
          y1="235.601"
          x2="87.0793"
          y2="218.937"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA39-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eO0d1GiqPoA39-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eO0d1GiqPoA40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA40-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA40-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA40-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA40-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA40-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA40-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA40-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA41-stroke"
          x1="1"
          y1="-0.015964"
          x2="1"
          y2="0.983627"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eO0d1GiqPoA41-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eO0d1GiqPoA41-stroke-1" offset="100%" stopColor="rgba(220,220,220,0)" />
        </linearGradient>
        <filter id="eO0d1GiqPoA42-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA42-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA42-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA42-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA42-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA42-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA42-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA42-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA43-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA44-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eO0d1GiqPoA45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA45-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA46-stroke"
          x1="2.846422"
          y1="1.054686"
          x2="2.846422"
          y2="-0.113172"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA46-stroke-0" offset="0%" stopColor="rgba(220,220,220,0)" />
          <stop
            id="eO0d1GiqPoA46-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eO0d1GiqPoA47-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA47-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA47-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA47-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA47-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA47-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA47-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA47-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA48-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA49-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eO0d1GiqPoA50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eO0d1GiqPoA50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA50-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eO0d1GiqPoA50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eO0d1GiqPoA51-stroke"
          x1="74.479276"
          y1="-349.713613"
          x2="12.97488"
          y2="-5.470225"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA51-stroke-0" offset="0%" stopColor="#838383" />
          <stop
            id="eO0d1GiqPoA51-stroke-1"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eO0d1GiqPoA51-stroke-2" offset="100%" stopColor="rgba(30,30,30,0)" />
        </linearGradient>
        <filter id="eO0d1GiqPoA52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA52-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eO0d1GiqPoA53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eO0d1GiqPoA53-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eO0d1GiqPoA53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eO0d1GiqPoA53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eO0d1GiqPoA53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eO0d1GiqPoA53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eO0d1GiqPoA54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eO0d1GiqPoA54-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eO0d1GiqPoA55-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eO0d1GiqPoA55-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="eO0d1GiqPoA55-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="eO0d1GiqPoA55-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g mask="url(#eO0d1GiqPoA56)">
        <g>
          <g transform="translate(-27.02347-6.66309)">
            <g transform="translate(2 0)">
              <path
                d="M235.691,233.94v-110.447L199.845,78.761l-.001-76.09791"
                transform="translate(0 4)"
                fill="none"
                stroke="url(#eO0d1GiqPoA6-stroke)"
              />
              <g transform="translate(186 0)" filter="url(#eO0d1GiqPoA7-filter)">
                <path
                  id="eO0d1GiqPoA8"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA8-stroke)"
                />
                <g id="eO0d1GiqPoA9_to" transform="translate(49.349457,235.66309)">
                  <g id="eO0d1GiqPoA9_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA9_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eO0d1GiqPoA9-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA10_to" transform="translate(49.349457,230.66309)">
                  <g id="eO0d1GiqPoA10_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA10_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eO0d1GiqPoA10-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA11_to" transform="translate(49.349457,229.66309)">
                  <g id="eO0d1GiqPoA11_tr" transform="rotate(0)">
                    <rect
                      id="eO0d1GiqPoA11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eO0d1GiqPoA11-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#eO0d1GiqPoA12-filter)">
                <path
                  id="eO0d1GiqPoA13"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA13-stroke)"
                />
                <g id="eO0d1GiqPoA14_to" transform="translate(49.349457,235.66309)">
                  <g id="eO0d1GiqPoA14_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA14_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eO0d1GiqPoA14-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA15_to" transform="translate(49.349457,230.66309)">
                  <g id="eO0d1GiqPoA15_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA15_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eO0d1GiqPoA15-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA16_to" transform="translate(49.349457,229.66309)">
                  <g id="eO0d1GiqPoA16_tr" transform="rotate(0)">
                    <rect
                      id="eO0d1GiqPoA16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eO0d1GiqPoA16-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#eO0d1GiqPoA17-filter)">
                <path
                  id="eO0d1GiqPoA18"
                  d="M-14.387819,106.271538v0l-18.67408-23.157707-.046745-21.6845"
                  transform="translate(46.96253 0)"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA18-stroke)"
                />
                <g id="eO0d1GiqPoA19_to" transform="translate(13.965137,65.993562)">
                  <rect
                    id="eO0d1GiqPoA19"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    opacity="0.9"
                    filter="url(#eO0d1GiqPoA19-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eO0d1GiqPoA20_to" transform="translate(13.963603,61.496423)">
                  <rect
                    id="eO0d1GiqPoA20"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    filter="url(#eO0d1GiqPoA20-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eO0d1GiqPoA21_to" transform="translate(14.040137,60.578326)">
                  <rect
                    id="eO0d1GiqPoA21"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#eO0d1GiqPoA21-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g>
              <path d="M141.977,6.66309v234" fill="none" stroke="url(#eO0d1GiqPoA23-stroke)" />
              <g filter="url(#eO0d1GiqPoA24-filter)">
                <g id="eO0d1GiqPoA25_to" transform="translate(142.349457,232.66309)">
                  <rect
                    id="eO0d1GiqPoA25"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA25-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eO0d1GiqPoA26_to" transform="translate(142.349457,225.66309)">
                  <rect
                    id="eO0d1GiqPoA26"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA26-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eO0d1GiqPoA27_to" transform="translate(142.349457,224.66309)">
                  <rect
                    id="eO0d1GiqPoA27"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA27-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#eO0d1GiqPoA28-filter)">
                <path
                  id="eO0d1GiqPoA29"
                  d="M1.620239,125.822142v1.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA29-stroke)"
                />
                <g id="eO0d1GiqPoA30_to" transform="translate(142.349457,237.66309)">
                  <g id="eO0d1GiqPoA30_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eO0d1GiqPoA30"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eO0d1GiqPoA30-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eO0d1GiqPoA31_to" transform="translate(142.349457,230.66309)">
                  <g id="eO0d1GiqPoA31_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eO0d1GiqPoA31"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eO0d1GiqPoA31-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eO0d1GiqPoA32_to" transform="translate(142.349457,229.66309)">
                  <rect
                    id="eO0d1GiqPoA32"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA32-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#eO0d1GiqPoA33-filter)">
                <path
                  id="eO0d1GiqPoA34"
                  d="M1.620239,126.822142v.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA34-stroke)"
                />
                <g id="eO0d1GiqPoA35_to" transform="translate(142.193038,127.481163)">
                  <g id="eO0d1GiqPoA35_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eO0d1GiqPoA35"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0.9"
                      filter="url(#eO0d1GiqPoA35-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eO0d1GiqPoA36_to" transform="translate(142.193038,121.258415)">
                  <g id="eO0d1GiqPoA36_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eO0d1GiqPoA36"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      filter="url(#eO0d1GiqPoA36-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eO0d1GiqPoA37_to" transform="translate(142.244248,120.335341)">
                  <rect
                    id="eO0d1GiqPoA37"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#eO0d1GiqPoA37-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-2 0)">
              <path
                d="M48.3008,235.601v-107.92L84.1294,82.9597v-76.29661"
                fill="none"
                stroke="url(#eO0d1GiqPoA39-stroke)"
              />
              <g filter="url(#eO0d1GiqPoA40-filter)">
                <path
                  id="eO0d1GiqPoA41"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA41-stroke)"
                />
                <g id="eO0d1GiqPoA42_to" transform="translate(48.349457,135.66309)">
                  <g id="eO0d1GiqPoA42_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA42_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA42"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0.9"
                        filter="url(#eO0d1GiqPoA42-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA43_to" transform="translate(48.349457,130.66309)">
                  <g id="eO0d1GiqPoA43_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA43_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA43"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        filter="url(#eO0d1GiqPoA43-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA44_to" transform="translate(48.349457,129.66309)">
                  <g id="eO0d1GiqPoA44_tr" transform="rotate(0)">
                    <rect
                      id="eO0d1GiqPoA44"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      filter="url(#eO0d1GiqPoA44-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eO0d1GiqPoA45-filter)">
                <path
                  id="eO0d1GiqPoA46"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA46-stroke)"
                />
                <g id="eO0d1GiqPoA47_to" transform="translate(49.349457,237.66309)">
                  <g id="eO0d1GiqPoA47_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA47_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA47"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eO0d1GiqPoA47-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA48_to" transform="translate(49.349457,230.66309)">
                  <g id="eO0d1GiqPoA48_tr" transform="rotate(0)">
                    <g id="eO0d1GiqPoA48_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eO0d1GiqPoA48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eO0d1GiqPoA48-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eO0d1GiqPoA49_to" transform="translate(49.349457,229.66309)">
                  <g id="eO0d1GiqPoA49_tr" transform="rotate(0)">
                    <rect
                      id="eO0d1GiqPoA49"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eO0d1GiqPoA49-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eO0d1GiqPoA50-filter)">
                <path
                  id="eO0d1GiqPoA51"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eO0d1GiqPoA51-stroke)"
                />
                <g id="eO0d1GiqPoA52_to" transform="translate(49.349457,237.66309)">
                  <rect
                    id="eO0d1GiqPoA52"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA52-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eO0d1GiqPoA53_to" transform="translate(49.349457,230.66309)">
                  <rect
                    id="eO0d1GiqPoA53"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA53-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eO0d1GiqPoA54_to" transform="translate(49.349457,229.66309)">
                  <rect
                    id="eO0d1GiqPoA54"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eO0d1GiqPoA54-filter)"
                    fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
          </g>
          <rect
            width="231"
            height="61.866793"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1.616376 0 134)"
            fill="url(#eO0d1GiqPoA55-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="eO0d1GiqPoA56" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="328" height="234" rx="0" ry="0" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eO0d1GiqPoA8 {
    animation-name: eO0d1GiqPoA8__m, eO0d1GiqPoA8_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA8__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    33.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    46.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.306743, 0.414094, 0.620378, 0.807255);
    }
    51.666667% {
      d: path('M-29.243604,87.852739L-29.243604,87.852739L-33.168515,83.071077L-33.255832,50.544574');
      animation-timing-function: cubic-bezier(0.308844, 0.6341, 0.639526, 1);
    }
    60% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes eO0d1GiqPoA8_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    61.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA9_to {
    animation: eO0d1GiqPoA9_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA9_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    35% {
      transform: translate(49.349457px, 129.993562px);
    }
    36.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    43.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    45% {
      transform: translate(17.349457px, 87.540794px);
    }
    46.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    48.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    58.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #eO0d1GiqPoA9_tr {
    animation: eO0d1GiqPoA9_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA9_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(0deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(-40deg);
    }
    48.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA9_ts {
    animation: eO0d1GiqPoA9_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA9_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA9 {
    animation: eO0d1GiqPoA9_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA9_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 0.9;
    }
    58.333333% {
      opacity: 0.9;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA10_to {
    animation: eO0d1GiqPoA10_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA10_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    35% {
      transform: translate(48.011629px, 125.66309px);
    }
    36.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    43.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    45% {
      transform: translate(15.215426px, 85.140794px);
    }
    46.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    48.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    58.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #eO0d1GiqPoA10_tr {
    animation: eO0d1GiqPoA10_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA10_ts {
    animation: eO0d1GiqPoA10_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA10_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA10 {
    animation: eO0d1GiqPoA10_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA10_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA11_to {
    animation: eO0d1GiqPoA11_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA11_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(47.093619px, 124.50245px);
    }
    36.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    43.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    45% {
      transform: translate(14.762322px, 84.381349px);
    }
    46.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    48.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    58.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #eO0d1GiqPoA11_tr {
    animation: eO0d1GiqPoA11_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA11 {
    animation: eO0d1GiqPoA11_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA11_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA13 {
    animation-name: eO0d1GiqPoA13__m, eO0d1GiqPoA13_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA13__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    83.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    96.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.325674, 0.351957, 0.654677, 0.695819);
    }
    100% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
    }
  }
  @keyframes eO0d1GiqPoA13_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA14_to {
    animation: eO0d1GiqPoA14_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA14_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    85% {
      transform: translate(49.349457px, 129.993562px);
    }
    86.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    93.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    95% {
      transform: translate(17.349457px, 87.540794px);
    }
    96.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    98.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    100% {
      transform: translate(13.965137px, 65.993562px);
    }
  }
  #eO0d1GiqPoA14_tr {
    animation: eO0d1GiqPoA14_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(-40deg);
    }
    98.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA14_ts {
    animation: eO0d1GiqPoA14_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA14_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA14 {
    animation: eO0d1GiqPoA14_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA14_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eO0d1GiqPoA15_to {
    animation: eO0d1GiqPoA15_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA15_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    85% {
      transform: translate(48.011629px, 125.66309px);
    }
    86.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    93.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    95% {
      transform: translate(15.215426px, 85.140794px);
    }
    96.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    98.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    100% {
      transform: translate(13.963603px, 61.496423px);
    }
  }
  #eO0d1GiqPoA15_tr {
    animation: eO0d1GiqPoA15_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA15_ts {
    animation: eO0d1GiqPoA15_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA15_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA15 {
    animation: eO0d1GiqPoA15_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA15_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA16_to {
    animation: eO0d1GiqPoA16_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA16_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(47.093619px, 124.50245px);
    }
    86.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    93.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    95% {
      transform: translate(14.762322px, 84.381349px);
    }
    96.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    98.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    100% {
      transform: translate(14.040137px, 60.578326px);
    }
  }
  #eO0d1GiqPoA16_tr {
    animation: eO0d1GiqPoA16_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA16 {
    animation: eO0d1GiqPoA16_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA16_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA18 {
    animation-name: eO0d1GiqPoA18__m, eO0d1GiqPoA18_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA18__m {
    0% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
      animation-timing-function: cubic-bezier(0.316312, 0.39148, 0.646318, 0.736182);
    }
    8.333333% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes eO0d1GiqPoA18_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    6.666667% {
      opacity: 1;
    }
    11.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA19_to {
    animation: eO0d1GiqPoA19_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA19_to__to {
    0% {
      transform: translate(13.965137px, 65.993562px);
    }
    8.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #eO0d1GiqPoA19 {
    animation: eO0d1GiqPoA19_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA19_c_o {
    0% {
      opacity: 0.9;
    }
    8.333333% {
      opacity: 0.9;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA20_to {
    animation: eO0d1GiqPoA20_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA20_to__to {
    0% {
      transform: translate(13.963603px, 61.496423px);
    }
    8.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #eO0d1GiqPoA20 {
    animation: eO0d1GiqPoA20_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA20_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA21_to {
    animation: eO0d1GiqPoA21_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA21_to__to {
    0% {
      transform: translate(14.040137px, 60.578326px);
    }
    8.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #eO0d1GiqPoA21 {
    animation: eO0d1GiqPoA21_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA21_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA25_to {
    animation: eO0d1GiqPoA25_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA25_to__to {
    0% {
      transform: translate(142.349457px, 232.66309px);
    }
    80% {
      transform: translate(142.349457px, 232.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 127.481163px);
    }
  }
  #eO0d1GiqPoA25 {
    animation: eO0d1GiqPoA25_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA25_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eO0d1GiqPoA26_to {
    animation: eO0d1GiqPoA26_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA26_to__to {
    0% {
      transform: translate(142.349457px, 225.66309px);
    }
    80% {
      transform: translate(142.349457px, 225.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 121.258415px);
    }
  }
  #eO0d1GiqPoA26 {
    animation: eO0d1GiqPoA26_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA26_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA27_to {
    animation: eO0d1GiqPoA27_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA27_to__to {
    0% {
      transform: translate(142.349457px, 224.66309px);
    }
    80% {
      transform: translate(142.349457px, 224.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.244248px, 120.335341px);
    }
  }
  #eO0d1GiqPoA27 {
    animation: eO0d1GiqPoA27_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA27_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA29 {
    animation-name: eO0d1GiqPoA29__m, eO0d1GiqPoA29_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA29__m {
    0% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
    }
    55% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.291939, 0.488237, 0.577592, 1);
    }
    76.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes eO0d1GiqPoA29_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    71.666667% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA30_to {
    animation: eO0d1GiqPoA30_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA30_to__to {
    0% {
      transform: translate(142.349457px, 237.66309px);
    }
    35% {
      transform: translate(142.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #eO0d1GiqPoA30_ts {
    animation: eO0d1GiqPoA30_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA30_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA30 {
    animation: eO0d1GiqPoA30_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA30_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 0.9;
    }
    76.666667% {
      opacity: 0.9;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA31_to {
    animation: eO0d1GiqPoA31_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA31_to__to {
    0% {
      transform: translate(142.349457px, 230.66309px);
    }
    35% {
      transform: translate(142.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #eO0d1GiqPoA31_ts {
    animation: eO0d1GiqPoA31_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA31_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA31 {
    animation: eO0d1GiqPoA31_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA31_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA32_to {
    animation: eO0d1GiqPoA32_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA32_to__to {
    0% {
      transform: translate(142.349457px, 229.66309px);
    }
    35% {
      transform: translate(142.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #eO0d1GiqPoA32 {
    animation: eO0d1GiqPoA32_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA32_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA34 {
    animation-name: eO0d1GiqPoA34__m, eO0d1GiqPoA34_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA34__m {
    0% {
      d: path('M1.620239,126.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.319296, 0.369702, 0.634542, 0.749896);
    }
    11.666667% {
      d: path('M1.762467,92.702027L1.762467,92.733775L1.762467,92.607038L1.676009,50.333564');
      animation-timing-function: cubic-bezier(0.297615, 0.610899, 0.623772, 1);
    }
    21.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes eO0d1GiqPoA34_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA35_to {
    animation: eO0d1GiqPoA35_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA35_to__to {
    0% {
      transform: translate(142.193038px, 127.481163px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #eO0d1GiqPoA35_ts {
    animation: eO0d1GiqPoA35_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA35_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA35 {
    animation: eO0d1GiqPoA35_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA35_c_o {
    0% {
      opacity: 0.9;
    }
    21.666667% {
      opacity: 0.9;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA36_to {
    animation: eO0d1GiqPoA36_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA36_to__to {
    0% {
      transform: translate(142.193038px, 121.258415px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #eO0d1GiqPoA36_ts {
    animation: eO0d1GiqPoA36_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA36_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA36 {
    animation: eO0d1GiqPoA36_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA37_to {
    animation: eO0d1GiqPoA37_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA37_to__to {
    0% {
      transform: translate(142.244248px, 120.335341px);
      animation-timing-function: cubic-bezier(0.430844, 0, 0.69332, 0.575556);
    }
    13.333333% {
      transform: translate(142.160623px, 33.975185px);
      animation-timing-function: cubic-bezier(0.28661, 0.558021, 0.597351, 1);
    }
    21.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #eO0d1GiqPoA37 {
    animation: eO0d1GiqPoA37_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA37_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA41 {
    animation-name: eO0d1GiqPoA41__m, eO0d1GiqPoA41_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA41__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    13.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.705,82.9513L36.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    16.666667% {
      d: path('M11.808104,114.29263L36.733725,83.0597L36.733725,82.983036L36.779932,59.996492');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    26.666667% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes eO0d1GiqPoA41_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    28.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA42_to {
    animation: eO0d1GiqPoA42_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA42_to__to {
    0% {
      transform: translate(48.349457px, 135.66309px);
    }
    1.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    3.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    11.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    13.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    15% {
      transform: translate(84.01347px, 79.993562px);
    }
    25% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #eO0d1GiqPoA42_tr {
    animation: eO0d1GiqPoA42_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(0deg);
    }
    3.333333% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(40deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA42_ts {
    animation: eO0d1GiqPoA42_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA42_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA42 {
    animation: eO0d1GiqPoA42_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA42_c_o {
    0% {
      opacity: 0.9;
    }
    25% {
      opacity: 0.9;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA43_to {
    animation: eO0d1GiqPoA43_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA43_to__to {
    0% {
      transform: translate(48.349457px, 130.66309px);
    }
    1.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    3.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    11.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    13.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    15% {
      transform: translate(84.011629px, 75.66309px);
    }
    25% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #eO0d1GiqPoA43_tr {
    animation: eO0d1GiqPoA43_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA43_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA43_ts {
    animation: eO0d1GiqPoA43_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA43_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA43 {
    animation: eO0d1GiqPoA43_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA43_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA44_to {
    animation: eO0d1GiqPoA44_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA44_to__to {
    0% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    1.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    3.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    11.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    13.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    15% {
      transform: translate(84.12347px, 74.828326px);
    }
    25% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #eO0d1GiqPoA44_tr {
    animation: eO0d1GiqPoA44_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA44_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA44 {
    animation: eO0d1GiqPoA44_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA44_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA46 {
    animation-name: eO0d1GiqPoA46__m, eO0d1GiqPoA46_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eO0d1GiqPoA46__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
    }
    46.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.882028,83.113746L36.960941,83.0597');
      animation-timing-function: cubic-bezier(0.38481, 0, 0.70726, 0.403804);
    }
    61.666667% {
      d: path('M7.458603,119.746485L36.882027,83.059701L36.868636,83.104574L36.960941,69.691269');
      animation-timing-function: cubic-bezier(0.330218, 0.34364, 0.565924, 1);
    }
    70% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes eO0d1GiqPoA46_c_o {
    0% {
      opacity: 0;
    }
    43.333333% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    66.666667% {
      opacity: 1;
    }
    73.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA47_to {
    animation: eO0d1GiqPoA47_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA47_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 135.66309px);
    }
    46.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    48.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    56.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    58.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    60% {
      transform: translate(84.01347px, 79.993562px);
    }
    70% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #eO0d1GiqPoA47_tr {
    animation: eO0d1GiqPoA47_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA47_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    48.333333% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(40deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA47_ts {
    animation: eO0d1GiqPoA47_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA47_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA47 {
    animation: eO0d1GiqPoA47_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA47_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 0.9;
    }
    70% {
      opacity: 0.9;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA48_to {
    animation: eO0d1GiqPoA48_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA48_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 130.66309px);
    }
    46.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    48.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    56.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    58.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    60% {
      transform: translate(84.011629px, 75.66309px);
    }
    70% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #eO0d1GiqPoA48_tr {
    animation: eO0d1GiqPoA48_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA48_ts {
    animation: eO0d1GiqPoA48_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA48_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eO0d1GiqPoA48 {
    animation: eO0d1GiqPoA48_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA48_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA49_to {
    animation: eO0d1GiqPoA49_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA49_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    48.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    56.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    58.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    60% {
      transform: translate(84.12347px, 74.828326px);
    }
    70% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #eO0d1GiqPoA49_tr {
    animation: eO0d1GiqPoA49_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eO0d1GiqPoA49 {
    animation: eO0d1GiqPoA49_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA49_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eO0d1GiqPoA51 {
    animation: eO0d1GiqPoA51_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA51_c_o {
    0% {
      opacity: 0;
    }
    98.333333% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA52_to {
    animation: eO0d1GiqPoA52_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA52_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 135.66309px);
    }
  }
  #eO0d1GiqPoA52 {
    animation: eO0d1GiqPoA52_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA52_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eO0d1GiqPoA53_to {
    animation: eO0d1GiqPoA53_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA53_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 130.66309px);
    }
  }
  #eO0d1GiqPoA53 {
    animation: eO0d1GiqPoA53_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA53_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eO0d1GiqPoA54_to {
    animation: eO0d1GiqPoA54_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA54_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 129.66309px);
    }
  }
  #eO0d1GiqPoA54 {
    animation: eO0d1GiqPoA54_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eO0d1GiqPoA54_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesMobileBg3;
