// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg10 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eETgrh93Y4F1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 874 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="874"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eETgrh93Y4F7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F7-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F8-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F8-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F8-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F9-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F10-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F12-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F13-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F13-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F13-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F14-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F15-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F16-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F17-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F18-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F18-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F19-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F20-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F26-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F27-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F27-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F27-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F28-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F29-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F30-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F31-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F32-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F32-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F32-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F33-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F34-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F35-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F41-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F42-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F42-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F42-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F43-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F44-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F46-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F47-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F47-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F47-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F48-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F49-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F50-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F51-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F52-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F52-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F52-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F53-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F54-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F55-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F60-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F61-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F61-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F61-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F62-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F63-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F63-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F63-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F63-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F63-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F63-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F63-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F64-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F65-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F66-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F66-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F66-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F67-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F68-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F69-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F75-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F75-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F75-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F75-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F75-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F75-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F75-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F75-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F76-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F76-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F76-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F77-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F78-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F79-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F80-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F80-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F80-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F80-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F80-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F80-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F80-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F81-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F81-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F81-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F82-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F82-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F82-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F82-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F82-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F82-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F82-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F82-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F83-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F84-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F85-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F85-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F85-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F85-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F85-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F85-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F85-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F86-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F86-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F86-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F87-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F87-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F87-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F87-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F87-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F87-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F87-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F87-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F88-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F89-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F93-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F93-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F93-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F93-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F93-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F93-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F93-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F93-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F94-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F94-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F94-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F95-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F95-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F95-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F95-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F95-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F95-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F95-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F95-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F96-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F97-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F98-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F98-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F98-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F98-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F98-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F98-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F98-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F98-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F99-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F99-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F99-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F100-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F101-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F102-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F103-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F103-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F103-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F103-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F103-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F103-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F103-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F103-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F104-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F104-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F104-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F105-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F105-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F105-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F105-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F105-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F105-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F105-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F105-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F106-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F106-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F106-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F106-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F106-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F106-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F106-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F106-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F107-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F107-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F112-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F113-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F113-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F113-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F114-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F115-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F115-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F115-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F115-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F115-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F115-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F115-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F115-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F116-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F117-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F117-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F117-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F117-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F117-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F117-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F117-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F117-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F118-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F118-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F118-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F119-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F119-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F119-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F119-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F119-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F119-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F119-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F119-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F120-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F120-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F120-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F120-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F120-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F120-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F120-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F120-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F121-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F121-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F127-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F127-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F127-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F127-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F127-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F127-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F127-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F127-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F128-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F128-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F128-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F129-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F129-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F129-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F129-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F129-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F129-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F129-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F130-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F130-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F130-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F130-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F130-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F130-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F130-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F130-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F131-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F131-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F132-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F132-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F132-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F132-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F132-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F132-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F132-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F132-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F133-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F133-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F133-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F134-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F134-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F134-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F134-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F134-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F134-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F134-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F134-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F135-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F135-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F135-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F135-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F135-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F135-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F135-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F135-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F136-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F136-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F136-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F136-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F136-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F136-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F136-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F136-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F141-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F141-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F141-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F141-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F141-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F141-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F141-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F141-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F142-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eETgrh93Y4F142-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eETgrh93Y4F142-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eETgrh93Y4F143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F143-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F144-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F144-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F144-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F144-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F144-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F144-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F144-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F144-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F145-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F145-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F146-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F146-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F146-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F146-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F146-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F146-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F146-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F146-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F147-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eETgrh93Y4F147-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eETgrh93Y4F147-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eETgrh93Y4F148-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F148-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F148-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F148-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F148-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F148-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F148-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F148-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F149-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F149-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F149-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F149-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F149-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F149-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F149-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F149-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F150-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F150-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F151-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F151-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F151-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F151-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F151-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F151-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F151-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F151-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F152-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eETgrh93Y4F152-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eETgrh93Y4F152-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eETgrh93Y4F153-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F153-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F153-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F153-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F153-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F153-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F153-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F153-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F154-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F154-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F154-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F154-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F154-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F154-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F154-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F154-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F155-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F155-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F161-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F161-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F161-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F161-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F161-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F161-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F161-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F161-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F162-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F162-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F162-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F163-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F163-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F163-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F163-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F163-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F163-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F163-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F163-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F164-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F164-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="7,7" />
          <feOffset id="eETgrh93Y4F164-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F164-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F164-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F164-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F164-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F164-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F165-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F165-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eETgrh93Y4F166-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F166-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eETgrh93Y4F166-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F166-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eETgrh93Y4F166-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F166-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F166-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F166-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eETgrh93Y4F167-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eETgrh93Y4F167-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eETgrh93Y4F167-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eETgrh93Y4F168-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F168-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F168-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F168-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F168-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F168-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F168-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F168-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F169-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F169-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eETgrh93Y4F169-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eETgrh93Y4F169-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eETgrh93Y4F169-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eETgrh93Y4F169-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eETgrh93Y4F169-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eETgrh93Y4F169-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eETgrh93Y4F170-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eETgrh93Y4F170-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#eETgrh93Y4F172)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-4.911987 1.012683)">
            <g transform="translate(-6 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F7-filter)">
                <path
                  id="eETgrh93Y4F8"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eETgrh93Y4F8-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F9_to" transform="translate(832.121519,-4.482748)">
                  <g id="eETgrh93Y4F9_tr" transform="rotate(-75)">
                    <g id="eETgrh93Y4F9_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eETgrh93Y4F9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eETgrh93Y4F9-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F10_to" transform="translate(826.798304,-5.828881)">
                  <g id="eETgrh93Y4F10_tr" transform="rotate(-75)">
                    <g id="eETgrh93Y4F10_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eETgrh93Y4F10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eETgrh93Y4F10-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F11_to" transform="translate(826.568884,-6.23967)">
                  <g id="eETgrh93Y4F11_tr" transform="rotate(-75)">
                    <rect
                      id="eETgrh93Y4F11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eETgrh93Y4F11-filter)"
                      fill={selected === 9 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F12-filter)">
                <path
                  id="eETgrh93Y4F13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F14_to" transform="translate(917.798427,143.771297)">
                  <g id="eETgrh93Y4F14_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F14_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F14-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F15_to" transform="translate(917.798427,137.271297)">
                  <g id="eETgrh93Y4F15_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F15_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F15-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F16_to" transform="translate(917.798427,137.771297)">
                  <g id="eETgrh93Y4F16_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F16-filter)"
                      fill={selected === 9 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F17-filter)">
                <path
                  id="eETgrh93Y4F18"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F18-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F19_to" transform="translate(917.798427,143.771297)">
                  <g id="eETgrh93Y4F19_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F19_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F19-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F20_to" transform="translate(917.798427,137.271297)">
                  <g id="eETgrh93Y4F20_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F20_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F20-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F21_to" transform="translate(917.798427,140.771297)">
                  <g id="eETgrh93Y4F21_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F21"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F21-filter)"
                      fill={selected === 9 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-11 0)" mask="url(#eETgrh93Y4F37)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F26-filter)">
                  <path
                    id="eETgrh93Y4F27"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F27-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F28_to" transform="translate(846.5,55.771297)">
                    <g id="eETgrh93Y4F28_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F28_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F28"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F28-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F29_to" transform="translate(846.5,49.271297)">
                    <g id="eETgrh93Y4F29_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F29_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F29"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F29-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F30_to" transform="translate(846.5,51.771297)">
                    <g id="eETgrh93Y4F30_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F30"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F30-filter)"
                        fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F31-filter)">
                  <path
                    id="eETgrh93Y4F32"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F32-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F33_to" transform="translate(846.5,55.771297)">
                    <g id="eETgrh93Y4F33_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F33_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F33"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F33-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F34_to" transform="translate(846.5,49.271297)">
                    <g id="eETgrh93Y4F34_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F34_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F34"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F34-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F35_to" transform="translate(846.5,50.771297)">
                    <g id="eETgrh93Y4F35_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F35"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F35-filter)"
                        fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eETgrh93Y4F37" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 605.931986-1.012683)"
                  fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-18.999999 0.624996)">
              <path
                d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F41-filter)">
                <path
                  id="eETgrh93Y4F42"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eETgrh93Y4F42-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F43_to" transform="translate(656.9,-33.549858)">
                  <rect
                    id="eETgrh93Y4F43"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#eETgrh93Y4F43-filter)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eETgrh93Y4F44_to" transform="translate(656.9,-37.443085)">
                  <rect
                    id="eETgrh93Y4F44"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#eETgrh93Y4F44-filter)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eETgrh93Y4F45_to" transform="translate(656.9,-38.549858)">
                  <rect
                    id="eETgrh93Y4F45"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#eETgrh93Y4F45-filter)"
                    fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F46-filter)">
                <path
                  id="eETgrh93Y4F47"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F47-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F48_to" transform="translate(786.798427,143.771297)">
                  <g id="eETgrh93Y4F48_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F48_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F48-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F49_to" transform="translate(786.798427,137.271297)">
                  <g id="eETgrh93Y4F49_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F49_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F49-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F50_to" transform="translate(786.798427,138.771297)">
                  <g id="eETgrh93Y4F50_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F50"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F50-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F51-filter)">
                <path
                  id="eETgrh93Y4F52"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F52-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F53_to" transform="translate(786.798427,143.771297)">
                  <g id="eETgrh93Y4F53_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F53_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F53"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F53-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F54_to" transform="translate(786.798427,137.271297)">
                  <g id="eETgrh93Y4F54_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F54_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F54"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F54-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F55_to" transform="translate(786.798427,136.771297)">
                  <g id="eETgrh93Y4F55_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F55-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-25 0)" mask="url(#eETgrh93Y4F71)">
              <g>
                <path
                  d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F60-filter)">
                  <path
                    id="eETgrh93Y4F61"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eETgrh93Y4F61-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F62_to" transform="translate(715.798427,56.771297)">
                    <g id="eETgrh93Y4F62_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F62_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F62"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eETgrh93Y4F62-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F63_to" transform="translate(715.798427,50.271297)">
                    <g id="eETgrh93Y4F63_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F63_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F63"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eETgrh93Y4F63-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F64_to" transform="translate(715.798427,51.771297)">
                    <g id="eETgrh93Y4F64_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F64"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eETgrh93Y4F64-filter)"
                        fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F65-filter)">
                  <path
                    id="eETgrh93Y4F66"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F66-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F67_to" transform="translate(715.798427,56.771297)">
                    <g id="eETgrh93Y4F67_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F67_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F67"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F67-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F68_to" transform="translate(715.798427,50.271297)">
                    <g id="eETgrh93Y4F68_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F68_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F68"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F68-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F69_to" transform="translate(715.798427,50.771297)">
                    <g id="eETgrh93Y4F69_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F69"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F69-filter)"
                        fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eETgrh93Y4F71" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 535.931986-1.012683)"
                  fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-30.999999-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F75-filter)">
                <path
                  id="eETgrh93Y4F76"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eETgrh93Y4F76-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F77_to" transform="translate(656.203897,77.481243)">
                  <g id="eETgrh93Y4F77_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F77_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F77"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eETgrh93Y4F77-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F78_to" transform="translate(656.203897,72.170302)">
                  <g id="eETgrh93Y4F78_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F78_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F78"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eETgrh93Y4F78-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F79_to" transform="translate(656.411986,71.237316)">
                  <g id="eETgrh93Y4F79_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F79"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eETgrh93Y4F79-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F80-filter)">
                <path
                  id="eETgrh93Y4F81"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F81-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F82_to" transform="translate(656.798427,143.771297)">
                  <g id="eETgrh93Y4F82_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F82_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F82"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F82-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F83_to" transform="translate(656.798427,137.271297)">
                  <g id="eETgrh93Y4F83_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F83_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F83"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F83-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F84_to" transform="translate(656.798427,136.771297)">
                  <g id="eETgrh93Y4F84_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F84"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F84-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F85-filter)">
                <path
                  id="eETgrh93Y4F86"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F86-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F87_to" transform="translate(656.798427,143.771297)">
                  <rect
                    id="eETgrh93Y4F87"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eETgrh93Y4F87-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eETgrh93Y4F88_to" transform="translate(656.798427,137.271297)">
                  <rect
                    id="eETgrh93Y4F88"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eETgrh93Y4F88-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eETgrh93Y4F89_to" transform="translate(656.798427,135.771297)">
                  <rect
                    id="eETgrh93Y4F89"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eETgrh93Y4F89-filter)"
                    fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(30 0)">
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F93-filter)">
                <path
                  id="eETgrh93Y4F94"
                  d="M292.146,232.836923v-55.235274l.108926,1.213711v.381492"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eETgrh93Y4F94-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F95_to" transform="translate(357.5,74.508595)">
                  <g id="eETgrh93Y4F95_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F95_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F95"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eETgrh93Y4F95-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F96_to" transform="translate(357.5,69.792183)">
                  <g id="eETgrh93Y4F96_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F96_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F96"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eETgrh93Y4F96-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F97_to" transform="translate(357.5,68.508595)">
                  <g id="eETgrh93Y4F97_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F97"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eETgrh93Y4F97-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F98-filter)">
                <path
                  id="eETgrh93Y4F99"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F99-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F100_to" transform="translate(357.5,143.771297)">
                  <g id="eETgrh93Y4F100_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F100_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F100"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F100-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F101_to" transform="translate(357.5,137.271297)">
                  <g id="eETgrh93Y4F101_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F101_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F101"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F101-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F102_to" transform="translate(357.5,138.771297)">
                  <g id="eETgrh93Y4F102_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F102"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F102-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F103-filter)">
                <path
                  id="eETgrh93Y4F104"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F104-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F105_to" transform="translate(357.5,143.771297)">
                  <rect
                    id="eETgrh93Y4F105"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eETgrh93Y4F105-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eETgrh93Y4F106_to" transform="translate(357.5,137.271297)">
                  <rect
                    id="eETgrh93Y4F106"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eETgrh93Y4F106-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eETgrh93Y4F107_to" transform="translate(357.5,136.771297)">
                  <rect
                    id="eETgrh93Y4F107"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eETgrh93Y4F107-filter)"
                    fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(25 0)" mask="url(#eETgrh93Y4F123)">
              <g transform="translate(.000001 0)">
                <path
                  d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F112-filter)">
                  <path
                    id="eETgrh93Y4F113"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F113-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F114_to" transform="translate(291.798427,53.771297)">
                    <g id="eETgrh93Y4F114_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F114_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F114"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F114-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F115_to" transform="translate(291.798427,47.271297)">
                    <g id="eETgrh93Y4F115_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F115_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F115"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F115-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F116_to" transform="translate(291.798427,49.771297)">
                    <g id="eETgrh93Y4F116_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F116"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F116-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F117-filter)">
                  <path
                    id="eETgrh93Y4F118"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F118-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F119_to" transform="translate(291.798427,53.771297)">
                    <g id="eETgrh93Y4F119_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F119_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F119"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F119-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F120_to" transform="translate(291.798427,47.271297)">
                    <g id="eETgrh93Y4F120_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F120_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F120"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F120-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F121_to" transform="translate(291.798427,49.771297)">
                    <g id="eETgrh93Y4F121_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F121"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F121-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eETgrh93Y4F123" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 209.931986-1.012683)"
                  fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(18 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F127-filter)">
                <path
                  id="eETgrh93Y4F128"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F128-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F129_to" transform="translate(226.798427,143.771297)">
                  <g id="eETgrh93Y4F129_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F129_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F129"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F129-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F130_to" transform="translate(226.798427,137.271297)">
                  <g id="eETgrh93Y4F130_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F130_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F130"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F130-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F131_to" transform="translate(226.798427,141.771297)">
                  <g id="eETgrh93Y4F131_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F131"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F131-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F132-filter)">
                <path
                  id="eETgrh93Y4F133"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F133-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F134_to" transform="translate(226.798427,143.771297)">
                  <g id="eETgrh93Y4F134_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F134_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F134"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F134-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F135_to" transform="translate(226.798427,137.271297)">
                  <g id="eETgrh93Y4F135_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F135_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F135"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F135-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F136_to" transform="translate(226.798427,138.771297)">
                  <g id="eETgrh93Y4F136_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F136"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F136-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(12 0)" mask="url(#eETgrh93Y4F157)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F141-filter)">
                  <path
                    id="eETgrh93Y4F142"
                    d="M96.7109,145.439636v-17.754678l.101249-.122374c0,0,.08233.25.08233.25"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eETgrh93Y4F142-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F143_to" transform="translate(161.798427,27.271297)">
                    <g id="eETgrh93Y4F143_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F143_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F143"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eETgrh93Y4F143-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F144_to" transform="translate(161.798427,23.771297)">
                    <g id="eETgrh93Y4F144_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F144_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F144"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eETgrh93Y4F144-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F145_to" transform="translate(161.798427,23.271297)">
                    <g id="eETgrh93Y4F145_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F145"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eETgrh93Y4F145-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F146-filter)">
                  <path
                    id="eETgrh93Y4F147"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F147-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F148_to" transform="translate(161.798427,53.771297)">
                    <g id="eETgrh93Y4F148_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F148_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F148"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F148-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F149_to" transform="translate(161.798427,47.271297)">
                    <g id="eETgrh93Y4F149_tr" transform="rotate(0)">
                      <g id="eETgrh93Y4F149_ts" transform="scale(1,1)">
                        <rect
                          id="eETgrh93Y4F149"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eETgrh93Y4F149-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eETgrh93Y4F150_to" transform="translate(161.798427,49.771297)">
                    <g id="eETgrh93Y4F150_tr" transform="rotate(0)">
                      <rect
                        id="eETgrh93Y4F150"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F150-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F151-filter)">
                  <path
                    id="eETgrh93Y4F152"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eETgrh93Y4F152-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eETgrh93Y4F153_to" transform="translate(161.798427,53.771297)">
                    <rect
                      id="eETgrh93Y4F153"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F153-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eETgrh93Y4F154_to" transform="translate(161.798427,47.271297)">
                    <rect
                      id="eETgrh93Y4F154"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F154-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eETgrh93Y4F155_to" transform="translate(161.798427,50.771297)">
                    <rect
                      id="eETgrh93Y4F155"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F155-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eETgrh93Y4F157" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 75.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(6 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F161-filter)">
                <path
                  id="eETgrh93Y4F162"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F162-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F163_to" transform="translate(96.798427,143.771297)">
                  <g id="eETgrh93Y4F163_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F163_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F163"
                        style={{ isolation: 'isolate' }}
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F163-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F164_to" transform="translate(96.798427,137.271297)">
                  <g id="eETgrh93Y4F164_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F164_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F164"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F164-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F165_to" transform="translate(96.798427,142.271297)">
                  <g id="eETgrh93Y4F165_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F165"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F165-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eETgrh93Y4F166-filter)">
                <path
                  id="eETgrh93Y4F167"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eETgrh93Y4F167-stroke)"
                  strokeLinecap="round"
                />
                <g id="eETgrh93Y4F168_to" transform="translate(96.798427,143.771297)">
                  <g id="eETgrh93Y4F168_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F168_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F168"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F168-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F169_to" transform="translate(96.798427,137.271297)">
                  <g id="eETgrh93Y4F169_tr" transform="rotate(0)">
                    <g id="eETgrh93Y4F169_ts" transform="scale(1,1)">
                      <rect
                        id="eETgrh93Y4F169"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eETgrh93Y4F169-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eETgrh93Y4F170_to" transform="translate(96.798427,139.771297)">
                  <g id="eETgrh93Y4F170_tr" transform="rotate(0)">
                    <rect
                      id="eETgrh93Y4F170"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eETgrh93Y4F170-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <rect
                width="63"
                height="63"
                rx="4.5"
                ry="4.5"
                transform="translate(.5 231.082)"
                fill="#030303"
                stroke="#878888"
              />
            </g>
          </g>
        </g>
        <mask id="eETgrh93Y4F172" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="896.38596"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eETgrh93Y4F8 {
    animation-name: eETgrh93Y4F8__m, eETgrh93Y4F8_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F8__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eETgrh93Y4F8_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F9_to {
    animation: eETgrh93Y4F9_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F9_to__to {
    0% {
      transform: translate(832.121519px, -4.482748px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -116.228703px);
    }
    100% {
      transform: translate(731.9px, -116.228703px);
    }
  }
  #eETgrh93Y4F9_tr {
    animation: eETgrh93Y4F9_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F9_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(-75deg);
    }
    10% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F9_ts {
    animation: eETgrh93Y4F9_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F9_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F9 {
    animation: eETgrh93Y4F9_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F9_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F10_to {
    animation: eETgrh93Y4F10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F10_to__to {
    0% {
      transform: translate(826.798304px, -5.828881px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.728703px);
    }
    100% {
      transform: translate(731.9px, -122.728703px);
    }
  }
  #eETgrh93Y4F10_tr {
    animation: eETgrh93Y4F10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F10_ts {
    animation: eETgrh93Y4F10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F10_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F10 {
    animation: eETgrh93Y4F10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F10_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F11_to {
    animation: eETgrh93Y4F11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F11_to__to {
    0% {
      transform: translate(826.568884px, -6.23967px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.228703px);
    }
    100% {
      transform: translate(731.9px, -122.228703px);
    }
  }
  #eETgrh93Y4F11_tr {
    animation: eETgrh93Y4F11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F11 {
    animation: eETgrh93Y4F11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F13 {
    animation-name: eETgrh93Y4F13__m, eETgrh93Y4F13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eETgrh93Y4F13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F14_to {
    animation: eETgrh93Y4F14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F14_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    25% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -115.228703px);
    }
    100% {
      transform: translate(731.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F14_tr {
    animation: eETgrh93Y4F14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(-75deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F14_ts {
    animation: eETgrh93Y4F14_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F14_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F14 {
    animation: eETgrh93Y4F14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    68.75% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F15_to {
    animation: eETgrh93Y4F15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F15_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    25% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.728703px);
    }
    100% {
      transform: translate(731.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F15_tr {
    animation: eETgrh93Y4F15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F15_ts {
    animation: eETgrh93Y4F15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F15 {
    animation: eETgrh93Y4F15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.9;
    }
    68.75% {
      opacity: 0.9;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F16_to {
    animation: eETgrh93Y4F16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F16_to__to {
    0% {
      transform: translate(917.798427px, 137.771297px);
    }
    25% {
      transform: translate(917.798427px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.228703px);
    }
    100% {
      transform: translate(731.9px, -120.228703px);
    }
  }
  #eETgrh93Y4F16_tr {
    animation: eETgrh93Y4F16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F16 {
    animation: eETgrh93Y4F16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F16_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F18 {
    animation-name: eETgrh93Y4F18__m, eETgrh93Y4F18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F18__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eETgrh93Y4F18_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F19_to {
    animation: eETgrh93Y4F19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F19_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    75% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(832.121519px, -4.482748px);
    }
  }
  #eETgrh93Y4F19_tr {
    animation: eETgrh93Y4F19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eETgrh93Y4F19_ts {
    animation: eETgrh93Y4F19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F19_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eETgrh93Y4F19 {
    animation: eETgrh93Y4F19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F20_to {
    animation: eETgrh93Y4F20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F20_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    75% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eETgrh93Y4F20_tr {
    animation: eETgrh93Y4F20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eETgrh93Y4F20_ts {
    animation: eETgrh93Y4F20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eETgrh93Y4F20 {
    animation: eETgrh93Y4F20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F20_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F21_to {
    animation: eETgrh93Y4F21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F21_to__to {
    0% {
      transform: translate(917.798427px, 140.771297px);
    }
    75% {
      transform: translate(917.798427px, 140.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eETgrh93Y4F21_tr {
    animation: eETgrh93Y4F21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eETgrh93Y4F21 {
    animation: eETgrh93Y4F21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F21_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F27 {
    animation-name: eETgrh93Y4F27__m, eETgrh93Y4F27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F27__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eETgrh93Y4F27_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F28_to {
    animation: eETgrh93Y4F28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F28_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    12.5% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -116.228703px);
    }
    100% {
      transform: translate(693.9px, -116.228703px);
    }
  }
  #eETgrh93Y4F28_tr {
    animation: eETgrh93Y4F28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(-73deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F28_ts {
    animation: eETgrh93Y4F28_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F28_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F28 {
    animation: eETgrh93Y4F28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F28_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F29_to {
    animation: eETgrh93Y4F29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F29_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    12.5% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F29_tr {
    animation: eETgrh93Y4F29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F29_ts {
    animation: eETgrh93Y4F29_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F29_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F29 {
    animation: eETgrh93Y4F29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F29_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F30_to {
    animation: eETgrh93Y4F30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F30_to__to {
    0% {
      transform: translate(846.5px, 51.771297px);
    }
    12.5% {
      transform: translate(846.5px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eETgrh93Y4F30_tr {
    animation: eETgrh93Y4F30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F30 {
    animation: eETgrh93Y4F30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F30_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F32 {
    animation-name: eETgrh93Y4F32__m, eETgrh93Y4F32_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F32__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eETgrh93Y4F32_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F33_to {
    animation: eETgrh93Y4F33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F33_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    51.25% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -115.228703px);
    }
    100% {
      transform: translate(693.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F33_tr {
    animation: eETgrh93Y4F33_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F33_ts {
    animation: eETgrh93Y4F33_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F33_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F33 {
    animation: eETgrh93Y4F33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F33_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.8;
    }
    86.25% {
      opacity: 0.8;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F34_to {
    animation: eETgrh93Y4F34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F34_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    51.25% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eETgrh93Y4F34_tr {
    animation: eETgrh93Y4F34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F34_ts {
    animation: eETgrh93Y4F34_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F34_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F34 {
    animation: eETgrh93Y4F34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F34_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.9;
    }
    86.25% {
      opacity: 0.9;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F35_to {
    animation: eETgrh93Y4F35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F35_to__to {
    0% {
      transform: translate(846.5px, 50.771297px);
    }
    51.25% {
      transform: translate(846.5px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eETgrh93Y4F35_tr {
    animation: eETgrh93Y4F35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F35 {
    animation: eETgrh93Y4F35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F35_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F42 {
    animation-name: eETgrh93Y4F42__m, eETgrh93Y4F42_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F42__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes eETgrh93Y4F42_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F43_to {
    animation: eETgrh93Y4F43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F43_to__to {
    0% {
      transform: translate(656.9px, -33.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -114.228703px);
    }
    100% {
      transform: translate(656.9px, -114.228703px);
    }
  }
  #eETgrh93Y4F43 {
    animation: eETgrh93Y4F43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F43_c_o {
    0% {
      opacity: 0.8;
    }
    10% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F44_to {
    animation: eETgrh93Y4F44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F44_to__to {
    0% {
      transform: translate(656.9px, -37.443085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eETgrh93Y4F44 {
    animation: eETgrh93Y4F44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F44_c_o {
    0% {
      opacity: 0.9;
    }
    10% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F45_to {
    animation: eETgrh93Y4F45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F45_to__to {
    0% {
      transform: translate(656.9px, -38.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.228703px);
    }
    100% {
      transform: translate(656.9px, -119.228703px);
    }
  }
  #eETgrh93Y4F45 {
    animation: eETgrh93Y4F45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F45_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F47 {
    animation-name: eETgrh93Y4F47__m, eETgrh93Y4F47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F47__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    60% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes eETgrh93Y4F47_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    62.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F48_to {
    animation: eETgrh93Y4F48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F48_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -115.228703px);
    }
    100% {
      transform: translate(656.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F48_tr {
    animation: eETgrh93Y4F48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(-70deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F48_ts {
    animation: eETgrh93Y4F48_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F48_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F48 {
    animation: eETgrh93Y4F48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F48_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.8;
    }
    62.5% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F49_to {
    animation: eETgrh93Y4F49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F49_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    22.5% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eETgrh93Y4F49_tr {
    animation: eETgrh93Y4F49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F49_ts {
    animation: eETgrh93Y4F49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F49 {
    animation: eETgrh93Y4F49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F49_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.9;
    }
    62.5% {
      opacity: 0.9;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F50_to {
    animation: eETgrh93Y4F50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F50_to__to {
    0% {
      transform: translate(786.798427px, 138.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #eETgrh93Y4F50_tr {
    animation: eETgrh93Y4F50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F50 {
    animation: eETgrh93Y4F50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F50_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F52 {
    animation-name: eETgrh93Y4F52__m, eETgrh93Y4F52_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F52__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,58.945745,591.54,58.945745'
      );
    }
  }
  @keyframes eETgrh93Y4F52_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F53_to {
    animation: eETgrh93Y4F53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F53_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -34.549858px);
    }
  }
  #eETgrh93Y4F53_tr {
    animation: eETgrh93Y4F53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(-70deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F53_ts {
    animation: eETgrh93Y4F53_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F53_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F53 {
    animation: eETgrh93Y4F53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F53_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eETgrh93Y4F54_to {
    animation: eETgrh93Y4F54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F54_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    68.75% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -39.443085px);
    }
  }
  #eETgrh93Y4F54_tr {
    animation: eETgrh93Y4F54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F54_ts {
    animation: eETgrh93Y4F54_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F54_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F54 {
    animation: eETgrh93Y4F54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F54_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eETgrh93Y4F55_to {
    animation: eETgrh93Y4F55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F55_to__to {
    0% {
      transform: translate(786.798427px, 136.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.549858px);
    }
  }
  #eETgrh93Y4F55_tr {
    animation: eETgrh93Y4F55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F55 {
    animation: eETgrh93Y4F55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F55_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F61 {
    animation-name: eETgrh93Y4F61__m, eETgrh93Y4F61_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F61__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eETgrh93Y4F61_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F62_to {
    animation: eETgrh93Y4F62_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F62_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -115.228703px);
    }
    100% {
      transform: translate(620.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F62_tr {
    animation: eETgrh93Y4F62_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F62_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(-68deg);
    }
    23.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F62_ts {
    animation: eETgrh93Y4F62_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F62_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F62 {
    animation: eETgrh93Y4F62_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F62_c_o {
    0% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F63_to {
    animation: eETgrh93Y4F63_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F63_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -121.728703px);
    }
    100% {
      transform: translate(620.9px, -121.728703px);
    }
  }
  #eETgrh93Y4F63_tr {
    animation: eETgrh93Y4F63_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F63_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F63_ts {
    animation: eETgrh93Y4F63_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F63_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F63 {
    animation: eETgrh93Y4F63_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F63_c_o {
    0% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F64_to {
    animation: eETgrh93Y4F64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F64_to__to {
    0% {
      transform: translate(715.798427px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -122.228703px);
    }
    100% {
      transform: translate(620.9px, -122.228703px);
    }
  }
  #eETgrh93Y4F64_tr {
    animation: eETgrh93Y4F64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F64 {
    animation: eETgrh93Y4F64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F64_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F66 {
    animation-name: eETgrh93Y4F66__m, eETgrh93Y4F66_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F66__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eETgrh93Y4F66_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F67_to {
    animation: eETgrh93Y4F67_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F67_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -114.228703px);
    }
    100% {
      transform: translate(620.9px, -114.228703px);
    }
  }
  #eETgrh93Y4F67_tr {
    animation: eETgrh93Y4F67_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(-68deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F67_ts {
    animation: eETgrh93Y4F67_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F67_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F67 {
    animation: eETgrh93Y4F67_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F67_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F68_to {
    animation: eETgrh93Y4F68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F68_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -119.728703px);
    }
    100% {
      transform: translate(620.9px, -119.728703px);
    }
  }
  #eETgrh93Y4F68_tr {
    animation: eETgrh93Y4F68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F68_ts {
    animation: eETgrh93Y4F68_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F68_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F68 {
    animation: eETgrh93Y4F68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F68_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F69_to {
    animation: eETgrh93Y4F69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F69_to__to {
    0% {
      transform: translate(715.798427px, 50.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(705.674548px, 12.059155px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.440845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -120.228703px);
    }
    100% {
      transform: translate(620.9px, -120.228703px);
    }
  }
  #eETgrh93Y4F69_tr {
    animation: eETgrh93Y4F69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F69 {
    animation: eETgrh93Y4F69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F69_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F76 {
    animation-name: eETgrh93Y4F76__m, eETgrh93Y4F76_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F76__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    30% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    35% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eETgrh93Y4F76_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F77_to {
    animation: eETgrh93Y4F77_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F77_to__to {
    0% {
      transform: translate(656.203897px, 77.481243px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(583.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(583.9px, -109.228703px);
    }
    100% {
      transform: translate(583.9px, -109.228703px);
    }
  }
  #eETgrh93Y4F77_tr {
    animation: eETgrh93Y4F77_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F77_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(-60deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F77_ts {
    animation: eETgrh93Y4F77_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F77_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F77 {
    animation: eETgrh93Y4F77_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F77_c_o {
    0% {
      opacity: 0.8;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F78_to {
    animation: eETgrh93Y4F78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F78_to__to {
    0% {
      transform: translate(656.203897px, 72.170302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(583.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(583.9px, -115.728703px);
    }
    100% {
      transform: translate(583.9px, -115.728703px);
    }
  }
  #eETgrh93Y4F78_tr {
    animation: eETgrh93Y4F78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F78_ts {
    animation: eETgrh93Y4F78_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F78_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F78 {
    animation: eETgrh93Y4F78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F78_c_o {
    0% {
      opacity: 0.9;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F79_to {
    animation: eETgrh93Y4F79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F79_to__to {
    0% {
      transform: translate(656.411986px, 71.237316px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(583.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(583.9px, -115.228703px);
    }
    100% {
      transform: translate(583.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F79_tr {
    animation: eETgrh93Y4F79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F79 {
    animation: eETgrh93Y4F79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F79_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F81 {
    animation-name: eETgrh93Y4F81__m, eETgrh93Y4F81_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F81__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    72.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eETgrh93Y4F81_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F82_to {
    animation: eETgrh93Y4F82_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F82_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -111.228703px);
    }
    100% {
      transform: translate(583.9px, -111.228703px);
    }
  }
  #eETgrh93Y4F82_tr {
    animation: eETgrh93Y4F82_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F82_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(-60deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F82_ts {
    animation: eETgrh93Y4F82_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F82_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F82 {
    animation: eETgrh93Y4F82_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F82_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F83_to {
    animation: eETgrh93Y4F83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F83_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    37.5% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -116.728703px);
    }
    100% {
      transform: translate(583.9px, -116.728703px);
    }
  }
  #eETgrh93Y4F83_tr {
    animation: eETgrh93Y4F83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F83_ts {
    animation: eETgrh93Y4F83_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F83_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F83 {
    animation: eETgrh93Y4F83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F83_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F84_to {
    animation: eETgrh93Y4F84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F84_to__to {
    0% {
      transform: translate(656.798427px, 136.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -118.228703px);
    }
    100% {
      transform: translate(583.9px, -118.228703px);
    }
  }
  #eETgrh93Y4F84_tr {
    animation: eETgrh93Y4F84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F84 {
    animation: eETgrh93Y4F84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F84_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F86 {
    animation-name: eETgrh93Y4F86__m, eETgrh93Y4F86_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F86__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    93.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
  }
  @keyframes eETgrh93Y4F86_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F87_to {
    animation: eETgrh93Y4F87_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F87_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    90% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 77.481243px);
    }
  }
  #eETgrh93Y4F87 {
    animation: eETgrh93Y4F87_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F87_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F88_to {
    animation: eETgrh93Y4F88_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F88_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    90% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 72.170302px);
    }
  }
  #eETgrh93Y4F88 {
    animation: eETgrh93Y4F88_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F88_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.928571;
    }
  }
  #eETgrh93Y4F89_to {
    animation: eETgrh93Y4F89_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F89_to__to {
    0% {
      transform: translate(656.798427px, 135.771297px);
    }
    90% {
      transform: translate(656.798427px, 135.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 70.481243px);
    }
  }
  #eETgrh93Y4F89 {
    animation: eETgrh93Y4F89_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F89_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F94 {
    animation-name: eETgrh93Y4F94__m, eETgrh93Y4F94_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F94__m {
    0% {
      d: path('M292.146,232.836923L292.146,177.601649L292.254926,178.81536L292.254926,179.196852');
    }
    5% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    18.75% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    23.75% {
      d: path('M316.216154,108.23846L316.216154,108.04326L370.374,76.7761L370.374,20.292588');
    }
    30% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    35% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eETgrh93Y4F94_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    26.25% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F95_to {
    animation: eETgrh93Y4F95_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F95_to__to {
    0% {
      transform: translate(357.5px, 74.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eETgrh93Y4F95_tr {
    animation: eETgrh93Y4F95_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F95_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(65deg);
    }
    20% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F95_ts {
    animation: eETgrh93Y4F95_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F95_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F95 {
    animation: eETgrh93Y4F95_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F95_c_o {
    0% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0.8;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F96_to {
    animation: eETgrh93Y4F96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F96_to__to {
    0% {
      transform: translate(357.5px, 69.792183px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -121.728703px);
    }
    100% {
      transform: translate(435.7px, -121.728703px);
    }
  }
  #eETgrh93Y4F96_tr {
    animation: eETgrh93Y4F96_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F96_ts {
    animation: eETgrh93Y4F96_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F96_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F96 {
    animation: eETgrh93Y4F96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F96_c_o {
    0% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0.9;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F97_to {
    animation: eETgrh93Y4F97_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F97_to__to {
    0% {
      transform: translate(357.5px, 68.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(433.502724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eETgrh93Y4F97_tr {
    animation: eETgrh93Y4F97_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F97_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F97 {
    animation: eETgrh93Y4F97_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F97_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F99 {
    animation-name: eETgrh93Y4F99__m, eETgrh93Y4F99_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F99__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    37.5% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    43.75% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    53.75% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    67.5% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    78.75% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    83.75% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eETgrh93Y4F99_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F100_to {
    animation: eETgrh93Y4F100_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F100_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    37.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eETgrh93Y4F100_tr {
    animation: eETgrh93Y4F100_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(65deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F100_ts {
    animation: eETgrh93Y4F100_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F100_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F100 {
    animation: eETgrh93Y4F100_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F100_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F101_to {
    animation: eETgrh93Y4F101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F101_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    37.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eETgrh93Y4F101_tr {
    animation: eETgrh93Y4F101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F101_ts {
    animation: eETgrh93Y4F101_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F101_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F101 {
    animation: eETgrh93Y4F101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F101_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0.9;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F102_to {
    animation: eETgrh93Y4F102_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F102_to__to {
    0% {
      transform: translate(357.5px, 138.771297px);
    }
    37.5% {
      transform: translate(357.5px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eETgrh93Y4F102_tr {
    animation: eETgrh93Y4F102_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F102 {
    animation: eETgrh93Y4F102_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F102_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F104 {
    animation-name: eETgrh93Y4F104__m, eETgrh93Y4F104_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F104__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    88.75% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    95% {
      d: path('M292.146,245.913846L292.146,243.521298L292.363848,245.008315L292.363849,245.771298');
    }
    100% {
      d: path('M292.146,237.836923L292.146,182.601649L292.254926,183.81536L292.254926,184.196852');
    }
  }
  @keyframes eETgrh93Y4F104_c_o {
    0% {
      opacity: 0;
    }
    87.5% {
      opacity: 0;
    }
    88.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F105_to {
    animation: eETgrh93Y4F105_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F105_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    92.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 74.508595px);
    }
  }
  #eETgrh93Y4F105 {
    animation: eETgrh93Y4F105_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F105_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F106_to {
    animation: eETgrh93Y4F106_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F106_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    92.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 69.792183px);
    }
  }
  #eETgrh93Y4F106 {
    animation: eETgrh93Y4F106_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F106_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F107_to {
    animation: eETgrh93Y4F107_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F107_to__to {
    0% {
      transform: translate(357.5px, 136.771297px);
    }
    92.5% {
      transform: translate(357.5px, 136.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 68.508595px);
    }
  }
  #eETgrh93Y4F107 {
    animation: eETgrh93Y4F107_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F107_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F113 {
    animation-name: eETgrh93Y4F113__m, eETgrh93Y4F113_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F113__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    41.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eETgrh93Y4F113_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F114_to {
    animation: eETgrh93Y4F114_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F114_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -115.228703px);
    }
    100% {
      transform: translate(398.699999px, -115.228703px);
    }
  }
  #eETgrh93Y4F114_tr {
    animation: eETgrh93Y4F114_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F114_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(68deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F114_ts {
    animation: eETgrh93Y4F114_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F114_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F114 {
    animation: eETgrh93Y4F114_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F114_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.8;
    }
    53.75% {
      opacity: 0.8;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F115_to {
    animation: eETgrh93Y4F115_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F115_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    18.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eETgrh93Y4F115_tr {
    animation: eETgrh93Y4F115_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F115_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F115_ts {
    animation: eETgrh93Y4F115_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F115_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F115 {
    animation: eETgrh93Y4F115_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F115_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.9;
    }
    53.75% {
      opacity: 0.9;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F116_to {
    animation: eETgrh93Y4F116_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F116_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eETgrh93Y4F116_tr {
    animation: eETgrh93Y4F116_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F116_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F116 {
    animation: eETgrh93Y4F116_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F116_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F118 {
    animation-name: eETgrh93Y4F118__m, eETgrh93Y4F118_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F118__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eETgrh93Y4F118_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F119_to {
    animation: eETgrh93Y4F119_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F119_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -114.228703px);
    }
    100% {
      transform: translate(398.699999px, -114.228703px);
    }
  }
  #eETgrh93Y4F119_tr {
    animation: eETgrh93Y4F119_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F119_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(68deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F119_ts {
    animation: eETgrh93Y4F119_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F119_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F119 {
    animation: eETgrh93Y4F119_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F119_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F120_to {
    animation: eETgrh93Y4F120_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F120_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    58.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eETgrh93Y4F120_tr {
    animation: eETgrh93Y4F120_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F120_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F120_ts {
    animation: eETgrh93Y4F120_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F120_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F120 {
    animation: eETgrh93Y4F120_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F120_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F121_to {
    animation: eETgrh93Y4F121_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F121_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.228703px);
    }
    100% {
      transform: translate(398.699999px, -119.228703px);
    }
  }
  #eETgrh93Y4F121_tr {
    animation: eETgrh93Y4F121_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F121_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F121 {
    animation: eETgrh93Y4F121_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F121_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F128 {
    animation-name: eETgrh93Y4F128__m, eETgrh93Y4F128_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F128__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    11.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    22.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    42.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eETgrh93Y4F128_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F129_to {
    animation: eETgrh93Y4F129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F129_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F129_tr {
    animation: eETgrh93Y4F129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(72deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F129_ts {
    animation: eETgrh93Y4F129_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F129_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F129 {
    animation: eETgrh93Y4F129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F129_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F130_to {
    animation: eETgrh93Y4F130_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F130_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    6.25% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F130_tr {
    animation: eETgrh93Y4F130_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F130_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F130_ts {
    animation: eETgrh93Y4F130_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F130_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F130 {
    animation: eETgrh93Y4F130_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F130_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0.9;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F131_to {
    animation: eETgrh93Y4F131_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F131_to__to {
    0% {
      transform: translate(226.798427px, 141.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 141.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #eETgrh93Y4F131_tr {
    animation: eETgrh93Y4F131_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F131_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F131 {
    animation: eETgrh93Y4F131_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F131_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F133 {
    animation-name: eETgrh93Y4F133__m, eETgrh93Y4F133_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F133__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eETgrh93Y4F133_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F134_to {
    animation: eETgrh93Y4F134_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F134_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F134_tr {
    animation: eETgrh93Y4F134_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F134_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(72deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F134_ts {
    animation: eETgrh93Y4F134_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F134_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F134 {
    animation: eETgrh93Y4F134_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F134_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.8;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F135_to {
    animation: eETgrh93Y4F135_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F135_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    52.5% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #eETgrh93Y4F135_tr {
    animation: eETgrh93Y4F135_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F135_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F135_ts {
    animation: eETgrh93Y4F135_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F135_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F135 {
    animation: eETgrh93Y4F135_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F135_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.9;
    }
    93.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F136_to {
    animation: eETgrh93Y4F136_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F136_to__to {
    0% {
      transform: translate(226.798427px, 138.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #eETgrh93Y4F136_tr {
    animation: eETgrh93Y4F136_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F136_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F136 {
    animation: eETgrh93Y4F136_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F136_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F142 {
    animation-name: eETgrh93Y4F142__m, eETgrh93Y4F142_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F142__m {
    0% {
      d: path(
        'M96.7109,145.439636L96.7109,127.684958L96.812149,127.562584C96.812149,127.562584,96.894479,127.812584,96.894479,127.812584'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    21.25% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,33.289957,259.817001,33.289957'
      );
    }
    26.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eETgrh93Y4F142_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F143_to {
    animation: eETgrh93Y4F143_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F143_to__to {
    0% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F143_tr {
    animation: eETgrh93Y4F143_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F143_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F143_ts {
    animation: eETgrh93Y4F143_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F143_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F143 {
    animation: eETgrh93Y4F143_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F143_c_o {
    0% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F144_to {
    animation: eETgrh93Y4F144_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F144_to__to {
    0% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -121.728703px);
    }
    100% {
      transform: translate(324.9px, -121.728703px);
    }
  }
  #eETgrh93Y4F144_tr {
    animation: eETgrh93Y4F144_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F144_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F144_ts {
    animation: eETgrh93Y4F144_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F144_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F144 {
    animation: eETgrh93Y4F144_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F144_c_o {
    0% {
      opacity: 0.9;
    }
    30% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F145_to {
    animation: eETgrh93Y4F145_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F145_to__to {
    0% {
      transform: translate(161.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    15% {
      transform: translate(309.773572px, -24.042276px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    22.5% {
      transform: translate(324.9px, -86.545293px);
      animation-timing-function: cubic-bezier(0.280804, 0.399795, 0.604922, 0.750053);
    }
    26.25% {
      transform: translate(324.9px, -111.961266px);
      animation-timing-function: cubic-bezier(0.291118, 0.499369, 0.620764, 0.851063);
    }
    30% {
      transform: translate(324.9px, -122.228703px);
    }
    100% {
      transform: translate(324.9px, -122.228703px);
    }
  }
  #eETgrh93Y4F145_tr {
    animation: eETgrh93Y4F145_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F145_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F145 {
    animation: eETgrh93Y4F145_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F145_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F147 {
    animation-name: eETgrh93Y4F147__m, eETgrh93Y4F147_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F147__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,21.289957,259.817001,21.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    81.25% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eETgrh93Y4F147_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F148_to {
    animation: eETgrh93Y4F148_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F148_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eETgrh93Y4F148_tr {
    animation: eETgrh93Y4F148_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F148_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(73deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F148_ts {
    animation: eETgrh93Y4F148_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F148_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F148 {
    animation: eETgrh93Y4F148_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F148_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.8;
    }
    81.25% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F149_to {
    animation: eETgrh93Y4F149_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F149_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    42.5% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F149_tr {
    animation: eETgrh93Y4F149_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F149_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F149_ts {
    animation: eETgrh93Y4F149_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F149_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F149 {
    animation: eETgrh93Y4F149_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F149_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.9;
    }
    81.25% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F150_to {
    animation: eETgrh93Y4F150_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F150_to__to {
    0% {
      transform: translate(161.798427px, 49.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(191.832868px, 8.675763px);
    }
    58.75% {
      transform: translate(231.488924px, -2.180118px);
    }
    66.25% {
      transform: translate(323.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eETgrh93Y4F150_tr {
    animation: eETgrh93Y4F150_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F150_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F150 {
    animation: eETgrh93Y4F150_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F150_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F152 {
    animation-name: eETgrh93Y4F152__m, eETgrh93Y4F152_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F152__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eETgrh93Y4F152_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F153_to {
    animation: eETgrh93Y4F153_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F153_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 38.016262px);
    }
  }
  #eETgrh93Y4F153 {
    animation: eETgrh93Y4F153_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F153_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F154_to {
    animation: eETgrh93Y4F154_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F154_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    96.25% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 33.299851px);
    }
  }
  #eETgrh93Y4F154 {
    animation: eETgrh93Y4F154_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F154_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F155_to {
    animation: eETgrh93Y4F155_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F155_to__to {
    0% {
      transform: translate(161.798427px, 50.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 34.016262px);
    }
  }
  #eETgrh93Y4F155 {
    animation: eETgrh93Y4F155_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F155_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eETgrh93Y4F162 {
    animation-name: eETgrh93Y4F162__m, eETgrh93Y4F162_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F162__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.920607,76.685694');
    }
    43.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    51.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eETgrh93Y4F162_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F163_to {
    animation: eETgrh93Y4F163_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F163_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    11.25% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eETgrh93Y4F163_tr {
    animation: eETgrh93Y4F163_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F163_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(75deg);
    }
    43.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F163_ts {
    animation: eETgrh93Y4F163_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F163_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F163 {
    animation: eETgrh93Y4F163_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F163_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F164_to {
    animation: eETgrh93Y4F164_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F164_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F164_tr {
    animation: eETgrh93Y4F164_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F164_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F164_ts {
    animation: eETgrh93Y4F164_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F164_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F164 {
    animation: eETgrh93Y4F164_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F164_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F165_to {
    animation: eETgrh93Y4F165_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F165_to__to {
    0% {
      transform: translate(96.798427px, 142.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 142.271297px);
    }
    17.5% {
      transform: translate(96.748252px, 116.509555px);
    }
    18.75% {
      transform: translate(96.738217px, 108.957207px);
    }
    21.25% {
      transform: translate(96.718147px, 82.185843px);
    }
    26.25% {
      transform: translate(96.678007px, 22.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    33.75% {
      transform: translate(174.541791px, -1.63208px);
    }
    41.25% {
      transform: translate(282.833827px, -26.93208px);
    }
    42.5% {
      transform: translate(287.711987px, -31.028703px);
    }
    43.75% {
      transform: translate(287.814424px, -38.440824px);
    }
    45% {
      transform: translate(287.91686px, -53.852945px);
    }
    47.5% {
      transform: translate(287.913114px, -81.677187px);
    }
    50% {
      transform: translate(287.909367px, -103.50143px);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F165_tr {
    animation: eETgrh93Y4F165_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F165_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F165 {
    animation: eETgrh93Y4F165_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F165_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F167 {
    animation-name: eETgrh93Y4F167__m, eETgrh93Y4F167_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eETgrh93Y4F167__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955001,75.463017');
    }
    88.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eETgrh93Y4F167_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F168_to {
    animation: eETgrh93Y4F168_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F168_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eETgrh93Y4F168_tr {
    animation: eETgrh93Y4F168_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F168_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(75deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F168_ts {
    animation: eETgrh93Y4F168_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F168_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F168 {
    animation: eETgrh93Y4F168_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F168_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F169_to {
    animation: eETgrh93Y4F169_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F169_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    57.5% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eETgrh93Y4F169_tr {
    animation: eETgrh93Y4F169_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F169_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F169_ts {
    animation: eETgrh93Y4F169_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F169_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eETgrh93Y4F169 {
    animation: eETgrh93Y4F169_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F169_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eETgrh93Y4F170_to {
    animation: eETgrh93Y4F170_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F170_to__to {
    0% {
      transform: translate(96.798427px, 139.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 139.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eETgrh93Y4F170_tr {
    animation: eETgrh93Y4F170_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F170_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eETgrh93Y4F170 {
    animation: eETgrh93Y4F170_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eETgrh93Y4F170_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg10;
