// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg12 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eZPd5k6m38e1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eZPd5k6m38e7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e7-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e8-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e8-stroke-0"
            offset="0%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e8-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e9-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e10-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e12-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e13-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e13-stroke-0"
            offset="0%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e13-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e14-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e15-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e16-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e17-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e18-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e18-stroke-0"
            offset="0%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e19-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e20-filter-drop-shadow-0-flood"
            floodColor={selected === 11 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e26-filter-drop-shadow-0-flood"
            floodColor={selected === 10 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e27-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e27-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e27-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e28-filter-drop-shadow-0-flood"
            floodColor={selected === 10 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e29-filter-drop-shadow-0-flood"
            floodColor={selected === 10 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e30-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e31-filter-drop-shadow-0-flood"
            floodColor={selected === 10 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e32-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e32-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e32-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e33-filter-drop-shadow-0-flood"
            floodColor={selected === 10 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e34-filter-drop-shadow-0-flood"
            floodColor={selected === 10 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e35-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e41-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e42-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e42-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e42-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e43-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e44-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e46-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e47-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e47-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e47-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e48-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e49-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e50-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e51-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e52-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e52-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e52-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e53-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e54-filter-drop-shadow-0-flood"
            floodColor={selected === 9 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e55-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e60-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e61-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e61-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e61-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e62-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e63-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e63-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e63-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e63-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e63-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e63-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e63-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e64-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e65-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e66-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e66-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e66-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e67-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e68-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e69-filter-drop-shadow-0-flood"
            floodColor={selected === 8 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e75-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e75-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e75-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e75-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e75-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e75-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e75-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e75-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e76-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e76-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e76-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e77-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e78-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e79-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e80-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e80-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e80-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e80-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e80-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e80-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e80-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e81-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e81-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e81-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e82-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e82-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e82-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e82-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e82-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e82-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e82-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e82-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e83-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e84-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e85-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e85-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e85-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e85-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e85-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e85-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e85-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e86-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e86-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e86-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e87-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e87-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e87-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e87-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e87-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e87-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e87-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e87-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e88-filter-drop-shadow-0-flood"
            floodColor={selected === 7 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e89-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e94-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e94-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e94-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e94-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e94-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e94-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e94-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e94-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e95-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e95-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e95-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e96-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e97-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e97-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e97-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e97-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e97-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e97-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e97-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e98-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e98-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e99-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e99-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e99-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e99-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e99-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e99-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e99-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e99-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e100-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e100-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e100-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e101-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e102-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e102-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e102-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e102-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e102-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e102-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e102-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e103-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e103-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e104-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e104-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e104-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e104-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e104-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e104-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e104-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e104-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e105-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e105-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e105-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e106-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e106-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e106-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e106-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e106-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e106-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e106-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e106-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e107-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e107-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e107-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e107-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e107-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e107-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e107-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e107-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e108-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e108-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e115-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e115-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e115-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e115-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e115-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e115-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e115-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e115-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e116-stroke"
          x1="0.586824"
          y1="0.007596"
          x2="0.413176"
          y2="0.992404"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e116-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e116-stroke-1" offset="77%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e117-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e117-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e117-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e117-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e117-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e117-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e117-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e117-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e118-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e118-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e118-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e118-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e118-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e118-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e118-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e119-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e119-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e120-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e120-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e120-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e120-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e120-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e120-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e120-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e120-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e121-stroke"
          x1="0.883022"
          y1="0.178606"
          x2="0.116978"
          y2="0.821394"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e121-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e121-stroke-1" offset="90%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e122-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e122-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e122-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e122-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e122-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e122-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e122-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e122-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e123-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e123-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e123-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e123-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e123-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e123-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e123-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e123-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e124-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e124-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e125-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e125-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e125-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e125-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e125-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e125-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e125-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e125-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e126-stroke"
          x1="0.883022"
          y1="0.178606"
          x2="0.116978"
          y2="0.821394"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e126-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e126-stroke-1" offset="96%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e127-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e127-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e127-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e127-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e127-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e127-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e127-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e127-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e128-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e128-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e128-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e128-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e128-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e128-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e128-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e128-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e129-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e135-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e135-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e135-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e135-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e135-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e135-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e135-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e135-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e136-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e136-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e136-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e137-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e137-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e137-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e137-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e137-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e137-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e137-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e137-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e138-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e138-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e138-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e138-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e138-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e138-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e138-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e138-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e139-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e139-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e140-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e140-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e140-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e140-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e140-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e140-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e140-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e140-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e141-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e141-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e141-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e142-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e142-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e142-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e142-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e142-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e142-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e142-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e142-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e143-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e144-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e144-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e145-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e145-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e145-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e145-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e145-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e145-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e145-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e145-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e146-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e146-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e146-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e147-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e147-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e147-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e147-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e147-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e147-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e147-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e147-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e148-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e148-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e148-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e148-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e148-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e148-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e148-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e148-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e149-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e149-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e154-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e154-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e154-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e154-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e154-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e154-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e154-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e154-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e155-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e155-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e155-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e156-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e156-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e156-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e156-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e156-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e156-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e156-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e156-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e157-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e157-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e157-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e157-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e157-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e157-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e157-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e157-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e158-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e158-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e159-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e159-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e159-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e159-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e159-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e159-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e159-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e159-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e160-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e160-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e160-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e161-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e161-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e161-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e161-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e161-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e161-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e161-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e161-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e162-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e162-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e162-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e162-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e162-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e162-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e162-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e162-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e163-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e163-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e169-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e169-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e169-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e169-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e169-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e169-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e169-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e169-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e170-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e170-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e170-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e171-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e171-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e171-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e171-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e171-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e171-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e171-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e171-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e172-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e172-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e172-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e172-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e172-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e172-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e172-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e172-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e173-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e173-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e174-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e174-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e174-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e174-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e174-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e174-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e174-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e174-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e175-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e175-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e175-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e176-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e176-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e176-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e176-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e176-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e176-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e176-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e176-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e177-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e177-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e177-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e177-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e177-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e177-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e177-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e177-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e178-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e178-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e178-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e178-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e178-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e178-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e178-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e178-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e183-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e183-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e183-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e183-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e183-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e183-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e183-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e183-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e184-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e184-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eZPd5k6m38e184-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZPd5k6m38e185-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e185-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e185-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e185-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e185-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e185-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e185-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e185-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e186-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e186-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e186-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e186-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e186-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e186-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e186-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e186-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e187-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e187-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e188-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e188-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e188-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e188-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e188-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e188-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e188-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e188-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e189-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e189-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eZPd5k6m38e189-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZPd5k6m38e190-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e190-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e190-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e190-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e190-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e190-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e190-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e190-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e191-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e191-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e191-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e191-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e191-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e191-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e191-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e191-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e192-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e192-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e193-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e193-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e193-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e193-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e193-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e193-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e193-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e193-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e194-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e194-stroke-0" offset="15%" stopColor={styles.stopColor} />
          <stop
            id="eZPd5k6m38e194-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZPd5k6m38e195-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e195-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e195-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e195-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e195-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e195-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e195-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e195-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e196-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e196-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e196-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e196-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e196-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e196-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e196-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e196-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e197-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e197-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e203-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e203-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e203-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e203-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e203-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e203-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e203-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e203-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e204-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e204-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e204-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e205-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e205-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e205-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e205-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e205-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e205-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e205-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e205-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e206-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e206-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="7,7" />
          <feOffset id="eZPd5k6m38e206-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e206-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e206-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e206-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e206-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e206-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e207-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e207-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eZPd5k6m38e208-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e208-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eZPd5k6m38e208-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e208-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eZPd5k6m38e208-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e208-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e208-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e208-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e209-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZPd5k6m38e209-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZPd5k6m38e209-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eZPd5k6m38e210-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e210-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e210-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e210-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e210-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e210-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e210-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e210-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e211-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e211-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eZPd5k6m38e211-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eZPd5k6m38e211-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eZPd5k6m38e211-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e211-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e211-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e211-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eZPd5k6m38e212-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZPd5k6m38e212-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eZPd5k6m38e233-fill"
          x1="719.15"
          y1="275.055"
          x2="713.525"
          y2="258.18"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e233-fill-0" offset="0%" stopColor="#fb9d22" />
          <stop id="eZPd5k6m38e233-fill-1" offset="100%" stopColor="#ff6a1f" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e234-fill"
          x1="725.15"
          y1="252.555"
          x2="730.775"
          y2="269.43"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e234-fill-0" offset="0%" stopColor="#fb9d22" />
          <stop id="eZPd5k6m38e234-fill-1" offset="100%" stopColor="#ff6a1f" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e241-fill"
          x1="665.157"
          y1="169.115"
          x2="639.019"
          y2="177.349"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e241-fill-0" offset="0%" stopColor="#44ae51" />
          <stop id="eZPd5k6m38e241-fill-1" offset="100%" stopColor="#6bb533" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e242-fill"
          x1="665.157"
          y1="169.115"
          x2="639.019"
          y2="177.349"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e242-fill-0" offset="0%" stopColor="#44ae51" />
          <stop id="eZPd5k6m38e242-fill-1" offset="100%" stopColor="#6bb533" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e243-fill"
          x1="665.157"
          y1="169.115"
          x2="639.019"
          y2="177.349"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e243-fill-0" offset="0%" stopColor="#44ae51" />
          <stop id="eZPd5k6m38e243-fill-1" offset="100%" stopColor="#6bb533" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e244-fill"
          x1="665.157"
          y1="169.115"
          x2="639.019"
          y2="177.349"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e244-fill-0" offset="0%" stopColor="#44ae51" />
          <stop id="eZPd5k6m38e244-fill-1" offset="100%" stopColor="#6bb533" />
        </linearGradient>
        <filter id="eZPd5k6m38e271-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur
            id="eZPd5k6m38e271-filter-drop-shadow-0-blur"
            in="SourceAlpha"
            stdDeviation="7.11111,7.11111"
          />
          <feOffset id="eZPd5k6m38e271-filter-drop-shadow-0-offset" dx="0" dy="2.84444" result="tmp" />
          <feFlood id="eZPd5k6m38e271-filter-drop-shadow-0-flood" floodColor="rgba(16,18,17,0.9)" />
          <feComposite id="eZPd5k6m38e271-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZPd5k6m38e271-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZPd5k6m38e271-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZPd5k6m38e271-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZPd5k6m38e348-fill"
          x1="31.2951"
          y1="268.064"
          x2="26.2823"
          y2="253.164"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e348-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e348-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e348-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e348-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e349-fill"
          x1="21.9424"
          y1="263.191"
          x2="21.1943"
          y2="273.509"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e349-fill-0" offset="0%" stopColor="rgba(229,229,227,0.898039)" />
          <stop id="eZPd5k6m38e349-fill-1" offset="5%" stopColor="rgba(229,229,227,0.709804)" />
          <stop id="eZPd5k6m38e349-fill-2" offset="11%" stopColor="rgba(229,229,227,0.54902)" />
          <stop id="eZPd5k6m38e349-fill-3" offset="17%" stopColor="rgba(229,229,227,0.4)" />
          <stop id="eZPd5k6m38e349-fill-4" offset="24%" stopColor="rgba(229,229,227,0.270588)" />
          <stop id="eZPd5k6m38e349-fill-5" offset="32%" stopColor="rgba(229,229,227,0.168627)" />
          <stop id="eZPd5k6m38e349-fill-6" offset="41%" stopColor="rgba(229,229,227,0.0901961)" />
          <stop id="eZPd5k6m38e349-fill-7" offset="52%" stopColor="rgba(229,229,227,0.0392157)" />
          <stop id="eZPd5k6m38e349-fill-8" offset="66%" stopColor="rgba(229,229,227,0.0117647)" />
          <stop id="eZPd5k6m38e349-fill-9" offset="100%" stopColor="rgba(229,229,227,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e350-fill"
          x1="27.892"
          y1="263.528"
          x2="43.4451"
          y2="267.345"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e350-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e350-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e350-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e350-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e352-fill"
          x1="30.2662"
          y1="266.858"
          x2="23.5655"
          y2="262.468"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e352-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e352-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e352-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e352-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e353-fill"
          x1="26.7058"
          y1="260.587"
          x2="38.032"
          y2="266.673"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e353-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e353-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e353-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e353-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e354-fill"
          x1="33.219"
          y1="260.912"
          x2="30.478"
          y2="270.553"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e354-fill-0" offset="0%" stopColor="rgba(229,229,227,0.898039)" />
          <stop id="eZPd5k6m38e354-fill-1" offset="5%" stopColor="rgba(229,229,227,0.709804)" />
          <stop id="eZPd5k6m38e354-fill-2" offset="11%" stopColor="rgba(229,229,227,0.54902)" />
          <stop id="eZPd5k6m38e354-fill-3" offset="17%" stopColor="rgba(229,229,227,0.4)" />
          <stop id="eZPd5k6m38e354-fill-4" offset="24%" stopColor="rgba(229,229,227,0.270588)" />
          <stop id="eZPd5k6m38e354-fill-5" offset="32%" stopColor="rgba(229,229,227,0.168627)" />
          <stop id="eZPd5k6m38e354-fill-6" offset="41%" stopColor="rgba(229,229,227,0.0901961)" />
          <stop id="eZPd5k6m38e354-fill-7" offset="52%" stopColor="rgba(229,229,227,0.0392157)" />
          <stop id="eZPd5k6m38e354-fill-8" offset="66%" stopColor="rgba(229,229,227,0.0117647)" />
          <stop id="eZPd5k6m38e354-fill-9" offset="100%" stopColor="rgba(229,229,227,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e356-fill"
          x1="32.0118"
          y1="263.062"
          x2="27.8455"
          y2="262.224"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e356-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e356-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e356-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e356-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e357-fill"
          x1="34.7554"
          y1="259.459"
          x2="30.8304"
          y2="267.333"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e357-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e357-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e357-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e357-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e358-fill"
          x1="27.3687"
          y1="260.47"
          x2="34.2922"
          y2="265.649"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e358-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e358-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e358-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e358-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e360-fill"
          x1="28.6128"
          y1="260.914"
          x2="32.0292"
          y2="264.573"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e360-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e360-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e360-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e360-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e361-fill"
          x1="34.1558"
          y1="263.988"
          x2="29.473"
          y2="261.831"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e361-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e361-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e361-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e361-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e362-fill"
          x1="34.8309"
          y1="258.91"
          x2="30.7112"
          y2="264.129"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e362-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e362-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e362-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e362-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e365-fill"
          x1="33.823"
          y1="262.225"
          x2="34.152"
          y2="260.039"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e365-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e365-fill-1" offset="12%" stopColor="rgba(16,18,17,0.619608)" />
          <stop id="eZPd5k6m38e365-fill-2" offset="46%" stopColor="rgba(16,18,17,0.141176)" />
          <stop id="eZPd5k6m38e365-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e367-fill"
          x1="34.8418"
          y1="263.251"
          x2="30.9099"
          y2="261.434"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e367-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e367-fill-1" offset="12%" stopColor="rgba(16,18,17,0.619608)" />
          <stop id="eZPd5k6m38e367-fill-2" offset="46%" stopColor="rgba(16,18,17,0.141176)" />
          <stop id="eZPd5k6m38e367-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e369-fill"
          x1="34.2072"
          y1="260.117"
          x2="32.2362"
          y2="263.941"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e369-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e369-fill-1" offset="12%" stopColor="rgba(16,18,17,0.619608)" />
          <stop id="eZPd5k6m38e369-fill-2" offset="46%" stopColor="rgba(16,18,17,0.141176)" />
          <stop id="eZPd5k6m38e369-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e392-fill"
          x1="31.2951"
          y1="268.064"
          x2="26.2823"
          y2="253.164"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e392-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e392-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e392-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e392-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e393-fill"
          x1="21.9424"
          y1="263.191"
          x2="21.1943"
          y2="273.509"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e393-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e393-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e393-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e393-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e394-fill"
          x1="27.892"
          y1="263.528"
          x2="43.4451"
          y2="267.345"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e394-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e394-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e394-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e394-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e396-fill"
          x1="30.2662"
          y1="266.858"
          x2="23.5655"
          y2="262.468"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e396-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e396-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e396-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e396-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e397-fill"
          x1="26.7058"
          y1="260.587"
          x2="38.032"
          y2="266.673"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e397-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e397-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e397-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e397-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e398-fill"
          x1="33.219"
          y1="260.912"
          x2="30.478"
          y2="270.553"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e398-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e398-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e398-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e398-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e399-fill"
          x1="32.0118"
          y1="263.062"
          x2="27.8455"
          y2="262.224"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e399-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e399-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e399-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e399-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e400-fill"
          x1="34.7554"
          y1="259.459"
          x2="30.8304"
          y2="267.333"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e400-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e400-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e400-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e400-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e401-fill"
          x1="27.3687"
          y1="260.47"
          x2="34.2922"
          y2="265.649"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e401-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e401-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e401-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e401-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e402-fill"
          x1="28.6128"
          y1="260.914"
          x2="32.0292"
          y2="264.573"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e402-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e402-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e402-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e402-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e403-fill"
          x1="34.1558"
          y1="263.988"
          x2="29.473"
          y2="261.831"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e403-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e403-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e403-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e403-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e404-fill"
          x1="34.8309"
          y1="258.91"
          x2="30.7112"
          y2="264.129"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e404-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e404-fill-1" offset="12%" stopColor="rgba(16,18,17,0.509804)" />
          <stop id="eZPd5k6m38e404-fill-2" offset="39%" stopColor="rgba(16,18,17,0.109804)" />
          <stop id="eZPd5k6m38e404-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e405-fill"
          x1="33.823"
          y1="262.225"
          x2="34.152"
          y2="260.039"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e405-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e405-fill-1" offset="12%" stopColor="rgba(16,18,17,0.619608)" />
          <stop id="eZPd5k6m38e405-fill-2" offset="46%" stopColor="rgba(16,18,17,0.141176)" />
          <stop id="eZPd5k6m38e405-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e406-fill"
          x1="34.8418"
          y1="263.251"
          x2="30.9099"
          y2="261.434"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e406-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e406-fill-1" offset="12%" stopColor="rgba(16,18,17,0.619608)" />
          <stop id="eZPd5k6m38e406-fill-2" offset="46%" stopColor="rgba(16,18,17,0.141176)" />
          <stop id="eZPd5k6m38e406-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
        <linearGradient
          id="eZPd5k6m38e407-fill"
          x1="34.2072"
          y1="260.117"
          x2="32.2362"
          y2="263.941"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZPd5k6m38e407-fill-0" offset="0%" stopColor="rgba(16,18,17,0.898039)" />
          <stop id="eZPd5k6m38e407-fill-1" offset="12%" stopColor="rgba(16,18,17,0.619608)" />
          <stop id="eZPd5k6m38e407-fill-2" offset="46%" stopColor="rgba(16,18,17,0.141176)" />
          <stop id="eZPd5k6m38e407-fill-3" offset="100%" stopColor="rgba(16,18,17,0.01)" />
        </linearGradient>
      </defs>
      <g mask="url(#eZPd5k6m38e214)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g>
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e7-filter)">
                <path
                  id="eZPd5k6m38e8"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eZPd5k6m38e8-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e9_to" transform="translate(832.121519,-4.482748)">
                  <g id="eZPd5k6m38e9_tr" transform="rotate(-75)">
                    <g id="eZPd5k6m38e9_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eZPd5k6m38e9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eZPd5k6m38e9-filter)"
                        fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e10_to" transform="translate(826.798304,-5.828881)">
                  <g id="eZPd5k6m38e10_tr" transform="rotate(-75)">
                    <g id="eZPd5k6m38e10_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eZPd5k6m38e10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eZPd5k6m38e10-filter)"
                        fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e11_to" transform="translate(826.568884,-6.23967)">
                  <g id="eZPd5k6m38e11_tr" transform="rotate(-75)">
                    <rect
                      id="eZPd5k6m38e11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eZPd5k6m38e11-filter)"
                      fill={selected === 11 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e12-filter)">
                <path
                  id="eZPd5k6m38e13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e14_to" transform="translate(917.798427,143.771297)">
                  <g id="eZPd5k6m38e14_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e14_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e14-filter)"
                        fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e15_to" transform="translate(917.798427,137.271297)">
                  <g id="eZPd5k6m38e15_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e15_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e15-filter)"
                        fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e16_to" transform="translate(917.798427,137.771297)">
                  <g id="eZPd5k6m38e16_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e16-filter)"
                      fill={selected === 11 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e17-filter)">
                <path
                  id="eZPd5k6m38e18"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e18-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e19_to" transform="translate(917.798427,143.771297)">
                  <g id="eZPd5k6m38e19_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e19_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e19-filter)"
                        fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e20_to" transform="translate(917.798427,137.271297)">
                  <g id="eZPd5k6m38e20_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e20_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e20-filter)"
                        fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e21_to" transform="translate(917.798427,140.771297)">
                  <g id="eZPd5k6m38e21_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e21"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e21-filter)"
                      fill={selected === 11 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eZPd5k6m38e37)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e26-filter)">
                  <path
                    id="eZPd5k6m38e27"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e27-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e28_to" transform="translate(846.5,55.771297)">
                    <g id="eZPd5k6m38e28_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e28_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e28"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e28-filter)"
                          fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e29_to" transform="translate(846.5,49.271297)">
                    <g id="eZPd5k6m38e29_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e29_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e29"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e29-filter)"
                          fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e30_to" transform="translate(846.5,51.771297)">
                    <g id="eZPd5k6m38e30_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e30"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e30-filter)"
                        fill={selected === 10 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e31-filter)">
                  <path
                    id="eZPd5k6m38e32"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e32-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e33_to" transform="translate(846.5,55.771297)">
                    <g id="eZPd5k6m38e33_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e33_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e33"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e33-filter)"
                          fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e34_to" transform="translate(846.5,49.271297)">
                    <g id="eZPd5k6m38e34_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e34_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e34"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e34-filter)"
                          fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e35_to" transform="translate(846.5,50.771297)">
                    <g id="eZPd5k6m38e35_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e35"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e35-filter)"
                        fill={selected === 10 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZPd5k6m38e37" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 605.931986-1.012683)"
                  fill={selected === 10 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001 0.624996)">
              <path
                d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e41-filter)">
                <path
                  id="eZPd5k6m38e42"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eZPd5k6m38e42-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e43_to" transform="translate(656.9,-33.549858)">
                  <rect
                    id="eZPd5k6m38e43"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#eZPd5k6m38e43-filter)"
                    fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZPd5k6m38e44_to" transform="translate(656.9,-37.443085)">
                  <rect
                    id="eZPd5k6m38e44"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#eZPd5k6m38e44-filter)"
                    fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZPd5k6m38e45_to" transform="translate(656.9,-38.549858)">
                  <rect
                    id="eZPd5k6m38e45"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#eZPd5k6m38e45-filter)"
                    fill={selected === 9 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e46-filter)">
                <path
                  id="eZPd5k6m38e47"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e47-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e48_to" transform="translate(786.798427,143.771297)">
                  <g id="eZPd5k6m38e48_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e48_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e48-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e49_to" transform="translate(786.798427,137.271297)">
                  <g id="eZPd5k6m38e49_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e49_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e49-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e50_to" transform="translate(786.798427,138.771297)">
                  <g id="eZPd5k6m38e50_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e50"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e50-filter)"
                      fill={selected === 9 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e51-filter)">
                <path
                  id="eZPd5k6m38e52"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e52-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e53_to" transform="translate(786.798427,143.771297)">
                  <g id="eZPd5k6m38e53_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e53_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e53"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e53-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e54_to" transform="translate(786.798427,137.271297)">
                  <g id="eZPd5k6m38e54_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e54_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e54"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e54-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e55_to" transform="translate(786.798427,136.771297)">
                  <g id="eZPd5k6m38e55_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e55-filter)"
                      fill={selected === 9 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eZPd5k6m38e71)">
              <g>
                <path
                  d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e60-filter)">
                  <path
                    id="eZPd5k6m38e61"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eZPd5k6m38e61-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e62_to" transform="translate(715.798427,56.771297)">
                    <g id="eZPd5k6m38e62_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e62_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e62"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eZPd5k6m38e62-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e63_to" transform="translate(715.798427,50.271297)">
                    <g id="eZPd5k6m38e63_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e63_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e63"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eZPd5k6m38e63-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e64_to" transform="translate(715.798427,51.771297)">
                    <g id="eZPd5k6m38e64_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e64"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eZPd5k6m38e64-filter)"
                        fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e65-filter)">
                  <path
                    id="eZPd5k6m38e66"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e66-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e67_to" transform="translate(715.798427,56.771297)">
                    <g id="eZPd5k6m38e67_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e67_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e67"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e67-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e68_to" transform="translate(715.798427,50.271297)">
                    <g id="eZPd5k6m38e68_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e68_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e68"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e68-filter)"
                          fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e69_to" transform="translate(715.798427,50.771297)">
                    <g id="eZPd5k6m38e69_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e69"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e69-filter)"
                        fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZPd5k6m38e71" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 535.931986-1.012683)"
                  fill={selected === 8 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e75-filter)">
                <path
                  id="eZPd5k6m38e76"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eZPd5k6m38e76-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e77_to" transform="translate(656.203897,77.481243)">
                  <g id="eZPd5k6m38e77_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e77_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e77"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eZPd5k6m38e77-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e78_to" transform="translate(656.203897,72.170302)">
                  <g id="eZPd5k6m38e78_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e78_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e78"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eZPd5k6m38e78-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e79_to" transform="translate(656.411986,71.237316)">
                  <g id="eZPd5k6m38e79_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e79"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eZPd5k6m38e79-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e80-filter)">
                <path
                  id="eZPd5k6m38e81"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e81-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e82_to" transform="translate(656.798427,143.771297)">
                  <g id="eZPd5k6m38e82_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e82_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e82"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e82-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e83_to" transform="translate(656.798427,137.271297)">
                  <g id="eZPd5k6m38e83_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e83_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e83"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e83-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e84_to" transform="translate(656.798427,136.771297)">
                  <g id="eZPd5k6m38e84_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e84"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e84-filter)"
                      fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e85-filter)">
                <path
                  id="eZPd5k6m38e86"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e86-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e87_to" transform="translate(656.798427,143.771297)">
                  <rect
                    id="eZPd5k6m38e87"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eZPd5k6m38e87-filter)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZPd5k6m38e88_to" transform="translate(656.798427,137.271297)">
                  <rect
                    id="eZPd5k6m38e88"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eZPd5k6m38e88-filter)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZPd5k6m38e89_to" transform="translate(656.798427,135.771297)">
                  <rect
                    id="eZPd5k6m38e89"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eZPd5k6m38e89-filter)"
                    fill={selected === 7 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#eZPd5k6m38e110)">
              <g>
                <path
                  d="M520.668,138.079v-16.398L480.969,76.7549l.005-76.508643"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e94-filter)">
                  <path
                    id="eZPd5k6m38e95"
                    d="M520.668,121.038711v.642289L480.969,76.7549l.005-4.679761"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eZPd5k6m38e95-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e96_to" transform="translate(545.9,-33.514307)">
                    <rect
                      id="eZPd5k6m38e96"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.8,0.8) translate(-4,-6.5)"
                      opacity="0.8"
                      filter="url(#eZPd5k6m38e96-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eZPd5k6m38e97_to" transform="translate(545.9,-38.280793)">
                    <rect
                      id="eZPd5k6m38e97"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.8,0.8) translate(-4,-6.5)"
                      opacity="0.9"
                      filter="url(#eZPd5k6m38e97-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eZPd5k6m38e98_to" transform="translate(545.9,-38.514307)">
                    <rect
                      id="eZPd5k6m38e98"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eZPd5k6m38e98-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e99-filter)">
                  <path
                    id="eZPd5k6m38e100"
                    d="M520.668,153.079v-2.777975-.741319-1.788408"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e100-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e101_to" transform="translate(584.798427,53.771297)">
                    <g id="eZPd5k6m38e101_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e101_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e101"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e101-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e102_to" transform="translate(584.798427,47.271297)">
                    <g id="eZPd5k6m38e102_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e102_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e102"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e102-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e103_to" transform="translate(584.798427,49.771297)">
                    <g id="eZPd5k6m38e103_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e103"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e103-filter)"
                        fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e104-filter)">
                  <path
                    id="eZPd5k6m38e105"
                    d="M520.668,153.079v-2.777975-.741319-1.788408"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e105-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e106_to" transform="translate(584.798427,53.771297)">
                    <g id="eZPd5k6m38e106_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e106_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e106"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e106-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e107_to" transform="translate(584.798427,47.271297)">
                    <g id="eZPd5k6m38e107_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e107_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e107"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e107-filter)"
                          fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e108_to" transform="translate(584.798427,48.771297)">
                    <g id="eZPd5k6m38e108_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e108"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e108-filter)"
                        fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZPd5k6m38e110" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="80"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 459.931986-1.012683)"
                  fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g mask="url(#eZPd5k6m38e131)">
              <g>
                <path
                  d="M357.283,138.189v-16.506l49.955-44.9838v-76.35545"
                  transform="translate(0 0.202507)"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e115-filter)">
                  <path
                    id="eZPd5k6m38e116"
                    d="M357.283,138.189v-16.506l16.069591-14.911067l2.209448-3.288527"
                    transform="translate(65-104.797494)"
                    opacity="0.8"
                    fill="none"
                    stroke="url(#eZPd5k6m38e116-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e117_to" transform="translate(445.052153,-3.536834)">
                    <g id="eZPd5k6m38e117_tr" transform="rotate(45)">
                      <g id="eZPd5k6m38e117_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e117"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eZPd5k6m38e117-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e118_to" transform="translate(449.063292,-7.316354)">
                    <g id="eZPd5k6m38e118_tr" transform="rotate(45)">
                      <g id="eZPd5k6m38e118_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e118"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eZPd5k6m38e118-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e119_to" transform="translate(450.624298,-9.536834)">
                    <g id="eZPd5k6m38e119_tr" transform="rotate(45)">
                      <rect
                        id="eZPd5k6m38e119"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eZPd5k6m38e119-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e120-filter)">
                  <path
                    id="eZPd5k6m38e121"
                    d="M357.283,138.189v-.338507h.01v.338507"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e121-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e122_to" transform="translate(422.798427,53.771297)">
                    <g id="eZPd5k6m38e122_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e122_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e122"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e122-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e123_to" transform="translate(422.798427,47.271297)">
                    <g id="eZPd5k6m38e123_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e123_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e123"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e123-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e124_to" transform="translate(422.798427,48.771297)">
                    <g id="eZPd5k6m38e124_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e124"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e124-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e125-filter)">
                  <path
                    id="eZPd5k6m38e126"
                    d="M357.283,138.189v1.139493l.01-1.478h-.01"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e126-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e127_to" transform="translate(422.798427,53.771297)">
                    <g id="eZPd5k6m38e127_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e127_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e127"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e127-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e128_to" transform="translate(422.798427,47.271297)">
                    <g id="eZPd5k6m38e128_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e128_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e128"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e128-filter)"
                          fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e129_to" transform="translate(422.798427,48.771297)">
                    <g id="eZPd5k6m38e129_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e129"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e129-filter)"
                        fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZPd5k6m38e131" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="80"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 341.931986-1.012683)"
                  fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e135-filter)">
                <path
                  id="eZPd5k6m38e136"
                  d="M292.146,232.836923v-55.235274l.108926,1.213711v.381492"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eZPd5k6m38e136-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e137_to" transform="translate(357.5,74.508595)">
                  <g id="eZPd5k6m38e137_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e137_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e137"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eZPd5k6m38e137-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e138_to" transform="translate(357.5,69.792183)">
                  <g id="eZPd5k6m38e138_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e138_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e138"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eZPd5k6m38e138-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e139_to" transform="translate(357.5,68.508595)">
                  <g id="eZPd5k6m38e139_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e139"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eZPd5k6m38e139-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e140-filter)">
                <path
                  id="eZPd5k6m38e141"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e141-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e142_to" transform="translate(357.5,143.771297)">
                  <g id="eZPd5k6m38e142_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e142_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e142"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e142-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e143_to" transform="translate(357.5,137.271297)">
                  <g id="eZPd5k6m38e143_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e143_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e143"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e143-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e144_to" transform="translate(357.5,138.771297)">
                  <g id="eZPd5k6m38e144_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e144"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e144-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e145-filter)">
                <path
                  id="eZPd5k6m38e146"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e146-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e147_to" transform="translate(357.5,143.771297)">
                  <rect
                    id="eZPd5k6m38e147"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eZPd5k6m38e147-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZPd5k6m38e148_to" transform="translate(357.5,137.271297)">
                  <rect
                    id="eZPd5k6m38e148"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eZPd5k6m38e148-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZPd5k6m38e149_to" transform="translate(357.5,136.771297)">
                  <rect
                    id="eZPd5k6m38e149"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eZPd5k6m38e149-filter)"
                    fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#eZPd5k6m38e165)">
              <g transform="translate(.000001 0)">
                <path
                  d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e154-filter)">
                  <path
                    id="eZPd5k6m38e155"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e155-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e156_to" transform="translate(291.798427,53.771297)">
                    <g id="eZPd5k6m38e156_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e156_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e156"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e156-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e157_to" transform="translate(291.798427,47.271297)">
                    <g id="eZPd5k6m38e157_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e157_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e157"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e157-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e158_to" transform="translate(291.798427,49.771297)">
                    <g id="eZPd5k6m38e158_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e158"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e158-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e159-filter)">
                  <path
                    id="eZPd5k6m38e160"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e160-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e161_to" transform="translate(291.798427,53.771297)">
                    <g id="eZPd5k6m38e161_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e161_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e161"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e161-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e162_to" transform="translate(291.798427,47.271297)">
                    <g id="eZPd5k6m38e162_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e162_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e162"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e162-filter)"
                          fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e163_to" transform="translate(291.798427,49.771297)">
                    <g id="eZPd5k6m38e163_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e163"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e163-filter)"
                        fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eZPd5k6m38e165" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 209.931986-1.012683)"
                  fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e169-filter)">
                <path
                  id="eZPd5k6m38e170"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e170-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e171_to" transform="translate(226.798427,143.771297)">
                  <g id="eZPd5k6m38e171_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e171_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e171"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e171-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e172_to" transform="translate(226.798427,137.271297)">
                  <g id="eZPd5k6m38e172_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e172_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e172"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e172-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e173_to" transform="translate(226.798427,141.771297)">
                  <g id="eZPd5k6m38e173_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e173"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e173-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e174-filter)">
                <path
                  id="eZPd5k6m38e175"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e175-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e176_to" transform="translate(226.798427,143.771297)">
                  <g id="eZPd5k6m38e176_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e176_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e176"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e176-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e177_to" transform="translate(226.798427,137.271297)">
                  <g id="eZPd5k6m38e177_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e177_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e177"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e177-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e178_to" transform="translate(226.798427,138.771297)">
                  <g id="eZPd5k6m38e178_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e178"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e178-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eZPd5k6m38e199)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke={styles.strokeColor} />
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e183-filter)">
                  <path
                    id="eZPd5k6m38e184"
                    d="M96.7109,145.439636v-17.754678l.101249-.122374c0,0,.08233.25.08233.25"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eZPd5k6m38e184-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e185_to" transform="translate(161.798427,27.271297)">
                    <g id="eZPd5k6m38e185_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e185_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e185"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eZPd5k6m38e185-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e186_to" transform="translate(161.798427,23.771297)">
                    <g id="eZPd5k6m38e186_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e186_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e186"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eZPd5k6m38e186-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e187_to" transform="translate(161.798427,23.271297)">
                    <g id="eZPd5k6m38e187_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e187"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eZPd5k6m38e187-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e188-filter)">
                  <path
                    id="eZPd5k6m38e189"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e189-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e190_to" transform="translate(161.798427,53.771297)">
                    <g id="eZPd5k6m38e190_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e190_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e190"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e190-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e191_to" transform="translate(161.798427,47.271297)">
                    <g id="eZPd5k6m38e191_tr" transform="rotate(0)">
                      <g id="eZPd5k6m38e191_ts" transform="scale(1,1)">
                        <rect
                          id="eZPd5k6m38e191"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eZPd5k6m38e191-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eZPd5k6m38e192_to" transform="translate(161.798427,49.771297)">
                    <g id="eZPd5k6m38e192_tr" transform="rotate(0)">
                      <rect
                        id="eZPd5k6m38e192"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e192-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e193-filter)">
                  <path
                    id="eZPd5k6m38e194"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eZPd5k6m38e194-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eZPd5k6m38e195_to" transform="translate(161.798427,53.771297)">
                    <rect
                      id="eZPd5k6m38e195"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e195-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eZPd5k6m38e196_to" transform="translate(161.798427,47.271297)">
                    <rect
                      id="eZPd5k6m38e196"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e196-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eZPd5k6m38e197_to" transform="translate(161.798427,50.771297)">
                    <rect
                      id="eZPd5k6m38e197"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e197-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eZPd5k6m38e199" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 75.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e203-filter)">
                <path
                  id="eZPd5k6m38e204"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e204-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e205_to" transform="translate(96.798427,143.771297)">
                  <g id="eZPd5k6m38e205_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e205_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e205"
                        style={{ isolation: 'isolate' }}
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e205-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e206_to" transform="translate(96.798427,137.271297)">
                  <g id="eZPd5k6m38e206_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e206_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e206"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e206-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e207_to" transform="translate(96.798427,142.271297)">
                  <g id="eZPd5k6m38e207_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e207"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e207-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZPd5k6m38e208-filter)">
                <path
                  id="eZPd5k6m38e209"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZPd5k6m38e209-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZPd5k6m38e210_to" transform="translate(96.798427,143.771297)">
                  <g id="eZPd5k6m38e210_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e210_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e210"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e210-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e211_to" transform="translate(96.798427,137.271297)">
                  <g id="eZPd5k6m38e211_tr" transform="rotate(0)">
                    <g id="eZPd5k6m38e211_ts" transform="scale(1,1)">
                      <rect
                        id="eZPd5k6m38e211"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eZPd5k6m38e211-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eZPd5k6m38e212_to" transform="translate(96.798427,139.771297)">
                  <g id="eZPd5k6m38e212_tr" transform="rotate(0)">
                    <rect
                      id="eZPd5k6m38e212"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eZPd5k6m38e212-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <rect
                width="63"
                height="63"
                rx="4.5"
                ry="4.5"
                transform="translate(.5 231.082)"
                fill="#030303"
                stroke="#878888"
              />
            </g>
          </g>
        </g>
        <mask id="eZPd5k6m38e214" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
      <g display="none" transform="translate(-1.5-4)">
        <rect width="888" height="300" rx="0" ry="0" fill="none" />
        <rect width="888" height="300" rx="0" ry="0" fill="none" />
        <rect width="32" height="32" rx="16" ry="16" transform="translate(838.308 248.43)" fill="#fff" />
        <path
          d="M857.215,255.17c-1.915-.001-3.783.593-5.347,1.698-1.564,1.106-2.747,2.669-3.386,4.474-1.638,0-3.208.651-4.366,1.808-1.158,1.158-1.808,2.729-1.808,4.366c0,1.638.65,3.208,1.808,4.366s2.728,1.808,4.366,1.808h8.733c2.427-.044,4.739-1.039,6.44-2.771c1.7-1.732,2.653-4.062,2.653-6.489s-.953-4.757-2.653-6.489c-1.701-1.732-4.013-2.727-6.44-2.771Zm0,14.919h-3.126c.613-1.327.733-2.83.339-4.239-.393-1.408-1.276-2.631-2.489-3.448.37-.962.996-1.804,1.81-2.437.814-.632,1.785-1.029,2.809-1.15c1.024-.12,2.061.042,3,.468.938.427,1.742,1.101,2.325,1.952.583.85.923,1.843.982,2.872s-.164,2.055-.645,2.967c-.481.911-1.202,1.674-2.085,2.205-.884.532-1.895.812-2.926.81h.006Z"
          fill="#e10081"
        />
        <path
          d="M847.743,295.098c-.094-.148-.14-.222-.167-.336-.019-.086-.019-.221,0-.307.027-.114.073-.188.167-.335.771-1.222,3.068-4.311,6.569-4.311c3.5,0,5.797,3.089,6.569,4.311.093.147.14.221.166.335.02.086.02.221,0,.307-.026.114-.073.188-.166.336-.772,1.222-3.069,4.31-6.569,4.31-3.501,0-5.798-3.088-6.569-4.31Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M854.312,296.609c1.104,0,2-.896,2-2c0-1.105-.896-2-2-2-1.105,0-2,.895-2,2c0,1.104.895,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M847.743,295.098c-.094-.148-.14-.222-.167-.336-.019-.086-.019-.221,0-.307.027-.114.073-.188.167-.335.771-1.222,3.068-4.311,6.569-4.311c3.5,0,5.797,3.089,6.569,4.311.093.147.14.221.166.335.02.086.02.221,0,.307-.026.114-.073.188-.166.336-.772,1.222-3.069,4.31-6.569,4.31-3.501,0-5.798-3.088-6.569-4.31Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M854.312,296.609c1.104,0,2-.896,2-2c0-1.105-.896-2-2-2-1.105,0-2,.895-2,2c0,1.104.895,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect width="32" height="32" rx="16" ry="16" transform="translate(766.182 154.019)" fill="#fff" />
        <path
          d="M770.682,164.728c.82,0,1.488-.057,2.002-.169.531-.129.941-.313,1.23-.554.29-.241.491-.547.603-.916.113-.37.169-.811.169-1.326h3.449v16.87h-3.98v-11.158h-3.473v-2.747Z"
          fill="#2469f0"
        />
        <path
          d="M780.84,178.634c0-1.365.105-2.514.314-3.446.225-.932.539-1.719.941-2.361.402-.643.868-1.189,1.398-1.639.547-.45,1.15-.876,1.809-1.277.499-.29.957-.563,1.375-.82.434-.273.812-.546,1.134-.819.321-.273.57-.571.747-.892.193-.337.29-.715.29-1.133c0-.674-.185-1.156-.555-1.445-.37-.306-.828-.458-1.375-.458-.611,0-1.125.192-1.543.578-.402.37-.603.996-.603,1.88h-3.883c0-.771.128-1.486.386-2.145.273-.659.667-1.229,1.181-1.711.515-.482,1.142-.86,1.882-1.133.739-.273,1.591-.409,2.556-.409.772,0,1.504.104,2.195.313.707.193,1.326.498,1.857.916.531.401.949.931,1.254,1.59.322.643.483,1.406.483,2.29c0,.706-.153,1.349-.459,1.928-.305.578-.683,1.092-1.133,1.542-.434.45-.909.851-1.423,1.205-.515.337-.981.626-1.399.867-.579.386-1.061.723-1.447,1.013-.37.273-.667.53-.893.771-.225.241-.385.49-.482.747-.08.257-.121.554-.121.891h7.236v3.157h-11.722Z"
          fill="#2469f0"
        />
        <path
          d="M775.617,202.172c-.093-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.569,4.311.094.148.14.222.167.336.019.086.019.221,0,.306-.027.114-.073.188-.167.336-.771,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M782.186,203.683c1.105,0,2-.895,2-2c0-1.104-.895-2-2-2-1.104,0-2,.896-2,2c0,1.105.896,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M775.617,202.172c-.093-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.569,4.311.094.148.14.222.167.336.019.086.019.221,0,.306-.027.114-.073.188-.167.336-.771,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M782.186,203.683c1.105,0,2-.895,2-2c0-1.104-.895-2-2-2-1.104,0-2,.896-2,2c0,1.105.896,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect width="32" height="32" rx="16" ry="16" transform="translate(706.15 247.805)" fill="#fff" />
        <path
          d="M715.586,264.422l1.314-6.242h-5.25l-1.362,8.173c-.086.514.101,1.036.495,1.378l7.504,6.526c.218.189.558.073.614-.21l.824-4.116c.107-.54-.088-1.095-.511-1.447l-3.121-2.601c-.427-.355-.622-.917-.507-1.461Z"
          fill="url(#eZPd5k6m38e233-fill)"
        />
        <path
          d="M728.714,263.189L727.4,269.43h5.25l1.362-8.172c.085-.514-.102-1.036-.496-1.378l-7.504-6.526c-.217-.189-.558-.073-.614.209l-.823,4.117c-.108.54.088,1.095.51,1.447l3.121,2.6c.427.356.622.918.508,1.462Z"
          fill="url(#eZPd5k6m38e234-fill)"
        />
        <path
          d="M711.878,256.812l-1.594,9.618c.087-.5.222-1.35.615-2.25.492-1.125,1.5-2.25,3-3.375c1.124-.842,5.184-2.189,7.441-2.87.267-.081.469-.301.523-.574l.908-4.539c.057-.284-.216-.523-.49-.428l-9.412,3.245c-.52.18-.901.63-.991,1.173Z"
          fill="#feb81c"
        />
        <path
          d="M732.422,270.799l1.534-9.244c-.086.5-.162.975-.555,1.875-.491,1.125-1.5,2.25-3,3.375-1.123.843-5.184,2.19-7.441,2.871-.266.081-.468.301-.523.574l-.908,4.539c-.056.284.217.523.49.428l9.413-3.245c.52-.18.9-.63.99-1.173Z"
          fill="#feb81c"
        />
        <path
          d="M715.584,295.097c-.093-.148-.14-.222-.166-.336-.019-.085-.019-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.57-4.311c3.5,0,5.797,3.089,6.569,4.311.093.148.14.222.166.336.019.086.019.221,0,.306-.026.114-.073.188-.166.336-.772,1.222-3.069,4.311-6.569,4.311-3.501,0-5.798-3.089-6.57-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M722.154,296.608c1.104,0,2-.895,2-2c0-1.104-.896-2-2-2-1.105,0-2,.896-2,2c0,1.105.895,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M715.584,295.097c-.093-.148-.14-.222-.166-.336-.019-.085-.019-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.57-4.311c3.5,0,5.797,3.089,6.569,4.311.093.148.14.222.166.336.019.086.019.221,0,.306-.026.114-.073.188-.166.336-.772,1.222-3.069,4.311-6.569,4.311-3.501,0-5.798-3.089-6.57-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M722.154,296.608c1.104,0,2-.895,2-2c0-1.104-.896-2-2-2-1.105,0-2,.896-2,2c0,1.105.895,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M639.019,169.689c0-.846.686-1.532,1.532-1.532h9.766c.846,0,1.532.686,1.532,1.532s-.686,1.532-1.532,1.532h-9.766c-.846,0-1.532-.686-1.532-1.532Z"
          fill="url(#eZPd5k6m38e241-fill)"
        />
        <path
          d="M639.019,173.328c0-.635.515-1.149,1.149-1.149h5.936c.635,0,1.149.514,1.149,1.149c0,.634-.514,1.148-1.149,1.148h-5.936c-.634,0-1.149-.514-1.149-1.148Z"
          fill="url(#eZPd5k6m38e242-fill)"
        />
        <path
          d="M639.019,176.296c0-.582.472-1.054,1.053-1.054h2.681c.582,0,1.053.472,1.053,1.054c0,.581-.471,1.053-1.053,1.053h-2.681c-.581,0-1.053-.472-1.053-1.053Z"
          fill="url(#eZPd5k6m38e243-fill)"
        />
        <path
          d="M660.849,177.349v-.029c2.903-.288,5.17-2.737,5.17-5.716c0-3.173-2.572-5.745-5.745-5.745-.456,0-.899.054-1.324.154-1.206-3.115-4.231-5.324-7.771-5.324-4.077,0-7.47,2.929-8.189,6.798h7.327c1.216,0,2.202.986,2.202,2.202s-.986,2.202-2.202,2.202h-3.096c.427.333.702.853.702,1.437c0,1.004-.814,1.819-1.819,1.819c0,0-.346,0-.568,0h-.772v2.202h16.085Z"
          fill="url(#eZPd5k6m38e244-fill)"
        />
        <path
          d="M645.454,202.172c-.093-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.797,3.089,6.569,4.311.094.148.14.222.166.336.02.086.02.221,0,.306-.026.114-.072.188-.166.336-.772,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M652.023,203.683c1.105,0,2-.895,2-2c0-1.104-.895-2-2-2-1.104,0-2,.896-2,2c0,1.105.896,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M645.454,202.172c-.093-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.797,3.089,6.569,4.311.094.148.14.222.166.336.02.086.02.221,0,.306-.026.114-.072.188-.166.336-.772,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M652.023,203.683c1.105,0,2-.895,2-2c0-1.104-.895-2-2-2-1.104,0-2,.896-2,2c0,1.105.896,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect width="32" height="32" rx="16" ry="16" transform="translate(575.373 247.805)" fill="#fff" />
        <path
          d="M598.781,258.422c-1.036-.293-2.111-.723-3.206-1.153-.058-.274-.312-.606-.801-1.036-.704-.626-2.053-.606-3.206-.332-1.27-.294-2.541-.411-3.753-.118-9.911,2.737-4.301,9.383-7.937,16.088.509,1.095,6.099,7.507,14.173,5.787c0,0-2.757-6.627,3.479-9.814c5.063-2.58,8.699-7.389,1.251-9.422Z"
          fill="#8bc53f"
        />
        <path d="M591.574,255.897c.723.059,3.343.899,4.007,1.368-.566-1.622-2.443-1.837-4.007-1.368Z" fill="#009345" />
        <path
          d="M600.019,262.78c0,.528-.645.801-1.017.43-.371-.372-.117-1.017.411-1.036.332.019.606.273.606.606Zm-7.761-2.756c0,1.114-.684,2.13-1.72,2.56s-2.228.196-3.03-.606c-.801-.801-1.036-1.994-.606-3.03s1.447-1.72,2.561-1.72c.743,0,1.446.293,1.955.802.547.547.84,1.251.84,1.994Z"
          fill="#fff"
        />
        <path
          d="M591.435,260.045c0,.782-.469,1.505-1.212,1.798-.723.294-1.564.137-2.131-.43s-.723-1.388-.43-2.13c.293-.724,1.017-1.212,1.799-1.212c1.094.019,1.974.899,1.974,1.974Z"
          fill="#58595b"
        />
        <path
          d="M602.266,264.01c-2.248,1.584-4.809,2.776-8.425,2.776-1.701,0-2.033-1.798-3.167-.918-.587.449-2.619,1.466-4.242,1.407-1.622-.078-4.242-1.036-4.965-4.477-.293,3.46-.43,6.002-1.74,8.914c2.58,4.145,8.738,7.331,14.329,5.962-.606-4.202,3.069-8.307,5.121-10.399.802-.821,2.307-2.111,3.089-3.265Z"
          fill="#8bc53f"
        />
        <path
          d="M602.163,264.11c-.703.645-1.524,1.114-2.365,1.525-.86.41-1.74.723-2.658.977-.919.235-1.877.411-2.835.333-.977-.079-1.955-.411-2.6-1.134l.039-.039c.782.508,1.701.684,2.6.723.919.02,1.838-.039,2.737-.215.899-.195,1.798-.469,2.658-.821.861-.352,1.701-.762,2.444-1.349h-.02Z"
          fill="#58595b"
        />
        <path
          d="M584.807,295.097c-.093-.148-.14-.222-.166-.336-.019-.086-.019-.221,0-.307.026-.114.073-.188.166-.335.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.57,4.311.093.147.14.221.166.335.019.086.019.221,0,.307-.026.114-.073.188-.166.336-.772,1.221-3.069,4.31-6.57,4.31-3.5,0-5.797-3.089-6.569-4.31Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M591.376,296.608c1.105,0,2-.896,2-2c0-1.105-.895-2-2-2-1.104,0-2,.895-2,2c0,1.104.896,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M584.807,295.097c-.093-.148-.14-.222-.166-.336-.019-.086-.019-.221,0-.307.026-.114.073-.188.166-.335.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.57,4.311.093.147.14.221.166.335.019.086.019.221,0,.307-.026.114-.073.188-.166.336-.772,1.221-3.069,4.31-6.57,4.31-3.5,0-5.797-3.089-6.569-4.31Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M591.376,296.608c1.105,0,2-.896,2-2c0-1.105-.895-2-2-2-1.104,0-2,.895-2,2c0,1.104.896,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M531.545,162.898c.636.401,1.043,1.088,1.073,1.839v10.278c-.029.675-.342,1.297-.874,1.71l-9.27,5.376c-.265.104-.545.157-.831.157-.313,0-.66-.069-.901-.186-.031-.015-.117-.063-.245-.134-.082-.046-.182-.103-.294-.167l-3.394-1.956-6.191-4.447v-10.635c.035-.696.381-1.329.949-1.737.108-.077.223-.145.342-.202c0,0,8.598-4.994,8.672-5.031.631-.318,1.417-.317,2.046,0l8.918,5.135Z"
          fill="#2d374b"
        />
        <path d="M521.695,173.478l3.407,5.355l3.147-1.827-4.475-7.062-2.079,3.534Z" fill="#28a0f0" />
        <path
          d="M531.122,174.86l-.002-1.463-4.889-7.625-1.814,3.082l4.72,7.643l1.706-.99c.168-.136.269-.336.28-.551l-.001-.096Z"
          fill="#28a0f0"
        />
        <path
          d="M510.618,176.241l2.41,1.39l8.019-12.878-1.361-.036c-1.16-.017-2.409.285-2.982,1.223l-4.554,7.068-1.532,2.357v.876v0Z"
          fill="#fff"
        />
        <path d="M525.686,164.758l-3.587.013-8.116,13.41l2.837,1.636.771-1.31l8.095-13.749Z" fill="#fff" />
        <path
          d="M532.618,164.737c-.03-.751-.437-1.438-1.073-1.839l-8.918-5.135c-.629-.317-1.415-.318-2.046,0-.074.037-8.672,5.031-8.672,5.031-.119.057-.234.125-.342.202-.568.408-.914,1.041-.949,1.737v10.635l1.532-2.357-.014-8.206c.002-.03.006-.06.011-.089.035-.193.142-.365.305-.482.04-.029,8.785-5.097,8.813-5.111.205-.103.466-.105.672-.003l8.803,5.07c.208.133.338.358.35.604v10.165c-.011.216-.096.416-.263.552l-1.707.99-.88.511-3.148,1.826-3.191,1.852c-.172.063-.373.059-.544-.01l-3.776-2.175-.772,1.31l3.394,1.956c.112.064.212.121.294.167.128.071.214.119.245.134.241.117.588.186.901.186.286,0,.566-.053.831-.157l9.27-5.376c.532-.413.845-1.035.874-1.71v-10.278Z"
          fill="#96bedc"
        />
        <path
          d="M515.052,202.235c-.093-.148-.14-.222-.166-.336-.019-.086-.019-.221,0-.307.026-.114.073-.188.166-.335.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.57,4.311.093.147.14.221.166.335.019.086.019.221,0,.307-.026.114-.073.188-.166.336-.772,1.221-3.069,4.31-6.57,4.31-3.5,0-5.797-3.089-6.569-4.31Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M521.621,203.746c1.105,0,2-.896,2-2c0-1.105-.895-2-2-2-1.104,0-2,.895-2,2c0,1.104.896,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M515.052,202.235c-.093-.148-.14-.222-.166-.336-.019-.086-.019-.221,0-.307.026-.114.073-.188.166-.335.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.57,4.311.093.147.14.221.166.335.019.086.019.221,0,.307-.026.114-.073.188-.166.336-.772,1.221-3.069,4.31-6.57,4.31-3.5,0-5.797-3.089-6.569-4.31Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M521.621,203.746c1.105,0,2-.896,2-2c0-1.105-.895-2-2-2-1.104,0-2,.895-2,2c0,1.104.896,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#eZPd5k6m38e276)">
          <g filter="url(#eZPd5k6m38e271-filter)">
            <path
              d="M374.42,170.016c0,8.837-7.164,16-16,16-8.837,0-16-7.163-16-16c0-8.836,7.163-16,16-16c8.836,0,16,7.164,16,16Z"
              fill="#f3f3f3"
            />
            <path
              d="M370.42,174.286c-.007-1.573-1.288-2.843-2.861-2.836-.73.003-1.395.281-1.897.735-.931.844-1.18,1.663-2.733,1.67-1.115.005-1.56-.374-2.125-1.083-.506-.767-1.371-1.273-2.353-1.281l-.02-.001h-.018-.006-.006-.012l-.022.002c-.83.013-1.572.38-2.084.956-.752.76-1.084,1.441-2.5,1.447-1.203.006-1.626-.435-2.262-1.257-.524-.677-1.345-1.112-2.266-1.108-1.572.007-2.842,1.289-2.835,2.861s1.288,2.842,2.86,2.835c.901-.004,1.702-.425,2.222-1.08.626-.794,1.215-1.244,2.33-1.248c1.209-.005,1.688.51,2.366,1.23.518.64,1.309,1.052,2.194,1.058h.018.016.006.006.019.017c.956-.016,1.796-.503,2.301-1.237.582-.696,1.164-1.087,2.203-1.092c1.307-.005,1.761.598,2.533,1.407.522.546,1.258.885,2.073.881c1.573-.006,2.843-1.288,2.836-2.859Z"
              fill="#1c3664"
            />
            <path
              d="M365.803,168.966c-.007-1.572-1.288-2.842-2.86-2.835-.73.004-1.395.281-1.897.736-.931.842-1.18,1.663-2.734,1.669-1.202.006-1.625-.435-2.261-1.258-.524-.676-1.345-1.11-2.266-1.106-1.573.007-2.843,1.288-2.836,2.86.007,1.573,1.288,2.843,2.861,2.836.901-.004,1.702-.426,2.221-1.082.627-.792,1.216-1.242,2.331-1.247c1.306-.005,1.76.596,2.533,1.406.522.547,1.258.886,2.073.883c1.573-.008,2.842-1.289,2.835-2.862Z"
              fill="#8abfd9"
            />
            <path
              d="M361.216,163.852c-.007-1.573-1.288-2.843-2.861-2.836s-2.843,1.288-2.835,2.861c.007,1.572,1.287,2.842,2.861,2.835c1.572-.007,2.842-1.288,2.835-2.86Z"
              fill="#f26724"
            />
          </g>
          <clipPath id="eZPd5k6m38e276">
            <rect width="32" height="32" rx="0" ry="0" transform="translate(342.42 154.016)" fill="#fff" />
          </clipPath>
        </g>
        <path
          d="M351.855,202.172c-.094-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.072-.188.166-.336.771-1.222,3.068-4.311,6.569-4.311c3.5,0,5.797,3.089,6.569,4.311.093.148.14.222.166.336.02.086.02.221,0,.306-.026.114-.073.188-.166.336-.772,1.222-3.069,4.311-6.569,4.311-3.501,0-5.798-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M358.424,203.683c1.104,0,2-.895,2-2c0-1.104-.896-2-2-2-1.105,0-2,.896-2,2c0,1.105.895,2,2,2Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M351.855,202.172c-.094-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.072-.188.166-.336.771-1.222,3.068-4.311,6.569-4.311c3.5,0,5.797,3.089,6.569,4.311.093.148.14.222.166.336.02.086.02.221,0,.306-.026.114-.073.188-.166.336-.772,1.222-3.069,4.311-6.569,4.311-3.501,0-5.798-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M358.424,203.683c1.104,0,2-.895,2-2c0-1.104-.896-2-2-2-1.105,0-2,.896-2,2c0,1.105.895,2,2,2Z"
          fill="#030303"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect width="32" height="32" rx="16" ry="16" transform="translate(277.331 245.608)" fill="#fff" />
        <path d="M287.76,257.894h7.893l-7.893,6.035v5.107l-7.429-7.428l7.429-7.429v3.715Z" fill="#030303" />
        <path d="M298.903,265.322h-7.893l7.893-6.036v-5.107l7.428,7.429-7.428,7.428v-3.714Z" fill="#030303" />
        <path
          d="M286.297,294.604c-.093-.148-.14-.222-.166-.336-.02-.086-.02-.221,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.569,4.311.094.148.14.222.167.336.019.085.019.22,0,.306-.027.114-.073.188-.167.336-.771,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M292.866,296.115c1.105,0,2-.896,2-2c0-1.105-.895-2-2-2-1.104,0-2,.895-2,2c0,1.104.896,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M286.297,294.604c-.093-.148-.14-.222-.166-.336-.02-.086-.02-.221,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.569,4.311.094.148.14.222.167.336.019.085.019.22,0,.306-.027.114-.073.188-.167.336-.771,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M292.866,296.115c1.105,0,2-.896,2-2c0-1.105-.895-2-2-2-1.104,0-2,.895-2,2c0,1.104.896,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#eZPd5k6m38e296)">
          <g>
            <path
              d="M244.188,170.016c0,8.837-7.164,16-16,16-8.837,0-16-7.163-16-16c0-8.836,7.163-16,16-16c8.836,0,16,7.164,16,16Z"
              fill="#fff"
            />
            <path
              d="M225.627,168.674h1.721v-1.314l-6.069-3.363h6.069v-1.836h-10.689v6.513h1.836v-4.02l.039.019l7.093,4.001Z"
              fill="#878888"
            />
            <path
              d="M239.814,168.674v-6.513h-10.727v1.836h6.069l-6.069,3.363v1.314h1.739l7.132-4.039v4.039h1.856Z"
              fill="#878888"
            />
            <path
              d="M235.426,174.897h-6.339v1.836h10.727v-6.494h-1.856v3.865l-.019-.019-6.842-3.846h-2.01v1.14l6.339,3.518Z"
              fill="#878888"
            />
            <path
              d="M216.562,170.221v1.875h6.282l-6.282,3.498v2.281l8.853-4.948v3.827h1.875v-6.533h-10.728Z"
              fill="#f4511e"
            />
          </g>
          <clipPath id="eZPd5k6m38e296">
            <rect width="32" height="32" rx="0" ry="0" transform="translate(212.188 154.016)" fill="#fff" />
          </clipPath>
        </g>
        <path
          d="M221.622,202.172c-.093-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.569,4.311.094.148.141.222.167.336.019.086.019.221,0,.306-.026.114-.073.188-.167.336-.771,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M228.191,203.683c1.105,0,2-.895,2-2c0-1.104-.895-2-2-2-1.104,0-2,.896-2,2c0,1.105.896,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M221.622,202.172c-.093-.148-.14-.222-.166-.336-.02-.085-.02-.22,0-.306.026-.114.073-.188.166-.336.772-1.222,3.069-4.311,6.569-4.311c3.501,0,5.798,3.089,6.569,4.311.094.148.141.222.167.336.019.086.019.221,0,.306-.026.114-.073.188-.167.336-.771,1.222-3.068,4.311-6.569,4.311-3.5,0-5.797-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M228.191,203.683c1.105,0,2-.895,2-2c0-1.104-.895-2-2-2-1.104,0-2,.896-2,2c0,1.105.896,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#eZPd5k6m38e309)">
          <g>
            <path
              d="M179.218,261.657c0,8.836-7.164,16-16,16-8.837,0-16-7.164-16-16c0-8.837,7.163-16,16-16c8.836,0,16,7.163,16,16Z"
              fill="#fff"
            />
            <path
              d="M150.648,259.403v4.531h1.15v-3.358l3.772,2.369v.989h1.173v-4.531h-1.173v2.024l-3.404-2.024h-1.518Z"
              fill="#6c6d6d"
            />
            <path d="M164.517,259.424v4.462h1.15v-1.472h3.726v-.966h-3.749v-1.104h3.749v-.92h-4.876Z" fill="#f95986" />
            <path
              d="M169.899,259.424h1.38l1.472,1.449l1.449-1.449h1.495l-2.185,2.07l2.277,2.415h-1.541l-1.495-1.564-1.564,1.518h-1.564l2.369-2.346-2.093-2.093Z"
              fill="#f95986"
            />
            <path
              d="M162.47,262.277c-.184.506-.667.851-1.242.851h-1.219c-.736,0-1.334-.598-1.334-1.334v-.299c0-.736.598-1.334,1.334-1.334h1.219c.598,0,1.127.414,1.288.966l1.012-.368c-.276-.805-1.035-1.403-1.955-1.403h-1.909c-1.15,0-2.07.92-2.07,2.07v.46c0,1.15.92,2.07,2.07,2.07h1.909c.92,0,1.679-.598,1.955-1.403l-1.058-.276Z"
              fill="#6c6d6d"
            />
          </g>
          <clipPath id="eZPd5k6m38e309">
            <rect width="32" height="32" rx="0" ry="0" transform="translate(147.218 245.657)" fill="#fff" />
          </clipPath>
        </g>
        <path
          d="M156.653,294.037c-.094-.148-.14-.221-.167-.335-.019-.086-.019-.221,0-.307.027-.114.073-.188.167-.336.771-1.222,3.068-4.31,6.569-4.31c3.5,0,5.797,3.088,6.569,4.31.093.148.14.222.166.336.02.086.02.221,0,.307-.026.114-.073.187-.166.335-.772,1.222-3.069,4.311-6.569,4.311-3.501,0-5.798-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M163.222,295.548c1.104,0,2-.895,2-2c0-1.104-.896-2-2-2-1.105,0-2,.896-2,2c0,1.105.895,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M156.653,294.037c-.094-.148-.14-.221-.167-.335-.019-.086-.019-.221,0-.307.027-.114.073-.188.167-.336.771-1.222,3.068-4.31,6.569-4.31c3.5,0,5.797,3.088,6.569,4.31.093.148.14.222.166.336.02.086.02.221,0,.307-.026.114-.073.187-.166.335-.772,1.222-3.069,4.311-6.569,4.311-3.501,0-5.798-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M163.222,295.548c1.104,0,2-.895,2-2c0-1.104-.896-2-2-2-1.105,0-2,.896-2,2c0,1.105.895,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect width="32" height="32" rx="16" ry="16" transform="translate(81.2485 154.016)" fill="#050505" />
        <path
          d="M104.859,162.374c.355.608.151,1.388-.457,1.743l-9.1975,5.378-.0094,12.209c-.0005.704-.5712,1.273-1.2747,1.273-.7034-.001-1.2732-.572-1.2727-1.275l.0099-12.939c.0004-.452.2405-.871.6309-1.099l9.8275-5.746c.608-.355,1.388-.151,1.743.456Z"
          clipRule="evenodd"
          fill="#6944fd"
          fillRule="evenodd"
        />
        <path
          d="M103.05,157.694c.351.61.141,1.389-.468,1.74l-12.4649,7.178v11.883c0,.703-.5703,1.274-1.2737,1.274s-1.2737-.571-1.2737-1.274v-12.62c0-.455.2433-.876.6381-1.103l13.1022-7.546c.61-.351,1.389-.141,1.74.468Z"
          clipRule="evenodd"
          fill="#6944fd"
          fillRule="evenodd"
        />
        <path
          d="M106.764,167.342c.344.613.126,1.39-.487,1.734l-6.004,3.37v6.097c0,.703-.57,1.274-1.2735,1.274-.7034,0-1.2737-.571-1.2737-1.274v-6.843c0-.46.2486-.885.6502-1.11l6.654-3.735c.613-.345,1.39-.127,1.734.487Z"
          clipRule="evenodd"
          fill="#6944fd"
          fillRule="evenodd"
        />
        <path
          d="M90.6834,202.172c-.0934-.148-.1401-.221-.1662-.335-.0197-.086-.0197-.221,0-.307.0261-.114.0728-.188.1662-.336.7717-1.222,3.0686-4.31,6.569-4.31c3.5006,0,5.7976,3.088,6.5696,4.31.093.148.14.222.166.336.019.086.019.221,0,.307-.026.114-.073.187-.166.335-.772,1.222-3.069,4.311-6.5696,4.311-3.5004,0-5.7973-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M97.2524,203.683c1.1046,0,2-.895,2-2c0-1.104-.8954-2-2-2-1.1045,0-2,.896-2,2c0,1.105.8955,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.6834,202.172c-.0934-.148-.1401-.221-.1662-.335-.0197-.086-.0197-.221,0-.307.0261-.114.0728-.188.1662-.336.7717-1.222,3.0686-4.31,6.569-4.31c3.5006,0,5.7976,3.088,6.5696,4.31.093.148.14.222.166.336.019.086.019.221,0,.307-.026.114-.073.187-.166.335-.772,1.222-3.069,4.311-6.5696,4.311-3.5004,0-5.7973-3.089-6.569-4.311Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.2524,203.683c1.1046,0,2-.895,2-2c0-1.104-.8954-2-2-2-1.1045,0-2,.896-2,2c0,1.105.8955,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g clipPath="url(#eZPd5k6m38e408)">
          <g>
            <path
              d="M49.2783,261.657c0,8.837-7.1634,16-16,16s-16-7.163-16-16s7.1635-16,16-16s16,7.163,16,16Z"
              fill="#fff"
            />
            <path
              d="M33.3886,248.774c-.0417-.072-.1192-.117-.203-.117s-.1612.045-.203.117l-11.674,20.507c-.0409.073-.0403.161.0016.233s.1187.116.202.116h23.5312c.0838.001.1614-.044.2034-.116.042-.073.042-.162.0001-.234L33.3886,248.774Zm-2.5411,13.295l4.1838-1.143-1.1328,4.153-3.051-3.01Z"
              clipRule="evenodd"
              fillRule="evenodd"
            />
            <g opacity="0.52">
              <path
                d="M21.5141,269.63h4.7291l4.3479-16.651-9.2825,16.303c-.0411.072-.0403.161.002.233s.1199.116.2034.115h.0001Z"
                fill="#d9d9d9"
              />
              <path
                d="M42.9222,265.264l-16.6815,4.365h18.8022c.0838,0,.1613-.044.2033-.116.042-.073.0421-.162.0002-.235l-2.3242-4.014Z"
                fill="#d9d9d9"
              />
              <path
                d="M40.366,262.715l2.5575,2.549-9.5369-16.486c-.0418-.073-.1192-.117-.203-.117s-.1613.044-.2031.117l-2.3933,4.204l9.7683,9.722.0105.011Z"
                fill="#d9d9d9"
              />
            </g>
            <g opacity="0.64">
              <path d="M26.2405,269.628l3.4378-.899-.0192-12.191-3.4186,13.09Z" fill="#d9d9d9" />
              <path d="M29.659,256.538l10.7053,6.176-9.776-9.736-.9293,3.56Z" fill="#d9d9d9" />
              <path d="M29.6779,268.729l13.2464-3.466-2.5604-2.549-10.686,6.015Z" fill="#d9d9d9" />
            </g>
            <g opacity="0.76">
              <path d="M29.6783,268.731l2.2925-1.291-2.3079-8.314.0154,9.605Z" fill="#d9d9d9" />
              <path d="M40.3639,262.716l-2.2541-1.301-6.1394,6.025l8.3935-4.724Z" fill="#d9d9d9" />
              <path d="M29.6635,259.126l8.4473,2.289-8.4511-4.875.0038,2.586Z" fill="#d9d9d9" />
            </g>
            <g opacity="0.88">
              <path d="M35.0311,260.93l1.2971.002-6.6646-1.806.4973,1.792l4.8587.012h.0115Z" fill="#d9d9d9" />
              <path d="M30.8337,262.05l-.673-1.131l1.8106,6.521l1.2826-1.258-2.4068-4.109-.0134-.023Z" fill="#d9d9d9" />
              <path d="M36.3282,260.932l-3.074,5.25l4.8568-4.766-1.7828-.484Z" fill="#d9d9d9" />
            </g>
            <path d="M35.0314,260.93l-4.8703-.011.6865,1.154l4.1838-1.143Z" fill="#d9d9d9" />
            <path d="M33.9001,265.08l-3.0519-3.007l2.4068,4.109.6451-1.102Z" fill="#d9d9d9" />
            <path d="M36.3285,260.932l-1.297-.002-1.1318,4.15l2.4288-4.148Z" fill="#d9d9d9" />
            <g style={{ mixBlendMode: 'overlay' }}>
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M21.5141,269.63h4.7291l4.3479-16.651-9.2825,16.303c-.0411.072-.0403.161.002.233s.1199.116.2034.115h.0001Z"
                  fill="url(#eZPd5k6m38e348-fill)"
                />
                <path
                  d="M42.9222,265.264L26.2407,269.63h18.8022c.0838,0,.1613-.044.2033-.116.042-.073.0421-.162.0002-.235l-2.3242-4.015Z"
                  fill="url(#eZPd5k6m38e349-fill)"
                />
                <path
                  d="M40.366,262.715l2.5575,2.549-9.5369-16.486c-.0418-.073-.1192-.117-.203-.117s-.1613.044-.2031.117l-2.3933,4.204l9.7683,9.722.0105.011Z"
                  fill="url(#eZPd5k6m38e350-fill)"
                />
              </g>
              <g style={{ mixBlendMode: 'multiply' }}>
                <path d="M26.2402,269.63l3.4379-.899-.0192-12.191-3.4187,13.09Z" fill="url(#eZPd5k6m38e352-fill)" />
                <path d="M29.6589,256.54l10.7053,6.176-9.776-9.737-.9293,3.561Z" fill="url(#eZPd5k6m38e353-fill)" />
                <path d="M29.6777,268.731l13.2465-3.466-2.5604-2.549-10.6861,6.015Z" fill="url(#eZPd5k6m38e354-fill)" />
              </g>
              <g style={{ mixBlendMode: 'multiply' }}>
                <path d="M29.6779,268.731l2.2925-1.291-2.3079-8.314.0154,9.605Z" fill="url(#eZPd5k6m38e356-fill)" />
                <path d="M40.3636,262.716l-2.2542-1.301L31.97,267.44l8.3936-4.724Z" fill="url(#eZPd5k6m38e357-fill)" />
                <path d="M29.663,259.126l8.4473,2.289-8.4511-4.875.0038,2.586Z" fill="url(#eZPd5k6m38e358-fill)" />
              </g>
              <g style={{ mixBlendMode: 'multiply' }}>
                <path
                  d="M35.0311,260.93l1.297.002-6.6645-1.806.4973,1.792l4.8587.012h.0115Z"
                  fill="url(#eZPd5k6m38e360-fill)"
                />
                <path
                  d="M30.8336,262.05l-.673-1.131l1.8107,6.521l1.2826-1.258-2.4068-4.109-.0135-.023Z"
                  fill="url(#eZPd5k6m38e361-fill)"
                />
                <path d="M36.3282,260.932l-3.074,5.25l4.8567-4.767-1.7827-.483Z" fill="url(#eZPd5k6m38e362-fill)" />
              </g>
              <g style={{ mixBlendMode: 'multiply' }}>
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
                  <path d="M35.0314,260.928l-4.8703-.011.6865,1.154l4.1838-1.143Z" fill="url(#eZPd5k6m38e365-fill)" />
                </g>
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
                  <path d="M33.9001,265.078l-3.0519-3.007l2.4068,4.109.6451-1.102Z" fill="url(#eZPd5k6m38e367-fill)" />
                </g>
                <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
                  <path d="M36.3285,260.93l-1.297-.002-1.1318,4.15l2.4288-4.148Z" fill="url(#eZPd5k6m38e369-fill)" />
                </g>
              </g>
            </g>
            <path
              d="M33.3886,248.774c-.0417-.072-.1192-.117-.203-.117s-.1612.045-.203.117l-11.674,20.507c-.0409.073-.0403.161.0016.233s.1187.116.202.116h23.5312c.0838.001.1614-.044.2034-.116.042-.073.042-.162.0001-.234L33.3886,248.774Zm-2.5411,13.295l4.1838-1.143-1.1328,4.153-3.051-3.01Z"
              clipRule="evenodd"
              fillRule="evenodd"
            />
            <path
              d="M21.5141,269.63h4.7291l4.3479-16.651-9.2825,16.303c-.0411.072-.0403.161.002.233s.1199.116.2034.115h.0001Z"
              fill="#646564"
            />
            <path
              d="M42.9222,265.264l-16.6815,4.365h18.8022c.0838,0,.1613-.044.2033-.116.042-.073.0421-.162.0002-.235l-2.3242-4.014Z"
              fill="#646564"
            />
            <path
              d="M40.366,262.715l2.5575,2.549-9.5369-16.486c-.0418-.073-.1192-.117-.203-.117s-.1613.044-.2031.117l-2.3933,4.204l9.7683,9.722.0105.011Z"
              fill="#646564"
            />
            <g opacity="0.88">
              <path d="M26.2405,269.628l3.4378-.899-.0192-12.191-3.4186,13.09Z" fill="#646564" />
              <path d="M29.659,256.538l10.7053,6.176-9.776-9.736-.9293,3.56Z" fill="#646564" />
              <path d="M29.6779,268.729l13.2464-3.466-2.5604-2.549-10.686,6.015Z" fill="#646564" />
            </g>
            <g opacity="0.76">
              <path d="M29.6783,268.731l2.2925-1.291-2.3079-8.314.0154,9.605Z" fill="#646564" />
              <path d="M40.3639,262.716l-2.2541-1.301-6.1394,6.025l8.3935-4.724Z" fill="#646564" />
              <path d="M29.6635,259.126l8.4473,2.289-8.4511-4.875.0038,2.586Z" fill="#646564" />
            </g>
            <g opacity="0.64">
              <path d="M35.0311,260.93l1.2971.002-6.6646-1.806.4973,1.792l4.8587.012h.0115Z" fill="#646564" />
              <path d="M30.8337,262.05l-.673-1.131l1.8106,6.521l1.2826-1.258-2.4068-4.109-.0134-.023Z" fill="#646564" />
              <path d="M36.3282,260.932l-3.074,5.25l4.8568-4.766-1.7828-.484Z" fill="#646564" />
            </g>
            <g opacity="0.52">
              <path d="M35.0314,260.93l-4.8703-.011.6865,1.154l4.1838-1.143Z" fill="#646564" />
              <path d="M33.9001,265.08l-3.0519-3.007l2.4068,4.109.6451-1.102Z" fill="#646564" />
              <path d="M36.3285,260.932l-1.297-.002-1.1318,4.15l2.4288-4.148Z" fill="#646564" />
            </g>
            <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
              <g opacity="0.7">
                <path
                  d="M21.5141,269.63h4.7291l4.3479-16.651-9.2825,16.303c-.0411.072-.0403.161.002.233s.1199.116.2034.115h.0001Z"
                  fill="url(#eZPd5k6m38e392-fill)"
                />
                <path
                  d="M42.9222,265.264L26.2407,269.63h18.8022c.0838,0,.1613-.044.2033-.116.042-.073.0421-.162.0002-.235l-2.3242-4.015Z"
                  fill="url(#eZPd5k6m38e393-fill)"
                />
                <path
                  d="M40.366,262.715l2.5575,2.549-9.5369-16.486c-.0418-.073-.1192-.117-.203-.117s-.1613.044-.2031.117l-2.3933,4.204l9.7683,9.722.0105.011Z"
                  fill="url(#eZPd5k6m38e394-fill)"
                />
              </g>
              <g opacity="0.9">
                <path d="M26.2402,269.63l3.4379-.899-.0192-12.191-3.4187,13.09Z" fill="url(#eZPd5k6m38e396-fill)" />
                <path d="M29.6589,256.54l10.7053,6.176-9.776-9.737-.9293,3.561Z" fill="url(#eZPd5k6m38e397-fill)" />
                <path d="M29.6777,268.731l13.2465-3.466-2.5604-2.549-10.6861,6.015Z" fill="url(#eZPd5k6m38e398-fill)" />
              </g>
              <path d="M29.6779,268.731l2.2925-1.291-2.3079-8.314.0154,9.605Z" fill="url(#eZPd5k6m38e399-fill)" />
              <path d="M40.3636,262.716l-2.2542-1.301L31.97,267.44l8.3936-4.724Z" fill="url(#eZPd5k6m38e400-fill)" />
              <path d="M29.663,259.126l8.4473,2.289-8.4511-4.875.0038,2.586Z" fill="url(#eZPd5k6m38e401-fill)" />
              <path
                d="M35.0311,260.93l1.297.002-6.6645-1.806.4973,1.792l4.8587.012h.0115Z"
                fill="url(#eZPd5k6m38e402-fill)"
              />
              <path
                d="M30.8336,262.05l-.673-1.131l1.8107,6.521l1.2826-1.258-2.4068-4.109-.0135-.023Z"
                fill="url(#eZPd5k6m38e403-fill)"
              />
              <path d="M36.3282,260.932l-3.074,5.25l4.8567-4.767-1.7827-.483Z" fill="url(#eZPd5k6m38e404-fill)" />
              <path d="M35.0314,260.928l-4.8703-.011.6865,1.154l4.1838-1.143Z" fill="url(#eZPd5k6m38e405-fill)" />
              <path d="M33.9001,265.078l-3.0519-3.007l2.4068,4.109.6451-1.102Z" fill="url(#eZPd5k6m38e406-fill)" />
              <path d="M36.3285,260.93l-1.297-.002-1.1318,4.15l2.4288-4.148Z" fill="url(#eZPd5k6m38e407-fill)" />
            </g>
          </g>
          <clipPath id="eZPd5k6m38e408">
            <rect width="32" height="32" rx="0" ry="0" transform="translate(17.2783 245.657)" fill="#fff" />
          </clipPath>
        </g>
        <path
          d="M26.7132,294.037c-.0934-.148-.1401-.222-.1662-.336-.0197-.086-.0197-.221,0-.307.0261-.114.0728-.188.1662-.335.7716-1.222,3.0686-4.311,6.569-4.311s5.7974,3.089,6.5691,4.311c.0934.147.1401.221.1662.335.0196.086.0196.221,0,.307-.0261.114-.0728.188-.1662.336-.7717,1.221-3.0686,4.31-6.5691,4.31s-5.7974-3.089-6.569-4.31Z"
          fill="#030303"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M33.2822,295.548c1.1046,0,2-.896,2-2c0-1.105-.8954-2-2-2s-2,.895-2,2c0,1.104.8955,2,2,2Z"
          fill="none"
          stroke="#030303"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.7132,294.037c-.0934-.148-.1401-.222-.1662-.336-.0197-.086-.0197-.221,0-.307.0261-.114.0728-.188.1662-.335.7716-1.222,3.0686-4.311,6.569-4.311s5.7974,3.089,6.5691,4.311c.0934.147.1401.221.1662.335.0196.086.0196.221,0,.307-.0261.114-.0728.188-.1662.336-.7717,1.221-3.0686,4.31-6.5691,4.31s-5.7974-3.089-6.569-4.31Z"
          fill="#030303"
          stroke="#878888"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.2822,295.548c1.1046,0,2-.896,2-2c0-1.105-.8954-2-2-2s-2,.895-2,2c0,1.104.8955,2,2,2Z"
          fill="none"
          stroke="#878888"
          strokeWidth="0.972263"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eZPd5k6m38e8 {
    animation-name: eZPd5k6m38e8__m, eZPd5k6m38e8_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e8__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    10% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.046162');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eZPd5k6m38e8_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e9_to {
    animation: eZPd5k6m38e9_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e9_to__to {
    0% {
      transform: translate(832.121519px, -4.482748px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -116.228703px);
    }
    100% {
      transform: translate(731.9px, -116.228703px);
    }
  }
  #eZPd5k6m38e9_tr {
    animation: eZPd5k6m38e9_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e9_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(-75deg);
    }
    10% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e9_ts {
    animation: eZPd5k6m38e9_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e9_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e9 {
    animation: eZPd5k6m38e9_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e9_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e10_to {
    animation: eZPd5k6m38e10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e10_to__to {
    0% {
      transform: translate(826.798304px, -5.828881px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.728703px);
    }
    100% {
      transform: translate(731.9px, -122.728703px);
    }
  }
  #eZPd5k6m38e10_tr {
    animation: eZPd5k6m38e10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e10_ts {
    animation: eZPd5k6m38e10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e10_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e10 {
    animation: eZPd5k6m38e10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e10_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e11_to {
    animation: eZPd5k6m38e11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e11_to__to {
    0% {
      transform: translate(826.568884px, -6.23967px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.228703px);
    }
    100% {
      transform: translate(731.9px, -122.228703px);
    }
  }
  #eZPd5k6m38e11_tr {
    animation: eZPd5k6m38e11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e11 {
    animation: eZPd5k6m38e11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e13 {
    animation-name: eZPd5k6m38e13__m, eZPd5k6m38e13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eZPd5k6m38e13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e14_to {
    animation: eZPd5k6m38e14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e14_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    25% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -115.228703px);
    }
    100% {
      transform: translate(731.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e14_tr {
    animation: eZPd5k6m38e14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(-75deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e14_ts {
    animation: eZPd5k6m38e14_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e14_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e14 {
    animation: eZPd5k6m38e14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    68.75% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e15_to {
    animation: eZPd5k6m38e15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e15_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    25% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.728703px);
    }
    100% {
      transform: translate(731.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e15_tr {
    animation: eZPd5k6m38e15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e15_ts {
    animation: eZPd5k6m38e15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e15 {
    animation: eZPd5k6m38e15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.9;
    }
    68.75% {
      opacity: 0.9;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e16_to {
    animation: eZPd5k6m38e16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e16_to__to {
    0% {
      transform: translate(917.798427px, 137.771297px);
    }
    25% {
      transform: translate(917.798427px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.228703px);
    }
    100% {
      transform: translate(731.9px, -120.228703px);
    }
  }
  #eZPd5k6m38e16_tr {
    animation: eZPd5k6m38e16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e16 {
    animation: eZPd5k6m38e16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e16_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e18 {
    animation-name: eZPd5k6m38e18__m, eZPd5k6m38e18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e18__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eZPd5k6m38e18_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e19_to {
    animation: eZPd5k6m38e19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e19_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    75% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(832.121519px, -4.482748px);
    }
  }
  #eZPd5k6m38e19_tr {
    animation: eZPd5k6m38e19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eZPd5k6m38e19_ts {
    animation: eZPd5k6m38e19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e19_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eZPd5k6m38e19 {
    animation: eZPd5k6m38e19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e20_to {
    animation: eZPd5k6m38e20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e20_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    75% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eZPd5k6m38e20_tr {
    animation: eZPd5k6m38e20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eZPd5k6m38e20_ts {
    animation: eZPd5k6m38e20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eZPd5k6m38e20 {
    animation: eZPd5k6m38e20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e20_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e21_to {
    animation: eZPd5k6m38e21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e21_to__to {
    0% {
      transform: translate(917.798427px, 140.771297px);
    }
    75% {
      transform: translate(917.798427px, 140.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eZPd5k6m38e21_tr {
    animation: eZPd5k6m38e21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eZPd5k6m38e21 {
    animation: eZPd5k6m38e21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e21_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e27 {
    animation-name: eZPd5k6m38e27__m, eZPd5k6m38e27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e27__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eZPd5k6m38e27_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e28_to {
    animation: eZPd5k6m38e28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e28_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    12.5% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -116.228703px);
    }
    100% {
      transform: translate(693.9px, -116.228703px);
    }
  }
  #eZPd5k6m38e28_tr {
    animation: eZPd5k6m38e28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(-73deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e28_ts {
    animation: eZPd5k6m38e28_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e28_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e28 {
    animation: eZPd5k6m38e28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e28_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.8;
    }
    47.5% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e29_to {
    animation: eZPd5k6m38e29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e29_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    12.5% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e29_tr {
    animation: eZPd5k6m38e29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e29_ts {
    animation: eZPd5k6m38e29_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e29_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(1, 1);
    }
    35% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e29 {
    animation: eZPd5k6m38e29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e29_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.9;
    }
    47.5% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e30_to {
    animation: eZPd5k6m38e30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e30_to__to {
    0% {
      transform: translate(846.5px, 51.771297px);
    }
    12.5% {
      transform: translate(846.5px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eZPd5k6m38e30_tr {
    animation: eZPd5k6m38e30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e30 {
    animation: eZPd5k6m38e30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e30_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e32 {
    animation-name: eZPd5k6m38e32__m, eZPd5k6m38e32_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e32__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eZPd5k6m38e32_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e33_to {
    animation: eZPd5k6m38e33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e33_to__to {
    0% {
      transform: translate(846.5px, 55.771297px);
    }
    51.25% {
      transform: translate(846.5px, 55.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(846.798427px, 19.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(836.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(704.801035px, -24.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(695.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -115.228703px);
    }
    100% {
      transform: translate(693.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e33_tr {
    animation: eZPd5k6m38e33_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e33_ts {
    animation: eZPd5k6m38e33_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e33_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e33 {
    animation: eZPd5k6m38e33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e33_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.8;
    }
    86.25% {
      opacity: 0.8;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e34_to {
    animation: eZPd5k6m38e34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e34_to__to {
    0% {
      transform: translate(846.5px, 49.271297px);
    }
    51.25% {
      transform: translate(846.5px, 49.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eZPd5k6m38e34_tr {
    animation: eZPd5k6m38e34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e34_ts {
    animation: eZPd5k6m38e34_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e34_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    60% {
      transform: scale(1, 1);
    }
    72.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e34 {
    animation: eZPd5k6m38e34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e34_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.9;
    }
    86.25% {
      opacity: 0.9;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e35_to {
    animation: eZPd5k6m38e35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e35_to__to {
    0% {
      transform: translate(846.5px, 50.771297px);
    }
    51.25% {
      transform: translate(846.5px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eZPd5k6m38e35_tr {
    animation: eZPd5k6m38e35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e35 {
    animation: eZPd5k6m38e35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e35_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e42 {
    animation-name: eZPd5k6m38e42__m, eZPd5k6m38e42_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e42__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes eZPd5k6m38e42_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e43_to {
    animation: eZPd5k6m38e43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e43_to__to {
    0% {
      transform: translate(656.9px, -33.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -114.228703px);
    }
    100% {
      transform: translate(656.9px, -114.228703px);
    }
  }
  #eZPd5k6m38e43 {
    animation: eZPd5k6m38e43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e43_c_o {
    0% {
      opacity: 0.8;
    }
    10% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e44_to {
    animation: eZPd5k6m38e44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e44_to__to {
    0% {
      transform: translate(656.9px, -37.443085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eZPd5k6m38e44 {
    animation: eZPd5k6m38e44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e44_c_o {
    0% {
      opacity: 0.9;
    }
    10% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e45_to {
    animation: eZPd5k6m38e45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e45_to__to {
    0% {
      transform: translate(656.9px, -38.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.228703px);
    }
    100% {
      transform: translate(656.9px, -119.228703px);
    }
  }
  #eZPd5k6m38e45 {
    animation: eZPd5k6m38e45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e45_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e47 {
    animation-name: eZPd5k6m38e47__m, eZPd5k6m38e47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e47__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    60% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes eZPd5k6m38e47_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e48_to {
    animation: eZPd5k6m38e48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e48_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -115.228703px);
    }
    100% {
      transform: translate(656.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e48_tr {
    animation: eZPd5k6m38e48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(-70deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e48_ts {
    animation: eZPd5k6m38e48_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e48_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e48 {
    animation: eZPd5k6m38e48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e48_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.8;
    }
    62.5% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e49_to {
    animation: eZPd5k6m38e49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e49_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    22.5% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eZPd5k6m38e49_tr {
    animation: eZPd5k6m38e49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e49_ts {
    animation: eZPd5k6m38e49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e49 {
    animation: eZPd5k6m38e49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e49_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.9;
    }
    62.5% {
      opacity: 0.9;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e50_to {
    animation: eZPd5k6m38e50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e50_to__to {
    0% {
      transform: translate(786.798427px, 138.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #eZPd5k6m38e50_tr {
    animation: eZPd5k6m38e50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e50 {
    animation: eZPd5k6m38e50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e50_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e52 {
    animation-name: eZPd5k6m38e52__m, eZPd5k6m38e52_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e52__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes eZPd5k6m38e52_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e53_to {
    animation: eZPd5k6m38e53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e53_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -34.549858px);
    }
  }
  #eZPd5k6m38e53_tr {
    animation: eZPd5k6m38e53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(-70deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e53_ts {
    animation: eZPd5k6m38e53_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e53_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e53 {
    animation: eZPd5k6m38e53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e53_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eZPd5k6m38e54_to {
    animation: eZPd5k6m38e54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e54_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    68.75% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -39.443085px);
    }
  }
  #eZPd5k6m38e54_tr {
    animation: eZPd5k6m38e54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e54_ts {
    animation: eZPd5k6m38e54_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e54_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e54 {
    animation: eZPd5k6m38e54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e54_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eZPd5k6m38e55_to {
    animation: eZPd5k6m38e55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e55_to__to {
    0% {
      transform: translate(786.798427px, 136.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.549858px);
    }
  }
  #eZPd5k6m38e55_tr {
    animation: eZPd5k6m38e55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e55 {
    animation: eZPd5k6m38e55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e55_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e61 {
    animation-name: eZPd5k6m38e61__m, eZPd5k6m38e61_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e61__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eZPd5k6m38e61_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e62_to {
    animation: eZPd5k6m38e62_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e62_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -115.228703px);
    }
    100% {
      transform: translate(620.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e62_tr {
    animation: eZPd5k6m38e62_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e62_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(-68deg);
    }
    23.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e62_ts {
    animation: eZPd5k6m38e62_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e62_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e62 {
    animation: eZPd5k6m38e62_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e62_c_o {
    0% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e63_to {
    animation: eZPd5k6m38e63_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e63_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -121.728703px);
    }
    100% {
      transform: translate(620.9px, -121.728703px);
    }
  }
  #eZPd5k6m38e63_tr {
    animation: eZPd5k6m38e63_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e63_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e63_ts {
    animation: eZPd5k6m38e63_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e63_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e63 {
    animation: eZPd5k6m38e63_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e63_c_o {
    0% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e64_to {
    animation: eZPd5k6m38e64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e64_to__to {
    0% {
      transform: translate(715.798427px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -122.228703px);
    }
    100% {
      transform: translate(620.9px, -122.228703px);
    }
  }
  #eZPd5k6m38e64_tr {
    animation: eZPd5k6m38e64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e64 {
    animation: eZPd5k6m38e64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e64_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e66 {
    animation-name: eZPd5k6m38e66__m, eZPd5k6m38e66_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e66__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eZPd5k6m38e66_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e67_to {
    animation: eZPd5k6m38e67_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e67_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -114.228703px);
    }
    100% {
      transform: translate(620.9px, -114.228703px);
    }
  }
  #eZPd5k6m38e67_tr {
    animation: eZPd5k6m38e67_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(-68deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e67_ts {
    animation: eZPd5k6m38e67_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e67_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e67 {
    animation: eZPd5k6m38e67_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e67_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e68_to {
    animation: eZPd5k6m38e68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e68_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -119.728703px);
    }
    100% {
      transform: translate(620.9px, -119.728703px);
    }
  }
  #eZPd5k6m38e68_tr {
    animation: eZPd5k6m38e68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e68_ts {
    animation: eZPd5k6m38e68_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e68_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e68 {
    animation: eZPd5k6m38e68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e68_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e69_to {
    animation: eZPd5k6m38e69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e69_to__to {
    0% {
      transform: translate(715.798427px, 50.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(705.674548px, 12.059155px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(620.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -35.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(620.9px, -116.228703px);
    }
    100% {
      transform: translate(620.9px, -116.228703px);
    }
  }
  #eZPd5k6m38e69_tr {
    animation: eZPd5k6m38e69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e69 {
    animation: eZPd5k6m38e69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e69_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e76 {
    animation-name: eZPd5k6m38e76__m, eZPd5k6m38e76_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e76__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    27.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    32.5% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eZPd5k6m38e76_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e77_to {
    animation: eZPd5k6m38e77_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e77_to__to {
    0% {
      transform: translate(656.203897px, 77.481243px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -109.228703px);
    }
    100% {
      transform: translate(582.9px, -109.228703px);
    }
  }
  #eZPd5k6m38e77_tr {
    animation: eZPd5k6m38e77_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e77_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(-60deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e77_ts {
    animation: eZPd5k6m38e77_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e77_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e77 {
    animation: eZPd5k6m38e77_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e77_c_o {
    0% {
      opacity: 0.8;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e78_to {
    animation: eZPd5k6m38e78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e78_to__to {
    0% {
      transform: translate(656.203897px, 72.170302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #eZPd5k6m38e78_tr {
    animation: eZPd5k6m38e78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e78_ts {
    animation: eZPd5k6m38e78_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e78_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    7.5% {
      transform: scale(1, 1);
    }
    20% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e78 {
    animation: eZPd5k6m38e78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e78_c_o {
    0% {
      opacity: 0.9;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e79_to {
    animation: eZPd5k6m38e79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e79_to__to {
    0% {
      transform: translate(656.411986px, 71.237316px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e79_tr {
    animation: eZPd5k6m38e79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e79 {
    animation: eZPd5k6m38e79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e79_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e81 {
    animation-name: eZPd5k6m38e81__m, eZPd5k6m38e81_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e81__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    72.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eZPd5k6m38e81_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e82_to {
    animation: eZPd5k6m38e82_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e82_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(590.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(584.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -111.228703px);
    }
    100% {
      transform: translate(583.9px, -111.228703px);
    }
  }
  #eZPd5k6m38e82_tr {
    animation: eZPd5k6m38e82_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e82_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(-60deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e82_ts {
    animation: eZPd5k6m38e82_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e82_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e82 {
    animation: eZPd5k6m38e82_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e82_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e83_to {
    animation: eZPd5k6m38e83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e83_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    37.5% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -116.728703px);
    }
    100% {
      transform: translate(583.9px, -116.728703px);
    }
  }
  #eZPd5k6m38e83_tr {
    animation: eZPd5k6m38e83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e83_ts {
    animation: eZPd5k6m38e83_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e83_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e83 {
    animation: eZPd5k6m38e83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e83_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e84_to {
    animation: eZPd5k6m38e84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e84_to__to {
    0% {
      transform: translate(656.798427px, 136.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(583.9px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(583.9px, -118.228703px);
    }
    100% {
      transform: translate(583.9px, -118.228703px);
    }
  }
  #eZPd5k6m38e84_tr {
    animation: eZPd5k6m38e84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e84 {
    animation: eZPd5k6m38e84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e84_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e86 {
    animation-name: eZPd5k6m38e86__m, eZPd5k6m38e86_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e86__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    93.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
  }
  @keyframes eZPd5k6m38e86_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e87_to {
    animation: eZPd5k6m38e87_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e87_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    90% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 77.481243px);
    }
  }
  #eZPd5k6m38e87 {
    animation: eZPd5k6m38e87_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e87_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e88_to {
    animation: eZPd5k6m38e88_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e88_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    90% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 72.170302px);
    }
  }
  #eZPd5k6m38e88 {
    animation: eZPd5k6m38e88_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e88_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.928571;
    }
  }
  #eZPd5k6m38e89_to {
    animation: eZPd5k6m38e89_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e89_to__to {
    0% {
      transform: translate(656.798427px, 135.771297px);
    }
    90% {
      transform: translate(656.798427px, 135.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(656.203897px, 70.481243px);
    }
  }
  #eZPd5k6m38e89 {
    animation: eZPd5k6m38e89_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e89_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e95 {
    animation-name: eZPd5k6m38e95__m, eZPd5k6m38e95_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e95__m {
    0% {
      d: path('M520.668,121.038711L520.668,121.681L480.969,76.7549L480.974,72.075139');
    }
    6.25% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes eZPd5k6m38e95_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    6.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e96_to {
    animation: eZPd5k6m38e96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e96_to__to {
    0% {
      transform: translate(545.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(545.9px, -114.228703px);
    }
    100% {
      transform: translate(545.9px, -114.228703px);
    }
  }
  #eZPd5k6m38e96 {
    animation: eZPd5k6m38e96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e96_c_o {
    0% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e97_to {
    animation: eZPd5k6m38e97_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e97_to__to {
    0% {
      transform: translate(545.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(545.9px, -121.728703px);
    }
    100% {
      transform: translate(545.9px, -121.728703px);
    }
  }
  #eZPd5k6m38e97 {
    animation: eZPd5k6m38e97_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e97_c_o {
    0% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e98_to {
    animation: eZPd5k6m38e98_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e98_to__to {
    0% {
      transform: translate(545.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(545.9px, -121.228703px);
    }
    100% {
      transform: translate(545.9px, -121.228703px);
    }
  }
  #eZPd5k6m38e98 {
    animation: eZPd5k6m38e98_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e98_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e100 {
    animation-name: eZPd5k6m38e100__m, eZPd5k6m38e100_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e100__m {
    0% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    21.25% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    31.25% {
      d: path('M520.668,138.079L520.668,121.681L520.096524,122.539067L520.900001,121.569396');
    }
    45% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    53.75% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes eZPd5k6m38e100_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e101_to {
    animation: eZPd5k6m38e101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e101_to__to {
    0% {
      transform: translate(584.798427px, 53.771297px);
    }
    21.25% {
      transform: translate(584.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    22.5% {
      transform: translate(585.831694px, 52.88972px);
    }
    30% {
      transform: translate(585.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(585.798427px, 20.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(582.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(550.801035px, -23px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(547.851035px, -25.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -114.228703px);
    }
    100% {
      transform: translate(545.9px, -114.228703px);
    }
  }
  #eZPd5k6m38e101_tr {
    animation: eZPd5k6m38e101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(0deg);
    }
    32.5% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(-45deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e101_ts {
    animation: eZPd5k6m38e101_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e101_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    31.25% {
      transform: scale(1, 1);
    }
    43.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e101 {
    animation: eZPd5k6m38e101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e101_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e102_to {
    animation: eZPd5k6m38e102_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e102_to__to {
    0% {
      transform: translate(584.798427px, 47.271297px);
    }
    21.25% {
      transform: translate(584.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    22.5% {
      transform: translate(585.4px, 46.489521px);
    }
    30% {
      transform: translate(585.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -119.728703px);
    }
    100% {
      transform: translate(545.9px, -119.728703px);
    }
  }
  #eZPd5k6m38e102_tr {
    animation: eZPd5k6m38e102_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e102_ts {
    animation: eZPd5k6m38e102_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e102_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    31.25% {
      transform: scale(1, 1);
    }
    43.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e102 {
    animation: eZPd5k6m38e102_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e102_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e103_to {
    animation: eZPd5k6m38e103_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e103_to__to {
    0% {
      transform: translate(584.798427px, 49.771297px);
    }
    21.25% {
      transform: translate(584.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    30% {
      transform: translate(585.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(579.798427px, 10.099627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.624298px, -26.300373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.851035px, -30.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -120.228703px);
    }
    100% {
      transform: translate(545.9px, -120.228703px);
    }
  }
  #eZPd5k6m38e103_tr {
    animation: eZPd5k6m38e103_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e103_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e103 {
    animation: eZPd5k6m38e103_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e103_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e105 {
    animation-name: eZPd5k6m38e105__m, eZPd5k6m38e105_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e105__m {
    0% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    75% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    85% {
      d: path('M520.668,138.079L520.668,121.681L520.096524,122.539067L520.900001,121.569396');
    }
    98.75% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    100% {
      d: path('M520.668,127.412333L520.668,121.681L480.969,76.7549L480.974,61.135146');
    }
  }
  @keyframes eZPd5k6m38e105_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e106_to {
    animation: eZPd5k6m38e106_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e106_to__to {
    0% {
      transform: translate(584.798427px, 53.771297px);
    }
    75% {
      transform: translate(584.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    76.25% {
      transform: translate(585.831694px, 52.88972px);
    }
    82.5% {
      transform: translate(585.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(585.798427px, 20.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(582.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(550.801035px, -23px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(547.851035px, -25.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(545.9px, -33.514307px);
    }
  }
  #eZPd5k6m38e106_tr {
    animation: eZPd5k6m38e106_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e106_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e106_ts {
    animation: eZPd5k6m38e106_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e106_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    97.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e106 {
    animation: eZPd5k6m38e106_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e106_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e107_to {
    animation: eZPd5k6m38e107_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e107_to__to {
    0% {
      transform: translate(584.798427px, 47.271297px);
    }
    75% {
      transform: translate(584.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    76.25% {
      transform: translate(585.4px, 46.489521px);
    }
    82.5% {
      transform: translate(585.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(545.9px, -38.280793px);
    }
  }
  #eZPd5k6m38e107_tr {
    animation: eZPd5k6m38e107_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e107_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e107_ts {
    animation: eZPd5k6m38e107_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e107_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    97.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e107 {
    animation: eZPd5k6m38e107_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e107_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.983333;
    }
  }
  #eZPd5k6m38e108_to {
    animation: eZPd5k6m38e108_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e108_to__to {
    0% {
      transform: translate(584.798427px, 48.771297px);
    }
    75% {
      transform: translate(584.798427px, 48.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(585.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(579.798427px, 10px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.124298px, -26.800373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(545.851035px, -30.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(545.9px, -38.962684px);
    }
  }
  #eZPd5k6m38e108_tr {
    animation: eZPd5k6m38e108_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e108_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e108 {
    animation: eZPd5k6m38e108_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e108_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e116 {
    animation-name: eZPd5k6m38e116__m, eZPd5k6m38e116_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e116__m {
    0% {
      d: path('M357.283,138.189L357.283,121.683L373.352591,106.771933L375.562039,103.483406');
    }
    7.5% {
      d: path('M357.293,122.419896L357.283,121.683L407.238,76.6992L407.900001,71.065917');
    }
    8.75% {
      d: path('M367.282,113.129698L367.274,112.540182L407.238,76.553141L407.9,63.316701');
    }
    13.75% {
      d: path('M407.238,75.968908L407.238,75.968908L407.238,75.968907L407.427143,17.282256');
    }
    18.75% {
      d: path('M407.238,75.676791L407.238,75.67679L407.238,75.67679L407.238,-4.231209');
    }
    100% {
      d: path('M407.238,75.676791L407.238,75.67679L407.238,75.67679L407.238,-4.231209');
    }
  }
  @keyframes eZPd5k6m38e116_c_o {
    0% {
      opacity: 0.8;
    }
    15% {
      opacity: 0.8;
    }
    17.5% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
  #eZPd5k6m38e117_to {
    animation: eZPd5k6m38e117_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e117_to__to {
    0% {
      transform: translate(445.052153px, -3.536834px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(467.4px, -23.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(470.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.9px, -114.228703px);
    }
    100% {
      transform: translate(472.9px, -114.228703px);
    }
  }
  #eZPd5k6m38e117_tr {
    animation: eZPd5k6m38e117_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e117_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(45deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e117_ts {
    animation: eZPd5k6m38e117_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e117_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    6.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e117 {
    animation: eZPd5k6m38e117_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e117_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e118_to {
    animation: eZPd5k6m38e118_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e118_to__to {
    0% {
      transform: translate(449.063292px, -7.316354px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(472.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.9px, -120.728703px);
    }
    100% {
      transform: translate(472.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e118_tr {
    animation: eZPd5k6m38e118_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e118_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e118_ts {
    animation: eZPd5k6m38e118_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e118_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    6.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e118 {
    animation: eZPd5k6m38e118_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e118_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e119_to {
    animation: eZPd5k6m38e119_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e119_to__to {
    0% {
      transform: translate(450.624298px, -9.536834px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.4px, -26.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(473.011987px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.9px, -117.228703px);
    }
    100% {
      transform: translate(472.9px, -117.228703px);
    }
  }
  #eZPd5k6m38e119_tr {
    animation: eZPd5k6m38e119_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e119_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e119 {
    animation: eZPd5k6m38e119_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e119_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e121 {
    animation-name: eZPd5k6m38e121__m, eZPd5k6m38e121_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e121__m {
    0% {
      d: path('M357.283,138.189L357.283,137.850493L357.293,137.850493L357.293,138.189');
    }
    38.75% {
      d: path('M357.283,138.189L357.283,137.850493L357.293,137.850493L357.293,138.189');
    }
    42.5% {
      d: path('M357.283,138.189L357.283,121.683L359.798427,118.801026L362.626854,116.450401');
    }
    56.25% {
      d: path('M357.283,121.683L357.283,121.683L407.238,76.6992L407.265249,74.260509');
    }
    57.5% {
      d: path('M365.608833,114.1857L365.608833,114.155782L407.238,76.571399L407.344593,64.549044');
    }
    61.25% {
      d: path('M390.586333,91.693801L390.586333,91.574129L407.238,76.187995L407.582624,7.41465');
    }
    63.75% {
      d: path('M407.238,76.699202L407.238,76.519693L407.238,75.932392L407.741312,-11.00828');
    }
    66.25% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
    100% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
  }
  @keyframes eZPd5k6m38e121_c_o {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 0;
    }
    36.25% {
      opacity: 0.8;
    }
    61.25% {
      opacity: 0.8;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e122_to {
    animation: eZPd5k6m38e122_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e122_to__to {
    0% {
      transform: translate(422.798427px, 53.771297px);
    }
    32.5% {
      transform: translate(422.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    41.25% {
      transform: translate(422.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(422.798427px, 21px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(425.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(467.4px, -23.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(470.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.9px, -114.228703px);
    }
    100% {
      transform: translate(472.9px, -114.228703px);
    }
  }
  #eZPd5k6m38e122_tr {
    animation: eZPd5k6m38e122_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e122_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    43.75% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(45deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e122_ts {
    animation: eZPd5k6m38e122_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e122_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    42.5% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e122 {
    animation: eZPd5k6m38e122_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e122_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.8;
    }
    65% {
      opacity: 0.8;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e123_to {
    animation: eZPd5k6m38e123_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e123_to__to {
    0% {
      transform: translate(422.798427px, 47.271297px);
    }
    32.5% {
      transform: translate(422.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    41.25% {
      transform: translate(422.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.9px, -120.728703px);
    }
    100% {
      transform: translate(472.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e123_tr {
    animation: eZPd5k6m38e123_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e123_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e123_ts {
    animation: eZPd5k6m38e123_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e123_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    42.5% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e123 {
    animation: eZPd5k6m38e123_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e123_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.9;
    }
    65% {
      opacity: 0.9;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e124_to {
    animation: eZPd5k6m38e124_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e124_to__to {
    0% {
      transform: translate(422.798427px, 48.771297px);
    }
    32.5% {
      transform: translate(422.798427px, 48.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    41.25% {
      transform: translate(422.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(425.798427px, 15px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(431.124298px, 8.699627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(471.4px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.9px, -121.228703px);
    }
    100% {
      transform: translate(472.9px, -121.228703px);
    }
  }
  #eZPd5k6m38e124_tr {
    animation: eZPd5k6m38e124_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e124_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e124 {
    animation: eZPd5k6m38e124_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e124_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e126 {
    animation-name: eZPd5k6m38e126__m, eZPd5k6m38e126_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e126__m {
    0% {
      d: path('M357.283,138.189L357.283,139.328493L357.293,137.850493L357.283,137.850493');
    }
    86.25% {
      d: path('M357.283,138.189L357.283,139.328493L357.293,137.850493L357.283,137.850493');
    }
    90% {
      d: path('M357.283,138.189L357.283,121.683L359.798427,118.801026L362.626854,116.450401');
    }
    98.75% {
      d: path('M357.283,138.189L357.283,121.683L378.447064,102.839971L380.992649,101.014044');
    }
    100% {
      d: path('M357.283,138.189L357.283,121.683L364.111115,114.973587L382.557334,99.694926');
    }
  }
  @keyframes eZPd5k6m38e126_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eZPd5k6m38e127_to {
    animation: eZPd5k6m38e127_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e127_to__to {
    0% {
      transform: translate(422.798427px, 53.771297px);
    }
    81.25% {
      transform: translate(422.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    88.75% {
      transform: translate(422.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(422.798427px, 21px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(425.798427px, 14px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(445.052153px, -3.536834px);
    }
  }
  #eZPd5k6m38e127_tr {
    animation: eZPd5k6m38e127_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e127_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eZPd5k6m38e127_ts {
    animation: eZPd5k6m38e127_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e127_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    90% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eZPd5k6m38e127 {
    animation: eZPd5k6m38e127_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e127_c_o {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eZPd5k6m38e128_to {
    animation: eZPd5k6m38e128_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e128_to__to {
    0% {
      transform: translate(422.798427px, 47.271297px);
    }
    81.25% {
      transform: translate(422.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    88.75% {
      transform: translate(422.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(449.063292px, -7.316354px);
    }
  }
  #eZPd5k6m38e128_tr {
    animation: eZPd5k6m38e128_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e128_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eZPd5k6m38e128_ts {
    animation: eZPd5k6m38e128_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e128_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    90% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eZPd5k6m38e128 {
    animation: eZPd5k6m38e128_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e128_c_o {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eZPd5k6m38e129_to {
    animation: eZPd5k6m38e129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e129_to__to {
    0% {
      transform: translate(422.798427px, 48.771297px);
    }
    81.25% {
      transform: translate(422.798427px, 48.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    88.75% {
      transform: translate(422.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(425.798427px, 15px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(430.798427px, 9px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(449.052153px, -7.536834px);
    }
  }
  #eZPd5k6m38e129_tr {
    animation: eZPd5k6m38e129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eZPd5k6m38e129 {
    animation: eZPd5k6m38e129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e129_c_o {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e136 {
    animation-name: eZPd5k6m38e136__m, eZPd5k6m38e136_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e136__m {
    0% {
      d: path('M292.146,232.836923L292.146,177.601649L292.254926,178.81536L292.254926,179.196852');
    }
    5% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    18.75% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    23.75% {
      d: path('M316.216154,108.23846L316.216154,108.04326L370.374,76.7761L370.374,20.292588');
    }
    30% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    35% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eZPd5k6m38e136_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    26.25% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e137_to {
    animation: eZPd5k6m38e137_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e137_to__to {
    0% {
      transform: translate(357.5px, 74.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eZPd5k6m38e137_tr {
    animation: eZPd5k6m38e137_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e137_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(65deg);
    }
    20% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e137_ts {
    animation: eZPd5k6m38e137_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e137_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e137 {
    animation: eZPd5k6m38e137_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e137_c_o {
    0% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0.8;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e138_to {
    animation: eZPd5k6m38e138_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e138_to__to {
    0% {
      transform: translate(357.5px, 69.792183px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -121.728703px);
    }
    100% {
      transform: translate(435.7px, -121.728703px);
    }
  }
  #eZPd5k6m38e138_tr {
    animation: eZPd5k6m38e138_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e138_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e138_ts {
    animation: eZPd5k6m38e138_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e138_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e138 {
    animation: eZPd5k6m38e138_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e138_c_o {
    0% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0.9;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e139_to {
    animation: eZPd5k6m38e139_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e139_to__to {
    0% {
      transform: translate(357.5px, 68.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(433.502724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(435.7px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eZPd5k6m38e139_tr {
    animation: eZPd5k6m38e139_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e139_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e139 {
    animation: eZPd5k6m38e139_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e139_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e141 {
    animation-name: eZPd5k6m38e141__m, eZPd5k6m38e141_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e141__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    37.5% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    43.75% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    53.75% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    67.5% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    78.75% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    83.75% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eZPd5k6m38e141_c_o {
    0% {
      opacity: 0;
    }
    53.75% {
      opacity: 0;
    }
    61.25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e142_to {
    animation: eZPd5k6m38e142_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e142_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    37.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(362.798427px, 12.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(428.801035px, -24.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(433.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -115.228703px);
    }
    100% {
      transform: translate(435.7px, -115.228703px);
    }
  }
  #eZPd5k6m38e142_tr {
    animation: eZPd5k6m38e142_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e142_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(65deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e142_ts {
    animation: eZPd5k6m38e142_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e142_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e142 {
    animation: eZPd5k6m38e142_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e142_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e143_to {
    animation: eZPd5k6m38e143_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e143_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    37.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eZPd5k6m38e143_tr {
    animation: eZPd5k6m38e143_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e143_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e143_ts {
    animation: eZPd5k6m38e143_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e143_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    53.75% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e143 {
    animation: eZPd5k6m38e143_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e143_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0.9;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e144_to {
    animation: eZPd5k6m38e144_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e144_to__to {
    0% {
      transform: translate(357.5px, 138.771297px);
    }
    37.5% {
      transform: translate(357.5px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    52.5% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    53.75% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    55% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    82.5% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eZPd5k6m38e144_tr {
    animation: eZPd5k6m38e144_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e144_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e144 {
    animation: eZPd5k6m38e144_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e144_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e146 {
    animation-name: eZPd5k6m38e146__m, eZPd5k6m38e146_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e146__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    88.75% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    95% {
      d: path('M292.146,245.913846L292.146,243.521298L292.363848,245.008315L292.363849,245.771298');
    }
    100% {
      d: path('M292.146,237.836923L292.146,182.601649L292.254926,183.81536L292.254926,184.196852');
    }
  }
  @keyframes eZPd5k6m38e146_c_o {
    0% {
      opacity: 0;
    }
    87.5% {
      opacity: 0;
    }
    88.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e147_to {
    animation: eZPd5k6m38e147_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e147_to__to {
    0% {
      transform: translate(357.5px, 143.771297px);
    }
    92.5% {
      transform: translate(357.5px, 143.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 74.508595px);
    }
  }
  #eZPd5k6m38e147 {
    animation: eZPd5k6m38e147_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e147_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e148_to {
    animation: eZPd5k6m38e148_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e148_to__to {
    0% {
      transform: translate(357.5px, 137.271297px);
    }
    92.5% {
      transform: translate(357.5px, 137.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 69.792183px);
    }
  }
  #eZPd5k6m38e148 {
    animation: eZPd5k6m38e148_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e148_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e149_to {
    animation: eZPd5k6m38e149_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e149_to__to {
    0% {
      transform: translate(357.5px, 136.771297px);
    }
    92.5% {
      transform: translate(357.5px, 136.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(357.5px, 68.508595px);
    }
  }
  #eZPd5k6m38e149 {
    animation: eZPd5k6m38e149_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e149_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e155 {
    animation-name: eZPd5k6m38e155__m, eZPd5k6m38e155_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e155__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    41.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eZPd5k6m38e155_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e156_to {
    animation: eZPd5k6m38e156_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e156_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -115.228703px);
    }
    100% {
      transform: translate(398.699999px, -115.228703px);
    }
  }
  #eZPd5k6m38e156_tr {
    animation: eZPd5k6m38e156_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e156_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(68deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e156_ts {
    animation: eZPd5k6m38e156_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e156_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e156 {
    animation: eZPd5k6m38e156_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e156_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.8;
    }
    53.75% {
      opacity: 0.8;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e157_to {
    animation: eZPd5k6m38e157_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e157_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    18.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eZPd5k6m38e157_tr {
    animation: eZPd5k6m38e157_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e157_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e157_ts {
    animation: eZPd5k6m38e157_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e157_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e157 {
    animation: eZPd5k6m38e157_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e157_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.9;
    }
    53.75% {
      opacity: 0.9;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e158_to {
    animation: eZPd5k6m38e158_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e158_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eZPd5k6m38e158_tr {
    animation: eZPd5k6m38e158_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e158_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e158 {
    animation: eZPd5k6m38e158_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e158_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e160 {
    animation-name: eZPd5k6m38e160__m, eZPd5k6m38e160_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e160__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eZPd5k6m38e160_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e161_to {
    animation: eZPd5k6m38e161_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e161_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -114.228703px);
    }
    100% {
      transform: translate(398.699999px, -114.228703px);
    }
  }
  #eZPd5k6m38e161_tr {
    animation: eZPd5k6m38e161_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e161_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(68deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e161_ts {
    animation: eZPd5k6m38e161_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e161_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e161 {
    animation: eZPd5k6m38e161_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e161_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e162_to {
    animation: eZPd5k6m38e162_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e162_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    58.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eZPd5k6m38e162_tr {
    animation: eZPd5k6m38e162_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e162_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e162_ts {
    animation: eZPd5k6m38e162_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e162_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e162 {
    animation: eZPd5k6m38e162_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e162_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e163_to {
    animation: eZPd5k6m38e163_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e163_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.228703px);
    }
    100% {
      transform: translate(398.699999px, -119.228703px);
    }
  }
  #eZPd5k6m38e163_tr {
    animation: eZPd5k6m38e163_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e163_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e163 {
    animation: eZPd5k6m38e163_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e163_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e170 {
    animation-name: eZPd5k6m38e170__m, eZPd5k6m38e170_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e170__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    11.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    22.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    42.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eZPd5k6m38e170_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e171_to {
    animation: eZPd5k6m38e171_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e171_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e171_tr {
    animation: eZPd5k6m38e171_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e171_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(72deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e171_ts {
    animation: eZPd5k6m38e171_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e171_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e171 {
    animation: eZPd5k6m38e171_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e171_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e172_to {
    animation: eZPd5k6m38e172_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e172_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    6.25% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e172_tr {
    animation: eZPd5k6m38e172_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e172_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e172_ts {
    animation: eZPd5k6m38e172_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e172_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e172 {
    animation: eZPd5k6m38e172_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e172_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0.9;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e173_to {
    animation: eZPd5k6m38e173_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e173_to__to {
    0% {
      transform: translate(226.798427px, 141.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 141.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #eZPd5k6m38e173_tr {
    animation: eZPd5k6m38e173_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e173_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e173 {
    animation: eZPd5k6m38e173_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e173_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e175 {
    animation-name: eZPd5k6m38e175__m, eZPd5k6m38e175_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e175__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eZPd5k6m38e175_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e176_to {
    animation: eZPd5k6m38e176_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e176_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e176_tr {
    animation: eZPd5k6m38e176_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e176_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(72deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e176_ts {
    animation: eZPd5k6m38e176_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e176_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e176 {
    animation: eZPd5k6m38e176_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e176_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.8;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e177_to {
    animation: eZPd5k6m38e177_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e177_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    52.5% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #eZPd5k6m38e177_tr {
    animation: eZPd5k6m38e177_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e177_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e177_ts {
    animation: eZPd5k6m38e177_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e177_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e177 {
    animation: eZPd5k6m38e177_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e177_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.9;
    }
    93.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e178_to {
    animation: eZPd5k6m38e178_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e178_to__to {
    0% {
      transform: translate(226.798427px, 138.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #eZPd5k6m38e178_tr {
    animation: eZPd5k6m38e178_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e178_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e178 {
    animation: eZPd5k6m38e178_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e178_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e184 {
    animation-name: eZPd5k6m38e184__m, eZPd5k6m38e184_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e184__m {
    0% {
      d: path(
        'M96.7109,145.439636L96.7109,127.684958L96.812149,127.562584C96.812149,127.562584,96.894479,127.812584,96.894479,127.812584'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    21.25% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,33.289957,259.817001,33.289957'
      );
    }
    26.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,0.34375,259.817,0.34375');
    }
  }
  @keyframes eZPd5k6m38e184_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e185_to {
    animation: eZPd5k6m38e185_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e185_to__to {
    0% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e185_tr {
    animation: eZPd5k6m38e185_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e185_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e185_ts {
    animation: eZPd5k6m38e185_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e185_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e185 {
    animation: eZPd5k6m38e185_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e185_c_o {
    0% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e186_to {
    animation: eZPd5k6m38e186_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e186_to__to {
    0% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    16.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    30% {
      transform: translate(324.9px, -121.728703px);
    }
    100% {
      transform: translate(324.9px, -121.728703px);
    }
  }
  #eZPd5k6m38e186_tr {
    animation: eZPd5k6m38e186_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e186_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e186_ts {
    animation: eZPd5k6m38e186_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e186_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    1.25% {
      transform: scale(1, 1);
    }
    16.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e186 {
    animation: eZPd5k6m38e186_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e186_c_o {
    0% {
      opacity: 0.9;
    }
    30% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e187_to {
    animation: eZPd5k6m38e187_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e187_to__to {
    0% {
      transform: translate(161.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    2.5% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    15% {
      transform: translate(309.773572px, -24.042276px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    18.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    22.5% {
      transform: translate(324.9px, -86.545293px);
      animation-timing-function: cubic-bezier(0.280804, 0.399795, 0.604922, 0.750053);
    }
    26.25% {
      transform: translate(324.9px, -111.961266px);
      animation-timing-function: cubic-bezier(0.291118, 0.499369, 0.620764, 0.851063);
    }
    30% {
      transform: translate(324.9px, -122.228703px);
    }
    100% {
      transform: translate(324.9px, -122.228703px);
    }
  }
  #eZPd5k6m38e187_tr {
    animation: eZPd5k6m38e187_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e187_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e187 {
    animation: eZPd5k6m38e187_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e187_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e189 {
    animation-name: eZPd5k6m38e189__m, eZPd5k6m38e189_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e189__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L246.232119,80.4759C246.232119,80.4759,258.801035,77.532604,258.801035,77.532604'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,21.289957,259.817001,21.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes eZPd5k6m38e189_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e190_to {
    animation: eZPd5k6m38e190_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e190_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(161.798427px, 19.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(174.798427px, 13.733345px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(317.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(322.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -115.228703px);
    }
    100% {
      transform: translate(324.9px, -115.228703px);
    }
  }
  #eZPd5k6m38e190_tr {
    animation: eZPd5k6m38e190_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e190_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(73deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e190_ts {
    animation: eZPd5k6m38e190_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e190_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e190 {
    animation: eZPd5k6m38e190_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e190_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.8;
    }
    81.25% {
      opacity: 0.8;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e191_to {
    animation: eZPd5k6m38e191_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e191_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    42.5% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(165.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.3352px, 12.30883px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e191_tr {
    animation: eZPd5k6m38e191_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e191_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e191_ts {
    animation: eZPd5k6m38e191_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e191_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e191 {
    animation: eZPd5k6m38e191_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e191_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    47.5% {
      opacity: 0.9;
    }
    81.25% {
      opacity: 0.9;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e192_to {
    animation: eZPd5k6m38e192_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e192_to__to {
    0% {
      transform: translate(161.798427px, 49.771297px);
    }
    42.5% {
      transform: translate(161.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(179.798427px, 12.157724px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(191.832868px, 8.675763px);
    }
    58.75% {
      transform: translate(231.488924px, -2.180118px);
    }
    66.25% {
      transform: translate(323.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    68.75% {
      transform: translate(324.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eZPd5k6m38e192_tr {
    animation: eZPd5k6m38e192_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e192_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e192 {
    animation: eZPd5k6m38e192_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e192_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e194 {
    animation-name: eZPd5k6m38e194__m, eZPd5k6m38e194_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e194__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eZPd5k6m38e194_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e195_to {
    animation: eZPd5k6m38e195_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e195_to__to {
    0% {
      transform: translate(161.798427px, 53.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 38.016262px);
    }
  }
  #eZPd5k6m38e195 {
    animation: eZPd5k6m38e195_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e195_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e196_to {
    animation: eZPd5k6m38e196_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e196_to__to {
    0% {
      transform: translate(161.798427px, 47.271297px);
    }
    96.25% {
      transform: translate(161.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 33.299851px);
    }
  }
  #eZPd5k6m38e196 {
    animation: eZPd5k6m38e196_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e196_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e197_to {
    animation: eZPd5k6m38e197_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e197_to__to {
    0% {
      transform: translate(161.798427px, 50.771297px);
    }
    96.25% {
      transform: translate(161.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(161.798427px, 34.016262px);
    }
  }
  #eZPd5k6m38e197 {
    animation: eZPd5k6m38e197_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e197_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZPd5k6m38e204 {
    animation-name: eZPd5k6m38e204__m, eZPd5k6m38e204_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e204__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.920607,76.685694');
    }
    51.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eZPd5k6m38e204_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e205_to {
    animation: eZPd5k6m38e205_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e205_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    11.25% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eZPd5k6m38e205_tr {
    animation: eZPd5k6m38e205_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e205_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(75deg);
    }
    43.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e205_ts {
    animation: eZPd5k6m38e205_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e205_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e205 {
    animation: eZPd5k6m38e205_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e205_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e206_to {
    animation: eZPd5k6m38e206_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e206_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e206_tr {
    animation: eZPd5k6m38e206_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e206_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e206_ts {
    animation: eZPd5k6m38e206_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e206_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e206 {
    animation: eZPd5k6m38e206_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e206_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e207_to {
    animation: eZPd5k6m38e207_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e207_to__to {
    0% {
      transform: translate(96.798427px, 142.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 142.271297px);
    }
    17.5% {
      transform: translate(96.748252px, 116.509555px);
    }
    18.75% {
      transform: translate(96.738217px, 108.957207px);
    }
    21.25% {
      transform: translate(96.718147px, 82.185843px);
    }
    26.25% {
      transform: translate(96.678007px, 22.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    33.75% {
      transform: translate(174.541791px, -1.63208px);
    }
    41.25% {
      transform: translate(282.833827px, -26.93208px);
    }
    42.5% {
      transform: translate(287.711987px, -31.028703px);
    }
    43.75% {
      transform: translate(287.814424px, -38.440824px);
    }
    45% {
      transform: translate(287.91686px, -53.852945px);
    }
    47.5% {
      transform: translate(287.913114px, -81.677187px);
    }
    50% {
      transform: translate(287.909367px, -103.50143px);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e207_tr {
    animation: eZPd5k6m38e207_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e207_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e207 {
    animation: eZPd5k6m38e207_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e207_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e209 {
    animation-name: eZPd5k6m38e209__m, eZPd5k6m38e209_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZPd5k6m38e209__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.851035,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eZPd5k6m38e209_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e210_to {
    animation: eZPd5k6m38e210_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e210_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eZPd5k6m38e210_tr {
    animation: eZPd5k6m38e210_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e210_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(75deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e210_ts {
    animation: eZPd5k6m38e210_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e210_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e210 {
    animation: eZPd5k6m38e210_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e210_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e211_to {
    animation: eZPd5k6m38e211_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e211_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    57.5% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eZPd5k6m38e211_tr {
    animation: eZPd5k6m38e211_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e211_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e211_ts {
    animation: eZPd5k6m38e211_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e211_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eZPd5k6m38e211 {
    animation: eZPd5k6m38e211_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e211_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZPd5k6m38e212_to {
    animation: eZPd5k6m38e212_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e212_to__to {
    0% {
      transform: translate(96.798427px, 139.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 139.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eZPd5k6m38e212_tr {
    animation: eZPd5k6m38e212_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e212_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZPd5k6m38e212 {
    animation: eZPd5k6m38e212_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZPd5k6m38e212_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg12;
