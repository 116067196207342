// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg7 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eSgRfdZOxyE1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 328 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="328"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eSgRfdZOxyE6-stroke"
          x1="282.586"
          y1="213.684"
          x2="242.632"
          y2="182.642"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE6-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eSgRfdZOxyE6-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eSgRfdZOxyE7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE7-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE8-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eSgRfdZOxyE8-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eSgRfdZOxyE8-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="eSgRfdZOxyE9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE9-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE10-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE12-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE13-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eSgRfdZOxyE13-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eSgRfdZOxyE13-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="eSgRfdZOxyE14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE14-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE15-filter-drop-shadow-0-flood"
            floodColor={selected === 6 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE18-stroke"
          x1="235.691"
          y1="233.94"
          x2="194.587"
          y2="216.085"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE18-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eSgRfdZOxyE18-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eSgRfdZOxyE19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE19-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE20-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE20-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE20-stroke-1"
            offset="100%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE21-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE21-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE21-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE21-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE21-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE21-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE21-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE22-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE23-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE24-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE25-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE25-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE25-stroke-1"
            offset="100%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE26-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE27-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE28-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE29-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE30-stroke"
          x1="2.72847"
          y1="135.163872"
          x2="-45.43906"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE30-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE30-stroke-1"
            offset="100%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE31-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE32-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE33-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE35-stroke"
          x1="188.844"
          y1="214.159"
          x2="170.027"
          y2="209.803"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE35-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eSgRfdZOxyE35-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eSgRfdZOxyE36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE36-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE37-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE37-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE37-stroke-1"
            offset="100%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE38-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE39-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE40-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE41-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE42-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE42-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE42-stroke-1"
            offset="100%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE43-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE44-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE47-stroke"
          x1="142.055"
          y1="134.612"
          x2="142.477"
          y2="253.311"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE47-stroke-0" offset="0%" stopColor={styles.strokeColor} />
          <stop id="eSgRfdZOxyE47-stroke-1" offset="100%" stopColor="#030303" />
        </linearGradient>
        <filter id="eSgRfdZOxyE48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE48-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE49-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE50-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE51-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE52-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE53-stroke"
          x1="1.68507"
          y1="84.330149"
          x2="0.186927"
          y2="6.0597"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE53-stroke-0" offset="0%" stopColor="rgba(51,51,51,0.3)" />
          <stop
            id="eSgRfdZOxyE53-stroke-1"
            offset="100%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE54-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE55-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE55-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE55-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE55-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE55-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE55-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE55-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE56-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE57-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE57-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE57-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE57-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE57-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE57-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE57-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE57-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE58-stroke"
          x1="1.803113"
          y1="84.330149"
          x2="1.45094"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE58-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE58-stroke-1"
            offset="100%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE59-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE59-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE59-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE59-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE59-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE59-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE59-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE59-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE60-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE60-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE60-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE60-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE60-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE60-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE60-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE60-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE61-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE61-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE63-stroke"
          x1="95.125"
          y1="214.384"
          x2="114.397"
          y2="209.963"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE63-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eSgRfdZOxyE63-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eSgRfdZOxyE64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE64-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE65-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE65-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="eSgRfdZOxyE65-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE66-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE67-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE68-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE69-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE70-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE70-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="eSgRfdZOxyE70-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE71-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE71-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE71-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE71-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE71-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE71-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE71-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE71-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE72-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE73-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE73-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE75-stroke"
          x1="48.3008"
          y1="235.601"
          x2="87.0793"
          y2="218.937"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE75-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eSgRfdZOxyE75-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eSgRfdZOxyE76-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE76-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE76-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE76-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE76-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE76-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE76-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE76-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE77-stroke"
          x1="1"
          y1="-0.015964"
          x2="1"
          y2="0.983627"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eSgRfdZOxyE77-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eSgRfdZOxyE77-stroke-1" offset="100%" stopColor="rgba(220,220,220,0)" />
        </linearGradient>
        <filter id="eSgRfdZOxyE78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE78-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE79-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE80-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE81-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE82-stroke"
          x1="2.846422"
          y1="1.054686"
          x2="2.846422"
          y2="-0.113172"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE82-stroke-0" offset="0%" stopColor="rgba(220,220,220,0)" />
          <stop
            id="eSgRfdZOxyE82-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE83-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE84-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE84-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE84-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE84-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE84-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE84-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE84-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE85-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE86-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE86-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE86-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE86-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE86-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE86-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE86-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE86-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE87-stroke"
          x1="74.479276"
          y1="-349.713613"
          x2="12.97488"
          y2="-5.470225"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE87-stroke-0" offset="0%" stopColor="#838383" />
          <stop
            id="eSgRfdZOxyE87-stroke-1"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eSgRfdZOxyE87-stroke-2" offset="100%" stopColor="rgba(30,30,30,0)" />
        </linearGradient>
        <filter id="eSgRfdZOxyE88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE88-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE89-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE89-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE89-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE89-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE89-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE89-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE89-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE90-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE90-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE92-stroke"
          x1="1.46094"
          y1="214.01"
          x2="43.493"
          y2="181.528"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE92-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eSgRfdZOxyE92-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eSgRfdZOxyE93-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE93-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE93-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE93-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE93-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE93-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE93-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE93-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE94-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE94-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE94-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE95-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE95-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE95-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE95-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE95-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE95-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE95-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE95-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE96-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE97-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eSgRfdZOxyE98-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE98-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eSgRfdZOxyE98-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE98-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eSgRfdZOxyE98-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE98-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE98-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE98-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eSgRfdZOxyE99-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE99-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eSgRfdZOxyE99-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eSgRfdZOxyE100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE100-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eSgRfdZOxyE101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eSgRfdZOxyE101-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eSgRfdZOxyE101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eSgRfdZOxyE101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eSgRfdZOxyE101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eSgRfdZOxyE101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eSgRfdZOxyE102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eSgRfdZOxyE102-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eSgRfdZOxyE103-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eSgRfdZOxyE103-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="eSgRfdZOxyE103-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="eSgRfdZOxyE103-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g mask="url(#eSgRfdZOxyE104)">
        <g>
          <g transform="translate(21.97653-6.66309)">
            <g transform="translate(2 0)">
              <path
                d="M284.586,213.684v-85.983L230.273,82.9652l-.004-76.30211"
                fill="none"
                stroke="url(#eSgRfdZOxyE6-stroke)"
              />
              <g transform="translate(388 0)" filter="url(#eSgRfdZOxyE7-filter)">
                <path
                  id="eSgRfdZOxyE8"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE8-stroke)"
                />
                <g id="eSgRfdZOxyE9_to" transform="translate(-104.650543,236.66309)">
                  <g id="eSgRfdZOxyE9_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE9_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE9-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE10_to" transform="translate(-104.650543,230.66309)">
                  <g id="eSgRfdZOxyE10_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE10_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE10-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE11_to" transform="translate(-104.650543,229.66309)">
                  <g id="eSgRfdZOxyE11_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE11-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(388 0)" filter="url(#eSgRfdZOxyE12-filter)">
                <path
                  id="eSgRfdZOxyE13"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE13-stroke)"
                />
                <g id="eSgRfdZOxyE14_to" transform="translate(-104.650543,236.66309)">
                  <g id="eSgRfdZOxyE14_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE14_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE14-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE15_to" transform="translate(-104.650543,230.66309)">
                  <g id="eSgRfdZOxyE15_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE15_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE15-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE16_to" transform="translate(-104.650543,229.66309)">
                  <g id="eSgRfdZOxyE16_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE16-filter)"
                      fill={selected === 6 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(2 0)">
              <path
                d="M235.691,233.94v-110.447L199.845,78.761l-.001-76.09791"
                transform="translate(0 4)"
                fill="none"
                stroke="url(#eSgRfdZOxyE18-stroke)"
              />
              <g transform="translate(186 0)" filter="url(#eSgRfdZOxyE19-filter)">
                <path
                  id="eSgRfdZOxyE20"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE20-stroke)"
                />
                <g id="eSgRfdZOxyE21_to" transform="translate(49.349457,235.66309)">
                  <g id="eSgRfdZOxyE21_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE21_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE21"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE21-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE22_to" transform="translate(49.349457,230.66309)">
                  <g id="eSgRfdZOxyE22_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE22_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE22"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE22-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE23_to" transform="translate(49.349457,229.66309)">
                  <g id="eSgRfdZOxyE23_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE23"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE23-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#eSgRfdZOxyE24-filter)">
                <path
                  id="eSgRfdZOxyE25"
                  d="M2.46094,127.785h.31159v0h-.04406"
                  transform="translate(46.96253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE25-stroke)"
                />
                <g id="eSgRfdZOxyE26_to" transform="translate(49.349457,235.66309)">
                  <g id="eSgRfdZOxyE26_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE26_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE26"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE26-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE27_to" transform="translate(49.349457,230.66309)">
                  <g id="eSgRfdZOxyE27_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE27_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE27"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE27-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE28_to" transform="translate(49.349457,229.66309)">
                  <g id="eSgRfdZOxyE28_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE28"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE28-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(186 0)" filter="url(#eSgRfdZOxyE29-filter)">
                <path
                  id="eSgRfdZOxyE30"
                  d="M-14.387819,106.271538v0l-18.67408-23.157707-.046745-21.6845"
                  transform="translate(46.96253 0)"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE30-stroke)"
                />
                <g id="eSgRfdZOxyE31_to" transform="translate(13.965137,65.993562)">
                  <rect
                    id="eSgRfdZOxyE31"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    opacity="0.9"
                    filter="url(#eSgRfdZOxyE31-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eSgRfdZOxyE32_to" transform="translate(13.963603,61.496423)">
                  <rect
                    id="eSgRfdZOxyE32"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.6,0.6) translate(-4,-6.66309)"
                    filter="url(#eSgRfdZOxyE32-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eSgRfdZOxyE33_to" transform="translate(14.040137,60.578326)">
                  <rect
                    id="eSgRfdZOxyE33"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#eSgRfdZOxyE33-filter)"
                    fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(1 0)">
              <path
                d="M188.844,214.159v-86.374L171.396,83.0597l-.034265-77"
                fill="none"
                stroke="url(#eSgRfdZOxyE35-stroke)"
              />
              <g transform="translate(94 0)" filter="url(#eSgRfdZOxyE36-filter)">
                <path
                  id="eSgRfdZOxyE37"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE37-stroke)"
                />
                <g id="eSgRfdZOxyE38_to" transform="translate(95.02347,237.66309)">
                  <g id="eSgRfdZOxyE38_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE38_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE38"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE38-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE39_to" transform="translate(95.02347,230.66309)">
                  <g id="eSgRfdZOxyE39_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE39_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE39"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE39-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE40_to" transform="translate(94.62347,229.66309)">
                  <g id="eSgRfdZOxyE40_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE40"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE40-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(94 0)" filter="url(#eSgRfdZOxyE41-filter)">
                <path
                  id="eSgRfdZOxyE42"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE42-stroke)"
                />
                <g id="eSgRfdZOxyE43_to" transform="translate(95.02347,237.66309)">
                  <g id="eSgRfdZOxyE43_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE43_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE43"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE43-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE44_to" transform="translate(95.02347,230.66309)">
                  <g id="eSgRfdZOxyE44_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE44_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE44"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE44-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE45_to" transform="translate(94.62347,229.66309)">
                  <g id="eSgRfdZOxyE45_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE45"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE45-filter)"
                      fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path d="M141.977,6.66309v234" fill="none" stroke="url(#eSgRfdZOxyE47-stroke)" />
              <g filter="url(#eSgRfdZOxyE48-filter)">
                <g id="eSgRfdZOxyE49_to" transform="translate(142.349457,232.66309)">
                  <rect
                    id="eSgRfdZOxyE49"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE49-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eSgRfdZOxyE50_to" transform="translate(142.349457,225.66309)">
                  <rect
                    id="eSgRfdZOxyE50"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE50-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eSgRfdZOxyE51_to" transform="translate(142.349457,224.66309)">
                  <rect
                    id="eSgRfdZOxyE51"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE51-filter)"
                    fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#eSgRfdZOxyE52-filter)">
                <path
                  id="eSgRfdZOxyE53"
                  d="M1.620239,125.822142v1.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE53-stroke)"
                />
                <g id="eSgRfdZOxyE54_to" transform="translate(142.349457,237.66309)">
                  <g id="eSgRfdZOxyE54_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eSgRfdZOxyE54"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE54-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eSgRfdZOxyE55_to" transform="translate(142.349457,230.66309)">
                  <g id="eSgRfdZOxyE55_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eSgRfdZOxyE55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE55-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eSgRfdZOxyE56_to" transform="translate(142.349457,229.66309)">
                  <rect
                    id="eSgRfdZOxyE56"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE56-filter)"
                    fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#eSgRfdZOxyE57-filter)">
                <path
                  id="eSgRfdZOxyE58"
                  d="M1.620239,126.822142v.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE58-stroke)"
                />
                <g id="eSgRfdZOxyE59_to" transform="translate(142.193038,127.481163)">
                  <g id="eSgRfdZOxyE59_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eSgRfdZOxyE59"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0.9"
                      filter="url(#eSgRfdZOxyE59-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eSgRfdZOxyE60_to" transform="translate(142.193038,121.258415)">
                  <g id="eSgRfdZOxyE60_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eSgRfdZOxyE60"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      filter="url(#eSgRfdZOxyE60-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eSgRfdZOxyE61_to" transform="translate(142.244248,120.335341)">
                  <rect
                    id="eSgRfdZOxyE61"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#eSgRfdZOxyE61-filter)"
                    fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-1 0.000001)">
              <path
                d="M95.125,214.384v-86.716l17.88847-45.004911v-76"
                fill="none"
                stroke="url(#eSgRfdZOxyE63-stroke)"
              />
              <g filter="url(#eSgRfdZOxyE64-filter)">
                <path
                  id="eSgRfdZOxyE65"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE65-stroke)"
                />
                <g id="eSgRfdZOxyE66_to" transform="translate(95.349457,237.66309)">
                  <g id="eSgRfdZOxyE66_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE66_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE66"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE66-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE67_to" transform="translate(95.349457,230.66309)">
                  <g id="eSgRfdZOxyE67_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE67_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE67"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE67-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE68_to" transform="translate(95.349457,229.66309)">
                  <g id="eSgRfdZOxyE68_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE68"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE68-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eSgRfdZOxyE69-filter)">
                <path
                  id="eSgRfdZOxyE70"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE70-stroke)"
                />
                <g id="eSgRfdZOxyE71_to" transform="translate(95.349457,237.66309)">
                  <g id="eSgRfdZOxyE71_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE71_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE71"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE71-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE72_to" transform="translate(95.349457,230.66309)">
                  <g id="eSgRfdZOxyE72_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE72_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE72"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE72-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE73_to" transform="translate(95.349457,229.66309)">
                  <g id="eSgRfdZOxyE73_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE73"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE73-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-2 0)">
              <path
                d="M48.3008,235.601v-107.92L84.1294,82.9597v-76.29661"
                fill="none"
                stroke="url(#eSgRfdZOxyE75-stroke)"
              />
              <g filter="url(#eSgRfdZOxyE76-filter)">
                <path
                  id="eSgRfdZOxyE77"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE77-stroke)"
                />
                <g id="eSgRfdZOxyE78_to" transform="translate(48.349457,135.66309)">
                  <g id="eSgRfdZOxyE78_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE78_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE78"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0.9"
                        filter="url(#eSgRfdZOxyE78-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE79_to" transform="translate(48.349457,130.66309)">
                  <g id="eSgRfdZOxyE79_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE79_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE79"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        filter="url(#eSgRfdZOxyE79-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE80_to" transform="translate(48.349457,129.66309)">
                  <g id="eSgRfdZOxyE80_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE80"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      filter="url(#eSgRfdZOxyE80-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eSgRfdZOxyE81-filter)">
                <path
                  id="eSgRfdZOxyE82"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE82-stroke)"
                />
                <g id="eSgRfdZOxyE83_to" transform="translate(49.349457,237.66309)">
                  <g id="eSgRfdZOxyE83_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE83_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE83"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE83-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE84_to" transform="translate(49.349457,230.66309)">
                  <g id="eSgRfdZOxyE84_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE84_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE84"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE84-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE85_to" transform="translate(49.349457,229.66309)">
                  <g id="eSgRfdZOxyE85_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE85"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE85-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eSgRfdZOxyE86-filter)">
                <path
                  id="eSgRfdZOxyE87"
                  d="M1.46094,128.01v-.346l-.674013.121v0"
                  transform="translate(47.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE87-stroke)"
                />
                <g id="eSgRfdZOxyE88_to" transform="translate(49.349457,237.66309)">
                  <rect
                    id="eSgRfdZOxyE88"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE88-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eSgRfdZOxyE89_to" transform="translate(49.349457,230.66309)">
                  <rect
                    id="eSgRfdZOxyE89"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE89-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eSgRfdZOxyE90_to" transform="translate(49.349457,229.66309)">
                  <rect
                    id="eSgRfdZOxyE90"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eSgRfdZOxyE90-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(134 0)">
              <path
                d="M1.46094,214.01v-86.346L55.705,82.9513v-76.28821"
                transform="translate(-137.088013 0)"
                fill="none"
                stroke="url(#eSgRfdZOxyE92-stroke)"
              />
              <g filter="url(#eSgRfdZOxyE93-filter)">
                <path
                  id="eSgRfdZOxyE94"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE94-stroke)"
                />
                <g id="eSgRfdZOxyE95_to" transform="translate(-135.650543,235.66309)">
                  <g id="eSgRfdZOxyE95_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE95_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE95"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE95-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE96_to" transform="translate(-135.650543,230.66309)">
                  <g id="eSgRfdZOxyE96_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE96_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE96"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE96-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE97_to" transform="translate(-135.650543,229.66309)">
                  <g id="eSgRfdZOxyE97_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE97"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE97-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eSgRfdZOxyE98-filter)">
                <path
                  id="eSgRfdZOxyE99"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eSgRfdZOxyE99-stroke)"
                />
                <g id="eSgRfdZOxyE100_to" transform="translate(-135.650543,235.66309)">
                  <g id="eSgRfdZOxyE100_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE100_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE100"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE100-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE101_to" transform="translate(-135.650543,230.66309)">
                  <g id="eSgRfdZOxyE101_tr" transform="rotate(0)">
                    <g id="eSgRfdZOxyE101_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eSgRfdZOxyE101"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eSgRfdZOxyE101-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eSgRfdZOxyE102_to" transform="translate(-135.650543,229.66309)">
                  <g id="eSgRfdZOxyE102_tr" transform="rotate(0)">
                    <rect
                      id="eSgRfdZOxyE102"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eSgRfdZOxyE102-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <rect
            width="328"
            height="61.866793"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1.616376 0 134)"
            fill="url(#eSgRfdZOxyE103-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="eSgRfdZOxyE104" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="328" height="234" rx="0" ry="0" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eSgRfdZOxyE8 {
    animation-name: eSgRfdZOxyE8__m, eSgRfdZOxyE8_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE8__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    21.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE8_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    41.666667% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE9_to {
    animation: eSgRfdZOxyE9_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE9_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    5% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    23.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    25% {
      transform: translate(-114.42px, 118.973437px);
    }
    33.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    35% {
      transform: translate(-156.650543px, 85.099607px);
    }
    36.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    46.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #eSgRfdZOxyE9_tr {
    animation: eSgRfdZOxyE9_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE9_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(-50deg);
    }
    36.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE9_ts {
    animation: eSgRfdZOxyE9_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE9_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE9 {
    animation: eSgRfdZOxyE9_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE9_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0.9;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE10_to {
    animation: eSgRfdZOxyE10_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE10_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    5% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    23.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    25% {
      transform: translate(-118.131567px, 116.016274px);
    }
    33.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    35% {
      transform: translate(-157.988371px, 81.66309px);
    }
    36.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    46.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #eSgRfdZOxyE10_tr {
    animation: eSgRfdZOxyE10_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE10_ts {
    animation: eSgRfdZOxyE10_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE10_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE10 {
    animation: eSgRfdZOxyE10_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE10_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE11_to {
    animation: eSgRfdZOxyE11_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE11_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    5% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    23.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    25% {
      transform: translate(-118.48136px, 115.681349px);
    }
    33.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    35% {
      transform: translate(-157.87653px, 80.828326px);
    }
    36.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    46.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #eSgRfdZOxyE11_tr {
    animation: eSgRfdZOxyE11_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE11 {
    animation: eSgRfdZOxyE11_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE11_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE13 {
    animation-name: eSgRfdZOxyE13__m, eSgRfdZOxyE13_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE13__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    66.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    88.333333% {
      d: path('M-12.054849,90.545445L-12.054849,90.545445L-21.125297,82.9513L-21.125297,27.635917');
    }
    91.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE13_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    88.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE14_to {
    animation: eSgRfdZOxyE14_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE14_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    50% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    68.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    70% {
      transform: translate(-114.42px, 118.973437px);
    }
    78.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    80% {
      transform: translate(-156.650543px, 85.099607px);
    }
    81.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    91.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #eSgRfdZOxyE14_tr {
    animation: eSgRfdZOxyE14_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(-50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE14_ts {
    animation: eSgRfdZOxyE14_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE14_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE14 {
    animation: eSgRfdZOxyE14_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE14_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    91.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE15_to {
    animation: eSgRfdZOxyE15_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE15_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    50% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    68.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    70% {
      transform: translate(-118.131567px, 116.016274px);
    }
    78.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    80% {
      transform: translate(-157.988371px, 81.66309px);
    }
    81.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    91.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #eSgRfdZOxyE15_tr {
    animation: eSgRfdZOxyE15_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE15_ts {
    animation: eSgRfdZOxyE15_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE15_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE15 {
    animation: eSgRfdZOxyE15_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE15_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE16_to {
    animation: eSgRfdZOxyE16_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE16_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    50% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    70% {
      transform: translate(-118.48136px, 115.681349px);
    }
    78.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    80% {
      transform: translate(-157.87653px, 80.828326px);
    }
    81.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    91.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #eSgRfdZOxyE16_tr {
    animation: eSgRfdZOxyE16_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE16 {
    animation: eSgRfdZOxyE16_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE16_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE20 {
    animation-name: eSgRfdZOxyE20__m, eSgRfdZOxyE20_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE20__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    33.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    46.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.306743, 0.414094, 0.620378, 0.807255);
    }
    51.666667% {
      d: path('M-29.243604,87.852739L-29.243604,87.852739L-33.168515,83.071077L-33.255832,50.544574');
      animation-timing-function: cubic-bezier(0.308844, 0.6341, 0.639526, 1);
    }
    60% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes eSgRfdZOxyE20_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    61.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE21_to {
    animation: eSgRfdZOxyE21_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE21_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    35% {
      transform: translate(49.349457px, 129.993562px);
    }
    36.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    43.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    45% {
      transform: translate(17.349457px, 87.540794px);
    }
    46.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    48.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    58.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE21_tr {
    animation: eSgRfdZOxyE21_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(0deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(-40deg);
    }
    48.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE21_ts {
    animation: eSgRfdZOxyE21_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE21_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE21 {
    animation: eSgRfdZOxyE21_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE21_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 0.9;
    }
    58.333333% {
      opacity: 0.9;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE22_to {
    animation: eSgRfdZOxyE22_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE22_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    35% {
      transform: translate(48.011629px, 125.66309px);
    }
    36.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    43.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    45% {
      transform: translate(15.215426px, 85.140794px);
    }
    46.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    48.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    58.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE22_tr {
    animation: eSgRfdZOxyE22_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE22_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE22_ts {
    animation: eSgRfdZOxyE22_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE22_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    33.333333% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE22 {
    animation: eSgRfdZOxyE22_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE22_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE23_to {
    animation: eSgRfdZOxyE23_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE23_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    16.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(47.093619px, 124.50245px);
    }
    36.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    43.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    45% {
      transform: translate(14.762322px, 84.381349px);
    }
    46.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    48.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    58.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE23_tr {
    animation: eSgRfdZOxyE23_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE23_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(-40deg);
    }
    36.666667% {
      transform: rotate(-40deg);
    }
    43.333333% {
      transform: rotate(-40deg);
    }
    45% {
      transform: rotate(-40deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE23 {
    animation: eSgRfdZOxyE23_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE23_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE25 {
    animation-name: eSgRfdZOxyE25__m, eSgRfdZOxyE25_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE25__m {
    0% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
    }
    83.333333% {
      d: path('M2.46094,127.785L2.77253,127.785L2.77253,127.785L2.72847,127.785');
      animation-timing-function: cubic-bezier(0.393594, 0, 0.711078, 0.421008);
    }
    96.666667% {
      d: path('M2.72847,127.493L2.72847,127.493L-32.93906,83.16309L-32.93906,83.165776');
      animation-timing-function: cubic-bezier(0.325674, 0.351957, 0.654677, 0.695819);
    }
    100% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
    }
  }
  @keyframes eSgRfdZOxyE25_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE26_to {
    animation: eSgRfdZOxyE26_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE26_to__to {
    0% {
      transform: translate(49.349457px, 235.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 135.66309px);
    }
    85% {
      transform: translate(49.349457px, 129.993562px);
    }
    86.666667% {
      transform: translate(45.533817px, 122.74181px);
    }
    93.333333% {
      transform: translate(23.349457px, 94.580997px);
    }
    95% {
      transform: translate(17.349457px, 87.540794px);
    }
    96.666667% {
      transform: translate(15.431748px, 84.268314px);
    }
    98.333333% {
      transform: translate(14.01347px, 78.993562px);
    }
    100% {
      transform: translate(13.965137px, 65.993562px);
    }
  }
  #eSgRfdZOxyE26_tr {
    animation: eSgRfdZOxyE26_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(-40deg);
    }
    98.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE26_ts {
    animation: eSgRfdZOxyE26_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE26_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE26 {
    animation: eSgRfdZOxyE26_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE26_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eSgRfdZOxyE27_to {
    animation: eSgRfdZOxyE27_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE27_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 130.66309px);
    }
    85% {
      transform: translate(48.011629px, 125.66309px);
    }
    86.666667% {
      transform: translate(42.844962px, 119.66309px);
    }
    93.333333% {
      transform: translate(20.778296px, 91.452083px);
    }
    95% {
      transform: translate(15.215426px, 85.140794px);
    }
    96.666667% {
      transform: translate(14.011629px, 80.66309px);
    }
    98.333333% {
      transform: translate(14.011629px, 74.66309px);
    }
    100% {
      transform: translate(13.963603px, 61.496423px);
    }
  }
  #eSgRfdZOxyE27_tr {
    animation: eSgRfdZOxyE27_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE27_ts {
    animation: eSgRfdZOxyE27_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE27_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    83.333333% {
      transform: scale(0.8, 0.8);
    }
    96.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE27 {
    animation: eSgRfdZOxyE27_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE27_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE28_to {
    animation: eSgRfdZOxyE28_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE28_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    66.666667% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.333333% {
      transform: translate(49.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(47.093619px, 124.50245px);
    }
    86.666667% {
      transform: translate(41.593619px, 118.5px);
    }
    93.333333% {
      transform: translate(20.462322px, 91.033239px);
    }
    95% {
      transform: translate(14.762322px, 84.381349px);
    }
    96.666667% {
      transform: translate(14.12347px, 79.828326px);
    }
    98.333333% {
      transform: translate(14.02347px, 73.728326px);
    }
    100% {
      transform: translate(14.040137px, 60.578326px);
    }
  }
  #eSgRfdZOxyE28_tr {
    animation: eSgRfdZOxyE28_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-40deg);
    }
    86.666667% {
      transform: rotate(-40deg);
    }
    93.333333% {
      transform: rotate(-40deg);
    }
    95% {
      transform: rotate(-40deg);
    }
    96.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE28 {
    animation: eSgRfdZOxyE28_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE28_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    66.666667% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE30 {
    animation-name: eSgRfdZOxyE30__m, eSgRfdZOxyE30_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE30__m {
    0% {
      d: path('M-14.387819,106.271538L-14.387819,106.271538L-33.061899,83.113831L-33.108644,61.429331');
      animation-timing-function: cubic-bezier(0.316312, 0.39148, 0.646318, 0.736182);
    }
    8.333333% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
    100% {
      d: path('M-33.196887,82.9513L-33.196887,82.9513L-33.196887,83.0597L-33.295,6.0597');
    }
  }
  @keyframes eSgRfdZOxyE30_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    5% {
      opacity: 1;
    }
    11.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE31_to {
    animation: eSgRfdZOxyE31_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE31_to__to {
    0% {
      transform: translate(13.965137px, 65.993562px);
    }
    8.333333% {
      transform: translate(13.72347px, 0.993562px);
    }
    100% {
      transform: translate(13.72347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE31 {
    animation: eSgRfdZOxyE31_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE31_c_o {
    0% {
      opacity: 0.9;
    }
    8.333333% {
      opacity: 0.9;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE32_to {
    animation: eSgRfdZOxyE32_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE32_to__to {
    0% {
      transform: translate(13.963603px, 61.496423px);
    }
    8.333333% {
      transform: translate(13.72347px, -4.33691px);
    }
    100% {
      transform: translate(13.72347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE32 {
    animation: eSgRfdZOxyE32_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE32_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE33_to {
    animation: eSgRfdZOxyE33_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE33_to__to {
    0% {
      transform: translate(14.040137px, 60.578326px);
    }
    8.333333% {
      transform: translate(14.12347px, -5.171674px);
    }
    100% {
      transform: translate(14.12347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE33 {
    animation: eSgRfdZOxyE33_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE33_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    8.333333% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE37 {
    animation-name: eSgRfdZOxyE37__m, eSgRfdZOxyE37_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE37__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    30% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    41.666667% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    51.666667% {
      d: path('M-14.393265,88.779336L-14.393265,88.779336L-16.643335,83.174861L-16.643335,23.521822');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    55% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE37_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.666667% {
      opacity: 1;
    }
    51.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE38_to {
    animation: eSgRfdZOxyE38_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE38_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 136.66309px);
    }
    31.666667% {
      transform: translate(95.02347px, 129.993562px);
    }
    33.333333% {
      transform: translate(92.792379px, 121.74181px);
    }
    41.666667% {
      transform: translate(79.572206px, 88.521493px);
    }
    43.333333% {
      transform: translate(77.728902px, 84.993562px);
    }
    45% {
      transform: translate(77.02347px, 79.660944px);
    }
    55% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE38_tr {
    animation: eSgRfdZOxyE38_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(-25deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE38_ts {
    animation: eSgRfdZOxyE38_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE38_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE38 {
    animation: eSgRfdZOxyE38_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE38_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 0.9;
    }
    55% {
      opacity: 0.9;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE39_to {
    animation: eSgRfdZOxyE39_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE39_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 130.66309px);
    }
    31.666667% {
      transform: translate(94.17641px, 125.66309px);
    }
    33.333333% {
      transform: translate(91px, 117.66309px);
    }
    41.666667% {
      transform: translate(78.011629px, 85.140794px);
    }
    43.333333% {
      transform: translate(77px, 81.5px);
    }
    45% {
      transform: translate(77.02347px, 75.66309px);
    }
    55% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE39_tr {
    animation: eSgRfdZOxyE39_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE39_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE39_ts {
    animation: eSgRfdZOxyE39_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE39_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE39 {
    animation: eSgRfdZOxyE39_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE39_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE40_to {
    animation: eSgRfdZOxyE40_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE40_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    13.333333% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    31.666667% {
      transform: translate(93.59994px, 124.50245px);
    }
    33.333333% {
      transform: translate(90.3px, 116.5px);
    }
    41.666667% {
      transform: translate(77.51864px, 84.381349px);
    }
    43.333333% {
      transform: translate(77.12347px, 80.828326px);
    }
    45% {
      transform: translate(77.12347px, 75.328326px);
    }
    55% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE40_tr {
    animation: eSgRfdZOxyE40_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE40_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE40 {
    animation: eSgRfdZOxyE40_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE40_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE42 {
    animation-name: eSgRfdZOxyE42__m, eSgRfdZOxyE42_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE42__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    73.333333% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    85% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    93.333333% {
      d: path('M-11.477665,96.161688L-11.477665,96.161688L-16.577892,83.320735L-16.577892,35.774152');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    98.333333% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE42_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0.25;
    }
  }
  #eSgRfdZOxyE43_to {
    animation: eSgRfdZOxyE43_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE43_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 136.66309px);
    }
    75% {
      transform: translate(95.02347px, 129.993562px);
    }
    76.666667% {
      transform: translate(92.792379px, 121.74181px);
    }
    85% {
      transform: translate(79.572206px, 88.521493px);
    }
    86.666667% {
      transform: translate(77.728902px, 84.993562px);
    }
    88.333333% {
      transform: translate(77.02347px, 79.660944px);
    }
    98.333333% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE43_tr {
    animation: eSgRfdZOxyE43_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE43_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(0deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(-25deg);
    }
    88.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE43_ts {
    animation: eSgRfdZOxyE43_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE43_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE43 {
    animation: eSgRfdZOxyE43_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE43_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 0.9;
    }
    98.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE44_to {
    animation: eSgRfdZOxyE44_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE44_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 130.66309px);
    }
    75% {
      transform: translate(94.17641px, 125.66309px);
    }
    76.666667% {
      transform: translate(91px, 117.66309px);
    }
    85% {
      transform: translate(78.011629px, 85.140794px);
    }
    86.666667% {
      transform: translate(77px, 81.5px);
    }
    88.333333% {
      transform: translate(77.02347px, 75.66309px);
    }
    98.333333% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE44_tr {
    animation: eSgRfdZOxyE44_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE44_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE44_ts {
    animation: eSgRfdZOxyE44_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE44_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE44 {
    animation: eSgRfdZOxyE44_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE44_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE45_to {
    animation: eSgRfdZOxyE45_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE45_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    56.666667% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      transform: translate(93.59994px, 124.50245px);
    }
    76.666667% {
      transform: translate(90.3px, 116.5px);
    }
    85% {
      transform: translate(77.51864px, 84.381349px);
    }
    86.666667% {
      transform: translate(77.12347px, 80.828326px);
    }
    88.333333% {
      transform: translate(77.12347px, 75.328326px);
    }
    98.333333% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE45_tr {
    animation: eSgRfdZOxyE45_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE45 {
    animation: eSgRfdZOxyE45_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE45_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE49_to {
    animation: eSgRfdZOxyE49_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE49_to__to {
    0% {
      transform: translate(142.349457px, 232.66309px);
    }
    80% {
      transform: translate(142.349457px, 232.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 127.481163px);
    }
  }
  #eSgRfdZOxyE49 {
    animation: eSgRfdZOxyE49_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE49_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eSgRfdZOxyE50_to {
    animation: eSgRfdZOxyE50_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE50_to__to {
    0% {
      transform: translate(142.349457px, 225.66309px);
    }
    80% {
      transform: translate(142.349457px, 225.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 121.258415px);
    }
  }
  #eSgRfdZOxyE50 {
    animation: eSgRfdZOxyE50_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE50_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE51_to {
    animation: eSgRfdZOxyE51_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE51_to__to {
    0% {
      transform: translate(142.349457px, 224.66309px);
    }
    80% {
      transform: translate(142.349457px, 224.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.244248px, 120.335341px);
    }
  }
  #eSgRfdZOxyE51 {
    animation: eSgRfdZOxyE51_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE51_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE53 {
    animation-name: eSgRfdZOxyE53__m, eSgRfdZOxyE53_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE53__m {
    0% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
    }
    55% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.291939, 0.488237, 0.577592, 1);
    }
    76.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE53_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    71.666667% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE54_to {
    animation: eSgRfdZOxyE54_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE54_to__to {
    0% {
      transform: translate(142.349457px, 237.66309px);
    }
    35% {
      transform: translate(142.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE54_ts {
    animation: eSgRfdZOxyE54_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE54_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE54 {
    animation: eSgRfdZOxyE54_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE54_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 0.9;
    }
    76.666667% {
      opacity: 0.9;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE55_to {
    animation: eSgRfdZOxyE55_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE55_to__to {
    0% {
      transform: translate(142.349457px, 230.66309px);
    }
    35% {
      transform: translate(142.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE55_ts {
    animation: eSgRfdZOxyE55_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE55_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE55 {
    animation: eSgRfdZOxyE55_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE55_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE56_to {
    animation: eSgRfdZOxyE56_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE56_to__to {
    0% {
      transform: translate(142.349457px, 229.66309px);
    }
    35% {
      transform: translate(142.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE56 {
    animation: eSgRfdZOxyE56_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE56_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE58 {
    animation-name: eSgRfdZOxyE58__m, eSgRfdZOxyE58_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE58__m {
    0% {
      d: path('M1.620239,126.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.319296, 0.369702, 0.634542, 0.749896);
    }
    11.666667% {
      d: path('M1.762467,92.702027L1.762467,92.733775L1.762467,92.607038L1.676009,50.333564');
      animation-timing-function: cubic-bezier(0.297615, 0.610899, 0.623772, 1);
    }
    21.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE58_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE59_to {
    animation: eSgRfdZOxyE59_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE59_to__to {
    0% {
      transform: translate(142.193038px, 127.481163px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE59_ts {
    animation: eSgRfdZOxyE59_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE59_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE59 {
    animation: eSgRfdZOxyE59_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE59_c_o {
    0% {
      opacity: 0.9;
    }
    21.666667% {
      opacity: 0.9;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE60_to {
    animation: eSgRfdZOxyE60_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE60_to__to {
    0% {
      transform: translate(142.193038px, 121.258415px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE60_ts {
    animation: eSgRfdZOxyE60_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE60_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE60 {
    animation: eSgRfdZOxyE60_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE60_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE61_to {
    animation: eSgRfdZOxyE61_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE61_to__to {
    0% {
      transform: translate(142.244248px, 120.335341px);
      animation-timing-function: cubic-bezier(0.430844, 0, 0.69332, 0.575556);
    }
    13.333333% {
      transform: translate(142.160623px, 33.975185px);
      animation-timing-function: cubic-bezier(0.28661, 0.558021, 0.597351, 1);
    }
    21.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE61 {
    animation: eSgRfdZOxyE61_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE61_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE65 {
    animation-name: eSgRfdZOxyE65__m, eSgRfdZOxyE65_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE65__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    25% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.666667% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    46.666667% {
      d: path('M17.127316,88.675201L17.144733,88.59732L19.483755,83.045699L19.705,23.39266');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    50% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE65_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    53.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE66_to {
    animation: eSgRfdZOxyE66_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE66_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 136.66309px);
    }
    26.666667% {
      transform: translate(95.349457px, 127.493561px);
    }
    28.333333% {
      transform: translate(96.886038px, 122.125682px);
    }
    36.666667% {
      transform: translate(110.349457px, 88.540794px);
    }
    38.333333% {
      transform: translate(112px, 84.993562px);
    }
    40% {
      transform: translate(113.22347px, 79.460943px);
    }
    50% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE66_tr {
    animation: eSgRfdZOxyE66_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(0deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(25deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE66_ts {
    animation: eSgRfdZOxyE66_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE66_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE66 {
    animation: eSgRfdZOxyE66_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE66_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.9;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE67_to {
    animation: eSgRfdZOxyE67_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE67_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 130.66309px);
    }
    26.666667% {
      transform: translate(96.5px, 122.66309px);
    }
    28.333333% {
      transform: translate(99px, 117.66309px);
    }
    36.666667% {
      transform: translate(112.011629px, 85.140794px);
    }
    38.333333% {
      transform: translate(113px, 81px);
    }
    40% {
      transform: translate(113.22347px, 75.66309px);
    }
    50% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE67_tr {
    animation: eSgRfdZOxyE67_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE67_ts {
    animation: eSgRfdZOxyE67_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE67_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE67 {
    animation: eSgRfdZOxyE67_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE67_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE68_to {
    animation: eSgRfdZOxyE68_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE68_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    26.666667% {
      transform: translate(97px, 121.50245px);
    }
    28.333333% {
      transform: translate(99.5px, 116.5px);
    }
    36.666667% {
      transform: translate(112.51864px, 84.381349px);
    }
    38.333333% {
      transform: translate(113px, 80px);
    }
    40% {
      transform: translate(113.12347px, 74.828326px);
    }
    50% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE68_tr {
    animation: eSgRfdZOxyE68_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE68 {
    animation: eSgRfdZOxyE68_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE68_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE70 {
    animation-name: eSgRfdZOxyE70__m, eSgRfdZOxyE70_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE70__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    68.333333% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M14.183989,95.925645L14.198135,95.749112L19.525321,83.027964L19.705,35.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE70_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE71_to {
    animation: eSgRfdZOxyE71_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE71_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 136.66309px);
    }
    70% {
      transform: translate(95.349457px, 127.493561px);
    }
    71.666667% {
      transform: translate(96.886038px, 122.125682px);
    }
    80% {
      transform: translate(110.349457px, 88.540794px);
    }
    81.666667% {
      transform: translate(112px, 84.993562px);
    }
    83.333333% {
      transform: translate(113.22347px, 79.460943px);
    }
    93.333333% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE71_tr {
    animation: eSgRfdZOxyE71_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE71_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(25deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE71_ts {
    animation: eSgRfdZOxyE71_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE71_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE71 {
    animation: eSgRfdZOxyE71_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE71_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE72_to {
    animation: eSgRfdZOxyE72_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE72_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 130.66309px);
    }
    70% {
      transform: translate(96.5px, 122.66309px);
    }
    71.666667% {
      transform: translate(99px, 117.66309px);
    }
    80% {
      transform: translate(112.011629px, 85.140794px);
    }
    81.666667% {
      transform: translate(113px, 81px);
    }
    83.333333% {
      transform: translate(113.22347px, 75.66309px);
    }
    93.333333% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE72_tr {
    animation: eSgRfdZOxyE72_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE72_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE72_ts {
    animation: eSgRfdZOxyE72_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE72_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE72 {
    animation: eSgRfdZOxyE72_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE72_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE73_to {
    animation: eSgRfdZOxyE73_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE73_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(97px, 121.50245px);
    }
    71.666667% {
      transform: translate(99.5px, 116.5px);
    }
    80% {
      transform: translate(112.51864px, 84.381349px);
    }
    81.666667% {
      transform: translate(113px, 80px);
    }
    83.333333% {
      transform: translate(113.12347px, 74.828326px);
    }
    93.333333% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE73_tr {
    animation: eSgRfdZOxyE73_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE73_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE73 {
    animation: eSgRfdZOxyE73_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE73_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE77 {
    animation-name: eSgRfdZOxyE77__m, eSgRfdZOxyE77_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE77__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    13.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.705,82.9513L36.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    16.666667% {
      d: path('M11.808104,114.29263L36.733725,83.0597L36.733725,82.983036L36.779932,59.996492');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    26.666667% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes eSgRfdZOxyE77_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    28.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE78_to {
    animation: eSgRfdZOxyE78_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE78_to__to {
    0% {
      transform: translate(48.349457px, 135.66309px);
    }
    1.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    3.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    11.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    13.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    15% {
      transform: translate(84.01347px, 79.993562px);
    }
    25% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE78_tr {
    animation: eSgRfdZOxyE78_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(0deg);
    }
    3.333333% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(40deg);
    }
    15% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE78_ts {
    animation: eSgRfdZOxyE78_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE78_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE78 {
    animation: eSgRfdZOxyE78_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE78_c_o {
    0% {
      opacity: 0.9;
    }
    25% {
      opacity: 0.9;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE79_to {
    animation: eSgRfdZOxyE79_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE79_to__to {
    0% {
      transform: translate(48.349457px, 130.66309px);
    }
    1.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    3.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    11.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    13.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    15% {
      transform: translate(84.011629px, 75.66309px);
    }
    25% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE79_tr {
    animation: eSgRfdZOxyE79_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE79_ts {
    animation: eSgRfdZOxyE79_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE79_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    1.666667% {
      transform: scale(0.8, 0.8);
    }
    13.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE79 {
    animation: eSgRfdZOxyE79_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE79_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE80_to {
    animation: eSgRfdZOxyE80_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE80_to__to {
    0% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    1.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    3.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    11.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    13.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    15% {
      transform: translate(84.12347px, 74.828326px);
    }
    25% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE80_tr {
    animation: eSgRfdZOxyE80_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE80_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.666667% {
      transform: rotate(40deg);
    }
    11.666667% {
      transform: rotate(40deg);
    }
    13.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE80 {
    animation: eSgRfdZOxyE80_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE80_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    26.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE82 {
    animation-name: eSgRfdZOxyE82__m, eSgRfdZOxyE82_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE82__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
    }
    46.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L0.786927,127.785L0.786927,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.333333% {
      d: path('M1.46094,127.266975L36.9978,83.113746L36.882028,83.113746L36.960941,83.0597');
      animation-timing-function: cubic-bezier(0.38481, 0, 0.70726, 0.403804);
    }
    61.666667% {
      d: path('M7.458603,119.746485L36.882027,83.059701L36.868636,83.104574L36.960941,69.691269');
      animation-timing-function: cubic-bezier(0.330218, 0.34364, 0.565924, 1);
    }
    70% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
    100% {
      d: path('M36.803113,82.9513L36.803113,82.9513L36.803113,83.0597L36.96094,4.284114');
    }
  }
  @keyframes eSgRfdZOxyE82_c_o {
    0% {
      opacity: 0;
    }
    43.333333% {
      opacity: 0;
    }
    45% {
      opacity: 1;
    }
    66.666667% {
      opacity: 1;
    }
    73.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE83_to {
    animation: eSgRfdZOxyE83_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE83_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 135.66309px);
    }
    46.666667% {
      transform: translate(48.349457px, 129.993562px);
    }
    48.333333% {
      transform: translate(51.349457px, 122.74181px);
    }
    56.666667% {
      transform: translate(81.009874px, 87.540794px);
    }
    58.333333% {
      transform: translate(82.349457px, 84.993562px);
    }
    60% {
      transform: translate(84.01347px, 79.993562px);
    }
    70% {
      transform: translate(84.01347px, 0.993562px);
    }
    100% {
      transform: translate(84.01347px, 0.993562px);
    }
  }
  #eSgRfdZOxyE83_tr {
    animation: eSgRfdZOxyE83_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(0deg);
    }
    48.333333% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(40deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE83_ts {
    animation: eSgRfdZOxyE83_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE83_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE83 {
    animation: eSgRfdZOxyE83_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE83_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 0.9;
    }
    70% {
      opacity: 0.9;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE84_to {
    animation: eSgRfdZOxyE84_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE84_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 130.66309px);
    }
    46.666667% {
      transform: translate(49.733442px, 125.66309px);
    }
    48.333333% {
      transform: translate(53.844962px, 119.66309px);
    }
    56.666667% {
      transform: translate(83.011629px, 85.140794px);
    }
    58.333333% {
      transform: translate(84.011629px, 81.66309px);
    }
    60% {
      transform: translate(84.011629px, 75.66309px);
    }
    70% {
      transform: translate(84.01347px, -4.33691px);
    }
    100% {
      transform: translate(84.01347px, -4.33691px);
    }
  }
  #eSgRfdZOxyE84_tr {
    animation: eSgRfdZOxyE84_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE84_ts {
    animation: eSgRfdZOxyE84_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE84_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    46.666667% {
      transform: scale(0.8, 0.8);
    }
    58.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE84 {
    animation: eSgRfdZOxyE84_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE84_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE85_to {
    animation: eSgRfdZOxyE85_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE85_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    28.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    45% {
      transform: translate(48.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      transform: translate(50.349457px, 124.50245px);
    }
    48.333333% {
      transform: translate(54.878456px, 118.5px);
    }
    56.666667% {
      transform: translate(83.51864px, 84.381349px);
    }
    58.333333% {
      transform: translate(84.12347px, 80.828326px);
    }
    60% {
      transform: translate(84.12347px, 74.828326px);
    }
    70% {
      transform: translate(84.12347px, -5.171674px);
    }
    100% {
      transform: translate(84.12347px, -5.171674px);
    }
  }
  #eSgRfdZOxyE85_tr {
    animation: eSgRfdZOxyE85_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE85_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    45% {
      transform: rotate(0deg);
    }
    46.666667% {
      transform: rotate(40deg);
    }
    56.666667% {
      transform: rotate(40deg);
    }
    58.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE85 {
    animation: eSgRfdZOxyE85_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE85_c_o {
    0% {
      opacity: 0;
    }
    26.666667% {
      opacity: 0;
    }
    28.333333% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    71.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE87 {
    animation: eSgRfdZOxyE87_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE87_c_o {
    0% {
      opacity: 0;
    }
    98.333333% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE88_to {
    animation: eSgRfdZOxyE88_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE88_to__to {
    0% {
      transform: translate(49.349457px, 237.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 135.66309px);
    }
  }
  #eSgRfdZOxyE88 {
    animation: eSgRfdZOxyE88_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE88_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eSgRfdZOxyE89_to {
    animation: eSgRfdZOxyE89_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE89_to__to {
    0% {
      transform: translate(49.349457px, 230.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 130.66309px);
    }
  }
  #eSgRfdZOxyE89 {
    animation: eSgRfdZOxyE89_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE89_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE90_to {
    animation: eSgRfdZOxyE90_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE90_to__to {
    0% {
      transform: translate(49.349457px, 229.66309px);
    }
    83.333333% {
      transform: translate(49.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(48.349457px, 129.66309px);
    }
  }
  #eSgRfdZOxyE90 {
    animation: eSgRfdZOxyE90_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE90_c_o {
    0% {
      opacity: 0;
    }
    81.666667% {
      opacity: 0;
    }
    83.333333% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eSgRfdZOxyE94 {
    animation-name: eSgRfdZOxyE94__m, eSgRfdZOxyE94_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE94__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    21.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    38.333333% {
      d: path('M17.077982,114.999859L17.077982,114.573417L55.412229,82.9513L55.412229,49.638019');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    46.666667% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE94_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    43.333333% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE95_to {
    animation: eSgRfdZOxyE95_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE95_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 135.66309px);
    }
    21.666667% {
      transform: translate(-135.650543px, 130.993562px);
    }
    23.333333% {
      transform: translate(-129.28364px, 121.74181px);
    }
    31.666667% {
      transform: translate(-86.650543px, 86.993562px);
    }
    33.333333% {
      transform: translate(-83.650543px, 83.993562px);
    }
    35% {
      transform: translate(-81.98653px, 79.993562px);
    }
    45% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #eSgRfdZOxyE95_tr {
    animation: eSgRfdZOxyE95_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE95_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE95_ts {
    animation: eSgRfdZOxyE95_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE95_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE95 {
    animation: eSgRfdZOxyE95_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE95_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0.9;
    }
    45% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE96_to {
    animation: eSgRfdZOxyE96_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE96_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 130.66309px);
    }
    21.666667% {
      transform: translate(-133.988371px, 126.66309px);
    }
    23.333333% {
      transform: translate(-125.655038px, 118.66309px);
    }
    31.666667% {
      transform: translate(-83.988371px, 84.66309px);
    }
    33.333333% {
      transform: translate(-81.988371px, 80.66309px);
    }
    35% {
      transform: translate(-81.988371px, 75.66309px);
    }
    45% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #eSgRfdZOxyE96_tr {
    animation: eSgRfdZOxyE96_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE96_ts {
    animation: eSgRfdZOxyE96_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE96_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE96 {
    animation: eSgRfdZOxyE96_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE96_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE97_to {
    animation: eSgRfdZOxyE97_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE97_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-132.650543px, 125.50245px);
    }
    23.333333% {
      transform: translate(-125.48136px, 118.381349px);
    }
    31.666667% {
      transform: translate(-83.48136px, 84.381349px);
    }
    33.333333% {
      transform: translate(-81.87653px, 79.828326px);
    }
    35% {
      transform: translate(-81.87653px, 74.828326px);
    }
    45% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #eSgRfdZOxyE97_tr {
    animation: eSgRfdZOxyE97_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE97_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE97 {
    animation: eSgRfdZOxyE97_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE97_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE99 {
    animation-name: eSgRfdZOxyE99__m, eSgRfdZOxyE99_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eSgRfdZOxyE99__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    68.333333% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.430309, 0, 0.626686, 0.804094);
    }
    78.333333% {
      d: path('M1.46094,128.256464L1.46094,127.664L46.219884,90.745962L46.219884,90.745961');
      animation-timing-function: cubic-bezier(0.310712, 0.637203, 0.641852, 1);
    }
    80% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M39.186071,96.218416L39.186071,96.041883L54.997771,82.9513L54.997771,34.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes eSgRfdZOxyE99_c_o {
    0% {
      opacity: 0;
    }
    61.666667% {
      opacity: 0;
    }
    63.333333% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE100_to {
    animation: eSgRfdZOxyE100_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE100_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 135.66309px);
    }
    70% {
      transform: translate(-135.650543px, 130.993562px);
    }
    71.666667% {
      transform: translate(-129.28364px, 121.74181px);
    }
    80% {
      transform: translate(-86.650543px, 86.993562px);
    }
    81.666667% {
      transform: translate(-83.650543px, 83.993562px);
    }
    83.333333% {
      transform: translate(-81.98653px, 79.993562px);
    }
    93.333333% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #eSgRfdZOxyE100_tr {
    animation: eSgRfdZOxyE100_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(50deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE100_ts {
    animation: eSgRfdZOxyE100_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE100_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE100 {
    animation: eSgRfdZOxyE100_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE100_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE101_to {
    animation: eSgRfdZOxyE101_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE101_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 130.66309px);
    }
    70% {
      transform: translate(-133.988371px, 126.66309px);
    }
    71.666667% {
      transform: translate(-125.655038px, 118.66309px);
    }
    80% {
      transform: translate(-83.988371px, 84.66309px);
    }
    81.666667% {
      transform: translate(-81.988371px, 80.66309px);
    }
    83.333333% {
      transform: translate(-81.988371px, 75.66309px);
    }
    93.333333% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #eSgRfdZOxyE101_tr {
    animation: eSgRfdZOxyE101_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE101_ts {
    animation: eSgRfdZOxyE101_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE101_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eSgRfdZOxyE101 {
    animation: eSgRfdZOxyE101_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE101_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eSgRfdZOxyE102_to {
    animation: eSgRfdZOxyE102_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE102_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(-132.650543px, 125.50245px);
    }
    71.666667% {
      transform: translate(-125.48136px, 118.381349px);
    }
    80% {
      transform: translate(-83.48136px, 84.381349px);
    }
    81.666667% {
      transform: translate(-81.87653px, 79.828326px);
    }
    83.333333% {
      transform: translate(-81.87653px, 74.828326px);
    }
    93.333333% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #eSgRfdZOxyE102_tr {
    animation: eSgRfdZOxyE102_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eSgRfdZOxyE102 {
    animation: eSgRfdZOxyE102_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eSgRfdZOxyE102_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg7;
