// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg1 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eqN9J7lahuH1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 41 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="41"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eqN9J7lahuH6-stroke"
          x1="142.055"
          y1="134.612"
          x2="142.477"
          y2="253.311"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eqN9J7lahuH6-stroke-0" offset="0%" stopColor={styles.strokeColor} />
          <stop id="eqN9J7lahuH6-stroke-1" offset="100%" stopColor="#030303" />
        </linearGradient>
        <filter id="eqN9J7lahuH7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eqN9J7lahuH7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH7-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eqN9J7lahuH7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqN9J7lahuH8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH8-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eqN9J7lahuH8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqN9J7lahuH9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH9-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eqN9J7lahuH9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH10-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eqN9J7lahuH11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH11-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eqN9J7lahuH11-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH11-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eqN9J7lahuH11-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH11-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH11-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH11-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqN9J7lahuH12-stroke"
          x1="1.68507"
          y1="84.330149"
          x2="0.186927"
          y2="6.0597"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eqN9J7lahuH12-stroke-0" offset="0%" stopColor="rgba(51,51,51,0.3)" />
          <stop
            id="eqN9J7lahuH12-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eqN9J7lahuH13-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH13-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqN9J7lahuH13-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH13-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eqN9J7lahuH13-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH13-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH13-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH13-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqN9J7lahuH14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH14-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eqN9J7lahuH14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH15-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eqN9J7lahuH16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eqN9J7lahuH16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH16-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eqN9J7lahuH16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqN9J7lahuH17-stroke"
          x1="1.803113"
          y1="84.330149"
          x2="1.45094"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eqN9J7lahuH17-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eqN9J7lahuH17-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eqN9J7lahuH18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqN9J7lahuH18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH18-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eqN9J7lahuH18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqN9J7lahuH19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eqN9J7lahuH19-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eqN9J7lahuH19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqN9J7lahuH19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqN9J7lahuH19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqN9J7lahuH19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eqN9J7lahuH20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqN9J7lahuH20-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eqN9J7lahuH21-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eqN9J7lahuH21-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="eqN9J7lahuH21-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="eqN9J7lahuH21-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g transform="translate(-51.000008 0)" mask="url(#eqN9J7lahuH22)">
        <g transform="translate(-92.592173 0)">
          <g transform="translate(21.97653-6.66309)">
            <g>
              <path d="M141.977,6.66309v234" fill="none" stroke="url(#eqN9J7lahuH6-stroke)" />
              <g filter="url(#eqN9J7lahuH7-filter)">
                <g id="eqN9J7lahuH8_to" transform="translate(142.349457,232.66309)">
                  <rect
                    id="eqN9J7lahuH8"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eqN9J7lahuH8-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eqN9J7lahuH9_to" transform="translate(142.349457,225.66309)">
                  <rect
                    id="eqN9J7lahuH9"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eqN9J7lahuH9-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eqN9J7lahuH10_to" transform="translate(142.349457,224.66309)">
                  <rect
                    id="eqN9J7lahuH10"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eqN9J7lahuH10-filter)"
                    fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#eqN9J7lahuH11-filter)">
                <path
                  id="eqN9J7lahuH12"
                  d="M1.620239,125.822142v1.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqN9J7lahuH12-stroke)"
                />
                <g id="eqN9J7lahuH13_to" transform="translate(142.349457,237.66309)">
                  <g id="eqN9J7lahuH13_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eqN9J7lahuH13"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eqN9J7lahuH13-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqN9J7lahuH14_to" transform="translate(142.349457,230.66309)">
                  <g id="eqN9J7lahuH14_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eqN9J7lahuH14"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eqN9J7lahuH14-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqN9J7lahuH15_to" transform="translate(142.349457,229.66309)">
                  <rect
                    id="eqN9J7lahuH15"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    opacity="0"
                    filter="url(#eqN9J7lahuH15-filter)"
                    fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g filter="url(#eqN9J7lahuH16-filter)">
                <path
                  id="eqN9J7lahuH17"
                  d="M1.620239,126.822142v.142839-.949534l-.045676-.244145"
                  transform="translate(140.56253 0)"
                  fill="none"
                  stroke="url(#eqN9J7lahuH17-stroke)"
                />
                <g id="eqN9J7lahuH18_to" transform="translate(142.193038,127.481163)">
                  <g id="eqN9J7lahuH18_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eqN9J7lahuH18"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      opacity="0.9"
                      filter="url(#eqN9J7lahuH18-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqN9J7lahuH19_to" transform="translate(142.193038,121.258415)">
                  <g id="eqN9J7lahuH19_ts" transform="scale(0.8,0.8)">
                    <rect
                      id="eqN9J7lahuH19"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="translate(-4,-6.66309)"
                      filter="url(#eqN9J7lahuH19-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqN9J7lahuH20_to" transform="translate(142.244248,120.335341)">
                  <rect
                    id="eqN9J7lahuH20"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.4,0.4) translate(-4,-6.66309)"
                    filter="url(#eqN9J7lahuH20-filter)"
                    fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
          </g>
          <rect
            width="261.492933"
            height="61.606537"
            rx="0"
            ry="0"
            transform="matrix(.156792 0 0 1.351538 143.592173 150.736424)"
            fill="url(#eqN9J7lahuH21-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="eqN9J7lahuH22" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="41" height="234" rx="0" ry="0" transform="translate(51 0)" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eqN9J7lahuH8_to {
    animation: eqN9J7lahuH8_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH8_to__to {
    0% {
      transform: translate(142.349457px, 232.66309px);
    }
    80% {
      transform: translate(142.349457px, 232.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 127.481163px);
    }
  }
  #eqN9J7lahuH8 {
    animation: eqN9J7lahuH8_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH8_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eqN9J7lahuH9_to {
    animation: eqN9J7lahuH9_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH9_to__to {
    0% {
      transform: translate(142.349457px, 225.66309px);
    }
    80% {
      transform: translate(142.349457px, 225.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.193038px, 121.258415px);
    }
  }
  #eqN9J7lahuH9 {
    animation: eqN9J7lahuH9_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH9_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqN9J7lahuH10_to {
    animation: eqN9J7lahuH10_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH10_to__to {
    0% {
      transform: translate(142.349457px, 224.66309px);
    }
    80% {
      transform: translate(142.349457px, 224.66309px);
      animation-timing-function: cubic-bezier(0.417391, 0, 0.711528, 0.488766);
    }
    100% {
      transform: translate(142.244248px, 120.335341px);
    }
  }
  #eqN9J7lahuH10 {
    animation: eqN9J7lahuH10_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH10_c_o {
    0% {
      opacity: 0;
    }
    78.333333% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqN9J7lahuH12 {
    animation-name: eqN9J7lahuH12__m, eqN9J7lahuH12_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqN9J7lahuH12__m {
    0% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
    }
    55% {
      d: path('M1.620239,125.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.291939, 0.488237, 0.577592, 1);
    }
    76.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes eqN9J7lahuH12_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    73.333333% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH13_to {
    animation: eqN9J7lahuH13_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH13_to__to {
    0% {
      transform: translate(142.349457px, 237.66309px);
    }
    35% {
      transform: translate(142.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #eqN9J7lahuH13_ts {
    animation: eqN9J7lahuH13_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH13_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eqN9J7lahuH13 {
    animation: eqN9J7lahuH13_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH13_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 0.9;
    }
    76.666667% {
      opacity: 0.9;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH14_to {
    animation: eqN9J7lahuH14_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH14_to__to {
    0% {
      transform: translate(142.349457px, 230.66309px);
    }
    35% {
      transform: translate(142.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #eqN9J7lahuH14_ts {
    animation: eqN9J7lahuH14_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH14_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    60% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eqN9J7lahuH14 {
    animation: eqN9J7lahuH14_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH14_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH15_to {
    animation: eqN9J7lahuH15_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH15_to__to {
    0% {
      transform: translate(142.349457px, 229.66309px);
    }
    35% {
      transform: translate(142.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #eqN9J7lahuH15 {
    animation: eqN9J7lahuH15_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH15_c_o {
    0% {
      opacity: 0;
    }
    33.333333% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    76.666667% {
      opacity: 1;
    }
    78.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH17 {
    animation-name: eqN9J7lahuH17__m, eqN9J7lahuH17_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqN9J7lahuH17__m {
    0% {
      d: path('M1.620239,126.822142L1.620239,126.964981L1.620239,126.015447L1.574563,125.771302');
      animation-timing-function: cubic-bezier(0.319296, 0.369702, 0.634542, 0.749896);
    }
    11.666667% {
      d: path('M1.762467,92.702027L1.762467,92.733775L1.762467,92.607038L1.676009,50.333564');
      animation-timing-function: cubic-bezier(0.297615, 0.610899, 0.623772, 1);
    }
    21.666667% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
    100% {
      d: path('M1.803113,82.9513L1.803113,82.9513L1.803113,83.0597L1.705,3.0597');
    }
  }
  @keyframes eqN9J7lahuH17_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    18.333333% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH18_to {
    animation: eqN9J7lahuH18_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH18_to__to {
    0% {
      transform: translate(142.193038px, 127.481163px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, 0.993562px);
    }
    100% {
      transform: translate(142.01347px, 0.993562px);
    }
  }
  #eqN9J7lahuH18_ts {
    animation: eqN9J7lahuH18_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH18_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eqN9J7lahuH18 {
    animation: eqN9J7lahuH18_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH18_c_o {
    0% {
      opacity: 0.9;
    }
    21.666667% {
      opacity: 0.9;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH19_to {
    animation: eqN9J7lahuH19_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH19_to__to {
    0% {
      transform: translate(142.193038px, 121.258415px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(142.01347px, -4.33691px);
    }
    100% {
      transform: translate(142.01347px, -4.33691px);
    }
  }
  #eqN9J7lahuH19_ts {
    animation: eqN9J7lahuH19_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH19_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    8.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eqN9J7lahuH19 {
    animation: eqN9J7lahuH19_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH19_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqN9J7lahuH20_to {
    animation: eqN9J7lahuH20_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH20_to__to {
    0% {
      transform: translate(142.244248px, 120.335341px);
      animation-timing-function: cubic-bezier(0.430844, 0, 0.69332, 0.575556);
    }
    13.333333% {
      transform: translate(142.160623px, 33.975185px);
      animation-timing-function: cubic-bezier(0.28661, 0.558021, 0.597351, 1);
    }
    21.666667% {
      transform: translate(142.12347px, -5.171674px);
    }
    100% {
      transform: translate(142.12347px, -5.171674px);
    }
  }
  #eqN9J7lahuH20 {
    animation: eqN9J7lahuH20_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eqN9J7lahuH20_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.666667% {
      opacity: 1;
    }
    23.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg1;
