import { RuleSet, css } from 'styled-components';

export const sourceMdStyles: Record<number, RuleSet<object>> = {
  1: css`
    .source-box-1 {
      top: 104px;
      left: calc(50% - 24px);
    }
  `,
  2: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 233px);
    }
    .source-box-2 {
      top: 167px;
      left: calc(50% + 186px);
    }
  `,
  3: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 234px);
    }
    .source-box-2 {
      top: 103px;
      left: calc(50% - 25px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% + 186px);
    }
  `,
  4: css`
    .source-box-1 {
      top: 102px;
      left: calc(50% - 279px);
    }
    .source-box-2 {
      top: 167px;
      left: calc(50% - 133px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% + 91px);
    }
    .source-box-4 {
      top: 102px;
      left: calc(50% + 234px);
    }
  `,
  5: css`
    .source-box-1 {
      top: 102px;
      left: calc(50% - 280px);
    }
    .source-box-2 {
      top: 167px;
      left: calc(50% - 134px);
    }
    .source-box-3 {
      top: 104px;
      left: calc(50% - 23px);
    }
    .source-box-4 {
      top: 167px;
      left: calc(50% + 90px);
    }
    .source-box-5 {
      top: 102px;
      left: calc(50% + 233px);
    }
  `,
  6: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 332px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 186px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 88px);
    }
    .source-box-4 {
      top: 167px;
      left: calc(50% + 43px);
    }
    .source-box-5 {
      top: 102px;
      left: calc(50% + 132px);
    }
    .source-box-6 {
      top: 167px;
      left: calc(50% + 284px);
    }
  `,
  7: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 331px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 185px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 89px);
    }
    .source-box-4 {
      top: 104px;
      left: calc(50% - 23px);
    }
    .source-box-5 {
      top: 167px;
      left: calc(50% + 35px);
    }
    .source-box-6 {
      top: 102px;
      left: calc(50% + 133px);
    }
    .source-box-7 {
      top: 167px;
      left: calc(50% + 284px);
    }
  `,

  8: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 320px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 260px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 200px);
    }
    .source-box-4 {
      top: 102px;
      left: calc(50% - 140px);
    }
    .source-box-5 {
      top: 102px;
      left: calc(50% + 89px);
    }
    .source-box-6 {
      top: 167px;
      left: calc(50% + 153px);
    }
    .source-box-7 {
      top: 102px;
      left: calc(50% + 208px);
    }
    .source-box-8 {
      top: 167px;
      left: calc(50% + 274px);
    }
  `,

  9: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 321px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 261px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 202px);
    }
    .source-box-4 {
      top: 102px;
      left: calc(50% - 142px);
    }
    .source-box-5 {
      top: 102px;
      left: calc(50% - 23px);
    }
    .source-box-6 {
      top: 102px;
      left: calc(50% + 88px);
    }
    .source-box-7 {
      top: 167px;
      left: calc(50% + 152px);
    }
    .source-box-8 {
      top: 102px;
      left: calc(50% + 207px);
    }
    .source-box-9 {
      top: 167px;
      left: calc(50% + 273px);
    }
  `,

  10: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 328px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 273px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 221px);
    }
    .source-box-4 {
      top: 102px;
      left: calc(50% - 168px);
    }
    .source-box-5 {
      top: 167px;
      left: calc(50% - 114px);
    }
    .source-box-6 {
      top: 167px;
      left: calc(50% + 63px);
    }
    .source-box-7 {
      top: 102px;
      left: calc(50% + 113px);
    }
    .source-box-8 {
      top: 167px;
      left: calc(50% + 171px);
    }
    .source-box-9 {
      top: 102px;
      left: calc(50% + 220px);
    }
    .source-box-10 {
      top: 167px;
      left: calc(50% + 279px);
    }
  `,

  11: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 328px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 273px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 221px);
    }
    .source-box-4 {
      top: 102px;
      left: calc(50% - 168px);
    }
    .source-box-5 {
      top: 167px;
      left: calc(50% - 114px);
    }
    .source-box-6 {
      top: 102px;
      left: calc(50% - 21px);
    }
    .source-box-7 {
      top: 167px;
      left: calc(50% + 63px);
    }
    .source-box-8 {
      top: 102px;
      left: calc(50% + 113px);
    }
    .source-box-9 {
      top: 167px;
      left: calc(50% + 171px);
    }
    .source-box-10 {
      top: 102px;
      left: calc(50% + 220px);
    }
    .source-box-11 {
      top: 167px;
      left: calc(50% + 279px);
    }
  `,

  12: css`
    .source-box-1 {
      top: 167px;
      left: calc(50% - 331px);
    }
    .source-box-2 {
      top: 102px;
      left: calc(50% - 283px);
    }
    .source-box-3 {
      top: 167px;
      left: calc(50% - 234px);
    }
    .source-box-4 {
      top: 102px;
      left: calc(50% - 185px);
    }
    .source-box-5 {
      top: 167px;
      left: calc(50% - 136px);
    }
    .source-box-6 {
      top: 102px;
      left: calc(50% - 87px);
    }
    .source-box-7 {
      top: 102px;
      left: calc(50% + 35px);
    }
    .source-box-8 {
      top: 167px;
      left: calc(50% + 87px);
    }
    .source-box-9 {
      top: 102px;
      left: calc(50% + 133px);
    }
    .source-box-10 {
      top: 167px;
      left: calc(50% + 186px);
    }
    .source-box-11 {
      top: 102px;
      left: calc(50% + 231px);
    }
    .source-box-12 {
      top: 167px;
      left: calc(50% + 284px);
    }
  `,
};

export const sourceLgStyles: Record<number, RuleSet<object>> = {
  1: css`
    .source-box-1 {
      top: 140px;
      left: calc(50% - 32px);
    }
  `,
  2: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 311px);
    }
    .source-box-2 {
      top: 224px;
      left: calc(50% + 248px);
    }
  `,
  3: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 313px);
    }
    .source-box-2 {
      top: 139px;
      left: calc(50% - 35px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% + 246px);
    }
  `,
  4: css`
    .source-box-1 {
      top: 137px;
      left: calc(50% - 373px);
    }
    .source-box-2 {
      top: 224px;
      left: calc(50% - 177px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% + 121px);
    }
    .source-box-4 {
      top: 137px;
      left: calc(50% + 312px);
    }
  `,
  5: css`
    .source-box-1 {
      top: 139px;
      left: calc(50% - 374px);
    }
    .source-box-2 {
      top: 224px;
      left: calc(50% - 178px);
    }
    .source-box-3 {
      top: 139px;
      left: calc(50% - 31px);
    }
    .source-box-4 {
      top: 224px;
      left: calc(50% + 120px);
    }
    .source-box-5 {
      top: 139px;
      left: calc(50% + 310px);
    }
  `,
  6: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 443px);
    }
    .source-box-2 {
      top: 137px;
      left: calc(50% - 248px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% - 118px);
    }
    .source-box-4 {
      top: 224px;
      left: calc(50% + 56px);
    }
    .source-box-5 {
      top: 137px;
      left: calc(50% + 176px);
    }
    .source-box-6 {
      top: 224px;
      left: calc(50% + 378px);
    }
  `,
  7: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 443px);
    }
    .source-box-2 {
      top: 137px;
      left: calc(50% - 248px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% - 118px);
    }
    .source-box-4 {
      top: 139px;
      left: calc(50% - 32px);
    }
    .source-box-5 {
      top: 224px;
      left: calc(50% + 45px);
    }
    .source-box-6 {
      top: 137px;
      left: calc(50% + 176px);
    }
    .source-box-7 {
      top: 224px;
      left: calc(50% + 378px);
    }
  `,
  8: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 428px);
    }
    .source-box-2 {
      top: 137px;
      left: calc(50% - 347px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% - 267px);
    }
    .source-box-4 {
      top: 137px;
      left: calc(50% - 187px);
    }
    .source-box-5 {
      top: 137px;
      left: calc(50% + 119px);
    }
    .source-box-6 {
      top: 224px;
      left: calc(50% + 204px);
    }
    .source-box-7 {
      top: 137px;
      left: calc(50% + 279px);
    }
    .source-box-8 {
      top: 224px;
      left: calc(50% + 365px);
    }
  `,

  9: css`
    .source-box-1 {
      top: 209px;
      left: calc(50% - 427px);
    }
    .source-box-2 {
      top: 122px;
      left: calc(50% - 348px);
    }
    .source-box-3 {
      top: 209px;
      left: calc(50% - 268px);
    }
    .source-box-4 {
      top: 122px;
      left: calc(50% - 188px);
    }
    .source-box-5 {
      top: 122px;
      left: calc(50% - 30px);
    }
    .source-box-6 {
      top: 122px;
      left: calc(50% + 118px);
    }
    .source-box-7 {
      top: 209px;
      left: calc(50% + 203px);
    }
    .source-box-8 {
      top: 122px;
      left: calc(50% + 278px);
    }
    .source-box-9 {
      top: 209px;
      left: calc(50% + 364px);
    }
  `,

  10: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 436px);
    }
    .source-box-2 {
      top: 137px;
      left: calc(50% - 365px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% - 294px);
    }
    .source-box-4 {
      top: 137px;
      left: calc(50% - 222px);
    }
    .source-box-5 {
      top: 224px;
      left: calc(50% - 151px);
    }
    .source-box-6 {
      top: 224px;
      left: calc(50% + 86px);
    }
    .source-box-7 {
      top: 137px;
      left: calc(50% + 152px);
    }
    .source-box-8 {
      top: 224px;
      left: calc(50% + 229px);
    }
    .source-box-9 {
      top: 137px;
      left: calc(50% + 295px);
    }
    .source-box-10 {
      top: 224px;
      left: calc(50% + 373px);
    }
  `,

  11: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 437px);
    }
    .source-box-2 {
      top: 137px;
      left: calc(50% - 365px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% - 295px);
    }
    .source-box-4 {
      top: 137px;
      left: calc(50% - 223px);
    }
    .source-box-5 {
      top: 224px;
      left: calc(50% - 152px);
    }
    .source-box-6 {
      top: 139px;
      left: calc(50% - 29px);
    }
    .source-box-7 {
      top: 224px;
      left: calc(50% + 85px);
    }
    .source-box-8 {
      top: 137px;
      left: calc(50% + 151px);
    }
    .source-box-9 {
      top: 224px;
      left: calc(50% + 228px);
    }
    .source-box-10 {
      top: 137px;
      left: calc(50% + 294px);
    }
    .source-box-11 {
      top: 224px;
      left: calc(50% + 372px);
    }
  `,

  12: css`
    .source-box-1 {
      top: 224px;
      left: calc(50% - 443px);
    }
    .source-box-2 {
      top: 137px;
      left: calc(50% - 378px);
    }
    .source-box-3 {
      top: 224px;
      left: calc(50% - 313px);
    }
    .source-box-4 {
      top: 137px;
      left: calc(50% - 248px);
    }
    .source-box-5 {
      top: 224px;
      left: calc(50% - 183px);
    }
    .source-box-6 {
      top: 137px;
      left: calc(50% - 118px);
    }
    .source-box-7 {
      top: 137px;
      left: calc(50% + 46px);
    }
    .source-box-8 {
      top: 224px;
      left: calc(50% + 116px);
    }
    .source-box-9 {
      top: 137px;
      left: calc(50% + 176px);
    }
    .source-box-10 {
      top: 224px;
      left: calc(50% + 246px);
    }
    .source-box-11 {
      top: 137px;
      left: calc(50% + 307px);
    }
    .source-box-12 {
      top: 224px;
      left: calc(50% + 378px);
    }
  `,
};
