// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg4 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eMoKek5g1rf1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 328 234"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="328"
      height="234"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eMoKek5g1rf6-stroke"
          x1="282.586"
          y1="213.684"
          x2="242.632"
          y2="182.642"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf6-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eMoKek5g1rf6-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eMoKek5g1rf7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf7-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf8-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eMoKek5g1rf8-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eMoKek5g1rf8-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="eMoKek5g1rf9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf9-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf10-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eMoKek5g1rf12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf12-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf13-stroke"
          x1="-38.813037"
          y1="6.663091"
          x2="28.23747"
          y2="134.904667"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eMoKek5g1rf13-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eMoKek5g1rf13-stroke-1" offset="100%" stopColor="rgba(3,3,3,0)" />
        </linearGradient>
        <filter id="eMoKek5g1rf14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf14-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf15-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eMoKek5g1rf18-stroke"
          x1="188.844"
          y1="214.159"
          x2="170.027"
          y2="209.803"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf18-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eMoKek5g1rf18-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eMoKek5g1rf19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf19-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf20-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf20-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eMoKek5g1rf20-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eMoKek5g1rf21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf21-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf21-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf21-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf21-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf21-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf21-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf21-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf22-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf23-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eMoKek5g1rf24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf24-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf25-stroke"
          x1="4.205"
          y1="130.275255"
          x2="-22.32247"
          y2="7.207539"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf25-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eMoKek5g1rf25-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eMoKek5g1rf26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf26-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf27-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf28-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eMoKek5g1rf30-stroke"
          x1="95.125"
          y1="214.384"
          x2="114.397"
          y2="209.963"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf30-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eMoKek5g1rf30-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eMoKek5g1rf31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf31-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf32-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf32-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="eMoKek5g1rf32-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eMoKek5g1rf33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf33-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf34-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf35-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eMoKek5g1rf36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf36-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf37-stroke"
          x1="1.786927"
          y1="125.771301"
          x2="24.98947"
          y2="6.66309"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf37-stroke-0" offset="0%" stopColor="rgba(21,21,21,0)" />
          <stop
            id="eMoKek5g1rf37-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eMoKek5g1rf38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf38-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf39-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf40-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eMoKek5g1rf42-stroke"
          x1="1.46094"
          y1="214.01"
          x2="43.493"
          y2="181.528"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf42-stroke-0" offset="0%" stopColor="#030303" />
          <stop id="eMoKek5g1rf42-stroke-1" offset="100%" stopColor={styles.strokeColor} />
        </linearGradient>
        <filter id="eMoKek5g1rf43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf43-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf44-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf44-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eMoKek5g1rf44-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eMoKek5g1rf45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf45-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf46-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf47-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf47-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eMoKek5g1rf48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eMoKek5g1rf48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf48-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eMoKek5g1rf48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eMoKek5g1rf49-stroke"
          x1="1.43747"
          y1="131.66309"
          x2="72.379913"
          y2="6.663091"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf49-stroke-0" offset="0%" stopColor="rgba(3,3,3,0)" />
          <stop
            id="eMoKek5g1rf49-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eMoKek5g1rf50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf50-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eMoKek5g1rf51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eMoKek5g1rf51-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eMoKek5g1rf51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eMoKek5g1rf51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eMoKek5g1rf51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eMoKek5g1rf51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eMoKek5g1rf52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eMoKek5g1rf52-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <linearGradient
          id="eMoKek5g1rf53-fill"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="0.748406"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eMoKek5g1rf53-fill-0" offset="0%" stopColor="rgba(17,22,72,0)" />
          <stop id="eMoKek5g1rf53-fill-1" offset="53%" stopColor="rgba(17,22,72,0.8)" />
          <stop id="eMoKek5g1rf53-fill-2" offset="100%" stopColor="rgba(17,22,72,1)" />
        </linearGradient>
      </defs>
      <g mask="url(#eMoKek5g1rf54)">
        <g>
          <g transform="translate(21.97653-6.66309)">
            <g transform="translate(2 0)">
              <path
                d="M284.586,213.684v-85.983L230.273,82.9652l-.004-76.30211"
                fill="none"
                stroke="url(#eMoKek5g1rf6-stroke)"
              />
              <g transform="translate(388 0)" filter="url(#eMoKek5g1rf7-filter)">
                <path
                  id="eMoKek5g1rf8"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf8-stroke)"
                />
                <g id="eMoKek5g1rf9_to" transform="translate(-104.650543,236.66309)">
                  <g id="eMoKek5g1rf9_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf9_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf9-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf10_to" transform="translate(-104.650543,230.66309)">
                  <g id="eMoKek5g1rf10_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf10_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf10-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf11_to" transform="translate(-104.650543,229.66309)">
                  <g id="eMoKek5g1rf11_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf11-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(388 0)" filter="url(#eMoKek5g1rf12-filter)">
                <path
                  id="eMoKek5g1rf13"
                  d="M31.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf13-stroke)"
                />
                <g id="eMoKek5g1rf14_to" transform="translate(-104.650543,236.66309)">
                  <g id="eMoKek5g1rf14_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf14_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf14-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf15_to" transform="translate(-104.650543,230.66309)">
                  <g id="eMoKek5g1rf15_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf15_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf15-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf16_to" transform="translate(-104.650543,229.66309)">
                  <g id="eMoKek5g1rf16_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf16-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path
                d="M188.844,214.159v-86.374L171.396,83.0597l-.034265-77"
                fill="none"
                stroke="url(#eMoKek5g1rf18-stroke)"
              />
              <g transform="translate(94 0)" filter="url(#eMoKek5g1rf19-filter)">
                <path
                  id="eMoKek5g1rf20"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf20-stroke)"
                />
                <g id="eMoKek5g1rf21_to" transform="translate(95.02347,237.66309)">
                  <g id="eMoKek5g1rf21_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf21_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf21"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf21-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf22_to" transform="translate(95.02347,230.66309)">
                  <g id="eMoKek5g1rf22_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf22_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf22"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf22-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf23_to" transform="translate(94.62347,229.66309)">
                  <g id="eMoKek5g1rf23_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf23"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf23-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(94 0)" filter="url(#eMoKek5g1rf24-filter)">
                <path
                  id="eMoKek5g1rf25"
                  d="M1.12653,127.98921v-.037l-.000999.030721h-.000001"
                  transform="translate(93.71847-.28821)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf25-stroke)"
                />
                <g id="eMoKek5g1rf26_to" transform="translate(95.02347,237.66309)">
                  <g id="eMoKek5g1rf26_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf26_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf26"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf26-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf27_to" transform="translate(95.02347,230.66309)">
                  <g id="eMoKek5g1rf27_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf27_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf27"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf27-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf28_to" transform="translate(94.62347,229.66309)">
                  <g id="eMoKek5g1rf28_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf28"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf28-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-1 0.000001)">
              <path
                d="M95.125,214.384v-86.716l17.88847-45.004911v-76"
                fill="none"
                stroke="url(#eMoKek5g1rf30-stroke)"
              />
              <g filter="url(#eMoKek5g1rf31-filter)">
                <path
                  id="eMoKek5g1rf32"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf32-stroke)"
                />
                <g id="eMoKek5g1rf33_to" transform="translate(95.349457,237.66309)">
                  <g id="eMoKek5g1rf33_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf33_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf33"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf33-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf34_to" transform="translate(95.349457,230.66309)">
                  <g id="eMoKek5g1rf34_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf34_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf34"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf34-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf35_to" transform="translate(95.349457,229.66309)">
                  <g id="eMoKek5g1rf35_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf35"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf35-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eMoKek5g1rf36-filter)">
                <path
                  id="eMoKek5g1rf37"
                  d="M1.45094,127.836999l.01-.172999-.01.172999v-.173"
                  transform="translate(93.56253 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf37-stroke)"
                />
                <g id="eMoKek5g1rf38_to" transform="translate(95.349457,237.66309)">
                  <g id="eMoKek5g1rf38_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf38_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf38"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf38-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf39_to" transform="translate(95.349457,230.66309)">
                  <g id="eMoKek5g1rf39_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf39_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf39"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf39-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf40_to" transform="translate(95.349457,229.66309)">
                  <g id="eMoKek5g1rf40_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf40"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf40-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(134 0)">
              <path
                d="M1.46094,214.01v-86.346L55.705,82.9513v-76.28821"
                transform="translate(-137.088013 0)"
                fill="none"
                stroke="url(#eMoKek5g1rf42-stroke)"
              />
              <g filter="url(#eMoKek5g1rf43-filter)">
                <path
                  id="eMoKek5g1rf44"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf44-stroke)"
                />
                <g id="eMoKek5g1rf45_to" transform="translate(-135.650543,235.66309)">
                  <g id="eMoKek5g1rf45_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf45_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf45"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf45-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf46_to" transform="translate(-135.650543,230.66309)">
                  <g id="eMoKek5g1rf46_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf46_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf46"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf46-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf47_to" transform="translate(-135.650543,229.66309)">
                  <g id="eMoKek5g1rf47_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf47"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf47-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#eMoKek5g1rf48-filter)">
                <path
                  id="eMoKek5g1rf49"
                  d="M1.46094,128.01v-.346.121h-.02347"
                  transform="translate(-137.088013 0)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eMoKek5g1rf49-stroke)"
                />
                <g id="eMoKek5g1rf50_to" transform="translate(-135.650543,235.66309)">
                  <g id="eMoKek5g1rf50_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf50_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf50"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf50-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf51_to" transform="translate(-135.650543,230.66309)">
                  <g id="eMoKek5g1rf51_tr" transform="rotate(0)">
                    <g id="eMoKek5g1rf51_ts" transform="scale(0.8,0.8)">
                      <rect
                        id="eMoKek5g1rf51"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.66309)"
                        opacity="0"
                        filter="url(#eMoKek5g1rf51-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eMoKek5g1rf52_to" transform="translate(-135.650543,229.66309)">
                  <g id="eMoKek5g1rf52_tr" transform="rotate(0)">
                    <rect
                      id="eMoKek5g1rf52"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.4,0.4) translate(-4,-6.66309)"
                      opacity="0"
                      filter="url(#eMoKek5g1rf52-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <rect
            width="328"
            height="61.866793"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1.616376 0 134)"
            fill="url(#eMoKek5g1rf53-fill)"
            strokeWidth="0"
          />
        </g>
        <mask id="eMoKek5g1rf54" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect width="328" height="234" rx="0" ry="0" fill="#d2dbed" strokeWidth="0" />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eMoKek5g1rf8 {
    animation-name: eMoKek5g1rf8__m, eMoKek5g1rf8_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf8__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    21.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    46.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes eMoKek5g1rf8_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    41.666667% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf9_to {
    animation: eMoKek5g1rf9_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf9_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    5% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    23.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    25% {
      transform: translate(-114.42px, 118.973437px);
    }
    33.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    35% {
      transform: translate(-156.650543px, 85.099607px);
    }
    36.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    46.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #eMoKek5g1rf9_tr {
    animation: eMoKek5g1rf9_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf9_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(-50deg);
    }
    36.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf9_ts {
    animation: eMoKek5g1rf9_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf9_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf9 {
    animation: eMoKek5g1rf9_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf9_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0.9;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf10_to {
    animation: eMoKek5g1rf10_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf10_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    5% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    23.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    25% {
      transform: translate(-118.131567px, 116.016274px);
    }
    33.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    35% {
      transform: translate(-157.988371px, 81.66309px);
    }
    36.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    46.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #eMoKek5g1rf10_tr {
    animation: eMoKek5g1rf10_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf10_ts {
    animation: eMoKek5g1rf10_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf10_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    35% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf10 {
    animation: eMoKek5g1rf10_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf10_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf11_to {
    animation: eMoKek5g1rf11_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf11_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    5% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    23.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    25% {
      transform: translate(-118.48136px, 115.681349px);
    }
    33.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    35% {
      transform: translate(-157.87653px, 80.828326px);
    }
    36.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    46.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #eMoKek5g1rf11_tr {
    animation: eMoKek5g1rf11_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(-50deg);
    }
    25% {
      transform: rotate(-50deg);
    }
    33.333333% {
      transform: rotate(-50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf11 {
    animation: eMoKek5g1rf11_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf11_c_o {
    0% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf13 {
    animation-name: eMoKek5g1rf13__m, eMoKek5g1rf13_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf13__m {
    0% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
    }
    66.666667% {
      d: path('M31.46094,128.01L31.46094,127.664L31.46094,127.785L31.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M32.674013,127.701L32.674013,127.701L-20.295,82.9513L-20.295,83.0597');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    88.333333% {
      d: path('M-12.054849,90.545445L-12.054849,90.545445L-21.125297,82.9513L-21.125297,27.635917');
    }
    91.666667% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
    100% {
      d: path('M-21.196887,82.9513L-21.196887,82.9513L-21.295,82.9513L-21.295,3.0597');
    }
  }
  @keyframes eMoKek5g1rf13_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    88.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf14_to {
    animation: eMoKek5g1rf14_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf14_to__to {
    0% {
      transform: translate(-104.650543px, 236.66309px);
    }
    50% {
      transform: translate(-104.650543px, 236.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 135.66309px);
    }
    68.333333% {
      transform: translate(-105.650543px, 127.993562px);
    }
    70% {
      transform: translate(-114.42px, 118.973437px);
    }
    78.333333% {
      transform: translate(-152.650543px, 87.899607px);
    }
    80% {
      transform: translate(-156.650543px, 85.099607px);
    }
    81.666667% {
      transform: translate(-157.98653px, 78.993562px);
    }
    91.666667% {
      transform: translate(-157.98653px, 0.993562px);
    }
    100% {
      transform: translate(-157.98653px, 0.993562px);
    }
  }
  #eMoKek5g1rf14_tr {
    animation: eMoKek5g1rf14_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(-50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf14_ts {
    animation: eMoKek5g1rf14_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf14_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf14 {
    animation: eMoKek5g1rf14_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf14_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    91.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf15_to {
    animation: eMoKek5g1rf15_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf15_to__to {
    0% {
      transform: translate(-104.650543px, 230.66309px);
    }
    50% {
      transform: translate(-104.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 130.66309px);
    }
    68.333333% {
      transform: translate(-107.360206px, 124.599607px);
    }
    70% {
      transform: translate(-118.131567px, 116.016274px);
    }
    78.333333% {
      transform: translate(-155.988371px, 85.099607px);
    }
    80% {
      transform: translate(-157.988371px, 81.66309px);
    }
    81.666667% {
      transform: translate(-157.988371px, 75.66309px);
    }
    91.666667% {
      transform: translate(-157.988371px, -4.33691px);
    }
    100% {
      transform: translate(-157.988371px, -4.33691px);
    }
  }
  #eMoKek5g1rf15_tr {
    animation: eMoKek5g1rf15_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf15_ts {
    animation: eMoKek5g1rf15_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf15_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    66.666667% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf15 {
    animation: eMoKek5g1rf15_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf15_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf16_to {
    animation: eMoKek5g1rf16_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf16_to__to {
    0% {
      transform: translate(-104.650543px, 229.66309px);
    }
    50% {
      transform: translate(-104.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.666667% {
      transform: translate(-104.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-108.650543px, 123.781349px);
    }
    70% {
      transform: translate(-118.48136px, 115.681349px);
    }
    78.333333% {
      transform: translate(-155.906381px, 85.181349px);
    }
    80% {
      transform: translate(-157.87653px, 80.828326px);
    }
    81.666667% {
      transform: translate(-158.07653px, 74.828326px);
    }
    91.666667% {
      transform: translate(-158.07653px, -5.171674px);
    }
    100% {
      transform: translate(-158.07653px, -5.171674px);
    }
  }
  #eMoKek5g1rf16_tr {
    animation: eMoKek5g1rf16_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.666667% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(-50deg);
    }
    70% {
      transform: rotate(-50deg);
    }
    78.333333% {
      transform: rotate(-50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf16 {
    animation: eMoKek5g1rf16_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf16_c_o {
    0% {
      opacity: 0;
    }
    48.333333% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    91.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf20 {
    animation-name: eMoKek5g1rf20__m, eMoKek5g1rf20_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf20__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    30% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    41.666667% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    51.666667% {
      d: path('M-14.393265,88.779336L-14.393265,88.779336L-16.643335,83.174861L-16.643335,23.521822');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    55% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes eMoKek5g1rf20_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.666667% {
      opacity: 1;
    }
    51.666667% {
      opacity: 1;
    }
    58.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf21_to {
    animation: eMoKek5g1rf21_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf21_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 136.66309px);
    }
    31.666667% {
      transform: translate(95.02347px, 129.993562px);
    }
    33.333333% {
      transform: translate(92.792379px, 121.74181px);
    }
    41.666667% {
      transform: translate(79.572206px, 88.521493px);
    }
    43.333333% {
      transform: translate(77.728902px, 84.993562px);
    }
    45% {
      transform: translate(77.02347px, 79.660944px);
    }
    55% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #eMoKek5g1rf21_tr {
    animation: eMoKek5g1rf21_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(0deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(-25deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf21_ts {
    animation: eMoKek5g1rf21_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf21_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf21 {
    animation: eMoKek5g1rf21_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf21_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 0.9;
    }
    55% {
      opacity: 0.9;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf22_to {
    animation: eMoKek5g1rf22_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf22_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    13.333333% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.02347px, 130.66309px);
    }
    31.666667% {
      transform: translate(94.17641px, 125.66309px);
    }
    33.333333% {
      transform: translate(91px, 117.66309px);
    }
    41.666667% {
      transform: translate(78.011629px, 85.140794px);
    }
    43.333333% {
      transform: translate(77px, 81.5px);
    }
    45% {
      transform: translate(77.02347px, 75.66309px);
    }
    55% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #eMoKek5g1rf22_tr {
    animation: eMoKek5g1rf22_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf22_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf22_ts {
    animation: eMoKek5g1rf22_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf22_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.8, 0.8);
    }
    43.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf22 {
    animation: eMoKek5g1rf22_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf22_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf23_to {
    animation: eMoKek5g1rf23_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf23_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    13.333333% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    31.666667% {
      transform: translate(93.59994px, 124.50245px);
    }
    33.333333% {
      transform: translate(90.3px, 116.5px);
    }
    41.666667% {
      transform: translate(77.51864px, 84.381349px);
    }
    43.333333% {
      transform: translate(77.12347px, 80.828326px);
    }
    45% {
      transform: translate(77.12347px, 75.328326px);
    }
    55% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #eMoKek5g1rf23_tr {
    animation: eMoKek5g1rf23_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf23_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.666667% {
      transform: rotate(-25deg);
    }
    33.333333% {
      transform: rotate(-25deg);
    }
    41.666667% {
      transform: rotate(-25deg);
    }
    43.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf23 {
    animation: eMoKek5g1rf23_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf23_c_o {
    0% {
      opacity: 0;
    }
    11.666667% {
      opacity: 0;
    }
    13.333333% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    56.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf25 {
    animation-name: eMoKek5g1rf25__m, eMoKek5g1rf25_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf25__m {
    0% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
    }
    73.333333% {
      d: path('M1.12653,127.98921L1.12653,127.95221L1.125531,127.982931L1.12553,127.982931');
      animation-timing-function: cubic-bezier(0.434076, 0, 0.669782, 0.65636);
    }
    85% {
      d: path('M1.12553,128.07321L1.12553,128.07321L-16.295,83.9513L-16.295,84.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    93.333333% {
      d: path('M-11.477665,96.161688L-11.477665,96.161688L-16.577892,83.320735L-16.577892,35.774152');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    98.333333% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
    100% {
      d: path('M-16.695,82.9513L-16.695,82.9513L-16.695,83.0597L-16.695,3.0597');
    }
  }
  @keyframes eMoKek5g1rf25_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0.25;
    }
  }
  #eMoKek5g1rf26_to {
    animation: eMoKek5g1rf26_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf26_to__to {
    0% {
      transform: translate(95.02347px, 237.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 136.66309px);
    }
    75% {
      transform: translate(95.02347px, 129.993562px);
    }
    76.666667% {
      transform: translate(92.792379px, 121.74181px);
    }
    85% {
      transform: translate(79.572206px, 88.521493px);
    }
    86.666667% {
      transform: translate(77.728902px, 84.993562px);
    }
    88.333333% {
      transform: translate(77.02347px, 79.660944px);
    }
    98.333333% {
      transform: translate(77.22347px, 0.993562px);
    }
    100% {
      transform: translate(77.22347px, 0.993562px);
    }
  }
  #eMoKek5g1rf26_tr {
    animation: eMoKek5g1rf26_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(0deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(-25deg);
    }
    88.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf26_ts {
    animation: eMoKek5g1rf26_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf26_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf26 {
    animation: eMoKek5g1rf26_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf26_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 0.9;
    }
    98.333333% {
      opacity: 0.9;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf27_to {
    animation: eMoKek5g1rf27_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf27_to__to {
    0% {
      transform: translate(95.02347px, 230.66309px);
    }
    56.666667% {
      transform: translate(95.02347px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.02347px, 130.66309px);
    }
    75% {
      transform: translate(94.17641px, 125.66309px);
    }
    76.666667% {
      transform: translate(91px, 117.66309px);
    }
    85% {
      transform: translate(78.011629px, 85.140794px);
    }
    86.666667% {
      transform: translate(77px, 81.5px);
    }
    88.333333% {
      transform: translate(77.02347px, 75.66309px);
    }
    98.333333% {
      transform: translate(77.22347px, -4.33691px);
    }
    100% {
      transform: translate(77.22347px, -4.33691px);
    }
  }
  #eMoKek5g1rf27_tr {
    animation: eMoKek5g1rf27_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf27_ts {
    animation: eMoKek5g1rf27_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf27_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(0.8, 0.8);
    }
    86.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf27 {
    animation: eMoKek5g1rf27_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf27_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf28_to {
    animation: eMoKek5g1rf28_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf28_to__to {
    0% {
      transform: translate(94.62347px, 229.66309px);
    }
    56.666667% {
      transform: translate(94.62347px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.333333% {
      transform: translate(95.12347px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      transform: translate(93.59994px, 124.50245px);
    }
    76.666667% {
      transform: translate(90.3px, 116.5px);
    }
    85% {
      transform: translate(77.51864px, 84.381349px);
    }
    86.666667% {
      transform: translate(77.12347px, 80.828326px);
    }
    88.333333% {
      transform: translate(77.12347px, 75.328326px);
    }
    98.333333% {
      transform: translate(77.22347px, -5.171674px);
    }
    100% {
      transform: translate(77.22347px, -5.171674px);
    }
  }
  #eMoKek5g1rf28_tr {
    animation: eMoKek5g1rf28_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    73.333333% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-25deg);
    }
    76.666667% {
      transform: rotate(-25deg);
    }
    85% {
      transform: rotate(-25deg);
    }
    86.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf28 {
    animation: eMoKek5g1rf28_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf28_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    56.666667% {
      opacity: 1;
    }
    98.333333% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf32 {
    animation-name: eMoKek5g1rf32__m, eMoKek5g1rf32_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf32__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    25% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.666667% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.433963, 0, 0.659745, 0.689596);
    }
    46.666667% {
      d: path('M17.127316,88.675201L17.144733,88.59732L19.483755,83.045699L19.705,23.39266');
      animation-timing-function: cubic-bezier(0.296462, 0.607824, 0.621891, 1);
    }
    50% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes eMoKek5g1rf32_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    46.666667% {
      opacity: 1;
    }
    53.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf33_to {
    animation: eMoKek5g1rf33_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf33_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 136.66309px);
    }
    26.666667% {
      transform: translate(95.349457px, 127.493561px);
    }
    28.333333% {
      transform: translate(96.886038px, 122.125682px);
    }
    36.666667% {
      transform: translate(110.349457px, 88.540794px);
    }
    38.333333% {
      transform: translate(112px, 84.993562px);
    }
    40% {
      transform: translate(113.22347px, 79.460943px);
    }
    50% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #eMoKek5g1rf33_tr {
    animation: eMoKek5g1rf33_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(0deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(25deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf33_ts {
    animation: eMoKek5g1rf33_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf33_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf33 {
    animation: eMoKek5g1rf33_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf33_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.9;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf34_to {
    animation: eMoKek5g1rf34_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf34_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 130.66309px);
    }
    26.666667% {
      transform: translate(96.5px, 122.66309px);
    }
    28.333333% {
      transform: translate(99px, 117.66309px);
    }
    36.666667% {
      transform: translate(112.011629px, 85.140794px);
    }
    38.333333% {
      transform: translate(113px, 81px);
    }
    40% {
      transform: translate(113.22347px, 75.66309px);
    }
    50% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #eMoKek5g1rf34_tr {
    animation: eMoKek5g1rf34_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf34_ts {
    animation: eMoKek5g1rf34_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf34_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    26.666667% {
      transform: scale(0.8, 0.8);
    }
    38.333333% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf34 {
    animation: eMoKek5g1rf34_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf34_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf35_to {
    animation: eMoKek5g1rf35_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf35_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    8.333333% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    25% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    26.666667% {
      transform: translate(97px, 121.50245px);
    }
    28.333333% {
      transform: translate(99.5px, 116.5px);
    }
    36.666667% {
      transform: translate(112.51864px, 84.381349px);
    }
    38.333333% {
      transform: translate(113px, 80px);
    }
    40% {
      transform: translate(113.12347px, 74.828326px);
    }
    50% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #eMoKek5g1rf35_tr {
    animation: eMoKek5g1rf35_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    26.666667% {
      transform: rotate(25deg);
    }
    28.333333% {
      transform: rotate(25deg);
    }
    36.666667% {
      transform: rotate(25deg);
    }
    38.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf35 {
    animation: eMoKek5g1rf35_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf35_c_o {
    0% {
      opacity: 0;
    }
    6.666667% {
      opacity: 0;
    }
    8.333333% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf37 {
    animation-name: eMoKek5g1rf37__m, eMoKek5g1rf37_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf37__m {
    0% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
    }
    68.333333% {
      d: path('M1.45094,127.836999L1.46094,127.664L1.45094,127.836999L1.45094,127.663999');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M1.46094,127.266975L1.46094,126.664L19.705,82.9513L19.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M14.183989,95.925645L14.198135,95.749112L19.525321,83.027964L19.705,35.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
    100% {
      d: path('M19.45094,82.9513L19.470941,82.9513L19.45094,83.0597L19.705,3.0597');
    }
  }
  @keyframes eMoKek5g1rf37_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf38_to {
    animation: eMoKek5g1rf38_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf38_to__to {
    0% {
      transform: translate(95.349457px, 237.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 237.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 136.66309px);
    }
    70% {
      transform: translate(95.349457px, 127.493561px);
    }
    71.666667% {
      transform: translate(96.886038px, 122.125682px);
    }
    80% {
      transform: translate(110.349457px, 88.540794px);
    }
    81.666667% {
      transform: translate(112px, 84.993562px);
    }
    83.333333% {
      transform: translate(113.22347px, 79.460943px);
    }
    93.333333% {
      transform: translate(113.01347px, 0.993562px);
    }
    100% {
      transform: translate(113.01347px, 0.993562px);
    }
  }
  #eMoKek5g1rf38_tr {
    animation: eMoKek5g1rf38_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(25deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf38_ts {
    animation: eMoKek5g1rf38_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf38_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf38 {
    animation: eMoKek5g1rf38_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf38_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf39_to {
    animation: eMoKek5g1rf39_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf39_to__to {
    0% {
      transform: translate(95.349457px, 230.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 130.66309px);
    }
    70% {
      transform: translate(96.5px, 122.66309px);
    }
    71.666667% {
      transform: translate(99px, 117.66309px);
    }
    80% {
      transform: translate(112.011629px, 85.140794px);
    }
    81.666667% {
      transform: translate(113px, 81px);
    }
    83.333333% {
      transform: translate(113.22347px, 75.66309px);
    }
    93.333333% {
      transform: translate(113.01347px, -4.33691px);
    }
    100% {
      transform: translate(113.01347px, -4.33691px);
    }
  }
  #eMoKek5g1rf39_tr {
    animation: eMoKek5g1rf39_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf39_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf39_ts {
    animation: eMoKek5g1rf39_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf39_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    81.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf39 {
    animation: eMoKek5g1rf39_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf39_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf40_to {
    animation: eMoKek5g1rf40_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf40_to__to {
    0% {
      transform: translate(95.349457px, 229.66309px);
    }
    51.666667% {
      transform: translate(95.349457px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(95.349457px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(97px, 121.50245px);
    }
    71.666667% {
      transform: translate(99.5px, 116.5px);
    }
    80% {
      transform: translate(112.51864px, 84.381349px);
    }
    81.666667% {
      transform: translate(113px, 80px);
    }
    83.333333% {
      transform: translate(113.12347px, 74.828326px);
    }
    93.333333% {
      transform: translate(112.82347px, -5.171674px);
    }
    100% {
      transform: translate(112.82347px, -5.171674px);
    }
  }
  #eMoKek5g1rf40_tr {
    animation: eMoKek5g1rf40_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf40_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(25deg);
    }
    71.666667% {
      transform: rotate(25deg);
    }
    80% {
      transform: rotate(25deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf40 {
    animation: eMoKek5g1rf40_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf40_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf44 {
    animation-name: eMoKek5g1rf44__m, eMoKek5g1rf44_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf44__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    21.666667% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    33.333333% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.400996, 0, 0.713116, 0.43784);
    }
    38.333333% {
      d: path('M17.077982,114.999859L17.077982,114.573417L55.412229,82.9513L55.412229,49.638019');
      animation-timing-function: cubic-bezier(0.308638, 0.417271, 0.568598, 1);
    }
    46.666667% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M55.803113,82.9513L55.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes eMoKek5g1rf44_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    43.333333% {
      opacity: 1;
    }
    48.333333% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf45_to {
    animation: eMoKek5g1rf45_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf45_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 135.66309px);
    }
    21.666667% {
      transform: translate(-135.650543px, 130.993562px);
    }
    23.333333% {
      transform: translate(-129.28364px, 121.74181px);
    }
    31.666667% {
      transform: translate(-86.650543px, 86.993562px);
    }
    33.333333% {
      transform: translate(-83.650543px, 83.993562px);
    }
    35% {
      transform: translate(-81.98653px, 79.993562px);
    }
    45% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #eMoKek5g1rf45_tr {
    animation: eMoKek5g1rf45_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(0deg);
    }
    23.333333% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf45_ts {
    animation: eMoKek5g1rf45_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf45_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf45 {
    animation: eMoKek5g1rf45_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf45_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 0.9;
    }
    45% {
      opacity: 0.9;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf46_to {
    animation: eMoKek5g1rf46_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf46_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 130.66309px);
    }
    21.666667% {
      transform: translate(-133.988371px, 126.66309px);
    }
    23.333333% {
      transform: translate(-125.655038px, 118.66309px);
    }
    31.666667% {
      transform: translate(-83.988371px, 84.66309px);
    }
    33.333333% {
      transform: translate(-81.988371px, 80.66309px);
    }
    35% {
      transform: translate(-81.988371px, 75.66309px);
    }
    45% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #eMoKek5g1rf46_tr {
    animation: eMoKek5g1rf46_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf46_ts {
    animation: eMoKek5g1rf46_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf46_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    21.666667% {
      transform: scale(0.8, 0.8);
    }
    31.666667% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf46 {
    animation: eMoKek5g1rf46_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf46_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf47_to {
    animation: eMoKek5g1rf47_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf47_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    3.333333% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.666667% {
      transform: translate(-132.650543px, 125.50245px);
    }
    23.333333% {
      transform: translate(-125.48136px, 118.381349px);
    }
    31.666667% {
      transform: translate(-83.48136px, 84.381349px);
    }
    33.333333% {
      transform: translate(-81.87653px, 79.828326px);
    }
    35% {
      transform: translate(-81.87653px, 74.828326px);
    }
    45% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #eMoKek5g1rf47_tr {
    animation: eMoKek5g1rf47_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf47_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.666667% {
      transform: rotate(50deg);
    }
    31.666667% {
      transform: rotate(50deg);
    }
    33.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf47 {
    animation: eMoKek5g1rf47_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf47_c_o {
    0% {
      opacity: 0;
    }
    1.666667% {
      opacity: 0;
    }
    3.333333% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf49 {
    animation-name: eMoKek5g1rf49__m, eMoKek5g1rf49_c_o;
    animation-duration: 6000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eMoKek5g1rf49__m {
    0% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
    }
    68.333333% {
      d: path('M1.46094,128.01L1.46094,127.664L1.46094,127.785L1.43747,127.785');
      animation-timing-function: cubic-bezier(0.430309, 0, 0.626686, 0.804094);
    }
    78.333333% {
      d: path('M1.46094,128.256464L1.46094,127.664L46.219884,90.745962L46.219884,90.745961');
      animation-timing-function: cubic-bezier(0.310712, 0.637203, 0.641852, 1);
    }
    80% {
      d: path('M1.46094,128.266975L1.46094,127.664L55.705,82.9513L55.705,83.0597');
      animation-timing-function: cubic-bezier(0.431402, 0, 0.691362, 0.582729);
    }
    88.333333% {
      d: path('M39.186071,96.218416L39.186071,96.041883L54.997771,82.9513L54.997771,34.481381');
      animation-timing-function: cubic-bezier(0.286884, 0.56216, 0.599004, 1);
    }
    93.333333% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
    100% {
      d: path('M54.803113,82.9513L54.803113,82.9513L55.705,82.9513L55.705,3.0597');
    }
  }
  @keyframes eMoKek5g1rf49_c_o {
    0% {
      opacity: 0;
    }
    61.666667% {
      opacity: 0;
    }
    63.333333% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.666667% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf50_to {
    animation: eMoKek5g1rf50_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf50_to__to {
    0% {
      transform: translate(-135.650543px, 235.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 235.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 135.66309px);
    }
    70% {
      transform: translate(-135.650543px, 130.993562px);
    }
    71.666667% {
      transform: translate(-129.28364px, 121.74181px);
    }
    80% {
      transform: translate(-86.650543px, 86.993562px);
    }
    81.666667% {
      transform: translate(-83.650543px, 83.993562px);
    }
    83.333333% {
      transform: translate(-81.98653px, 79.993562px);
    }
    93.333333% {
      transform: translate(-81.98653px, 0.993562px);
    }
    100% {
      transform: translate(-81.98653px, 0.993562px);
    }
  }
  #eMoKek5g1rf50_tr {
    animation: eMoKek5g1rf50_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.666667% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(50deg);
    }
    83.333333% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf50_ts {
    animation: eMoKek5g1rf50_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf50_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf50 {
    animation: eMoKek5g1rf50_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf50_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 0.9;
    }
    93.333333% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf51_to {
    animation: eMoKek5g1rf51_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf51_to__to {
    0% {
      transform: translate(-135.650543px, 230.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 230.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 130.66309px);
    }
    70% {
      transform: translate(-133.988371px, 126.66309px);
    }
    71.666667% {
      transform: translate(-125.655038px, 118.66309px);
    }
    80% {
      transform: translate(-83.988371px, 84.66309px);
    }
    81.666667% {
      transform: translate(-81.988371px, 80.66309px);
    }
    83.333333% {
      transform: translate(-81.988371px, 75.66309px);
    }
    93.333333% {
      transform: translate(-81.988371px, -4.33691px);
    }
    100% {
      transform: translate(-81.988371px, -4.33691px);
    }
  }
  #eMoKek5g1rf51_tr {
    animation: eMoKek5g1rf51_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf51_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf51_ts {
    animation: eMoKek5g1rf51_ts__ts 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf51_ts__ts {
    0% {
      transform: scale(0.8, 0.8);
    }
    70% {
      transform: scale(0.8, 0.8);
    }
    80% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
  #eMoKek5g1rf51 {
    animation: eMoKek5g1rf51_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf51_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eMoKek5g1rf52_to {
    animation: eMoKek5g1rf52_to__to 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf52_to__to {
    0% {
      transform: translate(-135.650543px, 229.66309px);
    }
    51.666667% {
      transform: translate(-135.650543px, 229.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.333333% {
      transform: translate(-135.650543px, 129.66309px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
      transform: translate(-132.650543px, 125.50245px);
    }
    71.666667% {
      transform: translate(-125.48136px, 118.381349px);
    }
    80% {
      transform: translate(-83.48136px, 84.381349px);
    }
    81.666667% {
      transform: translate(-81.87653px, 79.828326px);
    }
    83.333333% {
      transform: translate(-81.87653px, 74.828326px);
    }
    93.333333% {
      transform: translate(-81.87653px, -5.171674px);
    }
    100% {
      transform: translate(-81.87653px, -5.171674px);
    }
  }
  #eMoKek5g1rf52_tr {
    animation: eMoKek5g1rf52_tr__tr 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf52_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.333333% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(50deg);
    }
    81.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eMoKek5g1rf52 {
    animation: eMoKek5g1rf52_c_o 6000ms linear infinite normal forwards;
  }
  @keyframes eMoKek5g1rf52_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.666667% {
      opacity: 1;
    }
    93.333333% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg4;
