// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg2 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="e5vE8IRuXSg1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 622 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="622"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="e5vE8IRuXSg7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e5vE8IRuXSg7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg7-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e5vE8IRuXSg7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e5vE8IRuXSg8-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e5vE8IRuXSg8-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e5vE8IRuXSg8-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e5vE8IRuXSg9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg9-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg10-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e5vE8IRuXSg12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e5vE8IRuXSg12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg12-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e5vE8IRuXSg12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e5vE8IRuXSg13-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e5vE8IRuXSg13-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e5vE8IRuXSg13-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e5vE8IRuXSg14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg14-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg15-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg16-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e5vE8IRuXSg17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e5vE8IRuXSg17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg17-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e5vE8IRuXSg17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e5vE8IRuXSg18-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e5vE8IRuXSg18-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e5vE8IRuXSg18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e5vE8IRuXSg19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg19-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg20-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e5vE8IRuXSg25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e5vE8IRuXSg25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg25-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e5vE8IRuXSg25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e5vE8IRuXSg26-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e5vE8IRuXSg26-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e5vE8IRuXSg26-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e5vE8IRuXSg27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg27-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg28-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg29-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="e5vE8IRuXSg30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg30-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="e5vE8IRuXSg30-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg30-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="e5vE8IRuXSg30-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg30-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg30-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg30-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e5vE8IRuXSg31-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e5vE8IRuXSg31-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e5vE8IRuXSg31-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="e5vE8IRuXSg32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg32-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg33-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="e5vE8IRuXSg34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e5vE8IRuXSg34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="e5vE8IRuXSg34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="e5vE8IRuXSg34-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="e5vE8IRuXSg34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e5vE8IRuXSg34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e5vE8IRuXSg34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e5vE8IRuXSg34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g mask="url(#e5vE8IRuXSg36)">
        <g transform="translate(.000001 0)">
          <g transform="translate(-129.911987 1.012683)">
            <g transform="translate(.000001 0.624996)">
              <path
                d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#e5vE8IRuXSg7-filter)">
                <path
                  id="e5vE8IRuXSg8"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#e5vE8IRuXSg8-stroke)"
                  strokeLinecap="round"
                />
                <g id="e5vE8IRuXSg9_to" transform="translate(656.9,-33.549858)">
                  <rect
                    id="e5vE8IRuXSg9"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.8"
                    filter="url(#e5vE8IRuXSg9-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e5vE8IRuXSg10_to" transform="translate(656.9,-37.443085)">
                  <rect
                    id="e5vE8IRuXSg10"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#e5vE8IRuXSg10-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e5vE8IRuXSg11_to" transform="translate(656.9,-38.549858)">
                  <rect
                    id="e5vE8IRuXSg11"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#e5vE8IRuXSg11-filter)"
                    fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e5vE8IRuXSg12-filter)">
                <path
                  id="e5vE8IRuXSg13"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e5vE8IRuXSg13-stroke)"
                  strokeLinecap="round"
                />
                <g id="e5vE8IRuXSg14_to" transform="translate(786.798427,143.771297)">
                  <g id="e5vE8IRuXSg14_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg14_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg14-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg15_to" transform="translate(786.798427,137.271297)">
                  <g id="e5vE8IRuXSg15_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg15_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg15-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg16_to" transform="translate(786.798427,138.771297)">
                  <g id="e5vE8IRuXSg16_tr" transform="rotate(0)">
                    <rect
                      id="e5vE8IRuXSg16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e5vE8IRuXSg16-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e5vE8IRuXSg17-filter)">
                <path
                  id="e5vE8IRuXSg18"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e5vE8IRuXSg18-stroke)"
                  strokeLinecap="round"
                />
                <g id="e5vE8IRuXSg19_to" transform="translate(786.798427,143.771297)">
                  <g id="e5vE8IRuXSg19_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg19_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg19-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg20_to" transform="translate(786.798427,137.271297)">
                  <g id="e5vE8IRuXSg20_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg20_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg20-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg21_to" transform="translate(786.798427,136.771297)">
                  <g id="e5vE8IRuXSg21_tr" transform="rotate(0)">
                    <rect
                      id="e5vE8IRuXSg21"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e5vE8IRuXSg21-filter)"
                      fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#e5vE8IRuXSg25-filter)">
                <path
                  id="e5vE8IRuXSg26"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e5vE8IRuXSg26-stroke)"
                  strokeLinecap="round"
                />
                <g id="e5vE8IRuXSg27_to" transform="translate(226.798427,143.771297)">
                  <g id="e5vE8IRuXSg27_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg27_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg27"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg27-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg28_to" transform="translate(226.798427,137.271297)">
                  <g id="e5vE8IRuXSg28_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg28_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg28"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg28-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg29_to" transform="translate(226.798427,141.771297)">
                  <g id="e5vE8IRuXSg29_tr" transform="rotate(0)">
                    <rect
                      id="e5vE8IRuXSg29"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e5vE8IRuXSg29-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e5vE8IRuXSg30-filter)">
                <path
                  id="e5vE8IRuXSg31"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e5vE8IRuXSg31-stroke)"
                  strokeLinecap="round"
                />
                <g id="e5vE8IRuXSg32_to" transform="translate(226.798427,143.771297)">
                  <g id="e5vE8IRuXSg32_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg32_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg32"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg32-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg33_to" transform="translate(226.798427,137.271297)">
                  <g id="e5vE8IRuXSg33_tr" transform="rotate(0)">
                    <g id="e5vE8IRuXSg33_ts" transform="scale(1,1)">
                      <rect
                        id="e5vE8IRuXSg33"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#e5vE8IRuXSg33-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="e5vE8IRuXSg34_to" transform="translate(226.798427,138.771297)">
                  <g id="e5vE8IRuXSg34_tr" transform="rotate(0)">
                    <rect
                      id="e5vE8IRuXSg34"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#e5vE8IRuXSg34-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="e5vE8IRuXSg36" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="896.38596"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #e5vE8IRuXSg8 {
    animation-name: e5vE8IRuXSg8__m, e5vE8IRuXSg8_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e5vE8IRuXSg8__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes e5vE8IRuXSg8_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg9_to {
    animation: e5vE8IRuXSg9_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg9_to__to {
    0% {
      transform: translate(656.9px, -33.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -114.228703px);
    }
    100% {
      transform: translate(656.9px, -114.228703px);
    }
  }
  #e5vE8IRuXSg9 {
    animation: e5vE8IRuXSg9_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg9_c_o {
    0% {
      opacity: 0.8;
    }
    10% {
      opacity: 0.8;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg10_to {
    animation: e5vE8IRuXSg10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg10_to__to {
    0% {
      transform: translate(656.9px, -37.443085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e5vE8IRuXSg10 {
    animation: e5vE8IRuXSg10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg10_c_o {
    0% {
      opacity: 0.9;
    }
    10% {
      opacity: 0.9;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg11_to {
    animation: e5vE8IRuXSg11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg11_to__to {
    0% {
      transform: translate(656.9px, -38.549858px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.228703px);
    }
    100% {
      transform: translate(656.9px, -119.228703px);
    }
  }
  #e5vE8IRuXSg11 {
    animation: e5vE8IRuXSg11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg13 {
    animation-name: e5vE8IRuXSg13__m, e5vE8IRuXSg13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e5vE8IRuXSg13__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    62.5% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes e5vE8IRuXSg13_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg14_to {
    animation: e5vE8IRuXSg14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg14_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -115.228703px);
    }
    100% {
      transform: translate(656.9px, -115.228703px);
    }
  }
  #e5vE8IRuXSg14_tr {
    animation: e5vE8IRuXSg14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(-70deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg14_ts {
    animation: e5vE8IRuXSg14_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg14_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg14 {
    animation: e5vE8IRuXSg14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg14_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.8;
    }
    62.5% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg15_to {
    animation: e5vE8IRuXSg15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg15_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    22.5% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e5vE8IRuXSg15_tr {
    animation: e5vE8IRuXSg15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg15_ts {
    animation: e5vE8IRuXSg15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    37.5% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg15 {
    animation: e5vE8IRuXSg15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg15_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.9;
    }
    62.5% {
      opacity: 0.9;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg16_to {
    animation: e5vE8IRuXSg16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg16_to__to {
    0% {
      transform: translate(786.798427px, 138.771297px);
    }
    22.5% {
      transform: translate(786.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #e5vE8IRuXSg16_tr {
    animation: e5vE8IRuXSg16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg16 {
    animation: e5vE8IRuXSg16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg16_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg18 {
    animation-name: e5vE8IRuXSg18__m, e5vE8IRuXSg18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e5vE8IRuXSg18__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814L720.868081,121.997409C720.868081,121.997409,718.834067,121.997411,718.834067,121.997411'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,58.945745,591.54,58.945745'
      );
    }
  }
  @keyframes e5vE8IRuXSg18_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e5vE8IRuXSg19_to {
    animation: e5vE8IRuXSg19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg19_to__to {
    0% {
      transform: translate(786.798427px, 143.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 29.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(786.798427px, 18.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(779.8px, 12.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(664.801035px, -25.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(658.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -34.549858px);
    }
  }
  #e5vE8IRuXSg19_tr {
    animation: e5vE8IRuXSg19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(-70deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg19_ts {
    animation: e5vE8IRuXSg19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg19_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg19 {
    animation: e5vE8IRuXSg19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg19_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #e5vE8IRuXSg20_to {
    animation: e5vE8IRuXSg20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg20_to__to {
    0% {
      transform: translate(786.798427px, 137.271297px);
    }
    68.75% {
      transform: translate(786.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -39.443085px);
    }
  }
  #e5vE8IRuXSg20_tr {
    animation: e5vE8IRuXSg20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg20_ts {
    animation: e5vE8IRuXSg20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    83.75% {
      transform: scale(1, 1);
    }
    96.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg20 {
    animation: e5vE8IRuXSg20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg20_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #e5vE8IRuXSg21_to {
    animation: e5vE8IRuXSg21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg21_to__to {
    0% {
      transform: translate(786.798427px, 136.771297px);
    }
    68.75% {
      transform: translate(786.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.549858px);
    }
  }
  #e5vE8IRuXSg21_tr {
    animation: e5vE8IRuXSg21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg21 {
    animation: e5vE8IRuXSg21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg21_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e5vE8IRuXSg26 {
    animation-name: e5vE8IRuXSg26__m, e5vE8IRuXSg26_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e5vE8IRuXSg26__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    11.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    22.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    42.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e5vE8IRuXSg26_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg27_to {
    animation: e5vE8IRuXSg27_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg27_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #e5vE8IRuXSg27_tr {
    animation: e5vE8IRuXSg27_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(72deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg27_ts {
    animation: e5vE8IRuXSg27_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg27_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg27 {
    animation: e5vE8IRuXSg27_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg27_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.8;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg28_to {
    animation: e5vE8IRuXSg28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg28_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    6.25% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #e5vE8IRuXSg28_tr {
    animation: e5vE8IRuXSg28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg28_ts {
    animation: e5vE8IRuXSg28_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg28_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    22.5% {
      transform: scale(1, 1);
    }
    33.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg28 {
    animation: e5vE8IRuXSg28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg28_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.9;
    }
    48.75% {
      opacity: 0.9;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg29_to {
    animation: e5vE8IRuXSg29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg29_to__to {
    0% {
      transform: translate(226.798427px, 141.771297px);
    }
    6.25% {
      transform: translate(226.798427px, 141.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #e5vE8IRuXSg29_tr {
    animation: e5vE8IRuXSg29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg29 {
    animation: e5vE8IRuXSg29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg29_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg31 {
    animation-name: e5vE8IRuXSg31__m, e5vE8IRuXSg31_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e5vE8IRuXSg31__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,75.601233');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e5vE8IRuXSg31_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg32_to {
    animation: e5vE8IRuXSg32_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg32_to__to {
    0% {
      transform: translate(226.798427px, 143.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(226.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(234.798427px, 14.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(354.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(359.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -115.228703px);
    }
    100% {
      transform: translate(361.9px, -115.228703px);
    }
  }
  #e5vE8IRuXSg32_tr {
    animation: e5vE8IRuXSg32_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg32_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(72deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg32_ts {
    animation: e5vE8IRuXSg32_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg32_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg32 {
    animation: e5vE8IRuXSg32_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg32_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.8;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg33_to {
    animation: e5vE8IRuXSg33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg33_to__to {
    0% {
      transform: translate(226.798427px, 137.271297px);
    }
    52.5% {
      transform: translate(226.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #e5vE8IRuXSg33_tr {
    animation: e5vE8IRuXSg33_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg33_ts {
    animation: e5vE8IRuXSg33_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg33_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    66.25% {
      transform: scale(1, 1);
    }
    78.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #e5vE8IRuXSg33 {
    animation: e5vE8IRuXSg33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg33_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.9;
    }
    93.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e5vE8IRuXSg34_to {
    animation: e5vE8IRuXSg34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg34_to__to {
    0% {
      transform: translate(226.798427px, 138.771297px);
    }
    52.5% {
      transform: translate(226.798427px, 138.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #e5vE8IRuXSg34_tr {
    animation: e5vE8IRuXSg34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e5vE8IRuXSg34 {
    animation: e5vE8IRuXSg34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e5vE8IRuXSg34_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg2;
