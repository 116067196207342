// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg6 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletBrightColor: 'var(--sb-selected-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailGlowColor: 'var(--sb-selected-tail-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletBrightColor: 'var(--sb-bullet-bright-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailGlowColor: 'var(--sb-tail-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)',
    strokeColor: 'var(--sb-line-color)',
  };

  return (
    <AnimatedSvg
      id="eruxvjsThEy1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eruxvjsThEy7-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy7-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy7-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy7-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy7-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy7-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy7-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy7-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy8-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy8-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy8-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy9-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy10-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy11-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy12-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy13-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy13-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy13-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy14-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy15-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy16-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy17-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy17-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy17-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy17-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy17-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy17-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy17-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy17-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy18-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy18-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy18-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy19-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy19-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy19-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy19-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy19-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy19-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy19-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy19-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy20-filter-drop-shadow-0-flood"
            floodColor={selected === 5 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy21-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy26-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy27-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy27-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy27-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy28-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy29-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy30-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy31-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy32-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy32-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy32-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy33-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy34-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy35-filter-drop-shadow-0-flood"
            floodColor={selected === 4 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy41-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy42-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy42-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy42-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy43-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy44-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy45-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy46-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy47-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy47-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy47-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy48-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy49-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy50-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy51-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy52-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy52-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy52-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy53-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy54-filter-drop-shadow-0-flood"
            floodColor={selected === 3 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy55-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy59-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy59-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy59-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy59-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy59-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy59-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy59-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy59-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy60-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy60-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy60-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy61-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy61-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy61-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy61-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy61-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy61-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy61-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy61-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy62-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy63-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy64-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy65-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy65-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy65-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy66-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy67-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy68-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy69-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy70-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy70-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy70-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy71-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy71-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy71-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy71-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy71-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy71-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy71-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy71-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy72-filter-drop-shadow-0-flood"
            floodColor={selected === 2 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy73-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy73-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy77-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy78-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy78-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy78-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy79-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy80-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy80-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy80-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy80-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy80-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy80-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy80-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy81-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy82-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy82-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy82-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy82-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy82-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy82-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy82-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy82-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy83-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy83-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy83-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy84-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy84-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy84-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy84-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy84-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy84-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy84-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy84-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy85-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy85-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy85-filter-drop-shadow-0-flood"
            floodColor={selected === 1 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy85-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy85-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy85-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy85-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy86-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy86-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy92-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy92-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy92-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy92-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy92-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy92-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy92-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy92-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy93-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy93-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy93-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy94-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy94-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy94-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy94-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy94-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy94-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy94-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy94-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy95-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy95-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="7,7" />
          <feOffset id="eruxvjsThEy95-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy95-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy95-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy95-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy95-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy95-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy96-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
        <filter id="eruxvjsThEy97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy97-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="8,8" />
          <feOffset id="eruxvjsThEy97-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy97-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.bulletGlowColor : styles.bulletGlowColor}
          />
          <feComposite id="eruxvjsThEy97-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy97-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy97-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy97-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eruxvjsThEy98-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eruxvjsThEy98-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eruxvjsThEy98-stroke-1" offset="100%" stopColor={styles.stopColor} />
        </linearGradient>
        <filter id="eruxvjsThEy99-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy99-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy99-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy99-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy99-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy99-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy99-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy99-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="5,5" />
          <feOffset id="eruxvjsThEy100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood
            id="eruxvjsThEy100-filter-drop-shadow-0-flood"
            floodColor={selected === 0 ? selectedStyles.tailGlowColor : styles.tailGlowColor}
          />
          <feComposite id="eruxvjsThEy100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eruxvjsThEy100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eruxvjsThEy100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eruxvjsThEy100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="eruxvjsThEy101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eruxvjsThEy101-filter-blur-0" stdDeviation="1,1" result="result" />
        </filter>
      </defs>
      <g mask="url(#eruxvjsThEy103)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g>
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy7-filter)">
                <path
                  id="eruxvjsThEy8"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eruxvjsThEy8-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy9_to" transform="translate(832.121519,-4.482748)">
                  <g id="eruxvjsThEy9_tr" transform="rotate(-75)">
                    <g id="eruxvjsThEy9_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eruxvjsThEy9"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eruxvjsThEy9-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy10_to" transform="translate(826.798304,-5.828881)">
                  <g id="eruxvjsThEy10_tr" transform="rotate(-75)">
                    <g id="eruxvjsThEy10_ts" transform="scale(0.885714,0.885714)">
                      <rect
                        id="eruxvjsThEy10"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eruxvjsThEy10-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy11_to" transform="translate(826.568884,-6.23967)">
                  <g id="eruxvjsThEy11_tr" transform="rotate(-75)">
                    <rect
                      id="eruxvjsThEy11"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eruxvjsThEy11-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy12-filter)">
                <path
                  id="eruxvjsThEy13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy14_to" transform="translate(917.798427,143.771297)">
                  <g id="eruxvjsThEy14_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy14_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy14"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy14-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy15_to" transform="translate(917.798427,137.271297)">
                  <g id="eruxvjsThEy15_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy15_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy15"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy15-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy16_to" transform="translate(917.798427,137.771297)">
                  <g id="eruxvjsThEy16_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy16"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy16-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy17-filter)">
                <path
                  id="eruxvjsThEy18"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy18-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy19_to" transform="translate(917.798427,143.771297)">
                  <g id="eruxvjsThEy19_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy19_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy19"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy19-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy20_to" transform="translate(917.798427,137.271297)">
                  <g id="eruxvjsThEy20_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy20_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy20"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy20-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy21_to" transform="translate(917.798427,140.771297)">
                  <g id="eruxvjsThEy21_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy21"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy21-filter)"
                      fill={selected === 5 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eruxvjsThEy37)">
              <g>
                <path
                  d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy26-filter)">
                  <path
                    id="eruxvjsThEy27"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eruxvjsThEy27-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eruxvjsThEy28_to" transform="translate(715.798427,56.771297)">
                    <g id="eruxvjsThEy28_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy28_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy28"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.8"
                          filter="url(#eruxvjsThEy28-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy29_to" transform="translate(715.798427,50.271297)">
                    <g id="eruxvjsThEy29_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy29_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy29"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0.9"
                          filter="url(#eruxvjsThEy29-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy30_to" transform="translate(715.798427,51.771297)">
                    <g id="eruxvjsThEy30_tr" transform="rotate(0)">
                      <rect
                        id="eruxvjsThEy30"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        filter="url(#eruxvjsThEy30-filter)"
                        fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy31-filter)">
                  <path
                    id="eruxvjsThEy32"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eruxvjsThEy32-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eruxvjsThEy33_to" transform="translate(715.798427,56.771297)">
                    <g id="eruxvjsThEy33_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy33_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy33"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eruxvjsThEy33-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy34_to" transform="translate(715.798427,50.271297)">
                    <g id="eruxvjsThEy34_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy34_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy34"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eruxvjsThEy34-filter)"
                          fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy35_to" transform="translate(715.798427,50.771297)">
                    <g id="eruxvjsThEy35_tr" transform="rotate(0)">
                      <rect
                        id="eruxvjsThEy35"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy35-filter)"
                        fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eruxvjsThEy37" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 535.931986-1.012683)"
                  fill={selected === 4 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-59.706007-5)">
              <path
                d="M590.336,232.597v-106.78L539.819,80.7272l-.001-76.185286"
                transform="translate(0 0.805403)"
                fill="none"
                stroke={styles.strokeColor}
              />
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy41-filter)">
                <path
                  id="eruxvjsThEy42"
                  d="M590.336,125.761512v.055488L538.819,80.7272l-.001-.8104"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eruxvjsThEy42-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy43_to" transform="translate(604.851035,-22.791881)">
                  <g id="eruxvjsThEy43_tr" transform="rotate(-45)">
                    <rect
                      id="eruxvjsThEy43"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.8,0.8) translate(-4,-6.5)"
                      opacity="0.8"
                      filter="url(#eruxvjsThEy43-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eruxvjsThEy44_to" transform="translate(603.920607,-27.028703)">
                  <rect
                    id="eruxvjsThEy44"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.8,0.8) translate(-4,-6.5)"
                    opacity="0.9"
                    filter="url(#eruxvjsThEy44-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eruxvjsThEy45_to" transform="translate(603.911986,-27.262684)">
                  <rect
                    id="eruxvjsThEy45"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    filter="url(#eruxvjsThEy45-filter)"
                    fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy46-filter)">
                <path
                  id="eruxvjsThEy47"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy47-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy48_to" transform="translate(656.798427,143.771297)">
                  <g id="eruxvjsThEy48_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy48_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy48"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy48-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy49_to" transform="translate(656.798427,137.271297)">
                  <g id="eruxvjsThEy49_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy49_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy49"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy49-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy50_to" transform="translate(656.798427,136.771297)">
                  <g id="eruxvjsThEy50_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy50"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy50-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy51-filter)">
                <path
                  id="eruxvjsThEy52"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy52-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy53_to" transform="translate(656.798427,143.771297)">
                  <g id="eruxvjsThEy53_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy53_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy53"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy53-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy54_to" transform="translate(656.798427,137.271297)">
                  <g id="eruxvjsThEy54_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy54_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy54"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy54-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy55_to" transform="translate(656.798427,136.771297)">
                  <g id="eruxvjsThEy55_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy55"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy55-filter)"
                      fill={selected === 3 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(65 0)">
              <path d="M292.146,229.76v-108.078l50.228-44.9059v-76.43235" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy59-filter)">
                <path
                  id="eruxvjsThEy60"
                  d="M590.336,245.324273l-.483994-66.342359v0l-.003229-.00198"
                  transform="matrix(-1 0 0 1 947.153992-109.194598)"
                  fill="none"
                  stroke="url(#eruxvjsThEy60-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy61_to" transform="translate(357.5,74.508595)">
                  <g id="eruxvjsThEy61_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy61_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy61"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.8"
                        filter="url(#eruxvjsThEy61-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy62_to" transform="translate(357.5,69.792183)">
                  <g id="eruxvjsThEy62_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy62_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy62"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0.9"
                        filter="url(#eruxvjsThEy62-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy63_to" transform="translate(357.5,68.508595)">
                  <g id="eruxvjsThEy63_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy63"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      filter="url(#eruxvjsThEy63-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy64-filter)">
                <path
                  id="eruxvjsThEy65"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="matrix(-1 0 0 1 947.153992-109.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy65-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy66_to" transform="translate(357.5,114.508595)">
                  <g id="eruxvjsThEy66_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy66_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy66"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy66-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy67_to" transform="translate(357.5,109.792183)">
                  <g id="eruxvjsThEy67_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy67_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy67"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy67-filter)"
                        fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy68_to" transform="translate(357.5,108.508595)">
                  <g id="eruxvjsThEy68_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy68"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy68-filter)"
                      fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy69-filter)">
                <path
                  id="eruxvjsThEy70"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="matrix(-1 0 0 1 947.153992-109.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy70-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy71_to" transform="translate(357.5,114.508595)">
                  <rect
                    id="eruxvjsThEy71"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eruxvjsThEy71-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eruxvjsThEy72_to" transform="translate(357.5,109.792183)">
                  <rect
                    id="eruxvjsThEy72"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eruxvjsThEy72-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eruxvjsThEy73_to" transform="translate(357.5,108.508595)">
                  <rect
                    id="eruxvjsThEy73"
                    width="8"
                    height="13"
                    rx="4"
                    ry="4"
                    transform="scale(0.5,0.5) translate(-4,-6.5)"
                    opacity="0"
                    filter="url(#eruxvjsThEy73-filter)"
                    fill={selected === 2 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#eruxvjsThEy88)">
              <g transform="translate(.000001 0)">
                <path
                  d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117"
                  fill="none"
                  stroke={styles.strokeColor}
                />
                <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy77-filter)">
                  <path
                    id="eruxvjsThEy78"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eruxvjsThEy78-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eruxvjsThEy79_to" transform="translate(291.798427,53.771297)">
                    <g id="eruxvjsThEy79_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy79_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy79"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eruxvjsThEy79-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy80_to" transform="translate(291.798427,47.271297)">
                    <g id="eruxvjsThEy80_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy80_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy80"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eruxvjsThEy80-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy81_to" transform="translate(291.798427,49.771297)">
                    <g id="eruxvjsThEy81_tr" transform="rotate(0)">
                      <rect
                        id="eruxvjsThEy81"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy81-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy82-filter)">
                  <path
                    id="eruxvjsThEy83"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eruxvjsThEy83-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eruxvjsThEy84_to" transform="translate(291.798427,53.771297)">
                    <g id="eruxvjsThEy84_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy84_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy84"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eruxvjsThEy84-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy85_to" transform="translate(291.798427,47.271297)">
                    <g id="eruxvjsThEy85_tr" transform="rotate(0)">
                      <g id="eruxvjsThEy85_ts" transform="scale(1,1)">
                        <rect
                          id="eruxvjsThEy85"
                          width="8"
                          height="13"
                          rx="4"
                          ry="4"
                          transform="translate(-4,-6.5)"
                          opacity="0"
                          filter="url(#eruxvjsThEy85-filter)"
                          fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                        />
                      </g>
                    </g>
                  </g>
                  <g id="eruxvjsThEy86_to" transform="translate(291.798427,49.771297)">
                    <g id="eruxvjsThEy86_tr" transform="rotate(0)">
                      <rect
                        id="eruxvjsThEy86"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="scale(0.5,0.5) translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy86-filter)"
                        fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eruxvjsThEy88" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.988034 209.931986-1.012683)"
                  fill={selected === 1 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke={styles.strokeColor} />
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy92-filter)">
                <path
                  id="eruxvjsThEy93"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy93-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy94_to" transform="translate(96.798427,143.771297)">
                  <g id="eruxvjsThEy94_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy94_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy94"
                        style={{ isolation: 'isolate' }}
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy94-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy95_to" transform="translate(96.798427,137.271297)">
                  <g id="eruxvjsThEy95_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy95_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy95"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy95-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy96_to" transform="translate(96.798427,142.271297)">
                  <g id="eruxvjsThEy96_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy96"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy96-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eruxvjsThEy97-filter)">
                <path
                  id="eruxvjsThEy98"
                  d="M32.0957,249.88v-8.358702l-.0957-1-.201573-1.375004"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eruxvjsThEy98-stroke)"
                  strokeLinecap="round"
                />
                <g id="eruxvjsThEy99_to" transform="translate(96.798427,143.771297)">
                  <g id="eruxvjsThEy99_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy99_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy99"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy99-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy100_to" transform="translate(96.798427,137.271297)">
                  <g id="eruxvjsThEy100_tr" transform="rotate(0)">
                    <g id="eruxvjsThEy100_ts" transform="scale(1,1)">
                      <rect
                        id="eruxvjsThEy100"
                        width="8"
                        height="13"
                        rx="4"
                        ry="4"
                        transform="translate(-4,-6.5)"
                        opacity="0"
                        filter="url(#eruxvjsThEy100-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g id="eruxvjsThEy101_to" transform="translate(96.798427,139.771297)">
                  <g id="eruxvjsThEy101_tr" transform="rotate(0)">
                    <rect
                      id="eruxvjsThEy101"
                      width="8"
                      height="13"
                      rx="4"
                      ry="4"
                      transform="scale(0.5,0.5) translate(-4,-6.5)"
                      opacity="0"
                      filter="url(#eruxvjsThEy101-filter)"
                      fill={selected === 0 ? selectedStyles.bulletBrightColor : styles.bulletBrightColor}
                    />
                  </g>
                </g>
              </g>
              <rect
                width="63"
                height="63"
                rx="4.5"
                ry="4.5"
                transform="translate(.5 231.082)"
                fill="#030303"
                stroke="#878888"
              />
            </g>
          </g>
        </g>
        <mask id="eruxvjsThEy103" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eruxvjsThEy8 {
    animation-name: eruxvjsThEy8__m, eruxvjsThEy8_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy8__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eruxvjsThEy8_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy9_to {
    animation: eruxvjsThEy9_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy9_to__to {
    0% {
      transform: translate(832.121519px, -4.482748px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -116.228703px);
    }
    100% {
      transform: translate(731.9px, -116.228703px);
    }
  }
  #eruxvjsThEy9_tr {
    animation: eruxvjsThEy9_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy9_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(-75deg);
    }
    10% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy9_ts {
    animation: eruxvjsThEy9_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy9_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy9 {
    animation: eruxvjsThEy9_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy9_c_o {
    0% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy10_to {
    animation: eruxvjsThEy10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy10_to__to {
    0% {
      transform: translate(826.798304px, -5.828881px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.728703px);
    }
    100% {
      transform: translate(731.9px, -122.728703px);
    }
  }
  #eruxvjsThEy10_tr {
    animation: eruxvjsThEy10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy10_ts {
    animation: eruxvjsThEy10_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy10_ts__ts {
    0% {
      transform: scale(0.885714, 0.885714);
    }
    7.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy10 {
    animation: eruxvjsThEy10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy10_c_o {
    0% {
      opacity: 0.9;
    }
    20% {
      opacity: 0.9;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy11_to {
    animation: eruxvjsThEy11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy11_to__to {
    0% {
      transform: translate(826.568884px, -6.23967px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.9px, -122.228703px);
    }
    100% {
      transform: translate(731.9px, -122.228703px);
    }
  }
  #eruxvjsThEy11_tr {
    animation: eruxvjsThEy11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy11 {
    animation: eruxvjsThEy11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy13 {
    animation-name: eruxvjsThEy13__m, eruxvjsThEy13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eruxvjsThEy13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy14_to {
    animation: eruxvjsThEy14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy14_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    25% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(740.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(732.851035px, -28.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -115.228703px);
    }
    100% {
      transform: translate(731.9px, -115.228703px);
    }
  }
  #eruxvjsThEy14_tr {
    animation: eruxvjsThEy14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(-75deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy14_ts {
    animation: eruxvjsThEy14_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy14_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy14 {
    animation: eruxvjsThEy14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    68.75% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy15_to {
    animation: eruxvjsThEy15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy15_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    25% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.728703px);
    }
    100% {
      transform: translate(731.9px, -120.728703px);
    }
  }
  #eruxvjsThEy15_tr {
    animation: eruxvjsThEy15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy15_ts {
    animation: eruxvjsThEy15_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy15_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(1, 1);
    }
    55% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy15 {
    animation: eruxvjsThEy15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.9;
    }
    68.75% {
      opacity: 0.9;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy16_to {
    animation: eruxvjsThEy16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy16_to__to {
    0% {
      transform: translate(917.798427px, 137.771297px);
    }
    25% {
      transform: translate(917.798427px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(731.9px, -120.228703px);
    }
    100% {
      transform: translate(731.9px, -120.228703px);
    }
  }
  #eruxvjsThEy16_tr {
    animation: eruxvjsThEy16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy16 {
    animation: eruxvjsThEy16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy16_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy18 {
    animation-name: eruxvjsThEy18__m, eruxvjsThEy18_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy18__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eruxvjsThEy18_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy19_to {
    animation: eruxvjsThEy19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy19_to__to {
    0% {
      transform: translate(917.798427px, 143.771297px);
    }
    75% {
      transform: translate(917.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 28.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(917.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(910.798427px, 14.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(832.121519px, -4.482748px);
    }
  }
  #eruxvjsThEy19_tr {
    animation: eruxvjsThEy19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(0deg);
    }
    92.5% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eruxvjsThEy19_ts {
    animation: eruxvjsThEy19_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy19_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eruxvjsThEy19 {
    animation: eruxvjsThEy19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy20_to {
    animation: eruxvjsThEy20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy20_to__to {
    0% {
      transform: translate(917.798427px, 137.271297px);
    }
    75% {
      transform: translate(917.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eruxvjsThEy20_tr {
    animation: eruxvjsThEy20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eruxvjsThEy20_ts {
    animation: eruxvjsThEy20_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy20_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    91.25% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.885714, 0.885714);
    }
  }
  #eruxvjsThEy20 {
    animation: eruxvjsThEy20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy20_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy21_to {
    animation: eruxvjsThEy21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy21_to__to {
    0% {
      transform: translate(917.798427px, 140.771297px);
    }
    75% {
      transform: translate(917.798427px, 140.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(903.798427px, 12.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eruxvjsThEy21_tr {
    animation: eruxvjsThEy21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eruxvjsThEy21 {
    animation: eruxvjsThEy21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy21_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy27 {
    animation-name: eruxvjsThEy27__m, eruxvjsThEy27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy27__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eruxvjsThEy27_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy28_to {
    animation: eruxvjsThEy28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy28_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -115.228703px);
    }
    100% {
      transform: translate(620.9px, -115.228703px);
    }
  }
  #eruxvjsThEy28_tr {
    animation: eruxvjsThEy28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(-68deg);
    }
    23.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy28_ts {
    animation: eruxvjsThEy28_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy28_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy28 {
    animation: eruxvjsThEy28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy28_c_o {
    0% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.8;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy29_to {
    animation: eruxvjsThEy29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy29_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -121.728703px);
    }
    100% {
      transform: translate(620.9px, -121.728703px);
    }
  }
  #eruxvjsThEy29_tr {
    animation: eruxvjsThEy29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy29_ts {
    animation: eruxvjsThEy29_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy29_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    8.75% {
      transform: scale(1, 1);
    }
    21.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy29 {
    animation: eruxvjsThEy29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy29_c_o {
    0% {
      opacity: 0.9;
    }
    35% {
      opacity: 0.9;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy30_to {
    animation: eruxvjsThEy30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy30_to__to {
    0% {
      transform: translate(715.798427px, 51.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(620.9px, -122.228703px);
    }
    100% {
      transform: translate(620.9px, -122.228703px);
    }
  }
  #eruxvjsThEy30_tr {
    animation: eruxvjsThEy30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy30 {
    animation: eruxvjsThEy30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy30_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy32 {
    animation-name: eruxvjsThEy32__m, eruxvjsThEy32_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy32__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077001,76.696469L554.077001,76.7305L554.077001,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077001,76.696469L554.077001,76.7305L554.077001,-15.012683');
    }
  }
  @keyframes eruxvjsThEy32_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy33_to {
    animation: eruxvjsThEy33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy33_to__to {
    0% {
      transform: translate(715.798427px, 56.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 56.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(715.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(710.798427px, 14.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(628.801035px, -23.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(621.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -114.228703px);
    }
    100% {
      transform: translate(619.9px, -114.228703px);
    }
  }
  #eruxvjsThEy33_tr {
    animation: eruxvjsThEy33_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy33_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(-68deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy33_ts {
    animation: eruxvjsThEy33_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy33_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy33 {
    animation: eruxvjsThEy33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy33_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy34_to {
    animation: eruxvjsThEy34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy34_to__to {
    0% {
      transform: translate(715.798427px, 50.271297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -119.728703px);
    }
    100% {
      transform: translate(619.9px, -119.728703px);
    }
  }
  #eruxvjsThEy34_tr {
    animation: eruxvjsThEy34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy34_ts {
    animation: eruxvjsThEy34_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy34_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    52.5% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy34 {
    animation: eruxvjsThEy34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy34_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy35_to {
    animation: eruxvjsThEy35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy35_to__to {
    0% {
      transform: translate(715.798427px, 50.771297px);
    }
    43.75% {
      transform: translate(715.798427px, 50.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(705.674548px, 12.059155px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(620.011986px, -31.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -118.228703px);
    }
    100% {
      transform: translate(619.9px, -118.228703px);
    }
  }
  #eruxvjsThEy35_tr {
    animation: eruxvjsThEy35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy35 {
    animation: eruxvjsThEy35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy35_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy42 {
    animation-name: eruxvjsThEy42__m, eruxvjsThEy42_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy42__m {
    0% {
      d: path('M590.336,125.761512L590.336,125.817L538.819,80.7272L538.818,79.9168');
    }
    8.75% {
      d: path('M538.818,80.866225L538.818,80.550695L538.819,80.7272L538.818,-6.47656');
    }
    100% {
      d: path('M538.818,80.866225L538.818,80.550695L538.819,80.7272L538.818,-6.47656');
    }
  }
  @keyframes eruxvjsThEy42_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    6.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy43_to {
    animation: eruxvjsThEy43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy43_to__to {
    0% {
      transform: translate(604.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    1.25% {
      transform: translate(604.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    12.5% {
      transform: translate(604.9px, -111.228703px);
    }
    100% {
      transform: translate(604.9px, -111.228703px);
    }
  }
  #eruxvjsThEy43_tr {
    animation: eruxvjsThEy43_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy43_tr__tr {
    0% {
      transform: rotate(-45deg);
    }
    1.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy43 {
    animation: eruxvjsThEy43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy43_c_o {
    0% {
      opacity: 0.8;
    }
    12.5% {
      opacity: 0.8;
    }
    13.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy44_to {
    animation: eruxvjsThEy44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy44_to__to {
    0% {
      transform: translate(603.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    1.25% {
      transform: translate(604.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    12.5% {
      transform: translate(604.9px, -116.728703px);
    }
    100% {
      transform: translate(604.9px, -116.728703px);
    }
  }
  #eruxvjsThEy44 {
    animation: eruxvjsThEy44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy44_c_o {
    0% {
      opacity: 0.9;
    }
    12.5% {
      opacity: 0.9;
    }
    13.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy45_to {
    animation: eruxvjsThEy45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy45_to__to {
    0% {
      transform: translate(603.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    1.25% {
      transform: translate(604.9px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    12.5% {
      transform: translate(604.9px, -118.228703px);
    }
    100% {
      transform: translate(604.9px, -118.228703px);
    }
  }
  #eruxvjsThEy45 {
    animation: eruxvjsThEy45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy45_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    12.5% {
      opacity: 1;
    }
    13.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy47 {
    animation-name: eruxvjsThEy47__m, eruxvjsThEy47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy47__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L538.819,80.7272L538.818,79.9168');
    }
    73.75% {
      d: path('M539.818,80.866225L539.818,80.550695L539.819,80.7272L539.818,-6.47656');
    }
    100% {
      d: path('M539.818,80.866225L539.818,80.550695L539.819,80.7272L539.818,-6.47656');
    }
  }
  @keyframes eruxvjsThEy47_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    73.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy48_to {
    animation: eruxvjsThEy48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy48_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(610.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(604.851035px, -22.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(604.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(604.9px, -111.228703px);
    }
    100% {
      transform: translate(604.9px, -111.228703px);
    }
  }
  #eruxvjsThEy48_tr {
    animation: eruxvjsThEy48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-45deg);
    }
    66.25% {
      transform: rotate(-45deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy48_ts {
    animation: eruxvjsThEy48_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy48_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy48 {
    animation: eruxvjsThEy48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy48_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0.8;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy49_to {
    animation: eruxvjsThEy49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy49_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    37.5% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(606.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(603.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(604.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(604.9px, -116.728703px);
    }
    100% {
      transform: translate(604.9px, -116.728703px);
    }
  }
  #eruxvjsThEy49_tr {
    animation: eruxvjsThEy49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-45deg);
    }
    65% {
      transform: rotate(-45deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy49_ts {
    animation: eruxvjsThEy49_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy49_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    65% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy49 {
    animation: eruxvjsThEy49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy49_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0.9;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy50_to {
    animation: eruxvjsThEy50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy50_to__to {
    0% {
      transform: translate(656.798427px, 136.771297px);
    }
    37.5% {
      transform: translate(656.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(605.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(603.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(604.9px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(603.9px, -118.228703px);
    }
    100% {
      transform: translate(603.9px, -118.228703px);
    }
  }
  #eruxvjsThEy50_tr {
    animation: eruxvjsThEy50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-45deg);
    }
    52.5% {
      transform: rotate(-45deg);
    }
    65% {
      transform: rotate(-45deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy50 {
    animation: eruxvjsThEy50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy50_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy52 {
    animation-name: eruxvjsThEy52__m, eruxvjsThEy52_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy52__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    73.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    80% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    85% {
      d: path('M590.336,232.597L590.336,125.817L590.336,125.872597L590.336,125.872597');
    }
    100% {
      d: path('M590.336,125.761512L590.336,125.817L538.819,79.7272L538.818,79.9168');
    }
  }
  @keyframes eruxvjsThEy52_c_o {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    71.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy53_to {
    animation: eruxvjsThEy53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy53_to__to {
    0% {
      transform: translate(656.798427px, 143.771297px);
    }
    71.25% {
      transform: translate(656.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    83.75% {
      transform: translate(655.798427px, 32.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    85% {
      transform: translate(655.798427px, 25.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    86.25% {
      transform: translate(647.798427px, 16.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    98.75% {
      transform: translate(610.801035px, -18.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    100% {
      transform: translate(605.851035px, -22.791881px);
    }
  }
  #eruxvjsThEy53_tr {
    animation: eruxvjsThEy53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(0deg);
    }
    86.25% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(-45deg);
    }
  }
  #eruxvjsThEy53_ts {
    animation: eruxvjsThEy53_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy53_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    85% {
      transform: scale(1, 1);
    }
    98.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy53 {
    animation: eruxvjsThEy53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy53_c_o {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    71.25% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eruxvjsThEy54_to {
    animation: eruxvjsThEy54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy54_to__to {
    0% {
      transform: translate(656.798427px, 137.271297px);
    }
    71.25% {
      transform: translate(656.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    83.75% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    85% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    86.25% {
      transform: translate(644.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    98.75% {
      transform: translate(606.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    100% {
      transform: translate(603.920607px, -27.028703px);
    }
  }
  #eruxvjsThEy54_tr {
    animation: eruxvjsThEy54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy54_ts {
    animation: eruxvjsThEy54_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy54_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    85% {
      transform: scale(1, 1);
    }
    98.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy54 {
    animation: eruxvjsThEy54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy54_c_o {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    71.25% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eruxvjsThEy55_to {
    animation: eruxvjsThEy55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy55_to__to {
    0% {
      transform: translate(656.798427px, 136.771297px);
    }
    71.25% {
      transform: translate(656.798427px, 136.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    83.75% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    85% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    86.25% {
      transform: translate(643.726569px, 12.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    98.75% {
      transform: translate(605.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    100% {
      transform: translate(603.911986px, -27.262684px);
    }
  }
  #eruxvjsThEy55_tr {
    animation: eruxvjsThEy55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(-45deg);
    }
    86.25% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy55 {
    animation: eruxvjsThEy55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy55_c_o {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    71.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy60 {
    animation-name: eruxvjsThEy60__m, eruxvjsThEy60_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy60__m {
    0% {
      d: path('M590.336,245.324273L589.852006,178.981914L589.852006,178.981914L589.848777,178.979934');
    }
    5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    18.75% {
      d: path('M590.336,125.761512L590.336,125.817L539.819,80.7272L539.818,79.9168');
    }
    27.5% {
      d: path('M539.818,80.866225L539.818,80.550695L539.819,80.7272L539.818,-6.47656');
    }
    100% {
      d: path('M539.818,80.866225L539.818,80.550695L539.819,80.7272L539.818,-6.47656');
    }
  }
  @keyframes eruxvjsThEy60_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    21.25% {
      opacity: 1;
    }
    27.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy61_to {
    animation: eruxvjsThEy61_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy61_to__to {
    0% {
      transform: translate(357.5px, 74.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(362.798427px, 11.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(400.801035px, -22.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(405.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(407.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(407.7px, -115.228703px);
    }
    100% {
      transform: translate(407.7px, -115.228703px);
    }
  }
  #eruxvjsThEy61_tr {
    animation: eruxvjsThEy61_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy61_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    17.5% {
      transform: rotate(45deg);
    }
    18.75% {
      transform: rotate(45deg);
    }
    20% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy61_ts {
    animation: eruxvjsThEy61_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy61_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy61 {
    animation: eruxvjsThEy61_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy61_c_o {
    0% {
      opacity: 0.8;
    }
    31.25% {
      opacity: 0.8;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy62_to {
    animation: eruxvjsThEy62_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy62_to__to {
    0% {
      transform: translate(357.5px, 69.792183px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(359.798427px, 13.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(366.3352px, 8.263445px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(404.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(407.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(407.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(407.7px, -121.728703px);
    }
    100% {
      transform: translate(407.7px, -121.728703px);
    }
  }
  #eruxvjsThEy62_tr {
    animation: eruxvjsThEy62_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy62_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    17.5% {
      transform: rotate(45deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy62_ts {
    animation: eruxvjsThEy62_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy62_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    5% {
      transform: scale(1, 1);
    }
    17.5% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy62 {
    animation: eruxvjsThEy62_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy62_c_o {
    0% {
      opacity: 0.9;
    }
    31.25% {
      opacity: 0.9;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy63_to {
    animation: eruxvjsThEy63_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy63_to__to {
    0% {
      transform: translate(357.5px, 68.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(361px, 12.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(367.202724px, 7.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    17.5% {
      transform: translate(405.502724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(408.111986px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    20% {
      transform: translate(407.7px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    31.25% {
      transform: translate(407.7px, -121.228703px);
    }
    100% {
      transform: translate(407.7px, -121.228703px);
    }
  }
  #eruxvjsThEy63_tr {
    animation: eruxvjsThEy63_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy63_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    17.5% {
      transform: rotate(45deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy63 {
    animation: eruxvjsThEy63_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy63_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy65 {
    animation-name: eruxvjsThEy65__m, eruxvjsThEy65_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy65__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    37.5% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L539.819,80.7272L539.818,79.9168');
    }
    70% {
      d: path('M568.685429,106.520675L568.685429,106.417155L539.819,80.7272L539.818,24.891074');
    }
    75% {
      d: path('M539.818,80.866225L539.818,80.550695L539.819,80.7272L539.818,-6.47656');
    }
    100% {
      d: path('M539.818,80.866225L539.818,80.550695L539.819,80.7272L539.818,-6.47656');
    }
  }
  @keyframes eruxvjsThEy65_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy66_to {
    animation: eruxvjsThEy66_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy66_to__to {
    0% {
      transform: translate(357.5px, 114.508595px);
    }
    40% {
      transform: translate(357.5px, 114.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    45% {
      transform: translate(357.5px, 74.887316px);
    }
    50% {
      transform: translate(357.5px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(357.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(362.798427px, 11.961485px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    63.75% {
      transform: translate(400.801035px, -22.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    65% {
      transform: translate(405.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    66.25% {
      transform: translate(407.7px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    77.5% {
      transform: translate(407.7px, -115.228703px);
    }
    100% {
      transform: translate(407.7px, -115.228703px);
    }
  }
  #eruxvjsThEy66_tr {
    animation: eruxvjsThEy66_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(45deg);
    }
    63.75% {
      transform: rotate(45deg);
    }
    65% {
      transform: rotate(45deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy66_ts {
    animation: eruxvjsThEy66_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy66_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    63.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy66 {
    animation: eruxvjsThEy66_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy66_c_o {
    0% {
      opacity: 0;
    }
    38.75% {
      opacity: 0;
    }
    40% {
      opacity: 0.8;
    }
    77.5% {
      opacity: 0.8;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy67_to {
    animation: eruxvjsThEy67_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy67_to__to {
    0% {
      transform: translate(357.5px, 109.792183px);
    }
    40% {
      transform: translate(357.5px, 109.792183px);
      animation-timing-function: cubic-bezier(0.330549, 0.3248, 0.660517, 0.66115);
    }
    45% {
      transform: translate(357.5px, 69.787316px);
      animation-timing-function: cubic-bezier(0.321909, 0.333671, 0.652373, 0.669675);
    }
    50% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(359.798427px, 13.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(366.3352px, 8.263445px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    63.75% {
      transform: translate(404.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    65% {
      transform: translate(407.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    66.25% {
      transform: translate(407.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    77.5% {
      transform: translate(407.7px, -121.728703px);
    }
    100% {
      transform: translate(407.7px, -121.728703px);
    }
  }
  #eruxvjsThEy67_tr {
    animation: eruxvjsThEy67_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(45deg);
    }
    52.5% {
      transform: rotate(45deg);
    }
    63.75% {
      transform: rotate(45deg);
    }
    65% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy67_ts {
    animation: eruxvjsThEy67_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy67_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    51.25% {
      transform: scale(1, 1);
    }
    63.75% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy67 {
    animation: eruxvjsThEy67_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy67_c_o {
    0% {
      opacity: 0;
    }
    38.75% {
      opacity: 0;
    }
    40% {
      opacity: 0.9;
    }
    77.5% {
      opacity: 0.9;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy68_to {
    animation: eruxvjsThEy68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy68_to__to {
    0% {
      transform: translate(357.5px, 108.508595px);
    }
    40% {
      transform: translate(357.5px, 108.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    45% {
      transform: translate(357.5px, 67.237316px);
    }
    50% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(361px, 12.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(367.202724px, 7.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    63.75% {
      transform: translate(405.502724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    65% {
      transform: translate(408.111986px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    66.25% {
      transform: translate(407.7px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    77.5% {
      transform: translate(407.7px, -121.228703px);
    }
    100% {
      transform: translate(407.7px, -121.228703px);
    }
  }
  #eruxvjsThEy68_tr {
    animation: eruxvjsThEy68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(45deg);
    }
    52.5% {
      transform: rotate(45deg);
    }
    63.75% {
      transform: rotate(45deg);
    }
    65% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy68 {
    animation: eruxvjsThEy68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy68_c_o {
    0% {
      opacity: 0;
    }
    38.75% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    77.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy70 {
    animation-name: eruxvjsThEy70__m, eruxvjsThEy70_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy70__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    95% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,245.324273L590.336,204.979933L590.336,205.488622L589.848777,204.979934');
    }
  }
  @keyframes eruxvjsThEy70_c_o {
    0% {
      opacity: 0;
    }
    93.75% {
      opacity: 0;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy71_to {
    animation: eruxvjsThEy71_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy71_to__to {
    0% {
      transform: translate(357.5px, 114.508595px);
    }
    95% {
      transform: translate(357.5px, 114.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    100% {
      transform: translate(357.5px, 74.887316px);
    }
  }
  #eruxvjsThEy71 {
    animation: eruxvjsThEy71_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy71_c_o {
    0% {
      opacity: 0;
    }
    93.75% {
      opacity: 0;
    }
    95% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eruxvjsThEy72_to {
    animation: eruxvjsThEy72_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy72_to__to {
    0% {
      transform: translate(357.5px, 109.792183px);
    }
    95% {
      transform: translate(357.5px, 109.792183px);
      animation-timing-function: cubic-bezier(0.330549, 0.3248, 0.660517, 0.66115);
    }
    100% {
      transform: translate(357.5px, 69.787316px);
    }
  }
  #eruxvjsThEy72 {
    animation: eruxvjsThEy72_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy72_c_o {
    0% {
      opacity: 0;
    }
    93.75% {
      opacity: 0;
    }
    95% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
  #eruxvjsThEy73_to {
    animation: eruxvjsThEy73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy73_to__to {
    0% {
      transform: translate(357.5px, 108.508595px);
    }
    95% {
      transform: translate(357.5px, 108.508595px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    100% {
      transform: translate(357.5px, 67.237316px);
    }
  }
  #eruxvjsThEy73 {
    animation: eruxvjsThEy73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy73_c_o {
    0% {
      opacity: 0;
    }
    93.75% {
      opacity: 0;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eruxvjsThEy78 {
    animation-name: eruxvjsThEy78__m, eruxvjsThEy78_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy78__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    41.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eruxvjsThEy78_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy79_to {
    animation: eruxvjsThEy79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy79_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -115.228703px);
    }
    100% {
      transform: translate(398.699999px, -115.228703px);
    }
  }
  #eruxvjsThEy79_tr {
    animation: eruxvjsThEy79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(68deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy79_ts {
    animation: eruxvjsThEy79_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy79_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy79 {
    animation: eruxvjsThEy79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy79_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.8;
    }
    53.75% {
      opacity: 0.8;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy80_to {
    animation: eruxvjsThEy80_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy80_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    18.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eruxvjsThEy80_tr {
    animation: eruxvjsThEy80_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy80_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy80_ts {
    animation: eruxvjsThEy80_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy80_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    28.75% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy80 {
    animation: eruxvjsThEy80_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy80_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.9;
    }
    53.75% {
      opacity: 0.9;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy81_to {
    animation: eruxvjsThEy81_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy81_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    18.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eruxvjsThEy81_tr {
    animation: eruxvjsThEy81_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy81_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy81 {
    animation: eruxvjsThEy81_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy81_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy83 {
    animation-name: eruxvjsThEy83__m, eruxvjsThEy83_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy83__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,74.241133');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eruxvjsThEy83_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy84_to {
    animation: eruxvjsThEy84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy84_to__to {
    0% {
      transform: translate(291.798427px, 53.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 53.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(291.798427px, 19px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(299.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(391.801035px, -25.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(396.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -114.228703px);
    }
    100% {
      transform: translate(398.699999px, -114.228703px);
    }
  }
  #eruxvjsThEy84_tr {
    animation: eruxvjsThEy84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(68deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy84_ts {
    animation: eruxvjsThEy84_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy84_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy84 {
    animation: eruxvjsThEy84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy84_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy85_to {
    animation: eruxvjsThEy85_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy85_to__to {
    0% {
      transform: translate(291.798427px, 47.271297px);
    }
    58.75% {
      transform: translate(291.798427px, 47.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eruxvjsThEy85_tr {
    animation: eruxvjsThEy85_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy85_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy85_ts {
    animation: eruxvjsThEy85_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy85_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    68.75% {
      transform: scale(1, 1);
    }
    81.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy85 {
    animation: eruxvjsThEy85_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy85_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.9;
    }
    95% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy86_to {
    animation: eruxvjsThEy86_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy86_to__to {
    0% {
      transform: translate(291.798427px, 49.771297px);
    }
    58.75% {
      transform: translate(291.798427px, 49.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.228703px);
    }
    100% {
      transform: translate(398.699999px, -119.228703px);
    }
  }
  #eruxvjsThEy86_tr {
    animation: eruxvjsThEy86_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy86_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy86 {
    animation: eruxvjsThEy86_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy86_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy93 {
    animation-name: eruxvjsThEy93__m, eruxvjsThEy93_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy93__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955001,75.463017');
    }
    43.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    51.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eruxvjsThEy93_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy94_to {
    animation: eruxvjsThEy94_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy94_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    11.25% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eruxvjsThEy94_tr {
    animation: eruxvjsThEy94_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy94_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(75deg);
    }
    43.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy94_ts {
    animation: eruxvjsThEy94_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy94_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy94 {
    animation: eruxvjsThEy94_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy94_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.8;
    }
    56.25% {
      opacity: 0.8;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy95_to {
    animation: eruxvjsThEy95_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy95_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    26.25% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    28.75% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    41.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    42.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    43.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eruxvjsThEy95_tr {
    animation: eruxvjsThEy95_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy95_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy95_ts {
    animation: eruxvjsThEy95_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy95_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    27.5% {
      transform: scale(1, 1);
    }
    41.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy95 {
    animation: eruxvjsThEy95_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy95_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.9;
    }
    56.25% {
      opacity: 0.9;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy96_to {
    animation: eruxvjsThEy96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy96_to__to {
    0% {
      transform: translate(96.798427px, 142.271297px);
    }
    11.25% {
      transform: translate(96.798427px, 142.271297px);
    }
    17.5% {
      transform: translate(96.748252px, 116.509555px);
    }
    18.75% {
      transform: translate(96.738217px, 108.957207px);
    }
    21.25% {
      transform: translate(96.718147px, 82.185843px);
    }
    26.25% {
      transform: translate(96.678007px, 22.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    33.75% {
      transform: translate(174.541791px, -1.63208px);
    }
    41.25% {
      transform: translate(282.833827px, -26.93208px);
    }
    42.5% {
      transform: translate(287.711987px, -31.028703px);
    }
    43.75% {
      transform: translate(287.814424px, -38.440824px);
    }
    45% {
      transform: translate(287.91686px, -53.852945px);
    }
    47.5% {
      transform: translate(287.913114px, -81.677187px);
    }
    50% {
      transform: translate(287.909367px, -103.50143px);
    }
    56.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eruxvjsThEy96_tr {
    animation: eruxvjsThEy96_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy96 {
    animation: eruxvjsThEy96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy96_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy98 {
    animation-name: eruxvjsThEy98__m, eruxvjsThEy98_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eruxvjsThEy98__m {
    0% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,241.521298L32,240.521298L31.798427,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.678L33.14153,122.622403L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.73L222.955001,75.463017');
    }
    88.75% {
      d: path('M63.905583,112.728084L63.896417,114.135377L222.955,76.73L222.955001,64.605248');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eruxvjsThEy98_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy99_to {
    animation: eruxvjsThEy99_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy99_to__to {
    0% {
      transform: translate(96.798427px, 143.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 143.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 27.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(96.798427px, 19.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(109.798427px, 14px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(278.801035px, -25.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(285.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -33.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -116.228703px);
    }
    100% {
      transform: translate(287.9px, -116.228703px);
    }
  }
  #eruxvjsThEy99_tr {
    animation: eruxvjsThEy99_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy99_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(75deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy99_ts {
    animation: eruxvjsThEy99_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy99_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy99 {
    animation: eruxvjsThEy99_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy99_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.8;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy100_to {
    animation: eruxvjsThEy100_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy100_to__to {
    0% {
      transform: translate(96.798427px, 137.271297px);
    }
    57.5% {
      transform: translate(96.798427px, 137.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eruxvjsThEy100_tr {
    animation: eruxvjsThEy100_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy100_ts {
    animation: eruxvjsThEy100_ts__ts 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy100_ts__ts {
    0% {
      transform: scale(1, 1);
    }
    71.25% {
      transform: scale(1, 1);
    }
    86.25% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(0.8, 0.8);
    }
  }
  #eruxvjsThEy100 {
    animation: eruxvjsThEy100_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy100_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.9;
    }
    96.25% {
      opacity: 0.9;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eruxvjsThEy101_to {
    animation: eruxvjsThEy101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy101_to__to {
    0% {
      transform: translate(96.798427px, 139.771297px);
    }
    57.5% {
      transform: translate(96.798427px, 139.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eruxvjsThEy101_tr {
    animation: eruxvjsThEy101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eruxvjsThEy101 {
    animation: eruxvjsThEy101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eruxvjsThEy101_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg6;
